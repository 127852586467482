import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { MerchHierarchyLineage, IHierarchyMapParams } from '../../model/state/model';
import * as _ from 'lodash';
import { currencyFormatter } from '../../../infra/datagrid/datagrid.formatters';
import { ConfigurationSettingsService } from '../../../user';
import { MinPriceCellEditorComponent } from './min-price-cell-editor/min-price-cell-editor.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-department-minimum-price-assignment',
  templateUrl: './department-minimum-price-assignment.component.html',
  styleUrls: ['./department-minimum-price-assignment.component.scss'],
})
export class DepartmentMinimumPriceAssignmentComponent implements OnInit {
  @Input()
  merchHierarchy: MerchHierarchyLineage[];

  @Output()
  private hierarchyMapped: EventEmitter<any> = new EventEmitter();

  rowUpdatedSubject = new Subject();

  gridOptions: GridOptions = {
    context: {
      parentComponent: this,
    },
    deltaRowDataMode: true,
    getRowNodeId: (data) => data.id,
    onRowDataUpdated: (e) => {
      this.rowUpdatedSubject.next({ x: true });
      this.showLoadingOverlay = false;
      e.api.sizeColumnsToFit();
    },
    onGridReady: (e) => {
      e.api.sizeColumnsToFit();
    },
    autoGroupColumnDef: {
      headerName: 'Group',
      showRowGroup: true,
      cellRendererParams: {
        suppressCount: true,
        suppressDoubleClickExpand: true,
      },
    },
  };

  columns: ColDef[] = [
    { field: 'h1Desc', headerName: 'h1Desc', rowGroup: true, hide: true },
    { field: 'h2Desc', headerName: 'h2Desc', rowGroup: true, hide: true },
    {
      headerName: 'Minimum Price',
      field: 'minPrice',
      valueFormatter: currencyFormatter(
        this.configurationSettingsService.currency
      ),
      suppressMenu: true,
      sortable: false,
      editable: true,
      minWidth: 150,
      maxWidth: 150,
      cellRendererFramework: MinPriceCellEditorComponent,
    },
  ];

  constructor(
    private configurationSettingsService: ConfigurationSettingsService
  ) {}
  showLoadingOverlay;

  ngOnInit() {
  }
  mapHierarchy(e: IHierarchyMapParams) {
    this.hierarchyMapped.emit(e);
    this.showLoadingOverlay = true;
  }
}
