import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import { percentDecimalFormatter } from '../../../infra/datagrid/datagrid.formatters';

@Injectable({
  providedIn: 'root'
})
export class ReportsExportService {

  constructor() { }

  exportExcel(exportData) {

    const AWSheet = this.getCollectionData(exportData.AWCollections, exportData.AWCollectionRows);
    const SSSheet = this.getCollectionData(exportData.SSCollections, exportData.SSCollectionRows);
    const DiscountSheet = this.getDiscountData(exportData.skuTypes, exportData.reportsData);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, AWSheet, 'AW');
    XLSX.utils.book_append_sheet(wb, SSSheet, 'SS');
    XLSX.utils.book_append_sheet(wb, DiscountSheet, 'Discounts');
    XLSX.writeFile(wb, 'Reports.xlsx');

  }

  getCollectionData(collections, rows) {
    let topHeader = ['', ''];
    _(collections).map((collection) => {
      topHeader = topHeader.concat(collection, '', '', '', '', '');
    }).value();
    const collectionHeadings = [];
    _(collections).map((collection) =>
      collectionHeadings.push('Stock MD', 'Stock Total', '% Stock on MD', 'MD Depth %', 'Depth on all Disc Stock %',
        'Total Depth %')).value();
    const subHeader = ['Division', 'Department'].concat(collectionHeadings);
    const headers = [
      topHeader,
      subHeader
    ];
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(headers);
    if (!ws['!merges']) {
      ws['!merges'] = [];
    }

    _(collections).forEach((collection, i) => {
      const start = (3 * 2 * i) + 2;
      const end = start + 5;
      const merge = { s: { r: 0, c: start }, e: { r: 0, c: end } };
      ws['!merges'].push(merge);
    });

    XLSX.utils.sheet_add_json(ws, rows, {
      skipHeader: true,
      origin: 'A3'
    });
   return ws;
  }


  getDiscountData(skuTypes, discountReportRows) {
    const discountSubHeadings = [];
    const discountTopHeading = [];

    _(skuTypes).forEach((type) => {
      discountTopHeading.push(type, '', '', '', '', '');
      discountSubHeadings.push('Discount', 'Number of Skus', `% of ${type} SKUs`, 'Store Stock Units',
        '% of Store Stock', '');
    });
    const discountHeadings = [
      discountTopHeading,
      discountSubHeadings
    ];
    const wsDiscount: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(discountHeadings);
    if (!wsDiscount['!merges']) {
      wsDiscount['!merges'] = [];
    }
    _(skuTypes).forEach((type, i) => {
      const start = (6 * i);
      const end = start + 4;
      const merge = { s: { r: 0, c: start }, e: { r: 0, c: end } };
      wsDiscount['!merges'].push(merge);
    });
    _(skuTypes).forEach((type, index) => {
      const dataToAdd = _(discountReportRows).filter(rows => rows.skuType === type).map(row => {
        const discount_bin = row.discount_bin;
        const pctType = `% of ${type} SKUs`;
        return _(row.data).map(r => {
          return {
            'Discount': discount_bin, 'Number of Skus': r.sku_count, pctType: percentDecimalFormatter({ value: r.skuPct }),
            'Store Stock Units': r.store_stock_units, '% of Store Stock': percentDecimalFormatter({ value: r.storeStockPct })
          };
        }).value();
      }).flatten().value();
      const start = (index === 0) ? 0 : (index * 5) + index;
      XLSX.utils.sheet_add_json(wsDiscount, dataToAdd, {
        skipHeader: true,
        origin: { r: 2, c: start }
      });
      const values = _.first(_(discountReportRows).filter(val => val.skuType === type).value());
      const pctOfAllStock = _.sum(values.data.map(d => d.allStockPct));
      const fullAverageDiscount = _.sum(values.data.map(d => d.fullAverageDiscount));
      const currentAverageDiscount = _.sum(values.data.map(d => d.currentAverageDiscount));
      const totalSku = _.sum(_(discountReportRows).filter((val) => val.skuType === type).map(v => v.data).flatten().value()
        .map((d: any) => d.sku_count));
      const totalStockUnits = _.sum(_(discountReportRows).filter((val) => val.skuType === type).map(v => v.data).flatten().value().
        map((d: any) => d.store_stock_units));
      const totalRows = [['Total', totalSku, '', totalStockUnits], ['% of All Store Stock', percentDecimalFormatter({ value: pctOfAllStock })],
      ['Avg Discount From Full', percentDecimalFormatter({ value: fullAverageDiscount })], ['Avg Discount From Current', percentDecimalFormatter({ value: currentAverageDiscount })]]
      XLSX.utils.sheet_add_aoa(wsDiscount, totalRows, { origin: { r: 12, c: start } });
    });
    return wsDiscount;
  }
}
