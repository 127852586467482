import { Pipe, PipeTransform } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { filter, first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { getPriceLadders } from '../settings/price-ladders/state/price-ladders.selectors';
import { getDiscountLadders } from '../settings/discount-ladders/state/discount-ladders.selectors';
import { getMerchHierarchy } from '../../user/user.reducer';

@Pipe({
  name: 'merchHierarchy',
})
export class MerchHierarchyPipe implements PipeTransform {
  merchHierarchy;
  priceLadders: any;
  discountLadders: any;
  priceLaddersOutput$: Observable<any>;
  constructor(private store: Store<any>) {
    this.store
      .pipe(
        select(getMerchHierarchy),
        filter(d => !!d),
        first()
      )
      .subscribe(merchHierarchy => {
        this.merchHierarchy = merchHierarchy;
      });

    this.store
      .pipe(
        select(getPriceLadders),
        filter(d => !!d),
        first()
      )
      .subscribe(priceLadders => {
        this.priceLadders = priceLadders;
      });

    this.store
      .pipe(
        select(getDiscountLadders),
        filter(d => !!d),
        first()
      )
      .subscribe(discountLadders => {
        this.discountLadders = discountLadders;
      });
  }

  transform(value: any, args?: any): any {
    const field = args || 'ovrPrice';
    const merchHierarchyLevel = _.find(this.merchHierarchy, d => d.h3Desc === value.data.categoryDesc);
    if (merchHierarchyLevel) {
      const priceLadder = _.find(this.priceLadders, d => +d.id === +merchHierarchyLevel.priceLadderId);
      const onLadder = priceLadder && priceLadder.prices.indexOf(String(Number(value.data[field]))) !== -1;
      return {
        onLadder,
      };
    }
  }

  priceLadderNextValue(categoryDesc, price): any {
    const merchHierarchyLevel = _.find(this.merchHierarchy, d => d.h3Desc === categoryDesc);
    if (merchHierarchyLevel) {
      const priceLadder = _.find(this.priceLadders, d => +d.id === +merchHierarchyLevel.priceLadderId);
      if (priceLadder) {
        const priceLadderPoint = _.sortedIndex(priceLadder.prices, price);
        return priceLadder.prices[priceLadderPoint];
      } else {
        return price;
      }
    }
  }
}
