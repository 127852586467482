import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter
} from '@angular/core';

import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { IEvent } from '../../abstracts/event.interface';
import { isCurrentOrgMarkdownAndPromos } from '../../user/user.reducer';

type EventType = 'PromoEvent' | 'MarkdownEvent';

type EventTypeToggle = EventType & 'all';

@Component({
  selector: 'app-events-calendar',
  templateUrl: './events-calendar.component.html',
  styleUrls: ['./events-calendar.component.scss']
})
export class EventsCalendarComponent implements OnInit, OnChanges {
  eventForSummary: IEvent;
  showEventSummaryDialog = false;
  showCreateEventDialog = false;
  createEventDialogX: number;
  createEventDialogY: number;
  calendarEvents: any[];
  filter: EventType = null;

  @Input()
  events: IEvent[];

  @Output()
  rangeSelected = new EventEmitter();

  @Output()
  eventSelected = new EventEmitter();

  @Output()
  eventHover = new EventEmitter();
  isCurrentOrgMarkdownAndPromos$: Observable<boolean>;

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit() {
    this.isCurrentOrgMarkdownAndPromos$ = this.store.pipe(select(isCurrentOrgMarkdownAndPromos));
  }

  ngOnChanges(changes) {
    if (!changes.events) {
      return;
    }

    this.calendarEvents = this.formatEventsForCalendar(changes.events.currentValue);
  }

  private formatEventsForCalendar(events: any[]): any[] {
    return events.map((event) => {
      let color;
      const isPast = +moment() > +`${+moment(event.endDate)}`;
      /// this should be done with a class
      const isMarkdown = event.eventType === 'Markdown';
      if (isPast) {
        if (isMarkdown) {
          color = 'rgb(133,161,218)';
        } else if (!isMarkdown) {
          color = 'rgb(139,226,219)';
        }
      } else if (!isPast) {
        if (isMarkdown) {
          color = 'rgb(52,98,193)';
        } else if (!isMarkdown) {
          color = 'rgb(61,206,195)';
        }
      }
      return {
        // start: startOfDay(new Date(+`${event.start_date}000`)),
        start: startOfDay(moment(event.startDate).toDate()),
        end: addDays(endOfDay(new Date(event.endDate)), 1), // SBX-538
        title: event.name,
        allDay: true,
        resizable: {
          beforeStart: true,
          afterEnd: true
        },
        draggable: true,
        data: { ...event },
        color,
        classNames: [`event-${event.id}`, event.eventType.toLowerCase()],
      };
    });
  }

  toggleFilter(eventTypeToggle: EventTypeToggle) {
    let eventsToFormat: IEvent[];
    if (eventTypeToggle === 'all') {
      eventsToFormat = this.events;
      this.filter = null;
    } else {
      eventsToFormat = this.events.filter((event) => event.eventType.toLowerCase() === eventTypeToggle);
      this.filter = eventTypeToggle;
    }
    this.calendarEvents = this.formatEventsForCalendar(eventsToFormat);
  }

  onEventClick(calendarEvent: IEvent) {
    this.eventSelected.emit(calendarEvent);
  }

  onCloseCreateEventDialog(closeDialog: boolean) {
    if (closeDialog) {
      this.showCreateEventDialog = false;
    }
  }

  onDateClick(event) {
    this.rangeSelected.emit(event);
  }

  onEventMouseEnter(event) {
    this.eventHover.emit(event);
  }
}
