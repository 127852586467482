import {
  Component,
  OnInit,
  Input,
} from '@angular/core';

import { SideBarDef, GridOptions } from 'ag-grid-community';

import {
  dateFormatter,
} from '../../infra/datagrid/datagrid.formatters';
import { EventsGridNavCellComponent } from './events-grid-nav-cell/events-grid-nav-cell.component';
import { IEvent } from '../../abstracts/event.interface';
import { EventModelStatus } from '../../abstracts/event-status.type';
import { Subject } from 'rxjs';

import * as _ from 'lodash';
import { LinesExportService } from '../model/lines-export.service';

export const statusRenderer = (params) => {
  if (!params.value && !params.valueFormatted) {
    return '';
  }
  if (params.value === 'Closed') {
    return `<span class='in-grid'>${params.value}</span>`;
  }
  let val = params.value;
  if (!isNaN(+params.value)) {
    val = EventModelStatus[params.value];
  }

  const cssClassMap = {
    approved: 'light-blue no-select',
    submitted: 'pink no-select',
    live: 'light-green no-select',
    complete: 'forest-green no-select',
    planning: 'white with-border no-select',
    closed: 'dark-grey no-select',
  };

  const cssClass = cssClassMap[val.toLowerCase()];

  return `<span class='button button--x2 button--${cssClass} button--in-grid'>${val}</span>`;
};

export const eventTypeRenderer = (params) => {
  if (!params.value && !params.valueFormatted) {
    return '';
  }
  const val = params.valueFormatted || params.value;
  if (params.valueFormatted === '') {
    return '';
  }

  const cssClassMap = {
    promoevent: 'aqua',
    markdownevent: 'purple',
    promomodel: 'aqua',
    markdownmodel: 'purple',
    promo: 'aqua',
    markdown: 'purple',
  };

  const nameMap = {
    promoevent: 'Promo',
    markdownevent: 'Markdown',
    promomodel: 'Promo',
    markdownmodel: 'Markdown',
    promo: 'Promo',
    markdown: 'Markdown',
  };

  const cssClass = cssClassMap[val.toLowerCase()];
  const name = nameMap[val.toLowerCase()];

  return `<span class='button button--x2 button--${cssClass} no-select button--in-grid'>${name}</span>`;
};

export const getRowClassClosedEvent = (params) => {
  if (!params || !params.data || params.data.status !== 'Closed') {
    return;
  }

  return 'closed';
};

export const getContextMenuItems = params => {
  const menu = [
    'copy',
    'copyWithHeaders',
    'paste',
    'separator',
    {
      name: 'Export',
      subMenu: [
        {
          name: 'Excel Export (.xlsx)',
          action: function () {
            params.api.exportDataAsExcel({ sheetName: 'Sparkbox Export' });
          },
        }
      ],
      icon: `<span class ='ag-icon ag-icon-save'></span>`,
    }
  ];
  return menu;
};

@Component({
  selector: 'app-events-grid',
  templateUrl: './events-grid.component.html',
  styleUrls: ['./events-grid.component.scss']
})
export class EventsGridComponent implements OnInit {

  @Input()
  events: any[];
  api: any;
  filterValueChanged:boolean;

  gridOptions: GridOptions = {
    getRowClass: getRowClassClosedEvent,
    deltaRowDataMode: true,
    getRowNodeId: (data) => data.id,
    onRowDataUpdated: (e) => {
      e.api.sizeColumnsToFit();
    },
    onGridReady: (e) => {
      this.api = e.api;
      const unselectStatus = ['Complete', 'Closed'];  // SBX-782 Default filter for status remove - Complete, Closed
      e.api.sizeColumnsToFit();
      const statusFilterComponent = this.api.getFilterInstance('status');
      statusFilterComponent.resetFilterValues();
      unselectStatus.forEach((status) => statusFilterComponent.unselectValue(status));
      statusFilterComponent.applyModel();
      this.api.onFilterChanged();
    },
    getContextMenuItems: getContextMenuItems,
  };

  columns = [
    { headerName: 'ID', field: 'id', minWidth: 55, maxWidth: 55 },
    { headerName: 'EVENT TYPE', field: 'eventType', cellRenderer: eventTypeRenderer, minWidth: 119, maxWidth: 119 },
    { headerName: 'EVENT DETAIL', field: 'eventDetail' },
    { headerName: 'EVENT NAME', field: 'name' },
    { headerName: 'LAST EDITED', field: 'lastEdited', valueFormatter: dateFormatter('DD/MM/YYYY k:mm') },
    { headerName: 'LAST EDITED BY', field: 'lastEditUser' },
    { headerName: 'START DATE', field: 'startDate', valueFormatter: dateFormatter('DD/MM/YYYY'), filter: false },
    { headerName: 'END DATE', field: 'endDate', valueFormatter: dateFormatter('DD/MM/YYYY'), filter: false },
    { headerName: 'VARIANTS', field: 'variants' },
    { headerName: 'MODELS PUBLISHED', field: 'modelsPublished', hide: true },
    { headerName: 'STATUS', field: 'status', cellRenderer: statusRenderer, minWidth: 119, maxWidth: 119 },
    { headerName: 'OWNER', field: 'owner', hide: true },
    { headerName: 'NOTE', field: 'note' },
    {
      headerName: '',
      cellRendererFramework: EventsGridNavCellComponent,
      suppressMenu: true,
      sortable: false,
      minWidth: 106,
      maxWidth: 106,
      cellStyle: {'overflow': 'visible !important'}
    },
  ];

  showEventSummaryDialog = false;
  eventForSummary: IEvent;
  quickFilterText: string;
  filters$: Subject<{ condition: any, field: string }> = new Subject();

  sideBar: SideBarDef = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressSideButtons: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true
        }
      },
    ],
  };

  constructor(
    private linesExportService: LinesExportService,
  ) { }

  ngOnInit() {
  }

  onRowClick(event) {
    this.showEventSummaryDialog = false;
    setTimeout(() => this.showEventSummaryDialog = true, 0);
    this.eventForSummary = event.data;
  }

  onCloseEventSummaryDialog() {
    this.showEventSummaryDialog = false;
  }

  filterChanged(e){
    this.filterValueChanged = e;
  }
}
