import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { filter, tap, first, map } from 'rxjs/operators';

import * as _ from 'lodash';
import * as xlsx from 'xlsx';

import { MerchHierarchyPipe } from './merch-hierarchy.pipe';
import { ModelFacadeService } from './model-facade.service';
import { Model } from './state/model';
import { getRelatedModelsStatus2 } from './state/model.selectors';

interface IExportLine {
  skuId;
  finalPrice;
  region;
  tarifNodhos?;
  webCode?;
}

@Injectable({
  providedIn: 'root',
})
export class LinesExportService {
  constructor(private store: Store<any>, private merchHierarchyPipe: MerchHierarchyPipe, private modelFacadeService: ModelFacadeService) {}

  exportLinesByEvent(eventID, format, point) {
    const modelsByEventID$ = this.modelFacadeService.get({ eventId: eventID });
    modelsByEventID$
      .pipe(
        first(),
        map(models => models.filter(model => !model.lines))
      )
      .subscribe((models: Model[]) => this.modelFacadeService.getLines({ modelIDs: models.map(({ id }) => id) }));

    modelsByEventID$
      .pipe(
        filter((models: Model[]) => models.map(model => !!model.lines).indexOf(false) === -1),
        first(),
        map((models: Model[]) =>
          _(models)
            .filter(d => String(d.status) === '2' || d.status === 'Approved')
            .map(model =>
              model.lines
                .filter(
                  d => d.taken === 'Yes' && ((d.ovrPrice && d.ovrPrice !== (d.lastWavePrice ? d.lastWavePrice : d.currentPrice))
                    || (!d.ovrPrice && d.optPrice !== (d.lastWavePrice ? d.lastWavePrice : d.currentPrice)))
                )
                .map(line => ({
                  ...line,
                  numOp: '',
                  tarifNodhos: model.regionCode,
                  naturePrix: model.eventName,
                  refId: line.skuId.substring(0, 6),
                  colourNodhos: line.skuId.substring(6, 12),
                  colourCM: '',
                  finalPrice: line.finalPrice,
                  pct: (model.region === 'IT') || (point === 'discount') ?
                  Math.floor(Number((1 - line.finalPrice / (line.currentPriceEvent)).toFixed(3)) * 100) : '',
                  tauxPct: Math.floor(Number((1 - line.finalPrice / (line.currentPriceEvent)).toFixed(3)) * 20) * 5,
                  wave: model.type,
                  region: model.region,
                  gamme: '',
                  webCode: model.regionWebCode,
                  updatedFinalPrice: model.region === 'ES' ? this.updateFinalPrice(line.finalPrice, line.unitCost, line.categoryDesc) : 0,
                }))
            )
            .flatten()
            .value()
        )
      )
      .subscribe((lines: IExportLine[]) => {
        _(lines).forEach(line => {
          if (line.tarifNodhos) {
            lines.push({ ...line, tarifNodhos: line.webCode });
          }
        });
        if (format === 'csv') {
          this.exportCsv(lines);
        } else if (format === 'txt') {
          this.exportTxt(lines);
        } else if (format === 'xlsx') {
          this.exportXlsx(lines);
        }
      });
  }

  exportLinesByModelGroup(modelID, format) {
    this.store
      .pipe(select(getRelatedModelsStatus2(modelID)), first())
      .subscribe((models: Model[]) =>
        this.modelFacadeService.getLines({ modelIDs: models.filter(model => !model.lines).map(({ id }) => id) })
      );

    this.store
      .pipe(
        select(getRelatedModelsStatus2(modelID)),
        first(),
        filter((models: Model[]) => models.map(model => !!model.lines).indexOf(false) === -1),
        map((models: Model[]) =>
          _(models)
            .map(model =>
              model.lines.map(line => ({ skuId: line.skuId.substring(0, 6), finalPrice: line.finalPrice, region: model.region }))
            )
            .flatten()
            .value()
        )
      )
      .subscribe((lines: IExportLine[]) => {
        if (format === 'csv') {
          this.exportCsv(lines);
        } else if (format === 'txt') {
          this.exportTxt(lines);
        } else if (format === 'xlsx') {
          this.exportXlsx(lines);
        }
      });
  }

  exportLinesBySingleModel(modelID, format) {
    this.modelFacadeService
      .get({ id: modelID })
      .pipe(
        map(models => _.first(models)),
        tap(model => {
          if (!model.lines) {
            this.modelFacadeService.getLines({ modelIDs: [model.id] });
            // this.store.dispatch(new ModelsGetLinesAction({ modelID: model.id }));
          }
        }),
        filter(model => !!model.lines),
        first(),
        map(model => model.lines.map(line => ({ skuId: line.skuId.substring(0, 6), finalPrice: line.finalPrice, region: model.region })))
      )
      .subscribe((lines: IExportLine[]) => {
        if (format === 'csv') {
          this.exportCsv(lines);
        } else if (format === 'txt') {
          this.exportTxt(lines);
        } else if (format === 'xlsx') {
          this.exportXlsx(lines);
        }
      });
  }

  exportCsv(rows) {
    let newRows = rows.map(({ numOp, tarifNodhos, naturePrix, refId, colourNodhos, colourCM, finalPrice, pct }) => [
      numOp,
      tarifNodhos,
      naturePrix,
      refId,
      colourNodhos,
      colourCM,
      finalPrice,
      pct,
    ]);
    newRows = [['NUM_OP', 'TARIF_NODHOS', 'NATURE_PRIX', 'REFERENCE', 'COLORIS_NODHOS', 'COLORIS_CM400', 'PRIX_OP', 'POURCENTAGE']].concat(
      newRows
    );
    const csvContent = 'data:text/csv;charset=utf-8,' + newRows.map(e => e.join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'prices.csv');
    document.body.appendChild(link);

    link.click();
  }

  exportTxt(rows) {
    let newRows = rows.map(({ numOp, tarifNodhos, naturePrix, refId, colourNodhos, colourCM, finalPrice, pct }) => [
      numOp,
      tarifNodhos,
      naturePrix,
      refId,
      colourNodhos,
      colourCM,
      finalPrice,
      pct,
    ]);
    newRows = [['NUM_OP', 'TARIF_NODHOS', 'NATURE_PRIX', 'REFERENCE', 'COLORIS_NODHOS', 'COLORIS_CM400', 'PRIX_OP', 'POURCENTAGE']].concat(
      newRows
    );
    const textContent = newRows.map(e => e.join(' ')).join('\n');
    // @ts-ignore
    const blob = new Blob([textContent], { type: 'text/plain', endings: 'native' }) as any;
    const url = window.URL.createObjectURL(blob);
    // const encodedUri = encodeURI(textContent);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'prices.txt');
    document.body.appendChild(link);

    link.click();
  }

  exportXlsx(rows) {
    let newRowsPrices = rows.map(({ numOp, tarifNodhos, naturePrix, refId, colourNodhos, colourCM, finalPrice, pct }) => [
      numOp,
      tarifNodhos,
      naturePrix,
      refId,
      colourNodhos,
      colourCM,
      finalPrice,
      pct,
    ]);
    let newRowsListing = _.uniqBy(rows, (v: any) =>
      [v.skuId, v.region].join()
    ).map(
      ({
        refId,
        colourNodhos,
        skuId,
        departmentDesc,
        skuDesc,
        categoryDesc,
        colourDesc,
        collectionDesc,
        gamme,
        stockOnHand,
        finalPrice,
        pct,
        tauxPct,
        wave,
        region,
      }) => [
        refId,
        colourNodhos,
        skuId,
        departmentDesc,
        skuDesc,
        categoryDesc,
        colourDesc,
        collectionDesc,
        gamme,
        stockOnHand,
        finalPrice,
        pct,
        tauxPct,
        wave,
        region,
      ]
    );
    newRowsPrices = [
      ['NUM_OP', 'TARIF_NODHOS', 'NATURE_PRIX', 'REFERENCE', 'COLORIS_NODHOS', 'COLORIS_CM400', 'PRIX_OP', 'POURCENTAGE'],
    ].concat(newRowsPrices);
    newRowsListing = [
      [
        'Ref',
        'Col',
        'Sku',
        'Category',
        'Name',
        'Under Cat.',
        'Color',
        'Collection',
        'Gamme',
        'Stock in Store',
        'Sales Price',
        'MD',
        'Taux Web',
        'Wave',
        'Region',
      ],
    ].concat(newRowsListing);
    let catalunyaPrices = rows
      .filter(row => row.region === 'ES' && row.tarifNodhos === '3')
      .map(({ numOp, tarifNodhos, naturePrix, refId, colourNodhos, colourCM, updatedFinalPrice, pct }) => [
        numOp,
        tarifNodhos,
        naturePrix,
        refId,
        colourNodhos,
        colourCM,
        updatedFinalPrice,
        pct,
      ]);
    catalunyaPrices = [
      ['NUM_OP', 'TARIF_NODHOS', 'NATURE_PRIX', 'REFERENCE', 'COLORIS_NODHOS', 'COLORIS_CM400', 'PRIX_OP', 'POURCENTAGE'],
    ].concat(catalunyaPrices);
    const wsPrices = xlsx.utils.aoa_to_sheet(newRowsPrices);
    const wsListing = xlsx.utils.aoa_to_sheet(newRowsListing);
    const wsESCatalunya = xlsx.utils.aoa_to_sheet(catalunyaPrices);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, wsPrices, 'prices');
    xlsx.utils.book_append_sheet(wb, wsListing, 'listing');
    if (catalunyaPrices.length > 1) {
      xlsx.utils.book_append_sheet(wb, wsESCatalunya, 'catalogne');
    }
    xlsx.writeFile(wb, 'prices.xlsx');
  }

  updateFinalPrice(finalPrice, unitCost, categoryDesc) {
    return finalPrice < unitCost ? this.merchHierarchyPipe.priceLadderNextValue(categoryDesc, unitCost) : finalPrice;
  }
}
