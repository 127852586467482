import { SideBarDef, GridOptions, ColDef } from 'ag-grid-community';

import * as _ from 'lodash';

import {
  currencyFormatter,
  percentDecimalFormatter,
  thousandsRounded,
  roundingFormatter,
  dateFormatter,
} from '../../../../infra/datagrid/datagrid.formatters';
import { ModelDetailTakenCellComponent } from '../model-detail-taken-cell/model-detail-taken-cell.component';
import { ModelDetailGridCellEditorComponent } from '../model-detail-grid-cell-editor/model-detail-grid-cell-editor.component';
import { RegionPipe } from '../../../../user/region.pipe';
import { MerchHierarchyPipe } from '../../merch-hierarchy.pipe';

import data from '../../../../../assets/json_data/default-data.json';
import orgData from '../../../../../assets/json_data/org-data.json';
import { GridLayoutsToolPanelContainerComponent } from '../../../../infra/grid-layouts-tool-panel-container/grid-layouts-tool-panel-container.component';
import { ModelDetailTakeallCellComponent } from '../model-detail-takeall-cell/model-detail-takeall-cell.component';
import { ModelDetailNoteCellEditorComponent } from '../model-detail-note-cell-editor/model-detail-note-cell-editor.component';
import { ModelDetailStatusCustomFilterComponent } from '../model-detail-status-custom-filter/model-detail-status-custom-filter.component';
import { Model } from '../../state/model';
import { lowerWaveWithStatusLessThanApproved } from '../../utilities/lowerWaveWithStatusLessThanApproved';

function finalDiscountCurrentPrice(params) {
  if (params && params.data) {
    const finalCurrentDisc = 1 - params.data.finalPrice / params.data.currentPrice;
    return finalCurrentDisc;
  }
  return;
}

function finalDiscountCurrentEventPrice(params) {
  if (params && params.data) {
    const finalCurrentEventDisc = 1 - params.data.finalPrice / params.data.currentPriceEvent;
    return finalCurrentEventDisc;
  }
  return;
}

function finalDiscountLastWavePrice(params) {
  if (params && params.data) {
    if (!params.data.lastWavePrice) {
      return '';
    } else {
      const finalLastWaveDisc = 1 - params.data.finalPrice / params.data.lastWavePrice;
      return finalLastWaveDisc;
    }
  }
  return;
}

export const sideBar: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressSideButtons: true,
        suppressColumnFilter: true,
        suppressColumnSelectAll: true,
        suppressColumnExpandAll: true,
      },
    },
    {
      id: 'layouts',
      labelDefault: 'Layouts',
      labelKey: 'layouts',
      iconKey: 'columns',
      toolPanel: 'customLayoutsToolPanel',
    },
    // {
    //   id: 'filters',
    //   labelDefault: 'Filters',
    //   labelKey: 'filters',
    //   iconKey: 'filters',
    //   toolPanel: 'agFiltersToolPanel',
    // },
  ],
};

const checkOverViewPriceChange = params => {
  const region = _.first(_.split(params.colDef.field, `_`));

  if (params.data[region + '_priceChange'] === false) {
    return false;
  }

  return true;
};

const showOverviewWarningTip = params => {
  const priceChange = checkOverViewPriceChange(params);

  if (priceChange === false) {
    return 'No price change recommended';
  }
};

const showOverViewWarningCell = params => {
  const classes = [];
  const priceChange = checkOverViewPriceChange(params);

  if (priceChange === false) {
    classes.push('global-warning-icon');
  } else {
    classes.push(' ');
  }

  return classes;
};

const getHeaderName = (headerName: string) => {
  if (orgData[0].hasOwnProperty(headerName)) {
    return orgData[0][headerName];
  } else {
    return data[0][headerName];
  }
};

const checkPriceChange = params => {
  const field = params.colDef.field;
  let priceChange;

  if (params.data && params.data.taken === 'Yes') {
    if (
      params.data.ovrPrice &&
      params.data.ovrPrice === (params.data.lastWavePrice ? params.data.lastWavePrice : params.data.currentPrice)
    ) {
      priceChange = false;
    } else if (
      !params.data.ovrPrice &&
      params.data.optPrice === (params.data.lastWavePrice ? params.data.lastWavePrice : params.data.currentPrice)
    ) {
      priceChange = false;
    } else {
      priceChange = true;
    }
  }
  return priceChange;
};

const statusRenderer = params => {
  let stStatus;

  if (params.data && params.data.fcstEndSt && params.data.model.variant) {
    const targetST = _(params.data.model.variant).find(({ id }) => +id === params.data.model.variantId);

    if (params.data.fcstEndSt > targetST.optimisationTarget || targetST.optimisationTarget - params.data.fcstEndSt < 0.005) {
      stStatus = 'Reached sell-through target';
    } else {
      if (targetST.optimisationTarget - params.data.fcstEndSt > 0.1) {
        stStatus = 'Less than sell-through target';
      } else {
        stStatus = 'Sell-through target within 10%';
      }
    }
    return stStatus;
  }
};

const statusCellRenderer = params => {
  if (params.data && params.data.fcstEndSt && params.data.model.variant) {
    const targetST = _(params.data.model.variant).find(({ id }) => +id === params.data.model.variantId);
    const cssClass = [];
    if (params.data.fcstEndSt > targetST.optimisationTarget || targetST.optimisationTarget - params.data.fcstEndSt < 0.005) {
      cssClass.push('green');
    } else {
      if (targetST.optimisationTarget - params.data.fcstEndSt > 0.1) {
        cssClass.push('red');
      } else {
        cssClass.push('yellow');
      }
    }
    return `<span class ='circle status-icon-${cssClass}' style='width:10% height:10%'></span>`;
  }
};

export const getContextMenuItems = params => {
  const menu = [
    'copy',
    'copyWithHeaders',
    'paste',
    'separator',
    {
      name: 'Export',
      subMenu: [
        {
          name: 'Excel Export (.xlsx)',
          action() {
            params.api.exportDataAsExcel({ sheetName: 'Sparkbox Export' });
          },
        },
      ],
      icon: `<span class ='ag-icon ag-icon-save'></span>`,
    },
  ];
  return menu;
};

export const columns = (merchHierarchyPipe: MerchHierarchyPipe, currency) => [
  {
    headerName: 'PRODUCT DETAILS',
    children: [
      {
        headerName: 'NOTE',
        field: 'note',
        editable: true,
        colId: 'note',
        cellEditor: 'modelDetailNoteCellEditorComponent',
        suppressMenu: false,
        sortable: false,
        suppressSizeToFit: true,
        resizable: true,
      },
      {
        headerName: 'LOADING ICON',
        field: 'loadingIcon',
        colId: 'loadingIcon',
        headerTooltip: `Optimisation running`,
        lockPosition: true,
        lockVisible: true,
        valueGetter: params => {
          if (params.data && params.data.model) {
            return params.data.model.status !== 'Planning';
          }
        },
        cellRenderer: params => {
          if (params.data && params.data.model.status !== 'Planning') {
            return `<span class="icon-lock" title="Retract the model to make changes to this line in wave ${
              params.data.model.wave
            }" style="width: 5px;display: inline-block;"></span>`;
          } else if (
            params.data &&
            params.data.model &&
            params.data.model.status === 'Planning' &&
            lowerWaveWithStatusLessThanApproved(params.data.model.relatedModels, params.data.model)
          ) {
            return `<span class="icon-lock" title="Approve all previous waves to make changes to this line in wave ${
              params.data.model.wave
            }" style="width: 5px;display: inline-block;"></span>`;
          }

          return;
        },
        pinned: 'left',
        width: 30,
        cellRendererParams: {
          editted: false,
          skuChanged: '',
        },
        cellClassRules: {
          'wave-locked': params => {
            if (params.data) {
              return params.data.model.status !== 'Planning';
            }
          },
        },
        filterParams: {
          cellRenderer:  params => {
            if (params.value && params.value === 'true') {
              return `<span class="icon-lock" style="width: 5px;display: inline-block;"></span>`;
            }
          }
        }
      },
      {
        headerName: 'STATUS',
        field: 'status',
        colId: 'status',
        lockPosition: true,
        lockVisible: true,
        headerTooltip: `Green - Will reach ST target, Yellow - will reach within 10% of target ST, Red -
        will reach more than 10% of target ST`,
        valueGetter: statusRenderer,
        cellRenderer: statusCellRenderer,
        tooltipValueGetter: statusRenderer,
        pinned: 'left',
        width: 40,
        filterParams: {
          cellRenderer: 'modelDetailStatusCustomFilterComponent',
        },
      },
      { headerName: 'SKU ID', field: 'skuId', pinned: 'left', colId: 'skuId', cellClass: 'textFormat',
      headerTooltip: `Unique SKU ID number` },
      { headerName: 'SKU DESCRIPTION', field: 'skuDesc', pinned: 'left', colId: 'skuDesc', headerTooltip: `Product name (Modèle)` },
      { headerName: 'COLOUR', field: 'colourDesc', hide: true, colId: 'colourDesc' },
      { headerName: getHeaderName('DEPARTMENT'), field: 'departmentDesc', hide: true, colId: 'departmentDesc', enableRowGroup: true, 
      headerTooltip: `Famille/ Department` },
      { headerName: 'CATEGORY', field: 'categoryDesc', colId: 'categoryDesc', hide: true, enableRowGroup: true, headerTooltip: `Category` },
      { headerName: 'GAMME', field: 'salesGammeId', colId: 'salesGammeId', hide: true, enableRowGroup: true, headerTooltip: `Gamme Id` },
      {
        headerName: 'TAKEN',
        field: 'taken',
        headerComponentFramework: ModelDetailTakeallCellComponent,
        cellRendererFramework: ModelDetailTakenCellComponent,
        colId: 'taken',
        headerTooltip: `Products that will be included in the event`
      },
      {
        headerName: 'UNIT COST',
        field: 'unitCost',
        valueFormatter: currencyFormatter(currency),
        hide: true,
        colId: 'unitCost',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
        headerTooltip: `Purchase cost of the product`
      },
      {
        headerName: 'MD COUNT',
        field: 'mdCount',
        hide: true,
        colId: 'mdCount',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
        headerTooltip: `Price changes since first sale`
      },
      {
        headerName: 'MD COUNT (EVENT)',
        field: 'mdCountEvent',
        hide: true,
        colId: 'mdCountEvent',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
      },
      {
        headerName: 'Age (FIRST SALE)',
        field: 'age',
        hide: true,
        colId: 'age',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
        headerTooltip: `Weeks since first sale` },
      {
        headerName: 'AGE (STORE)',
        field: 'ageStore',
        hide: true,
        colId: 'ageStore',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
        headerTooltip: `Weeks since inventory first in store`
      },
      {
        headerName: 'LAST PRICE CHANGE',
        field: 'lastPriceChangeDate',
        hide: true,
        coldId: 'lastPriceChangeDate',
        valueFormatter: dateFormatter('DD/MM/YYYY'),
        headerTooltip: `Date of the last price change`
      },
      { headerName: 'SEASON', field: 'season', hide: true, colId: 'season', enableRowGroup: true,
      headerTooltip: `Product season code` },
      { headerName: 'COLLECTION', field: 'collectionDesc', hide: true, colId: 'collectionDesc', enableRowGroup: true,
       headerTooltip: `Product collection code`},
      {
        headerName: 'STORE COUNT',
        field: 'storesCount',
        hide: true,
        colId: 'storesCount',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
        headerTooltip: `Number of stores that currently stock the product`
      }, // SBX-539
    ],
  },
  {
    headerName: 'PRICE',
    children: [
      {
        headerName: 'FULL PRICE',
        field: 'fullPrice',
        valueFormatter: currencyFormatter(currency),
        hide: true,
        colId: 'fullPrice',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
        headerTooltip: `Initial full price of the product`
      },
      {
        headerName: 'CURRENT PRICE',
        field: 'currentPrice',
        valueFormatter: currencyFormatter(currency),
        filter: 'agNumberColumnFilter',
        colId: 'currentPrice',
        filterParams: { clearButton: true },
        headerTooltip: `Price of the product on Monday morning of the week of planning`
      },
      {
        headerName: 'CURRENT PRICE (EVENT)',
        field: 'currentPriceEvent',
        valueFormatter: currencyFormatter(currency),
        filter: 'agNumberColumnFilter',
        colId: 'currentPriceEvent',
        filterParams: { clearButton: true },
      },
      {
        headerName: 'LAST WAVE PRICE',
        field: 'lastWavePrice',
        valueFormatter: currencyFormatter(currency),
        filter: 'agNumberColumnFilter',
        colId: 'lastWavePrice',
        filterParams: { clearButton: true },
        headerTooltip: `The price of the SKU in the previous wave of the event.`
      },
      {
        headerName: 'OPT. PRICE',
        field: 'optPrice',
        headerTooltip: `Optimised price recommendation to meet the goals for the event `,
        valueFormatter: currencyFormatter(currency),
        colId: 'optPrice',
        cellClassRules: {
          'override-price': params => {
            if (params.data) {
              const response = merchHierarchyPipe.transform(params, 'optPrice');
              return params.value && response && response.onLadder;
            }
          },
          'off-ladder': params => {
            if (params.data) {
              const response = merchHierarchyPipe.transform(params);
              return params.value && response && !response.onLadder;
            }
          },
          'below-cost-or-min-margin': params => {
            if (params.data) {
              const priceBelowMinMarginOrCost = params.value < params.data.unitCost;
              return params.value && priceBelowMinMarginOrCost;
            }
          },
          'global-warning-icon': params => {
            if (params.data) {
              const ovrPriceChange = checkPriceChange(params);
              return !params.data.ovrPrice && !ovrPriceChange;
            }
          },
        },
        tooltipValueGetter: (params, field) => {
          const optPriceChange = checkPriceChange(params);
          if (optPriceChange === false) {
            return `No price change recommended`;
          }
        },
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
      },
      {
        headerName: 'OVR. PRICE',
        field: 'ovrPrice',
        headerTooltip: `Overwritten price entered when planning a model`,
        editable: true,
        cellEditor: 'modelDetailGridCellEditorComponent',
        valueFormatter: params => {
          if (params.value) {
            const variant = _.find(params.data.model.variant, d => +d.id === +params.data.model.variantId);
            let price: number;
            if (variant && variant.discountMethod === 'full_price') {
              price = params.data.fullPrice;
            } else {
              price = params.node.data.lastWavePrice ? params.node.data.lastWavePrice : params.node.data.currentPrice;
            }
            const discount = 1 - params.value / price;
            return `${currencyFormatter(currency)(params)} (${percentDecimalFormatter({ value: discount })})`;
          }

          return currencyFormatter(currency)(params);
        },
        colId: 'ovrPrice',
        cellClassRules: {
          'override-price': params => {
            if (params.data) {
              const response = merchHierarchyPipe.transform(params);
              return params.value && response && response.onLadder;
            }
          },
          'off-ladder': params => {
            if (params.data) {
              const response = merchHierarchyPipe.transform(params);
              return params.value && response && !response.onLadder;
            }
          },
          'below-cost-or-min-margin': params => {
            if (params.data) {
              const priceBelowMinMarginOrCost = params.value < params.data.unitCost;
              return params.value && priceBelowMinMarginOrCost;
            }
          },
          'global-warning-icon': params => {
            if (params.data) {
              const ovrPriceChange = checkPriceChange(params);
              return params.value && !ovrPriceChange;
            }
          },
        },
        tooltipValueGetter: (params, field) => {
          const ovrChange = checkPriceChange(params);
          if (ovrChange === false) {
            return `No price change recommended`;
          }
        },
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
      },
      {
        headerName: 'FINAL PRICE',
        field: 'finalPrice',
        headerTooltip: `Price that will be taken into the event`,
        valueFormatter: currencyFormatter(currency),
        colId: 'finalPrice',
        cellClassRules: {
          'override-price': params => {
            if (params.data) {
              const response = merchHierarchyPipe.transform(params, 'finalPrice');
              return params.value && response && response.onLadder;
            }
          },
          'off-ladder': params => {
            if (params.data) {
              const response = merchHierarchyPipe.transform(params);
              return params.value && response && !response.onLadder;
            }
          },
          'below-cost-or-min-margin': params => {
            if (params.data) {
              const priceBelowMinMarginOrCost = params.value < params.data.unitCost;
              return params.value && priceBelowMinMarginOrCost;
            }
          },
          'global-warning-icon': params => {
            if (params.data) {
              const ovrPriceChange = checkPriceChange(params);
              return params.value && !ovrPriceChange;
            }
          },
        },
        tooltipValueGetter: params => {
          const ovrChange = checkPriceChange(params);
          if (ovrChange === false) {
            return `No price change recommended`;
          }
        },
        hide: true,
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
      },
    ],
  },
  {
    headerName: 'DISCOUNT',
    children: [
      {
        headerName: 'OPT. DISCOUNT',
        field: 'optDisc',
        headerTooltip: `% Discount based on optimised price`,
        valueFormatter: percentDecimalFormatter,
        filterValueGetter: params => roundingFormatter(2)({ value: params.data[params.colDef.field] }),
        hide: true,
        colId: 'optDisc',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
        cellClass: params => {
          if (params.data && params.data.taken === 'Yes' && params.data.optDisc < 0.1) {
            return 'global-warning-icon';
          } else {
            return '';
          }
        },
        tooltipValueGetter: params => {
          if (params.data && params.data.optDisc < 0.1) {
            return `Optimised discount is less than 10%`;
          }
        },
      },
      {
        headerName: 'FINAL DISCOUNT (FULL PRICE)',
        field: 'finalDisc',
        headerTooltip: `% Discount based on the initial full price of the product`,
        valueFormatter: percentDecimalFormatter,
        filterValueGetter: params => roundingFormatter(2)({ value: params.data[params.colDef.field] }),
        colId: 'finalDisc',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
        cellClass: params => {
          if (params.data && params.data.taken === 'Yes' && params.data.finalDisc < 0.1) {
            return 'global-warning-icon';
          } else {
            return '';
          }
        },
        tooltip: params => {
          if (params.data && params.data.finalDisc < 0.1) {
            return `Final discount is less than 10%`;
          }
        },
      },
      {
        headerName: 'FINAL DISCOUNT (CURRENT PRICE)',
        field: 'finalCurrentPriceDisc',
        headerTooltip: `% Discount based on current price`,
        valueFormatter: percentDecimalFormatter,
        filterValueGetter: (params) => roundingFormatter(2)({ value: finalDiscountCurrentPrice(params) }),
        colId: 'finalCurrentPriceDisc',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
        valueGetter: finalDiscountCurrentPrice,
        cellClass: params => {
          if (params && params.data) {
            const finalCurrentDisc = finalDiscountCurrentPrice(params);
            if (params.data && params.data.taken === 'Yes' && finalCurrentDisc < 0.1) {
              return 'global-warning-icon';
            }
          }

          return '';
        },
        tooltip: params => {
          if (params && params.data) {
            const finalCurrentDisc = finalDiscountCurrentPrice(params);
            if (params.data && finalCurrentDisc < 0.1) {
              return `Final Current Price discount is less than 10% (current)`;
            }
          }
        },
      },
      {
        headerName: 'FINAL DISCOUNT (CURRENT EVENT PRICE)',
        field: 'finalCurrentEventDisc',
        valueFormatter: percentDecimalFormatter,
        filterValueGetter: params => roundingFormatter(2)({ value: finalDiscountCurrentEventPrice(params) }),
        colId: 'finalCurrentEventDisc',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
        valueGetter: finalDiscountCurrentEventPrice,
        cellClass: params => {
          if (params && params.data) {
            const finalCurrentEventDisc = finalDiscountCurrentEventPrice(params);
            if (params.data && params.data.taken === 'Yes' && finalCurrentEventDisc < 0.1) {
              return 'global-warning-icon';
            }
          }

          return '';
        },
        tooltip: params => {
          if (params && params.data) {
            const finalCurrentEventDisc = finalDiscountCurrentEventPrice(params);
            if (params.data && finalCurrentEventDisc < 0.1) {
              return `Final Current Price (Event) discount is less than 10% (current)`;
            }
          }
        },
      },
      {
        headerName: 'FINAL DISCOUNT (LAST WAVE PRICE)',
        field: 'finalLastWaveDisc',
        valueFormatter: percentDecimalFormatter,
        filterValueGetter: params => roundingFormatter(2)({ value: finalDiscountLastWavePrice(params) }),
        colId: 'finalLastWaveDisc',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
        valueGetter: finalDiscountLastWavePrice,
        cellClass: params => {
          if (params && params.data) {
            const finalLastWaveDisc = finalDiscountLastWavePrice(params);
            if (params.data && params.data.taken === 'Yes' && finalLastWaveDisc !== '' && finalLastWaveDisc < 0.1) {
              return 'global-warning-icon';
            }
          }

          return '';
        },
        tooltip: params => {
          if (params && params.data) {
            const finalLastWaveDisc = finalDiscountLastWavePrice(params);
            if (params.data && finalLastWaveDisc < 0.1) {
              return `Final Last Wave Price discount is less than 10% (current)`;
            }
          }
        },
      },
    ],
  },
  {
    headerName: 'MARGIN',
    children: [
      {
        headerName: 'CURRENT MARGIN',
        field: 'currentMargin',
        headerTooltip: `Product margin at current selling price`,
        valueFormatter: percentDecimalFormatter,
        filterValueGetter: params => roundingFormatter(2)({ value: params.data[params.colDef.field] }),
        hide: true,
        colId: 'currentMargin',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
      },
      {
        headerName: 'OPT MARGIN',
        field: 'optMargin',
        headerTooltip: `Product margin at optimised selling price`,
        valueFormatter: percentDecimalFormatter,
        filterValueGetter: params => roundingFormatter(2)({ value: params.data[params.colDef.field] }),
        hide: true,
        colId: 'optMargin',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
      },
      {
        headerName: 'FINAL MARGIN',
        field: 'finalMargin',
        headerTooltip: `Product margin using the final selling price`,
        valueFormatter: percentDecimalFormatter,
        filterValueGetter: params => roundingFormatter(2)({ value: params.data[params.colDef.field] }),
        hide: true,
        colId: 'finalMargin',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
      },
    ],
  },
  {
    headerName: 'COVER',
    children: [
      {
        headerName: 'LW COVER',
        field: 'lwCover',
        headerTooltip: `Cover last week (closing stock/ LW sales)`,
        valueFormatter: roundingFormatter(1),
        filterValueGetter: roundingFormatter(1),
        colId: 'lwCover',
        hide: true,
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
      },
      {
        headerName: 'AVG. COVER',
        field: 'avgCover',
        headerTooltip: `Average cover over the Last 4 weeks, if less then average cover since the first sale`,
        valueFormatter: roundingFormatter(1),
        filterValueGetter: roundingFormatter(1),
        filter: 'agNumberColumnFilter',
        hide: true,
        colId: 'avgCover',
        filterParams: { clearButton: true },
      },
      // { headerName: 'ENDING COVER', field: 'fcstEndCover', valueFormatter: roundingFormatter(1), hide: true, colId: 'fcstEndCover' },
    ],
  },
  {
    headerName: 'STOCK',
    children: [
      {
        headerName: getHeaderName('STOCK ON HAND'),
        field: 'stockOnHand',
        headerTooltip: `Stock available at stores`,
        valueFormatter: thousandsRounded(1),
        hide: true,
        filter: 'agNumberColumnFilter',
        colId: 'stockOnHand',
        filterParams: { clearButton: true },
      },
      {
        headerName: 'COMMITMENT',
        field: 'commitment',
        headerTooltip: `Number of units inbound or on order to a DC, but not yet received`,
        valueFormatter: thousandsRounded(1),
        hide: true,
        filter: 'agNumberColumnFilter',
        colId: 'commitment',
        filterParams: { clearButton: true },
      },
      {
        headerName: 'TOTAL STOCK',
        field: 'totalStock',
        headerTooltip: `Stock in the Federal DC, Regional DC and Stores.`,
        valueFormatter: thousandsRounded(1),
        hide: true,
        colId: 'totalStock',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
      },
      {
        headerName: 'STARTING STOCK',
        field: 'fcstStartStock',
        headerTooltip: `Forecasted stock position at the beginning of the event`,
        valueFormatter: thousandsRounded(1),
        hide: true,
        colId: 'fcstStartStock',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
      },
      {
        headerName: 'ENDING STOCK',
        field: 'fcstEndStock',
        headerTooltip: `Forecasted stock position at the end of the event `,
        valueFormatter: thousandsRounded(1),
        hide: true,
        colId: 'fcstEndStock',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
      },
      {
        headerName: 'STOCK PER STORE',
        field: 'stockPerStore',
        headerTooltip: `Average stock on hand per store`,
        valueFormatter: thousandsRounded(1),
        hide: true,
        colId: 'stockPerStore',
        filter: 'agNumberColumnFilter',
        valueGetter: params => {
          if (params && params.data) {
            const stockOnHand = params.data.stockOnHand;
            const storesCount = params.data.storesCount;
            return stockOnHand / storesCount;
          }
          return;
        },
        filterParams: { clearButton: true },
      },
      {
        headerName: 'DISPOSAL COST',
        field: 'disposalCost',
        headerTooltip: `Estimated cost to dispose of the ending stock`,
        valueFormatter: currencyFormatter(currency),
        hide: true,
        colId: 'disposalCost',
        valueGetter: params => {
          if (params && params.data) {
            return params.data.fcstEndStock * 4;
          }
          return;
        },
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
      },
    ],
  },
  {
    headerName: 'SALES',
    children: [
      {
        headerName: 'TOTAL SALES',
        field: 'totalSales',
        headerTooltip: `Total number of sales for a product, since the first sale`,
        valueFormatter: thousandsRounded(1),
        hide: true,
        colId: 'totalSales',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
      },
      {
        headerName: 'LW SALES',
        field: 'lwSales',
        headerTooltip: `Unit sales last week for a product`,
        valueFormatter: thousandsRounded(1),
        hide: true,
        colId: 'lwSales',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
      },
      {
        headerName: 'AVG. SALES',
        field: 'avgSales',
        headerTooltip: `Average unit sales over the last 4 weeks`,
        valueFormatter: thousandsRounded(1),
        hide: true,
        colId: 'avgSales',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
      },
      {
        headerName: 'PRE-EVENT SALES',
        field: 'fcstPremdSales',
        headerTooltip: `Forecasted number of sales from the beginning of the current planning week to the event start`,
        valueFormatter: thousandsRounded(1),
        hide: true,
        colId: 'fcstPremdSales',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
      },
      {
        headerName: 'AVG. SALES PER STORE',
        field: 'avgSalesStore',
        headerTooltip: `Last weeks sales units / Store count`,
        valueFormatter: thousandsRounded(1),
        hide: true,
        colId: 'avgSalesStore',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
      }, // SBX-539
      {
        headerName: 'EVENT SALES',
        field: 'fcstMdSales',
        headerTooltip: `Forecasted number of unit sales during the event`,
        valueFormatter: thousandsRounded(1),
        hide: true,
        colId: 'fcstMdSales',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
      },
    ],
  },
  {
    headerName: 'SELL THROUGH',
    children: [
      {
        headerName: 'CURRENT ST %',
        field: 'currentSt',
        headerTooltip: `Current ST of the product, % sold out of total that has been purchased`,
        valueFormatter: percentDecimalFormatter,
        filterValueGetter: params => roundingFormatter(2)({ value: params.data[params.colDef.field] }),
        filter: 'agNumberColumnFilter',
        colId: 'currentSt',
        filterParams: { clearButton: true },
      },
      {
        headerName: 'STARTING ST %',
        field: 'fcstStartSt',
        headerTooltip: `Forecasted ST % at the start of the event`,
        valueFormatter: percentDecimalFormatter,
        filterValueGetter: params => roundingFormatter(2)({ value: params.data[params.colDef.field] }),
        hide: true,
        colId: 'fcstStartSt',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
      },
      {
        headerName: 'ENDING ST %',
        field: 'fcstEndSt',
        headerTooltip: `Forecasted ST % at the end of the event`,
        valueFormatter: percentDecimalFormatter,
        filterValueGetter: params => roundingFormatter(2)({ value: params.data[params.colDef.field] }),
        colId: 'fcstEndSt',
        filter: 'agNumberColumnFilter',
        filterParams: { clearButton: true },
      },
    ],
  },
  {
    headerName: 'SPEND',
    children: [
      {
        headerName: 'SPEND',
        field: 'fcstSpend',
        headerTooltip: `Discount on current price x total stock`,
        valueFormatter: currencyFormatter(currency),
        hide: true,
        filter: 'agNumberColumnFilter',
        colId: 'fcstSpend',
        filterParams: { clearButton: true },
      },
    ],
  },
];

export const autoGroupColumnDef = {
  headerName: 'Group',
  field: 'skuDesc',
};

export const gridOptions: GridOptions = {
  suppressAggFuncInHeader: true,
  suppressScrollOnNewData: true,
  enableFilter: true,
  frameworkComponents: {
    modelDetailGridCellEditorComponent: ModelDetailGridCellEditorComponent,
    customLayoutsToolPanel: GridLayoutsToolPanelContainerComponent,
    modelDetailNoteCellEditorComponent: ModelDetailNoteCellEditorComponent,
    modelDetailStatusCustomFilterComponent: ModelDetailStatusCustomFilterComponent,
  },
  defaultColDef: {
    rowGroup: false,
    width: 120,
  },
  rowClassRules: {
    'not-taken': params => {
      if (params && params.data) {
        return params.data.taken === 'No';
      }
    },
    locked: params => {
      if (params && params.data) {
        return !!lowerWaveWithStatusLessThanApproved(params.data.model.relatedModels, params.data.model);
      }
    },
  },
  deltaRowDataMode: true,
  getRowNodeId: d => d.skuId,
  excelStyles: [
    {
      id: 'textFormat',
      dataType: 'string',
    },
  ],
  getContextMenuItems,
};

export function getOverviewColDefs(models: Model[], regionPipe: RegionPipe, currency) {
  const baseDef: Array<any> = [
    {
      headerName: 'PRODUCT DETAILS',
      children: [
        { headerName: 'SKU ID', field: 'skuId', pinned: 'left', cellClass: 'textFormat' },
        { headerName: 'SKU DESCRIPTION', field: 'skuDesc', pinned: 'left' },
        {
          headerName: 'TAKEN',
          field: 'taken',
          cellRendererFramework: ModelDetailTakenCellComponent,
          colId: 'taken',
        },
      ],
    },
  ];

  models.forEach(model => {
    baseDef.push({
      headerName: regionPipe.transform(model.region),
      children: [
        { headerName: 'START DATE', field: `region${model.region}_startDate`, valueFormatter: dateFormatter('DD/MM/YYYY') },
        {
          headerName: 'OPT. PRICE',
          field: `region${model.region}_optPrice`,
          valueFormatter: currencyFormatter(currency),
          cellClass: showOverViewWarningCell,
          tooltipValueGetter: showOverviewWarningTip,
          filter: 'agNumberColumnFilter',
          filterParams: { clearButton: true },
        },
        {
          headerName: 'DISCOUNT',
          field: `region${model.region}_finalDisc`,
          valueFormatter: percentDecimalFormatter,
          filterValueGetter: params => roundingFormatter(2)({ value: params.data[params.colDef.field] }),
          filter: 'agNumberColumnFilter',
          filterParams: { clearButton: true },
        },
      ],
    });
  });

  return baseDef;
}

////

export function getOverviewColDefsRegions(regions: string[], regionPipe: RegionPipe, currency) {
  const baseDef: Array<any> = [
    {
      headerName: 'PRODUCT DETAILS',
      children: [
        { headerName: 'SKU ID', field: 'skuId_overview', pinned: 'left', cellClass: 'textFormat' },
        { headerName: 'SKU DESCRIPTION', field: 'skuDesc_overview', pinned: 'left' },
        {
          headerName: 'TAKEN',
          field: 'taken',
          cellRendererFramework: ModelDetailTakenCellComponent,
          colId: 'taken',
        },
      ],
    },
  ];

  regions.forEach(region => {
    baseDef.push({
      headerName: regionPipe.transform(region),
      children: [
        { headerName: 'START DATE', field: `region${region}_startDate`, valueFormatter: dateFormatter('DD/MM/YYYY') },
        {
          headerName: 'OPT. PRICE',
          field: `region${region}_optPrice`,
          valueFormatter: currencyFormatter(currency),
          cellClass: showOverViewWarningCell,
          tooltipValueGetter: showOverviewWarningTip,
          filter: 'agNumberColumnFilter',
          filterParams: { clearButton: true },
        },
        {
          headerName: 'DISCOUNT',
          field: `region${region}_finalDisc`,
          valueFormatter: percentDecimalFormatter,
          filterValueGetter: params => roundingFormatter(2)({ value: params.data[params.colDef.field] }),
          filter: 'agNumberColumnFilter',
          filterParams: { clearButton: true },
        },
      ],
    });
  });

  return baseDef;
}
