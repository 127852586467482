import {
  Component,
  OnInit,
  AfterViewInit,
  ViewContainerRef,
  ViewChild
} from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import * as _ from 'lodash';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, filter, withLatestFrom } from 'rxjs/operators';
import { ModelFacadeService } from '../../model-facade.service';
import { UserFacadeService } from '../../../../user/user-facade.service';
import { Line } from '../../state/model';

@Component({
  selector: 'app-model-detail-note-cell-editor',
  templateUrl: './model-detail-note-cell-editor.component.html',
  styleUrls: ['./model-detail-note-cell-editor.component.scss']
})
export class ModelDetailNoteCellEditorComponent
  implements OnInit, ICellEditorAngularComp, AfterViewInit {
  params: ICellEditorParams;
  value;
  notes$: Observable<string[]>;
  allLines$: Observable<Array<Line>>;
  public noteForm: FormGroup;
  note;

  @ViewChild('input', { read: ViewContainerRef, static: false }) public input;

  constructor(
    private modelsFacadeService: ModelFacadeService,
    private userFacadeService: UserFacadeService
  ) {}

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.value = !this.params.value ? '' : this.params.value;
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.input.element.nativeElement.focus();
    });
  }

  getValue(): any {
    if (!this.noteForm.value.noteValue) {
      return '';
    }
    return this.noteForm.value.noteValue;
  }

  ngOnInit() {
    const seasonList$: Observable<string[]> = this.userFacadeService.getSelectedSeasons();
    const includedSkus$ = this.userFacadeService.getIncludedSkus();
    const excludedSkus$ = this.userFacadeService.getExcludedSkus();

    this.allLines$ = this.modelsFacadeService
      .getSelectedModelsRegionLines()
      .pipe(
        withLatestFrom(seasonList$, includedSkus$, excludedSkus$),
        map(([lines, seasonList, includedIds, excludedIds]) => {
          if (seasonList && seasonList.length > 0) {
            return lines.filter(line => ((_(seasonList).includes(line.season) || _(includedIds).includes(line.skuId))
          ) && !_(excludedIds).includes(line.skuId));
          }
          return lines;
        })
      );
    this.noteForm = new FormGroup({ noteValue: new FormControl(this.value) });
    this.notes$ = this.allLines$.pipe(
      map(lines =>
        _(lines)
          .filter(line => line.note !== null && line.note !== '')
          .map(d => d.note)
          .uniq()
          .value()
      )
    );
  }
}
