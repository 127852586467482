import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

import * as _ from 'lodash';

import { Line } from '../../state/model';

@Component({
  selector: 'app-model-detail-filters',
  templateUrl: './model-detail-filters.component.html',
  styleUrls: ['./model-detail-filters.component.scss'],
})
export class ModelDetailFiltersComponent implements OnInit {
  @Output()
  clickTitle = new EventEmitter();

  @Output()
  clickTaken = new EventEmitter();

  @Output()
  clickUntaken = new EventEmitter();

  @Output()
  quickFilterChanged = new EventEmitter();

  @Output()
  sellThroughRangeChanged = new EventEmitter();

  @Output()
  spendRangeChanged = new EventEmitter();

  @Output()
  coverRangeChanged = new EventEmitter();

  @Output()
  priceRangeChanged = new EventEmitter();

  @Output()
  collectionChanged = new EventEmitter();

  @Output()
  categoryChanged = new EventEmitter();

  @Output()
  stockClicked = new EventEmitter();

  @Output()
  commitmentClicked = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter();

  @Output()
  seasonChanged = new EventEmitter();

  @Input()
  show: boolean;

  @Input()
  lines: Line[];

  @Input()
  filterValueChanged: boolean;

  collections = [];
  seasons = [];
  categories = [];
  selectedCategories;
  selectedCollections;
  selectedSeasons;

  showTaken = true;
  showUntaken = true;
  showSellThroughSlider = false;
  quickFilterText: string;
  sellThroughRange = [0, 0];
  showSpendSlider = false;
  spendRange = [0, 0];
  showCoverSlider = false;
  coverRange = [0, 0];
  showPriceSlider = false;
  priceRange = [0, 0];
  showStock = false;
  showCommitment = false;

  constructor() {}

  ngOnInit() {
    if (this.lines) {
      this.collections = _.sortBy(_.uniq(this.lines.map((d: Line) => d.collectionDesc)));
      this.seasons = _.sortBy(_.uniq(this.lines.map((d: Line) => d.season)));
      this.categories = (_.uniq(this.lines.map((d: Line) => d.categoryDesc))).sort((a, b) => a.localeCompare(b));
    }

    this.selectedCategories = this.categories;
    this.selectedCollections = this.collections;
    this.selectedSeasons = this.seasons;
  }

  onClickTitle() {
    this.clickTitle.emit();
  }

  onQuickFilterTextChange(e: string) {
    this.quickFilterChanged.emit(e);
  }

  toggleSellThroughSlider(force?: boolean) {
    if (force) {
      this.showSellThroughSlider = force;
    } else {
      this.showSellThroughSlider = !this.showSellThroughSlider;
    }
  }

  onSellThroughSliderHighValueChange(e) {
    this.sellThroughRange[1] = e;
    this.sellThroughRangeChanged.emit(this.sellThroughRange);
  }

  onSellThroughValuechange(e) {
    this.sellThroughRange[0] = e;
    this.sellThroughRangeChanged.emit(this.sellThroughRange);
  }

  toggleSpendSlider(force?: boolean) {
    if (force) {
      this.showSpendSlider = force;
    } else {
      this.showSpendSlider = !this.showSpendSlider;
    }
  }

  onSpendSliderHighValueChange(e: number) {
    this.spendRange[1] = e;
    this.spendRangeChanged.emit(this.spendRange);
  }

  onSpendValuechange(e: number) {
    this.spendRange[0] = e;
    this.spendRangeChanged.emit(this.spendRange);
  }

  toggleCoverSlider(force?: boolean) {
    if (force) {
      this.showCoverSlider = force;
    } else {
      this.showCoverSlider = !this.showCoverSlider;
    }
  }

  onCoverSliderHighValueChange(e: number) {
    this.coverRange[1] = e;
    this.coverRangeChanged.emit(this.coverRange);
  }

  onCoverValueChange(e: number) {
    this.coverRange[0] = e;
    this.coverRangeChanged.emit(this.coverRange);
  }

  togglePriceSlider(force?: boolean) {
    if (force) {
      this.showPriceSlider = force;
    } else {
      this.showPriceSlider = !this.showPriceSlider;
    }
  }

  onPriceSliderHighValueChange(e: number) {
    this.priceRange[1] = e;
    this.priceRangeChanged.emit(this.priceRange);
  }

  onPriceValueChange(e: number) {
    this.priceRange[0] = e;
    this.priceRangeChanged.emit(this.priceRange);
  }

  clearQuickFilter() {
    this.quickFilterText = null;
    this.quickFilterChanged.emit(null);
  }

  onClickTaken() {
    this.showTaken = !this.showTaken;
    this.clickTaken.emit();
  }

  onClickUntaken() {
    this.showUntaken = !this.showUntaken;
    this.clickUntaken.emit();
  }

  onCollectionChange({ value }) {
    const field = 'collectionDesc';
    this.collectionChanged.emit({ value, field });
  }

  onCategoryChange({ value }) {
    const field = 'categoryDesc';
    this.categoryChanged.emit({ value, field });
  }

  onClickStock() {
    this.showStock = !this.showStock;
    this.stockClicked.emit(this.showStock);
  }

  onClickCommitment() {
    this.showCommitment = !this.showCommitment;
    this.commitmentClicked.emit(this.showCommitment);
  }

  onClearFilter() {
    this.selectedCategories = this.categories;
    this.selectedCollections = this.collections;
    this.clearFilter.emit();
  }

  onSeasonChange({ value }) {
    const field = "season";
    this.seasonChanged.emit({ value, field });
  }
}
