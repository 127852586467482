import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material';
import { Line, Model } from '../../state/model';
import { ModelFacadeService } from '../../model-facade.service';

@Component({
  selector: 'app-model-detail-bulk-sku-dialog',
  templateUrl: './model-detail-bulk-sku-dialog.component.html',
  styleUrls: ['./model-detail-bulk-sku-dialog.component.scss'],
})
export class ModelDetailBulkSkuDialogComponent {
  take = true;

  _skuList: '';

  get skuList() {
    return this._skuList.split(/\r?\n/).filter(d => !!d);
  }

  constructor(
    private modelFacadeService: ModelFacadeService,
    private dialogRef: MatDialogRef<ModelDetailBulkSkuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBarService: MatSnackBar
  ) {}

  toggleLines() {
    const skuList = this.skuList;
    const linesToTake: Array<Line & { model: Model }> = this.data.lines.filter(({ skuId }) => skuList.indexOf(skuId) > -1);
    const skuIdsToTake = _(linesToTake)
      .map(({ skuId }) => skuId)
      .uniq()
      .value();
    const linesNotFound = skuList.filter(skuId => skuIdsToTake.indexOf(skuId) === -1);

    _(linesToTake)
      .groupBy(line => line.model.id)
      .forEach(lines =>
        this.modelFacadeService.takeUntakeLines({
          lines: lines.filter(line => !!line.skuId && !!line.model && !!line.model.id),
          taken: this.take ? 1 : 0,
        })
      );

    // todo: don't show this message for error case
    let snackbarStr = `${skuIdsToTake.length} lines were ${this.take ? 'taken' : 'untaken'} of ${skuList.length} provided.`;
    if (linesNotFound.length > 0) {
      snackbarStr += ` Following lines were not found: ${linesNotFound.join(', ')}`;
    }
    const snackBarRef = this.snackBarService.open(snackbarStr);
    snackBarRef._dismissAfter(10000);

    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
