import { Injectable } from '@angular/core';
import * as _ from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class ReportingGridSelectService {

  constructor() { }

  selected = [];

  get selectedIDs() {
    return this.selected.map(d => d.id);
  }
  get first() {
  		return _.first(this.selected);
  }
 }
