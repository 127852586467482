import {
  Action,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

export const NAVIGATIONTOGGLE = '[NAVIGATION] NavigationToggle';

export class NavigationToggleAction implements Action {
  type = NAVIGATIONTOGGLE;

  constructor() { }
}

export interface INavigationState {
  expanded: boolean;
}

export const InitialState: INavigationState = {
  expanded: true,
};

export function reducer(state = InitialState, action: NavigationToggleAction) {
  switch (action.type) {
    case NAVIGATIONTOGGLE: {
      return {
        expanded: !state.expanded
      };
    }
    default:
      return state;
  }
}
export const getNavigationState = createFeatureSelector<INavigationState>('navigation');

export const getNavigationExpanded = createSelector(
  getNavigationState,
  (state: INavigationState) => state.expanded
);
