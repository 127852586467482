import {
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import { IPriceLaddersState } from './price-ladders.reducer';

import * as _ from 'lodash';

export const getPriceLaddersState = createFeatureSelector<IPriceLaddersState>('priceLadders');

export const getPriceLadders = createSelector(
  getPriceLaddersState,
  (state: IPriceLaddersState) => _.map(state.entities, (val) => val)
);

export const getPriceLaddersIDs = createSelector(
  getPriceLaddersState,
  (state: IPriceLaddersState) => state.ids
);

export const getPriceLadderByID = (id) => createSelector(
  getPriceLaddersState,
  (state: IPriceLaddersState) => state.entities[id]
);