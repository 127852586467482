import { Component, OnInit } from '@angular/core';

import { Store, select } from '@ngrx/store';

import * as fromRoot from '../../app.reducer';
import { OrgGetUsersAction } from '../../features/sb-admin/org/state/org.actions';
import { getCurrentUser } from '../../user/user.reducer';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { getUsersByOrg } from '../sb-admin/org/state/org.selectors';

import { IOrg } from '../sb-admin/org/state/org.reducer';
import { AdminPanelDisableUserCellComponent } from './admin-panel-disable-user-cell/admin-panel-disable-user-cell.component';
import { IUser } from '../../user';

export const getRowClassDisabledUser = (params) => {
  if (!params || !params.data || params.data.active) {
    return;
  }

  return 'disabled';
};

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss']
})
export class AdminPanelComponent implements OnInit {
  users$: Observable<any[]>;
  quickFilterText: string;
  showCreateUserDialog: boolean;
  showUpdateUserDialog: boolean;
  selectedUser: IUser;

  columns = [
    {
      headerName: '',
      cellRendererFramework: AdminPanelDisableUserCellComponent,
      suppressMenu: true,
      sortable: false,
    },
    { headerName: 'EMAIL', field: 'email' },
    { headerName: 'ROLES', field: 'role' },
  ];
  currentUserOrg: IOrg;

  gridOptions = {
    getRowClass: getRowClassDisabledUser
  };

  constructor(
    public store: Store<fromRoot.State>,
  ) { }

  ngOnInit() {
    this.store.pipe(
      select(getCurrentUser),
      filter((user) => !!user)
    )
    .subscribe((currentUser) => {
      if (currentUser.role.includes('Superuser')) {
        this.store.dispatch(new OrgGetUsersAction());
        this.currentUserOrg = currentUser.org;
        this.users$ = this.store.pipe(
          select(getUsersByOrg(currentUser.org.id),
          filter((users) => !!users)
        ));
      }
    });
  }

  clearQuickFilter() {
    this.quickFilterText = null;
  }

  toggleShowCreateUserDialog() {
    this.showUpdateUserDialog = false;
    this.showCreateUserDialog = true;
  }

  onCreateCloseDialog(closeDialog: boolean) {
    if (closeDialog) {
      this.showCreateUserDialog = false;
    }
  }

  onUpdateCloseDialog(closeDialog: boolean) {
    if (closeDialog) {
      this.showUpdateUserDialog = false;
    }
  }

  onRowClick(event) {
    this.showCreateUserDialog = false;
    this.showUpdateUserDialog = false;
    setTimeout(() => this.showUpdateUserDialog = true, 0);
    this.selectedUser = event.data;
  }
}
