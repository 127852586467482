import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { Line } from '../../state/model';

@Component({
  selector: 'app-model-detail-selection-filter-toggle',
  templateUrl: './model-detail-selection-filter-toggle.component.html',
  styleUrls: ['./model-detail-selection-filter-toggle.component.scss'],
})
export class ModelDetailSelectionFilterToggleComponent implements OnInit {
  @Input()
  lines: Array<Line>;

  @Input()
  gridFilter$: Subject<any>;

  @Input()
  filterValueChanged: boolean;

  @Output()
  quickFilterChanged = new EventEmitter();

  constructor() {}

  show = 'filters';
  showTaken = true;

  ngOnInit() {}

  public toggleQuickSelectionFilters(showWhich) {
    this.show = showWhich;
  }

  public onModelFilterQuickFilterChange(quickFilterText: string) {
    this.quickFilterChanged.next(quickFilterText);
  }

  public onModelFilterSellThroughRangeChange(e: Array<number>) {
    const condition = {
      type: 'inRange',
      filter: e[0] - 0.000001,
      filterTo: e[1] + 0.000001,
    };

    this.gridFilter$.next({
      field: 'currentSt',
      condition,
    });
  }

  onModelFilterSpendRangeChange(e: Array<number>) {
    const condition = {
      type: 'inRange',
      filter: e[0] - 0.000001,
      filterTo: e[1] + 0.000001,
    };

    this.gridFilter$.next({
      field: 'fcstSpend',
      condition,
    });
  }

  onModelFilterPriceRangeChange(e: Array<number>) {
    const condition = {
      type: 'inRange',
      filter: e[0] - 0.000001,
      filterTo: e[1] + 0.000001,
    };

    this.gridFilter$.next({
      field: 'currentPrice',
      condition,
    });
  }

  onModelFilterCoverRangeChange(e: Array<number>) {
    const condition = {
      type: 'inRange',
      filter: e[0] - 0.000001,
      filterTo: e[1] + 0.000001,
    };

    this.gridFilter$.next({
      field: 'avgCover',
      condition,
    });
  }

  onModelFilterCollectionChange({ field, value }) {
    this.gridFilter$.next({
      field,
      condition: value,
    });
  }

  onModelFilterCategoryChange({ field, value }) {
    this.gridFilter$.next({
      field,
      condition: value,
    });
  }

  onModelFilterStockClick(e: boolean) {
    this.gridFilter$.next({
      field: 'stockOnHand',
      condition: {
        type: e ? 'greaterThan' : 'greaterThanOrEqual',
        filter: 0,
      },
    });
  }

  onModelFilterCommitmentClick(e: boolean) {
    this.gridFilter$.next({
      field: 'commitment',
      condition: {
        type: e ? 'greaterThan' : 'greaterThanOrEqual',
        filter: 0,
      },
    });
  }

  // wtf?
  public onClickUntaken(e) {
    let condition: Array<string>;
    this.showTaken = !this.showTaken;

    if (this.showTaken) {
      condition = ['Yes', 'No'];
    } else {
      condition = ['Yes'];
    }

    this.gridFilter$.next({
      condition,
      field: 'taken',
    });
  }

  onFilterCleared(e) {
    this.gridFilter$.next({
      condition: null,
    });
  }

  onModelFilterSeasonChange({ field, value }) {
    this.gridFilter$.next({
      field,
      condition: value,
    });
  }
}
