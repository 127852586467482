import { Component, OnInit, HostListener } from '@angular/core';

import { Store, select } from '@ngrx/store';

import * as fromRoot from '../../app.reducer';

import {
  getUserViews,
  isCurrentUserAdmin,
  getCurrentUser,
  isCurrentUserSuperuser,
} from '../../user/user.reducer';
import { filter, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { CookieService } from 'ngx-cookie-service';
import { getNavigationExpanded, NavigationToggleAction } from './navigation.state';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  public userHasMarkdown: boolean;
  public userHasPromos: boolean;
  public isAdmin$: Observable<boolean>;
  public showUserMenu = false;

  user$: Observable<any>;
  isSuperuser$: Observable<boolean>;
  collapseRestoreHover = false;
  expanded$: Observable<boolean>;

  @HostListener('document:click')
  onClick() {
    this.showUserMenu = false;
  }

  constructor(
    public store: Store<fromRoot.State>,
    private authService: AuthService,
  ) {
    this.isSuperuser$ = this.store
      .select(isCurrentUserSuperuser);
    this.isAdmin$ = this.store
      .select(isCurrentUserAdmin);
    this.user$ = this.store
      .select(getCurrentUser);

    this.store
      .select(getUserViews)
      .pipe(filter((userViews) => !!userViews))
      .subscribe((userViews) => {
        this.userHasMarkdown = userViews.includes('markdown');
        this.userHasPromos = userViews.includes('promos');
      });
  }

  ngOnInit() {
    this.expanded$ = this.store.pipe(select(getNavigationExpanded));
    if (this.user$) {
       this.user$.pipe(take(1)).subscribe((user) => {
         if(user.name) {
          let Tawk_API;
          const Tawk_LoadStart = new Date();
          if (typeof Tawk_API === 'undefined') {
            Tawk_API = {};
          }
          this.loadScript();
         }
       });
    }
  }

  toggleCollapseNav() {
    this.store.dispatch(new NavigationToggleAction());
  }

  toggleUserMenu(event) {
    event.stopPropagation();
    this.showUserMenu = !this.showUserMenu;
  }

  openHelp() {
    window.open('http://www.sparkbox.co', '_blank');
  }

  signOut() {
    this.user$
      .pipe(take(1))
      .subscribe((user) => {
        if (user.url) {
          window.location.assign(user.url);
        } else {
          this.authService.signOut();
        }
      });
  }

  loadScript() {
    const s1 = document.createElement('script'),
    s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/5df2336dd96992700fcbf840/default';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  }
}
