import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as _ from 'lodash';
import * as moment from 'moment';

import {
  estimatedSpend,
  averageNewDepth,
  percentLinesOverridden,
  estimatedClosingST,
} from '../model-detail-summary-ribbon/model-detail-summary-ribbon.component';
import { IMerchHierarchy } from '../../../../abstracts/merch-hierarchy.interface';
import { isStartDateBeforeEndDate } from '../../../../infra/customValidators';
import { ModelFacadeService } from '../../model-facade.service';
import { UserFacadeService } from '../../../../user/user-facade.service';
import { Model, Line } from '../../state/model';

@Component({
  selector: 'app-model-update-dialog',
  templateUrl: './model-update-dialog.component.html',
  styleUrls: ['./model-update-dialog.component.scss'],
})
export class ModelUpdateDialogComponent implements OnInit, OnChanges {
  @Input()
  model: {
    allLines: Array<Line>;
    hideVariant: boolean;
    numberOfRegions: number;
    lastOptimisationDate: Date;
    orgLastDataRefresh: Date;
    lowestStatus: number;
    relatedModels: Array<Model>;
  } & Model;

  @Output()
  closeDialog = new EventEmitter();
  updateModelForm: FormGroup;
  selectedVariant: any;
  merchHierarchy: IMerchHierarchy[];
  editMode = false;
  department: string;
  merchHierarchyHash: { [key: string]: IMerchHierarchy };
  showUpdateModelLoadingOverlay = false;
  estimatedSpendCalc: Number;
  averageNewDepthCalc: Number;
  estimatedClosingSTCalc: Number;
  complianceCalc: Number;
  showOptimise = true;
  startDate = new Date();
  endDate = new Date();

  constructor(private modelFacadeService: ModelFacadeService, private userFacadeService: UserFacadeService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model) {
      this.editMode = false;
      this.showUpdateModelLoadingOverlay = false;
      // when switching between models under the same master, the component is not destroyed
      if (!changes.model.firstChange && changes.model.currentValue.id !== changes.model.previousValue.id) {
        this.modelFacadeService.getDetails(changes.model.currentValue.id);
      }
      this.selectedVariant = _.find(changes.model.currentValue.variant, variant => +variant.id === +changes.model.currentValue.variantId);
    }
  }

  ngOnInit() {
    this.modelFacadeService.getDetails(<any>this.model.id);

    this.startDate = _.min(_(this.model.variant).map((v) => v.startDate).value());
    this.endDate = _.max(_(this.model.variant).map((v) => v.endDate).value());
    if (this.model.allLines) {
      const takenLines = this.model.allLines.filter(line => line.taken === 'Yes');
      this.estimatedSpendCalc = +estimatedSpend(takenLines) * 1000;
      this.averageNewDepthCalc = averageNewDepth(takenLines);
      this.estimatedClosingSTCalc = estimatedClosingST(takenLines);
      this.complianceCalc = 1 - +percentLinesOverridden(takenLines);
    }

    this.userFacadeService.getMerchHierarchy().subscribe(mhMapping => {
      const merchHierarchy = mhMapping.map(mh => ({ hierarchyId: mh.h2Id, hierarchyDesc: mh.h2Desc }));
      this.merchHierarchy = _(merchHierarchy)
        .uniqBy('hierarchyId')
        .orderBy(['hierarchyId'])
        .value();
      this.merchHierarchyHash = _.keyBy(merchHierarchy, 'hierarchyId');
    });
  }

  toggleEditMode() {
    this.updateModelForm = new FormGroup(
      {
        name: new FormControl(this.model.name, [
          Validators.required,
          Validators.maxLength(255),
          Validators.pattern(/^[\x00-\x7F]+$/),
        ]),
        startDate: new FormControl({ value: this.startDate, disabled: true} , [Validators.required]),
        endDate: new FormControl({value: this.endDate, disabled: true }, [Validators.required]),
      },
      [isStartDateBeforeEndDate]
    );
    this.editMode = !this.editMode;
  }

  saveModelSummary() {
    this.showUpdateModelLoadingOverlay = true;
    this.model.relatedModels.forEach(model => {
      this.modelFacadeService.update({
        id: model.id,
        modelName: this.updateModelForm.value.name,
        startDate: moment(this.updateModelForm.value.startDate),
        endDate: moment(this.updateModelForm.value.endDate),
        departmentId: model.departmentId,
      });
    });
  }

  onCloseDialog() {
    this.closeDialog.emit(true);
  }

  applySelectedVariant() {
    this.modelFacadeService.applyVariant({ id: this.model.id, variantId: +this.selectedVariant.id });
    this.model.variantId = this.selectedVariant.id; // not good if there's an error.....
  }

  public getErrorMessage(fields: string[]) {
    const errors = _.assign({}, ...fields.map(field => this.updateModelForm.controls[field].errors).filter(error => !!error));

    if (errors['required']) {
      return 'Required field';
    }
    if (errors['notBetween0and100']) {
      return 'Must be between 0 and 100%';
    }
    if (errors['pattern']) {
      return 'Must not contain special characters';
    }
    if (errors['startDateAfterEndDate']) {
      return 'Start must precede end date';
    }
    if (errors['maxlength']) {
      return 'Must be fewer than 255 characters';
    }
  }

  optimizeModel() {
    if (window.confirm('Are you sure you want to optimise this model?')) {
      const modelIDs: Array<string> = this.model.relatedModels.filter(d => d.status === 'Planning').map(({ id }) => id);
      this.modelFacadeService.optimize(modelIDs);
    }
  }
  deleteModel() {
    if (window.confirm('Are you sure you want to delete this model?')) {
      this.modelFacadeService.delete({ id: this.model.id });
    }
  }
}
