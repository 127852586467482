import { SideBarDef, ICellRendererParams } from 'ag-grid-community';

import * as mapper from './reporting-detail.mapper';

import {
  thousandsFormatter,
  thousandsRounded,
  roundingFormatter,
  percentDecimalFormatter,
} from '../../../infra/datagrid/datagrid.formatters';

import data from '../../../../assets/json_data/default-data.json';
import orgData from '../../../../assets/json_data/org-data.json';

import * as _ from 'lodash';
import { Line } from '../../model/state/model';

export const sellThroughRenderer = (params: ICellRendererParams) => {
  const value = percentDecimalFormatter(params);
  const width = value;
  let backgroundColor;
  if (params.value < 0.7) {
    backgroundColor = '#d83c59';
  }
  if (params.value >= 0.7 && params.value < 0.84) {
    backgroundColor = '#f7f558';
  }
  if (params.value >= 0.84) {
    backgroundColor = '#3dce87';
  }

  return `<span class="sell-through-bar" style="background: ${backgroundColor}; width: ${width}">${value}</span>`;
};

export const getLinesUnderlying = params => {
  if (params.node.footer) {
    return _.flatten(params.node.sibling.allLeafChildren.map(d => d.data.linesUnderlying)); // For calculating the total value
  } else {
    return _.flatten(params.node.allLeafChildren.map(d => d.data.linesUnderlying));
  }
};

export const getUnderlyingData = params => {
  if (params.node.footer) {
    return _.flatten(params.node.sibling.allLeafChildren.map(d => d.data.dataByDivision));
  } else {
    return _.flatten(params.node.allLeafChildren.map(d => d.data.dataByDivision));
  }
};
export const sideBar: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressPivots: false,
        suppressPivotMode: false,
        suppressSideButtons: true,
        suppressColumnFilter: true,
        suppressColumnSelectAll: true,
        suppressColumnExpandAll: true,
      },
    },
  ],
};

export const columns = currency => [
  {
    headerName: 'Current',
    children: [
      {
        headerName: 'Stock Units',
        field: 'currentStockUnits',
        aggFunc: 'sum',
        enableValue: true,
        valueFormatter: thousandsFormatter,
      },
      {
        headerName: `Stock Value (${currency}k)`,
        field: 'currentStockValue',
        aggFunc: 'sum',
        enableValue: true,
        valueFormatter: thousandsRounded(1),
      },
      {
        headerName: 'Cover',
        field: 'currentCover',
        valueGetter: params => {
          if (params.data) {
            return roundingFormatter(1)({ value: params.data.currentCover });
          } else {
            const lines: any = getLinesUnderlying(params);
            return roundingFormatter(1)({ value: mapper.currentCover(lines) });
          }
        },
      },
      {
        headerName: 'Sell Through %',
        field: 'currentSellThrough',
        valueGetter: params => {
          if (params.data) {
            return params.data.currentSellThrough;
          } else {
            const lines: any = getLinesUnderlying(params);
            return mapper.currentSellThrough(lines);
          }
        },
        cellRenderer: sellThroughRenderer,
      },
      {
        headerName: 'MD Depth %',
        field: 'currentDepth',
        valueGetter: params => {
          if (params.data) {
            return percentDecimalFormatter({ value: params.data.currentDepth });
          } else {
            const lines: any = getLinesUnderlying(params);
            return percentDecimalFormatter({ value: mapper.currentDepth(lines) });
          }
        },
      },
      {
        headerName: 'Total Depth %',
        field: 'currentTotalDepth',
        valueGetter: params => {
          if (params.data) {
            return percentDecimalFormatter({ value: params.data.currentTotalDepth });
          } else {
            const lines: any = getLinesUnderlying(params);
            return percentDecimalFormatter({ value: mapper.currentTotalDepth(lines) });
          }
        },
      },
    ],
  },
  {
    headerName: 'MD Start (Forecast)',
    children: [
      {
        headerName: 'Stock Units',
        field: 'startStockUnits',
        aggFunc: 'sum',
        enableValue: true,
        valueFormatter: thousandsFormatter,
      },
      {
        headerName: `Stock Value (${currency}k)`,
        field: 'startStockValue',
        aggFunc: 'sum',
        enableValue: true,
        valueFormatter: thousandsRounded(1),
      },
      {
        headerName: 'Cover',
        field: 'startCover',
        valueGetter: params => {
          if (params.data) {
            return roundingFormatter(1)({ value: params.data.startCover });
          } else {
            const lines: any = getLinesUnderlying(params);
            return roundingFormatter(1)({ value: mapper.startCover(lines) });
          }
        },
      },
      {
        headerName: 'Sell Through %',
        field: 'startSellThrough',
        valueGetter: params => {
          if (params.data) {
            return params.data.startSellThrough;
          } else {
            const lines: any = getLinesUnderlying(params);
            return mapper.startSellThrough(lines);
          }
        },
        cellRenderer: sellThroughRenderer,
      },
    ],
  },
  {
    headerName: 'MD Action (Forecast)',
    children: [
      {
        headerName: 'Lines Taken',
        field: 'linesTaken',
        valueGetter: params => {
          if (params.data) {
            return params.data.linesTaken;
          } else {
            const lines: any = getLinesUnderlying(params);
            return mapper.linesTaken(lines);
          }
        },
      },
      {
        headerName: `Spend (${currency}k)`,
        field: 'spend',
        aggFunc: 'sum',
        enableValue: true,
        valueFormatter: thousandsRounded(1),
      },
      {
        headerName: 'New MD Depth %',
        field: 'newDepth',
        valueGetter: params => {
          if (params.data) {
            return percentDecimalFormatter({ value: params.data.newDepth });
          } else {
            const lines: any = getLinesUnderlying(params);
            return percentDecimalFormatter({ value: mapper.newDepth(lines) });
          }
        },
      },
      {
        headerName: 'New Total Depth %',
        field: 'newTotalDepth',
        valueGetter: params => {
          if (params.data) {
            return percentDecimalFormatter({ value: params.data.newTotalDepth });
          } else {
            const lines: any = getLinesUnderlying(params);
            return percentDecimalFormatter({ value: mapper.newTotalDepth(lines) });
          }
        },
      },
    ],
  },
  {
    headerName: 'Projected Result (Forecast)',
    children: [
      {
        headerName: 'Stock Units',
        field: 'endStockUnits',
        aggFunc: 'sum',
        enableValue: true,
        valueFormatter: thousandsFormatter,
      },
      {
        headerName: `Stock Value (${currency}k)`,
        field: 'endStockValue',
        aggFunc: 'sum',
        enableValue: true,
        valueFormatter: thousandsRounded(1),
      },
      {
        headerName: 'Cover',
        field: 'endCover',
        valueGetter: params => {
          if (params.data) {
            return roundingFormatter(1)({ value: params.data.endCover });
          } else {
            const lines: any = getLinesUnderlying(params);
            return roundingFormatter(1)({ value: mapper.endCover(lines) });
          }
        },
      },
      {
        headerName: 'Sell Through %',
        field: 'endSellThrough',
        valueGetter: params => {
          if (params.data) {
            return params.data.endSellThrough;
          } else {
            const lines: any = getLinesUnderlying(params);
            return mapper.endSellThrough(lines);
          }
        },
        cellRenderer: sellThroughRenderer,
      },
    ],
  },
  { headerName: 'Division', field: 'divisionDesc', hide: true, rowGroup: true, enableRowGroup: true },
  { headerName: 'Region', field: 'region', hide: true, rowGroup: true, enableRowGroup: true },
  { headerName: getHeaderName('Department'), field: 'departmentDesc', hide: true, rowGroup: true, enableRowGroup: true },
  { headerName: 'Collection', field: 'collection', hide: true, rowGroup: true, enableRowGroup: true },
];

export function multipleViewColumns(currency) {
  const multipleViewColDef: Array<any> = [
    {
      headerName: 'Current',
      children: [
        {
          headerName: 'Stock Units',
          field: 'currentStockUnits',
          aggFunc: 'sum',
          enableValue: true,
          valueFormatter: thousandsFormatter,
        },
        {
          headerName: `Stock Value (${currency}k)`,
          field: 'currentStockValue',
          aggFunc: 'sum',
          enableValue: true,
          valueFormatter: thousandsRounded(1),
        },
        {
          headerName: 'Cover',
          field: 'currentCover',
          valueGetter: params => {
            if (params.data) {
              return roundingFormatter(1)({ value: params.data.currentCover });
            } else {
              const lines: any = getLinesUnderlying(params);
              return roundingFormatter(1)({ value: mapper.currentCover(lines) });
            }
          },
        },
        {
          headerName: 'Sell Through %',
          field: 'currentSellThrough',
          valueGetter: params => {
            if (params.data) {
              return params.data.currentSellThrough;
            } else {
              const lines: any = getLinesUnderlying(params);
              return mapper.currentSellThrough(lines);
            }
          },
          cellRenderer: sellThroughRenderer,
        },
        {
          headerName: 'MD Depth %',
          field: 'currentDepth',
          valueGetter: params => {
            if (params.data) {
              return percentDecimalFormatter({ value: params.data.currentDepth });
            } else {
              const lines: any = getLinesUnderlying(params);
              return percentDecimalFormatter({ value: mapper.currentDepth(lines) });
            }
          },
        },
        {
          headerName: 'Total Depth %',
          field: 'currentTotalDepth',
          valueGetter: params => {
            if (params.data) {
              return percentDecimalFormatter({ value: params.data.currentTotalDepth });
            } else {
              const lines: any = getLinesUnderlying(params);
              return percentDecimalFormatter({ value: mapper.currentTotalDepth(lines) });
            }
          },
        },
      ],
    },
    {
      headerName: 'MD Action (Forecast)',
      children: [
        {
          headerName: 'Lines Taken',
          field: 'linesTaken',
          valueGetter: params => {
            if (params.data) {
              return params.data.linesTaken;
            } else {
              const lines: any = getLinesUnderlying(params);
              return mapper.linesTaken(lines);
            }
          },
        },
        {
          headerName: `Spend (${currency}k)`,
          field: 'spend',
          aggFunc: 'sum',
          enableValue: true,
          valueFormatter: thousandsRounded(1),
        },
      ],
    },
    {
      headerName: 'Projected Result (Forecast)',
      children: [
        {
          headerName: 'Stock Units',
          field: 'endStockUnits',
          aggFunc: 'sum',
          enableValue: true,
          valueFormatter: thousandsFormatter,
        },
        {
          headerName: `Stock Value (${currency}k)`,
          field: 'endStockValue',
          aggFunc: 'sum',
          enableValue: true,
          valueFormatter: thousandsRounded(1),
        },
        {
          headerName: 'Cover',
          field: 'endCover',
          valueGetter: params => {
            if (params.data) {
              return roundingFormatter(1)({ value: params.data.endCover });
            } else {
              const lines: any = getLinesUnderlying(params);
              return roundingFormatter(1)({ value: mapper.endCover(lines) });
            }
          },
        },
        {
          headerName: 'Sell Through %',
          field: 'endSellThrough',
          valueGetter: params => {
            if (params.data) {
              return params.data.endSellThrough;
            } else {
              const lines: any = getLinesUnderlying(params);
              return mapper.endSellThrough(lines);
            }
          },
          cellRenderer: sellThroughRenderer,
        },
      ],
    },
    { headerName: 'Division', field: 'divisionDesc', hide: true, rowGroup: true, enableRowGroup: true },
    { headerName: 'Region', field: 'region', hide: true, rowGroup: true, enableRowGroup: true },
    { headerName: getHeaderName('Department'), field: 'departmentDesc', hide: true, rowGroup: true, enableRowGroup: true },
    { headerName: 'Collection', field: 'collection', hide: true, rowGroup: true, enableRowGroup: true },
  ];

  return multipleViewColDef;
}

export function collectionView(collections: string[], currency, view) {
  const collectionViewColDef: Array<any> = [
    { headerName: 'Division', field: 'division_desc', hide: true, rowGroup: true, enableRowGroup: true  },
    { headerName: getHeaderName('Department'), field: 'department_desc', hide: true, pinned: 'left', rowGroup: true, enableRowGroup: true  }
  ];

  collections.forEach(collection => {
    collectionViewColDef.push({
      headerName: `${collection}`,
      colId: `${collection}`,
      headerTooltip: `Collection`,
      children: [
        {
          headerName: 'Stock MD',
          field: `stockMd_${collection}`,
          enableValue: true,
          aggFunc: 'sum',
          colId: `stockMd_${collection}`,
          valueFormatter: thousandsFormatter,
          headerTooltip: `Stock for the taken SKUs in the event`,
        },
        {
          headerName: 'Stock Total',
          field: `stockTotal_${collection}`,
          enableValue: true,
          aggFunc: 'sum',
          colId: `stockTotal_${collection}`,
          valueFormatter: thousandsFormatter,
          headerTooltip: `Total department stock for SKUs in that collection (discounts for not)`,
        },
        {
          headerName: '% Stock on MD',
          field: `stockOverMd_${collection}`,
          colId: `stockOverMd_${collection}`,
          valueGetter: params => {
            const field = params.colDef.field;
            if (params.data) {
              return percentDecimalFormatter({ value: params.data[field] });
            } else {
              const lines: any = getUnderlyingData(params);
              return percentDecimalFormatter({ value: mapper.stockOverMD(lines, collection, view) });
            }
          },
          headerTooltip: `% of stock in the collection marked down during the event`,
        },
        {
          headerName: 'MD Depth % (Current)',
          field: `mdDepthCurrent_${collection}`,
          colId: `mdDepthCurrent_${collection}`,
          valueGetter: params => {
            const field = params.colDef.field;
            if (params.data) {
              return percentDecimalFormatter({ value: params.data[field] });
            } else {
              const lines: any = getUnderlyingData(params);
              return percentDecimalFormatter({ value: mapper.mdDepthCurrentByCollection(lines, collection, view) });
            }
          },
          headerTooltip: `Average discount depth from the current price for the taken lines, weighted by stock and price`,
        },
        {
          headerName: 'MD Depth % (Full)',
          field: `mdDepthFull_${collection}`,
          colId: `mdDepthFull_${collection}`,
          valueGetter: params => {
            const field = params.colDef.field;
            if (params.data) {
              return percentDecimalFormatter({ value: params.data[field] });
            } else  {
              const lines: any = getUnderlyingData(params);
              return percentDecimalFormatter({ value: mapper.mdDepthFullByCollection(lines, collection, view) });
            }
          },
          headerTooltip: `Average discount depth from the initial full price for the taken lines, weighted by stock and price`,
        },
        {
          headerName: 'Total Depth %',
          field: `totalDepth_${collection}`,
          colId: `totalDepth_${collection}`,
          valueGetter: params => {
            const field = params.colDef.field;
            if (params.data) {
              return percentDecimalFormatter({ value: params.data[field] });
            } else  {
              const lines: any = getUnderlyingData(params);
              return percentDecimalFormatter({ value: mapper.newTotalDepthByCollection(lines, collection, view) });
            }
          },
          headerTooltip: `Average discount across all lines in the collection (including full price)`,
        },
      ],
    });
  });
  return collectionViewColDef;
}

const defaultGroupSortComparator = (a, b) => {
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  } else {
    return 0;
  }
};

export const autoGroupColumnDef = {
  headerName: 'Group',
  field: 'modelName',
  sortable: true,
  sort: 'asc',
  pinned: 'left',
  defaultGroupSortComparator: defaultGroupSortComparator,
  cellRendererParams: {
    suppressCount: true,
  },
};

export const getContextMenuItems = params => {
  const menu = [
    'copy',
    'copyWithHeaders',
    'paste',
    'separator',
    {
      name: 'Export',
      subMenu: [
        {
          name: 'Excel Export (.xlsx)',
          action: function () {
            params.api.exportDataAsExcel({ sheetName: 'Sparkbox Export' });
          },
        }
      ],
      icon: `<span class ='ag-icon ag-icon-save'></span>`,
    }
  ];
  return menu;
};

export const gridOptions = {
  rowHeight: 28, // SBX 542
  suppressAggFuncInHeader: true,
  groupDefaultExpanded: true,
  suppressColumnVirtualisation: true,
  suppressMakeColumnVisibleAfterUnGroup: true,
  groupRemoveLowestSingleChildren: false,
  onRowDataChanged: params => {
        params.columnApi.autoSizeAllColumns();
  },
  onRowDataUpdated: params => {
        params.columnApi.autoSizeAllColumns();
  },
  getContextMenuItems: getContextMenuItems
};

const getHeaderName = (headerName: string) => {
  if (orgData[0].hasOwnProperty(headerName)) {
    return orgData[0][headerName];
  } else {
    return data[0][headerName];
  }
};
