import { Component, OnInit } from '@angular/core';
import { ModelGridSelectService } from './model-grid-select.service';

import * as _ from 'lodash';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-model-grid-select-cell',
  templateUrl: './model-grid-select-cell.component.html',
  styleUrls: ['./model-grid-select-cell.component.scss'],
})
export class ModelGridSelectCellComponent implements OnInit, ICellRendererAngularComp {
  private params: ICellRendererParams;

  checked = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  constructor(public modelGridSelectService: ModelGridSelectService) {}

  get eventID() {
    const first = _.first(this.params.node.allLeafChildren);

    return first && first.data.eventId;
  }

  ngOnInit() {
    this.modelGridSelectService.idSelected$.subscribe(selectedIDs => {
      if (
        this.params.node.allLeafChildren &&
        _.difference(this.params.node.allLeafChildren.map(({ data }) => data.id), selectedIDs).length === 0
      ) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    });
  }

  refresh(): boolean {
    return false;
  }

  onClick(e: Event) {
    e.stopPropagation();
  }

  toggleTaken(e: Event) {
    const models = this.params.node.allLeafChildren.map(d => d.data);
    const first = _.first(models);
    // if models are in selected, remove them
    if (this.modelGridSelectService.selectedIDs.indexOf(first.id) > -1) {
      const modelsIDs = models.map(dd => dd.id);
      this.modelGridSelectService.selected = this.modelGridSelectService.selected.filter(d => modelsIDs.indexOf(d.id) === -1);
    } else {
      // if models are not in selected, don't remove them
      // @ts-ignore
      this.modelGridSelectService.selected = this.modelGridSelectService.selected.concat([...models]);
    }
    // todo: yikes
    this.modelGridSelectService.idSelected$.next(this.modelGridSelectService.selectedIDs);
  }
}
