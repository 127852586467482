import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';
import { IZone } from '../../../../user';

import * as _ from 'lodash';
import * as moment from 'moment';
import { VariantDetails, EventDetails } from '../../event';

@Component({
  selector: 'app-event-create-edit-stepper-event-region-wave-scheduler',
  templateUrl: './event-create-edit-stepper-event-region-wave-scheduler.component.html',
  styleUrls: ['./event-create-edit-stepper-event-region-wave-scheduler.component.scss'],
})
export class EventCreateEditStepperEventRegionWaveSchedulerComponent implements OnInit, OnChanges, AfterViewInit {
  @Input()
  variants: Array<VariantDetails & { region: IZone }> = [];

  @Input()
  savedVariants: Array<VariantDetails & { region: IZone }> = [];

  @Input()
  eventDetails: EventDetails;

  @Input()
  selectedRegions;

  @Input()
  allowDelete = false;

  @Input()
  removeItemHighlight;

  @Output()
  waveSelected = new EventEmitter();

  @Output()
  variantDateInvalid = new EventEmitter();

  @Output()
  itemsDeleted = new EventEmitter();

  regionRows = {};
  regionItems = {};
  selectedPeriod = 'week';
  schedulerHeight = 0;

  eventStart = new Date();
  eventEnd = new Date();
  filter: string;
  selectedItem = {};
  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.variants) {
      this.setVariantsToChart(changes.variants.currentValue);
    }
    if (changes.eventDetails) {
      this.eventStart = moment(changes.eventDetails.currentValue.startDate).toDate();
      this.eventEnd = moment(changes.eventDetails.currentValue.endDate).toDate();
      this.setVariantsToChart(this.variants);
    }
    if (changes.removeItemHighlight) {
      if (changes.removeItemHighlight.currentValue) {
        this.selectedItem = {};
      }
    }
  }

  onItemClicked(data) {
    // todo: this is brittle
    if (data.item.id.includes("Variant")) {
      this.waveSelected.emit({ item: data.item, edit: data.edit });
      this.selectedItem = data.item;
    }
  }

  onItemsDeleted(items) {
    this.itemsDeleted.emit(items);
  }

  private setVariantsToChart(allVariants: Array<VariantDetails & { region: IZone }>) {
    const rows = {};
    const items = {};

    const regions = _(allVariants)
      .groupBy((variant) =>
        variant.region.id ? variant.region.id : variant.region
      )
      .mapValues((variants) => _(variants).groupBy("wave").value())
      .value();

    _(regions).forEach((waves, region) => {
      rows[region] = {
        id: region,
        label: region,
        expanded: true,
      };

      // item for region has start & end dates of earliest variant start and latest variant end
      const allVariantsForRegion = _(waves)
        .map(d => d)
        .flatten();

      let data;
      if (this.allowDelete) {
        data = {
          children: allVariantsForRegion.value(),
          level: 'region',
          allowRegionDelete: allVariantsForRegion.filter(({ preventDelete }) => preventDelete).value().length === 0,
        };
      }

      const minDate = allVariantsForRegion.minBy(variant => +moment(variant.startDate)).startDate;
      const maxDate = allVariantsForRegion.maxBy(variant => +moment(variant.endDate)).endDate;
      items[region] = {
        id: region,
        label: region,
        rowId: region,
        data,
        time: {
          start: moment(minDate)
            .startOf('day')
            .toDate()
            .getTime(),
          end: moment(maxDate)
            .endOf('day')
            .toDate()
            .getTime(),
        },
        style: {
          background: '#3f51b5',
          cursor: 'pointer',
        },
      };

      _(waves).forEach((variants, wave) => {
        const allVariantsForWave = _(variants)
          .map(d => d)
          .flatten();
        const variantMinDate = allVariantsForWave.minBy(variant => +moment(variant.startDate)).startDate;
        const variantMaxDate = allVariantsForWave.maxBy(variant => +moment(variant.endDate)).endDate;
        if (!wave.includes('Wave')) {
          wave = `Wave ${wave}`;
        }

        rows[wave + region] = {
          id: wave + region,
          label: wave,
          parentId: region,
          expanded: true,
        };

        let data;
        if (this.allowDelete) {
          data = {
            children: allVariantsForRegion.filter(variant => variant.wave.replace(/Wave /g, '') >= wave.replace(/Wave /g, '')).value(),
            level: 'wave',
          };
        }

        items[wave + region] = {
          id: wave + region,
          label: wave + ' ' + region,
          rowId: wave + region,
          data,
          time: {
            start: moment(variantMinDate)
              .startOf('day')
              .toDate()
              .getTime(),
            end: moment(variantMaxDate)
              .endOf('day')
              .toDate()
              .getTime(),
          },
          style: {
            background: '#7bcdd0',
            cursor: 'pointer',
          },
        };

        _(variants).forEach((variant, index) => {
          let alias = variant.alias;
          if (variant.alias.includes('.')) {
            alias = variant.alias.replace(/\./g, '');
          }
          rows[alias + wave + region + index] = {
            id: alias + wave + region + index,
            label: variant.alias,
            parentId: wave + region,
            expanded: true,
          };
          const valid = this.checkDatesValidity(variant.startDate, variant.endDate);
          const border = valid ? 'none' : '1px solid red';

          let data;
          if (this.allowDelete) {
            // todo: I'm sure this logic could make more sense and be more readable
            data = { children: [variant], level: 'variant' };
            // is this the last wave of its type in this region
            const variantInThisRegion = allVariantsForRegion.find(regionVariant => regionVariant.wave === variant.wave);
            // are there waves larger than it
            if (variantInThisRegion) {
              const wavesLargerThanIt = allVariantsForRegion
                .filter(regionVariant => +regionVariant.wave.replace(/Wave /, '') > +variant.wave.replace(/Wave /, ''))
                .value();
              if (wavesLargerThanIt.length > 0) {
                data = { children: [variant], level: 'variant', disableChildren: true };
              }
            }
          }

          items[alias + wave + region + index] = {
            id: alias + wave + region + index + 'Variant',
            label: variant.alias,
            rowId: alias + wave + region + index,
            data,
            time: {
              start: moment(variant.startDate)
                .startOf('day')
                .toDate()
                .getTime(),
              end: moment(variant.endDate)
                .endOf('day')
                .toDate()
                .getTime(),
            },
            valid: valid,
            style: {
              background: '#3fb584',
              cursor: 'pointer',
              border: border,
            },
            wave,
          };
        });
      });
    });
    this.regionRows = rows;
    this.regionItems = items;
    this.validateVariantsDates();
  }
  private checkDatesValidity(variantStart, variantEnd) {
    if (
      moment(this.eventStart)
        .startOf('day')
        .toDate() >
        moment(variantStart)
          .startOf('day')
          .toDate() ||
      moment(this.eventEnd)
        .endOf('day')
        .toDate() <
        moment(variantEnd)
          .endOf('day')
          .toDate()
    ) {
      return false;
    }
    return true;
  }

  private validateVariantsDates() {
    const allItems = Object.values(this.regionItems);
    if (_.some(allItems, ['valid', false])) {
      this.variantDateInvalid.emit(true);
    } else {
      this.variantDateInvalid.emit(false);
    }
  }

  applyFilter(type) {
    this.filter = type;
  }

  toggleSelectedFilter(type) {
    this.filter = type;
  }
}
