import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output, ElementRef, AfterViewInit } from '@angular/core';
import * as moment from 'moment';
import { fromEvent, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import ChartTimelineItemsRowItem from './components/chart-timeline-items-row-item-with-delete';

@Component({
  selector: 'app-gantt-calendar',
  templateUrl: './gantt-calendar.component.html',
  styleUrls: ['./gantt-calendar.component.scss'],
})
export class GanttCalendarComponent implements OnInit, OnChanges, AfterViewInit {
  @Input()
  rows = {};

  @Input()
  items = {};

  @Input()
  columnHeader = {};

  @Input()
  config;

  @Input()
  selectedPeriod;

  @Input()
  startDate = new Date();

  @Input()
  endDate = new Date();

  @Input()
  allowClick;

  @Input()
  filter;

  @Input()
  selectedItem;

  @Output()
  itemClicked = new EventEmitter();

  @Output()
  itemsDeleted = new EventEmitter();

  @Output()
  toggleSelectedFilter = new EventEmitter();

  gstcState;
  schedulerHeight;
  defaultPeriod = 'week';
  showChart;
  constructor(private hostElement: ElementRef) {}

  ngAfterViewInit() {
    this.schedulerHeight = this.hostElement.nativeElement.clientHeight;
    this.updateChartHeight(this.schedulerHeight);
  }

  ngOnInit() {
    const columns = {
      percent: 100,
      resizer: {
        inRealTime: true,
      },
      data: {
        label: {
          id: 'label',
          data: 'label',
          expander: true,
          isHtml: true,
          width: 230,
          minWidth: 100,
          header: {
            content: this.columnHeader,
          },
        },
      },
    };
    this.config = {
      height: 800,
      width: 800,
      list: {
        rows: this.rows,
        columns: columns,
      },
      components: {
        ChartTimelineItemsRowItem: ChartTimelineItemsRowItem,
      },
      chart: {
        items: this.items,
        time: {
          to: moment(this.endDate)
            .toDate()
            .getTime(),
          from: moment(this.startDate)
            .toDate()
            .getTime(),
          period: 'week',
          additionalSpaces: {
            day: { before: 1, after: 1, period: 'week' },
            week: { before: 1, after: 5, period: 'week' },
          },
          zoom: 21,
        },
      },
      actions: {
        'chart-timeline-items-row-item': this.allowClick ? [this.clickAction] : [],
        'list-column-row-expander-toggle': this.allowClick ? [this.expandAction] : [],
      },
    };
    this.showChart = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rows) {
      this.updateChartConfig(changes.rows.currentValue, this.items);
      this.updateChartHeight(this.schedulerHeight);
    }
    if (changes.items) {
      this.updateChartConfig(this.rows, changes.items.currentValue);
      this.updateChartHeight(this.schedulerHeight);
    }
    if (changes.selectedPeriod) {
      this.updateChartPeriod(changes.selectedPeriod);
    }
    if (changes.startDate) {
      this.updateChartDate({ startDate: changes.startDate.currentValue });
      this.updateChartHeight(this.schedulerHeight);
    }
    if (changes.endDate) {
      this.updateChartDate({ endDate: changes.endDate.currentValue });
      this.updateChartHeight(this.schedulerHeight);
    }
    if (changes.filter) {
      this.setFilterOnChart(changes.filter.currentValue);
    }
    if (changes.selectedItem) {
      this.highlightVariant(changes.selectedItem.currentValue);
    }
  }

  onState(state) {
    this.gstcState = state;
  }

  // todo: should not be implemented at this level
  // private onInitItemAction = (element, data) => {
  //   return {
  //     update: (element, { item }) => {
  //       if (item.data && item.data.children) {
  //         const existingElement = element.querySelector('.icon-trash');
  //         if (existingElement) {
  //           existingElement.remove();
  //         }

  //         const node = document.createElement('span');
  //         node.classList.add('icon-trash');
  //         element.appendChild(node);
  //         element.addEventListener('click', e => {
  //           if (e.target.className.indexOf('trash') > -1 && this.itemsDeleted) {
  //             this.itemsDeleted.emit(item.data.children);
  //           }
  //         });
  //       }
  //     },
  //     destroy: (element, { item }) => {
  //       const existingElement = element.querySelector('.icon-trash');
  //       if (existingElement) {
  //         existingElement.remove();
  //       }
  //     },
  //   };
  // }

  private clickAction = (element, data) => {
    const mousedown$ = fromEvent(element, 'mousedown');
    const mouseup$ = fromEvent(element, 'mouseup');
    mousedown$.subscribe((e: MouseEvent) => {
      const clickTimer$ = timer(200);
      mouseup$.pipe(takeUntil(clickTimer$)).subscribe(() => {
        const { item } = data;
        if (!data.item.data) {
          document.querySelectorAll('.highlight-item').forEach(k => k.classList.remove('highlight-item'));
          element.classList.add('highlight-item');
        }
        if (e.target['className'].indexOf('icon-trash') > -1 && !item.data.disableChildren) {
          let message = '';
          if (item.data.level === 'region') {
            message = 'Delete entire region, including all waves and variants?';
          } else if (item.data.level === 'wave') {
            message = 'Delete entire wave, including all variants?';
          } else {
            message = 'Delete variant?';
          }
          if (window.confirm(message)) {
            this.itemsDeleted.next(item.data.children);
          }
        } else {
            if ((e.target['className'].indexOf('icon-pencil') > -1 && item.data.level === 'variant')) {
              this.itemClicked.next({item, edit: true});
            } else {
              this.itemClicked.next({item, edit: false});
            }
        }
      });
    });
    return {
      update(_, newData) {
        data = newData;
      },
    };
  }

  private updateChartConfig(rows, items) {
    if (this.gstcState) {
      this.gstcState.update('config', config => {
        const rowValues = Object.values(config.list.rows);
        const collapsedRows = _(rowValues)
          .map((row: any) => row)
          .filter(r => r.expanded === false)
          .value();
        if (collapsedRows.length > 0) {
          _(collapsedRows).forEach(row => {
            const rowID = row.id;
            rows[row.id].expanded = false;
          });
        }
        config.list.rows = rows;
        config.chart.items = items;
        config.chart.time.zoom = 21;
        config.chart.time.period = 'week';
        config.actions['chart-timeline-items-row-item'] = [this.clickAction];
        return config;
      });
    }
  }

  private updateChartHeight(height) {
    if (this.gstcState) {
      this.gstcState.update('config', config => {
        config.height = height;

        return config;
      });
    }
  }

  private updateChartPeriod(selectedPeriod) {
    if (this.gstcState) {
      this.gstcState.update('config.chart.time.period', period => {
        period = selectedPeriod.currentValue;
        return period;
      });
      if (selectedPeriod.currentValue === 'day') {
        this.gstcState.update('config.chart.time.zoom', zoom => {
          zoom = 21;
          return zoom;
        });
      }
    }
  }

  private updateChartDate({ startDate, endDate }: { startDate?; endDate? }) {
    if (this.gstcState) {
      this.gstcState.update('config', config => {
        if (startDate) {
          config.chart.time.from = moment(startDate)
            .startOf('day')
            .toDate()
            .getTime();
        }
        if (endDate) {
          config.chart.time.to = moment(endDate)
            .endOf('day')
            .toDate()
            .getTime();
        }

        return config;
      });
    }
  }

  private setFilterOnChart(filter) {
    if (this.gstcState) {
      this.gstcState.update('config.list.rows', rows => {
        if (filter === 'region') {
          _(rows).forEach((row: any) => {
            rows[row.id].expanded = false;
          });
        }
        if (filter === 'wave') {
          const waveRows = _(rows)
            .map((row: any) => row)
            .filter(r => r.id.includes('Wave'))
            .value();
          const regionRows = _(rows)
            .map((row: any) => row)
            .filter(r => !r.id.includes('Wave') && !r.id.includes('Variant'))
            .value();
          _(waveRows).forEach(row => {
            rows[row.id].expanded = false;
          });
          _(regionRows).forEach(row => {
            rows[row.id].expanded = true;
          });
        }
        if (filter === 'variant') {
          _(rows).forEach((row: any) => {
            rows[row.id].expanded = true;
          });
        }
        return rows;
      });
    }
  }

  private highlightVariant(selectedItem) {
    if (this.gstcState) {
    this.gstcState.update('config.chart.items', (items) => {
       _(Object.values(items)).forEach((item: any) => item.style.border = 'none');
       if (Object.values(selectedItem).length > 0) {
         items[selectedItem.rowId].style.border = '3px solid #0077c0';
       }
      return items;
    });
  }
  }

  private expandAction = (element, data) => {
    const mousedown$ = fromEvent(element, 'mousedown');
    const mouseup$ = fromEvent(element, 'mouseup');
    mousedown$.subscribe((e: MouseEvent) => {
      const clickTimer$ = timer(200);
      mouseup$.pipe(takeUntil(clickTimer$)).subscribe(() => {
        this.toggleSelectedFilter.emit(null);
      });
    });
    return {
      update(_, newData) {
        data = newData;
      },
    };
  }
}
