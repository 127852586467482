import { Component, OnInit } from '@angular/core';
import {
  DiscountLaddersCreateAction,
  DiscountLaddersUpdateAction,
  DiscountLaddersDeleteAction,
} from '../discount-ladders/state/discount-ladders.actions';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import { DiscountLadderFacadeService } from '../discount-ladder-facade.service';
@Component({
  selector: 'app-discount-ladder-grid-actions-cell',
  templateUrl: './discount-ladder-grid-actions-cell.component.html',
  styleUrls: ['./discount-ladder-grid-actions-cell.component.scss']
})
export class DiscountLadderGridActionsCellComponent implements OnInit {

  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  constructor(
    private discountLadderFacadeService: DiscountLadderFacadeService
  ) { }

  ngOnInit() {
  }

  refresh(): boolean {
    return false;
  }

  removeDiscountLadder() {
    this.params.api.updateRowData({ remove: [this.params.data] });
  }

  deleteDiscountLadder() {
    if (this.params.data.id) {
      this.discountLadderFacadeService.delete(this.params.data);
    }
  }

  saveDiscountLadder() {
    if (this.params.data.id) {
      this.discountLadderFacadeService.update({
          ...this.params.data,
          discounts: this.params.data.discounts && this.params.data.discounts.split(','),
        });
    } else {
      this.discountLadderFacadeService.create({
          ...this.params.data,
          name: this.params.data.ladder || this.params.data.name,
          discounts: this.params.data.discounts && this.params.data.discounts.split(','),
        });
    }
  }

}
