import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../app.reducer';
import { OrgCreateAction } from '../state/org.actions';

@Component({
  selector: 'app-create-org-dialog',
  templateUrl: './create-org-dialog.component.html',
  styleUrls: ['./create-org-dialog.component.scss']
})
export class CreateOrgDialogComponent implements OnInit {
  @Output()
  closeDialog = new EventEmitter();

  name: string;
  db_name: string;
  admin_user: string;

  constructor(
    public store: Store<fromRoot.State>
  ) { }

  ngOnInit() {
  }

  createNewOrg() {
    if (!this.name || !this.db_name) {
      return;
    }
    this.store.dispatch(new OrgCreateAction({ name: this.name, db_name: this.db_name, admin_user: this.admin_user }));
    this.name = null;
    this.db_name = null;
  }

  onCloseDialog() {
    this.closeDialog.emit(true);
  }

}
