import * as org from './org.actions';
import * as _ from 'lodash';

type MarkdownOrPromos = 'markdown' | 'promos';

export interface IOrg {
  db_name: string;
  name: string;
  id: number;
  products: MarkdownOrPromos[];
  users: any;
  last_data_refresh:Date;
  settings: {
    rounding_method_discount_max: number;
    rounding_method_discount_min: number;
    zones_enabled: number;
  };
}

export interface IOrgState {
  orgs: { [key: string]: IOrg };
  ids: string[];
  selectedOrg: any;
}

export const InitialState: IOrgState = {
  orgs: {},
  ids: [],
  selectedOrg: null,
};

export function reducer(state = InitialState, action: org.actions) {
  switch (action.type) {
    case org.ORGGETSUCCESS: {
      return {
        ...state,
        ids: _.map(action.payload, (entity) => entity.id),
        orgs: _.keyBy(action.payload, 'id')
      };
    }
    case org.ORGCREATESUCCESS: {
      return {
        ...state,
        orgs: {
          ...state.orgs,
          [action.payload.id]: action.payload
        },
        ids: [
          ...state.ids,
          action.payload.id
        ],
      };
    }
    case org.ORGSELECT: {
      return {
        ...state,
        selectedOrg: action.payload,
      };
    }
    case org.ORGCREATEUSERSUCCESS: {
      const newState = {
        ...state,
        orgs: {
          ...state.orgs,
          [action.payload.orgID]: {
            ...state.orgs[action.payload.orgID],
            users: state.orgs[action.payload.orgID].users.concat([action.payload])
          }
        }
      };
      return newState;
    }
    case org.ORGGETUSERSSUCCESS:
      return {
        ...state,
        orgs: {
          ...state.orgs,
          [action.payload.id]: {
            ...state.orgs[action.payload.id],
            users: action.payload.users
          }
        }
      };
    case org.ORGTOGGLEUSERACTIVESUCCESS: {
      const newState = _.cloneDeep(state);
      const user = _.find(newState.orgs[action.payload.org.id].users, (u) => u.id === action.payload.id);
      user.active = action.payload.active;

      return newState;
    }
    case org.ORGUPDATEUSERROLESSUCCESS: {
      const newState = _.cloneDeep(state);
      const user = _.find(newState.orgs[action.payload.org.id].users, (u) => u.id === action.payload.id);
      user.role = action.payload.role;

      return newState;
    }
    default:
      return state;
  }
}
