import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap, first, filter, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Line, Model } from '../../state/model';
import * as _ from 'lodash';

const DEFAULT_VIEW = 'SS';
const DEFAULT_REPORT = 'collection';
@Component({
  selector: 'app-model-planning-dialog',
  templateUrl: './model-planning-dialog.component.html',
  styleUrls: ['./model-planning-dialog.component.scss']
})
export class ModelPlanningDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
              lines: Observable<Array<Line & { model: Model }>>,
              region: string, wave: number },
              private router: Router,
              private dialogRef: MatDialogRef<ModelPlanningDialogComponent> ) { }
  lines$: Observable<Array<Line>>;
  collections = [];
  collectionReportRows = [];
  showTotalDetails = true;
  showCollectionDetails;
  view;
  toolTipLinesTaken: string;
  toolTipStockMD: string;
  toolTipStockOnMD: string;
  toolTipMDDepthCurrent: string;
  toolTipMDDepthFull: string;
  toolTipTotalDepth: string;
  tooltipOtherCollections: string;
  ngOnInit() {
    this.view = DEFAULT_VIEW;
    this.lines$ = this.data.lines;
    this.getPlanningData(this.lines$, this.view);
    this.toolTipLinesTaken = `Lines taken from each collection.
    New lines have not yet been discounted and MD+ lines which have previously been discounted.`;
    this.toolTipStockMD = `Stock from the collection being discounted`;
    this.toolTipStockOnMD = `% of the total stock for the collection being marked down`;
    this.toolTipMDDepthCurrent = `Average discount depth from the current price for the taken lines, weighted by stock and price`;
    this.toolTipMDDepthFull = `Average discount depth from the initial full price for the taken lines, weighted by stock and price`;
    this.toolTipTotalDepth = `Average discount depth for all stock in the collection, weighted by stock and price`;
    this.tooltipOtherCollections = `Taken lines from the season not in the view.`;
  }

  getPlanningData(lines$, view) {
    const sortAlphaNum = (a, b) =>
    a.localeCompare(b, 'en', { numeric: true });
    this.lines$.pipe(first(), map(lines => lines))
    .subscribe((lines) => {
      this.collections = _(lines).map((lines) => lines.collectionDesc).uniq().value().filter(collection =>
      _.startsWith(collection, view)).sort(sortAlphaNum);
      const viewOnly = 'Total' + ' ' + this.view;
      this.collections.push(...[viewOnly, 'Other', 'Total']);
      _(this.collections).forEach(collection => {
        const collectionReportRow = {};
        const takenLines = lines.filter(line => line.taken === 'Yes');
        collectionReportRow[`collection`] = collection;
        collectionReportRow[`linesTaken`] = this.numberOfLinesTaken(takenLines, collection, view);
        collectionReportRow[`newLinesTaken`] = this.newLinesTaken(takenLines, collection, view);
        collectionReportRow[`MDLinesTaken`] = this.mdLinesTaken(takenLines, collection, view);
        collectionReportRow[`stockMD`] = this.stockMD(takenLines, collection, view);
        collectionReportRow[`stockMDPct`] = this.stockMDPct(lines, collection, view);
        collectionReportRow[`mdDepthCurrent`] = this.mdDepthCurrentByCollection(lines, collection, view),
        collectionReportRow[`mdDepthFull`] = this.mdDepthFullByCollection(lines, collection, view),
        collectionReportRow[`totalDepth`] = this.newTotalDepthByCollection(lines, collection, view);
        this.collectionReportRows.push(collectionReportRow);
      });
     });
  }

  toggleCollectionDetails() {
    this.showTotalDetails = true;
    this.showCollectionDetails = !this.showCollectionDetails;
  }

  getCollectionLines(data, collection, view) {
    if (collection === 'Other') {
      return data.filter((d) => !(_.startsWith(d.collectionDesc, view)) );
    }
    if (collection === 'Total ' + this.view) {
      return data.filter((d) => (_.startsWith(d.collectionDesc, view)));
    }
    if (collection === 'Total') {
      return data;
    } else {
      return data.filter((d) => d.collectionDesc === collection);
    }
  }

  numberOfLinesTaken(data, collection, view) {
    const linesByCollection = this.getCollectionLines(data, collection, view);
    return linesByCollection.length;
  }

  takenLines(lines) {
    return lines.filter((line) => line.taken === 'Yes');
  }

  untakenLines(lines) {
    return lines.filter((line) => line.taken === 'No');
  }

  newLinesTaken(data, collection, view) {
    const linesTaken = this.getCollectionLines(data, collection, view);
    return linesTaken.filter(line => (line.lastWavePrice ? line.lastWavePrice : (line.lastWavePrice ? line.lastWavePrice : line.currentPrice)) === line.fullPrice).length;
  }

  mdLinesTaken(data, collection, view) {
    const linesTaken = this.getCollectionLines(data, collection, view);
    return linesTaken.filter(line => (line.lastWavePrice ? line.lastWavePrice : (line.lastWavePrice ? line.lastWavePrice : line.currentPrice)) < line.fullPrice).length;
  }

  getDiscountedLines(lines) {
    return lines
      .filter((line) => ((line.taken === 'Yes') || ((line.lastWavePrice ? line.lastWavePrice : line.currentPrice) < line.fullPrice)));
  }

  stockMD(data, collection, view) {
    const linesByCollection = this.getCollectionLines(data, collection, view);
    return linesByCollection.reduce((acc, line) => acc + line.stockOnHand, 0);
  }

  stockMDPct(data, collection, view) {
    const linesByCollection = this.getCollectionLines(data, collection, view);
    const linesTakenByCollection = this.takenLines(linesByCollection);
    const stockMDTaken = linesTakenByCollection.reduce((acc, line) => acc + line.stockOnHand, 0);
    const totalStock = linesByCollection.reduce((acc, line) => acc + line.stockOnHand, 0);
    if (!stockMDTaken) {
      return 0;
    }
    return stockMDTaken / totalStock;
  }

  mdDepthCurrentByCollection(data, collection, view) {
    const linesByCollection = this.getCollectionLines(data, collection, view);
    const linesTakenByCollection = this.takenLines(linesByCollection);
    const finalPriceSum = linesTakenByCollection
    .reduce((acc, line) => acc + (line.finalPrice * line.stockOnHand), 0);
    const currentPriceSum = linesTakenByCollection
      .reduce((acc, line) => acc + ((line.lastWavePrice ? line.lastWavePrice : line.currentPrice) * line.stockOnHand), 0);

    if (!currentPriceSum) {
      return 0;
    }

    return 1 - (finalPriceSum / currentPriceSum);
  }

  mdDepthFullByCollection(data, collection, view) {
    const linesByCollection = this.getCollectionLines(data, collection, view);
    const linesTakenByCollection = this.takenLines(linesByCollection);
    const finalPriceSum = linesTakenByCollection
      .reduce((acc, line) => acc + (line.finalPrice * line.stockOnHand), 0);
    const fullPriceSum = linesTakenByCollection
      .reduce((acc, line) => acc + (line.fullPrice * line.stockOnHand), 0);

    if (!fullPriceSum) {
      return 0;
    }

    return 1 - (finalPriceSum / fullPriceSum);
  }

  newTotalDepthByCollection(data, collection, view) {
    const linesByCollection = this.getCollectionLines(data, collection, view);
    const takenLines = this.takenLines(linesByCollection);
    const untakenLines = this.untakenLines(linesByCollection);
    const finalPriceSumTaken = takenLines
      .reduce((acc, line) => acc + (line.finalPrice * line.stockOnHand), 0);
    const finalPriceSumUntaken = untakenLines
      .reduce((acc, line) => acc + ((line.lastWavePrice ? line.lastWavePrice : line.currentPrice) * line.stockOnHand), 0);
    const currentPriceSum = linesByCollection
      .reduce((acc, line) => acc + ((line.lastWavePrice ? line.lastWavePrice : line.currentPrice) * line.stockOnHand), 0);

    if (!currentPriceSum) {
      return 0;
    }

    return 1 - ((finalPriceSumTaken + finalPriceSumUntaken) / currentPriceSum);

  }
  switchView(collection) {
    this.view = collection;
    this.collectionReportRows = [];
    this.getPlanningData(this.lines$, this.view);
  }
  goToReports() {
    this.lines$.pipe(first())
      .subscribe((lines: (Line & { model: Model})[]) => {
        const eventID = _.first(lines).model.eventId;
        this.dialogRef.close();
        this.router.navigateByUrl(`/reporting/${eventID}/${DEFAULT_REPORT}/${this.data.region}/${this.data.wave}/${DEFAULT_VIEW}`);
      });
  }

}
