import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { getVariantIDs } from './state/variants.selectors';
import { tap, filter, first, switchMap, catchError } from 'rxjs/operators';
import { VariantsGetAction } from './state/variants.actions';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';

@Injectable({
  providedIn: 'root'
})
export class VariantsGuard implements CanActivate {
  constructor(private store: Store<fromRoot.State>) { }

  getFromStoreOrAPI(): Observable<any> {
    return this.store
    .pipe(
        select(getVariantIDs),
        tap((variantsIDs: any) => {
          if (!variantsIDs) {
            this.store.dispatch(new VariantsGetAction());
          }
        }),
        filter((variantsIDs: any) => variantsIDs),
        first()
      );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      return this.getFromStoreOrAPI()
      .pipe(
        switchMap(() => of(true)),
        catchError(() => of(false))
      );
  }
}
