import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { filter, tap, takeUntil, map, withLatestFrom } from 'rxjs/operators';
import { Subject, Observable, of } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';

import * as fromRoot from '../../../../app.reducer';
import { getCurrentUser } from '../../../../user/user.reducer';
import { columns, sideBar, gridOptions } from './models.grid-config';
import { EventModelStatus, EventStatus } from '../../../../abstracts/event-status.type';
import { RegionPipe } from '../../../../user/region.pipe';
import { ModelGridSelectService } from '../model-grid-select-cell/model-grid-select.service';
import { ModelFacadeService } from '../../model-facade.service';
import { Model } from '../../state/model';
import { UserFacadeService } from '../../../../user/user-facade.service';
import { GridReadyEvent } from 'ag-grid-community';

@Component({
  selector: 'app-models',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.scss'],
})
export class ModelsComponent implements OnInit, OnDestroy {
  showModelSummaryDialog = false;
  modelForSummaryDialog$;

  componentDestroy$ = new Subject();

  columns = columns(this.regionPipe);
  sidebar = sideBar;
  gridOptions = gridOptions;

  user$: any;

  allModels: Model[];
  visibleModels: Model[];
  quickFilterText: string;

  filters$ = new Subject();
  rowClick$ = new Subject();

  defaultStatusFilter = true;

  @Input()
  filterValueChanged: boolean;

  api: any;
  statusList = [];

  constructor(
    private router: Router,
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private regionPipe: RegionPipe,
    public modelGridSelectService: ModelGridSelectService,
    private modelFacadeService: ModelFacadeService,
    private userFacadeService: UserFacadeService
  ) {
    this.modelGridSelectService.selected = [];
  }

  ngOnInit() {
    gridOptions.groupDefaultExpanded = 0;
    this.user$ = this.store.pipe(select(getCurrentUser), takeUntil(this.componentDestroy$));
    this.user$.pipe(filter((user: any) => !!user.env)).subscribe(user => {
      if (!user.name) {
        this.router.navigate(['/login']);
      }
    });

    this.route.queryParams.pipe(takeUntil(this.componentDestroy$)).subscribe(params => {
      if (params.eventID) {
        setTimeout(() => {
          this.filters$.next({
            condition: [String(params.eventID)],
            field: 'eventId',
          });
        }, 125);
      }
    });

    const modelGroups$: Observable<Model[]> = this.modelFacadeService.getModelGroups();

    modelGroups$.pipe(takeUntil(this.componentDestroy$)).subscribe(models => {
      this.allModels = models;
      this.visibleModels = models;
      this.statusList = _(this.visibleModels).map(event => event.status).uniq().value();
    });
  }

  ngOnDestroy() {
    this.componentDestroy$.next();
  }

  onCloseDialog(closeDialog) {
    if (!closeDialog) {
      return;
    }
    this.modelForSummaryDialog$ = null;
  }

  onRowClick(event) {
    //disable model summary popup
    /*if (!event.data) {
      const firstModel: Model = _.first(event.node.allLeafChildren);
      let firstRun = true;
      this.rowClick$.next();
      this.modelForSummaryDialog$ = this.modelFacadeService
        .getRelatedModels(firstModel.id)
        .pipe(
          tap((models) => {
            if (firstRun) {
              const modelIDs = models
                .filter(({ lines }) => !lines)
                .map(({ id }) => id);
              if (modelIDs.length > 0) {
                this.modelFacadeService.getLines({ modelIDs });
              }
              firstRun = false;
            }
          }),
          filter(
            (models) =>
              models.map((model) => !!model.lines).indexOf(false) === -1
          ),
          takeUntil(this.rowClick$),
          withLatestFrom(this.userFacadeService.getLastDataRefresh()),
          map(([models, orgLastDataRefresh]) => ({
            ...models[0],
            variant: _(this.allModels).filter((model) => model.id === models[0].id).map((m) => m.variant).flatten().value(),
            hideVariant: true,
            allLines: _(models)
              .map((m) => m.lines)
              .flatten()
              .value(),
            numberOfRegions: models.length,
            lastOptimisationDate: moment
              .min(models.map((d) => moment(d.lastOptimisationDate)))
              .toDate(),
            orgLastDataRefresh,
            lowestStatus: _(models)
              .map(({ status }) => EventModelStatus[status])
              .sort()
              .first(),
            relatedModels: models,
          }))
        );
    } else {
      this.modelForSummaryDialog$ = of(event.data).pipe(
        withLatestFrom(this.userFacadeService.getLastDataRefresh()),
        map(([model, orgLastDataRefresh]) => {
          return { ...model, orgLastDataRefresh, lowestStatus: EventModelStatus[model.status], relatedModels: [model],
          variant: model.variant };
        }, takeUntil(this.rowClick$))
      );
    } */
  }

  onEventTypeClick(e: string[]) {
    this.filters$.next({
      field: 'eventType',
      condition: e,
    });
  }

  onDateRangeChange([_fromDate, _toDate]) {
    const fromDate = _fromDate || new Date('Jan 1, 2001');
    const toDate = _toDate || new Date('Dec 31, 2050');
    this.visibleModels = this.allModels.filter(model => {
      return (
        (+moment(model.startDate) >= +moment(fromDate) && +moment(model.startDate) <= +moment(toDate)) ||
        (+moment(model.endDate) >= +moment(fromDate) && +moment(model.endDate) <= +moment(toDate))
      );
    });
  }

  onStatusClicked(e: EventStatus[]) {
    this.filters$.next({
      field: 'modelStatus',
      condition: e.map(eventStatus => EventModelStatus[eventStatus]),
      allStatusValues: this.statusList
    });
  }

  onQuickFilterTextChange(e: string) {
    this.quickFilterText = e;
  }

  onClickEditSelectedModels() {
    const modelIDs = this.modelGridSelectService.selected.map(d => d.id);
    this.modelFacadeService.selectModels(modelIDs);
    this.modelGridSelectService.idSelected$.next([]);
  }

  onFilterCleared(e) {
    let condition: null;
    this.filters$.next({
      condition,
    });
  }

  filterChanged(e) {
    this.filterValueChanged = e;
  }

  // SBX-782 Default filter for status remove - Complete, Closed
  onGridReady(e?: GridReadyEvent) {
    this.filters$.next({
      field: 'modelStatus',
      condition: ['Planning', 'Submitted', 'Approved', 'Live'],
      allStatusValues: this.statusList
    });
  }
}
