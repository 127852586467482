import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import * as _ from 'lodash';

import * as fromRoot from '../../app.reducer';
import { getPriceLadders } from './price-ladders/state/price-ladders.selectors';
import { GridOptions, GridApi } from 'ag-grid-community';
import { PriceLadderGridActionsCellComponent } from './price-ladder-grid-actions-cell/price-ladder-grid-actions-cell.component';

import { map, first, filter } from 'rxjs/operators';
import { IHierarchyMapParams } from '../model/state/model';

import { getOrgSetting, getMerchHierarchy, getSeasons } from '../../user/user.reducer';
import { UserSetOrgSettingAction } from '../../user/user.actions';
import { DiscountLadderGridActionsCellComponent } from './discount-ladder-grid-actions-cell/discount-ladder-grid-actions-cell.component';
import { getDiscountLadders } from './discount-ladders/state/discount-ladders.selectors';
import { UserFacadeService } from '../../user/user-facade.service';
import { SkuListGridActionsCellComponent } from './sku-list-grid-actions-cell/sku-list-grid-actions-cell.component';
import { SettingsBulkSkuDialogComponent } from './settings-bulk-sku-dialog/settings-bulk-sku-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent {
  priceLadders$: Observable<any>;
  merchHierarchy$: Observable<any[]>;
  discountLadders$: Observable<any>;

  columns = [
    {
      headerName: "PRICE LADDER",
      field: "name",
      editable: true,
      minWidth: 210,
      maxWidth: 210,
    },
    { headerName: "PRICES", field: "prices", editable: true },
    {
      headerName: "",
      cellRendererFramework: PriceLadderGridActionsCellComponent,
      suppressMenu: true,
      sortable: false,
      minWidth: 60,
      maxWidth: 60,
    },
  ];

  discountColumns = [
    {
      headerName: "DISCOUNT LADDER",
      field: "name",
      editable: true,
      minWidth: 210,
      maxWidth: 210,
    },
    { headerName: "Discounts", field: "discounts", editable: true },
    {
      headerName: "",
      cellRendererFramework: DiscountLadderGridActionsCellComponent,
      suppressMenu: true,
      sortable: false,
      minWidth: 60,
      maxWidth: 60,
    },
  ];

  skuListColumns = [
    {
      headerName: "SKU ID",
      field: "sku_id",
      minWidth: 210,
      maxWidth: 210,
      cellClassRules: {
        "include-text": (params) => {
          return params.data.list === "Included";
        },
        "exclude-text": (params) => {
          if (params.data) {
            return params.data.list === "Excluded";
          }
        },
      },
    },
    { headerName: "Description", field: "sku_desc" },
    { headerName: "Collection", field: "collection_desc" },
    { headerName: "Season", field: "season_desc" },
    { headerName: "List", field: "list" },
    {
      headerName: "",
      cellRendererFramework: SkuListGridActionsCellComponent,
      suppressMenu: true,
      sortable: false,
      minWidth: 60,
      maxWidth: 60,
    },
  ];

  priceLadderGridApi: GridApi;
  discountLadderGridApi: GridApi;
  skuListGridApi: GridApi;
  maxDiscount: any;
  minDiscount: any;
  departments$; // : Observable<Array<string>>;
  deptName: FormControl;
  deptListChanged = false;
  public skuListForm: FormGroup;
  skuId: FormControl;
  allSkuList$: Observable<any>;
  skuList = [];
  includedIds = [];
  excludedIds = [];
  existingSkuList$ :Observable<any>;
  filterSelected;
  priceLadderGridOptions: GridOptions = {
    onGridReady: (e) => {
      this.priceLadderGridApi = e.api;
      e.api.sizeColumnsToFit();
    },
    getRowClass: (params) => {
      if (params.data.new) {
        return "new";
      }
      if (params.data.changed) {
        return "new";
      }
    },
    onCellValueChanged: (params) => {
      params.data.changed = true;
      params.api.refreshCells();
      params.api.redrawRows();
    },
    onRowDataChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    // deltaRowDataMode: true,
  };

  discountLadderGridOptions: GridOptions = {
    onGridReady: (e) => {
      this.discountLadderGridApi = e.api;
      e.api.sizeColumnsToFit();
    },
    getRowClass: (params) => {
      if (params.data.new) {
        return "new";
      }
      if (params.data.changed) {
        return "new";
      }
    },
    onCellValueChanged: (params) => {
      params.data.changed = true;
      params.api.refreshCells();
      params.api.redrawRows();
    },
    onRowDataChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    // deltaRowDataMode: true,
  };

  skuListGridOptions: GridOptions = {
    onGridReady: (e) => {
      this.skuListGridApi = e.api;
      e.api.sizeColumnsToFit();
    },
    onCellValueChanged: (params) => {
      params.data.changed = true;
      params.api.refreshCells();
      params.api.redrawRows();
    },
    onRowDataChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
  };

statusBar = {
  statusPanels: [
    {
      statusPanel: 'agTotalAndFilteredRowCountComponent',
      align: 'left',
    },
    {
      statusPanel: 'agTotalRowCountComponent',
      align: 'center',
    },
    { statusPanel: 'agFilteredRowCountComponent' },
    { statusPanel: 'agSelectedRowCountComponent' },
    { statusPanel: 'agAggregationComponent' },
  ],
}

enableRegions: any;
dataStoreDepts: any;
seasons$: Observable<any>;
seasonName: FormControl;
seasonChanged: boolean;
id = '';
constructor(private dialog: MatDialog,
    public store: Store<fromRoot.State>,
    public userFacadeService: UserFacadeService
  ) {
    this.store
      .pipe(select(getOrgSetting(['rounding_method_discount_max', 'rounding_method_discount_min', 'zones_enabled',
       'departments', 'seasons', 'included_lines', 'excluded_lines'])))
      .subscribe(({ rounding_method_discount_min, rounding_method_discount_max, zones_enabled, departments,
         seasons, included_lines, excluded_lines }) => {
        this.minDiscount = +rounding_method_discount_min;
        this.maxDiscount = +rounding_method_discount_max;
        this.enableRegions = zones_enabled;
        this.dataStoreDepts = departments;
        this.seasonName = new FormControl(seasons);
        this.includedIds = included_lines;
        this.excludedIds = excluded_lines;
        }
      );
    this.priceLadders$ = this.store.pipe(select(getPriceLadders));
    this.discountLadders$ = this.store.pipe(select(getDiscountLadders));
    this.merchHierarchy$ = this.store.pipe(
      select(getMerchHierarchy),
      map(d =>
        d.map(dd => {
          const newObj = {};
          Object.keys(dd).forEach(key => {
            if (dd[key] !== '' && +dd[key] !== 0) {
              newObj[key] = dd[key];
            }
          });

          return newObj;
        })
      )
    );

    this.departments$ = this.merchHierarchy$.pipe(
      map(mhMapping =>
        _(mhMapping.map(mh => ({ hierarchyId: mh.h2Id, hierarchyDesc: mh.h2Desc })))
          .uniqBy('hierarchyId')
          .orderBy(['hierarchyDesc'])
          .value()
      )
    );
    this.deptName = new FormControl(this.dataStoreDepts);

    this.seasons$ = this.store.pipe(
      select(getSeasons),
      map((seasons) => (seasons).sort((a, b) => b.localeCompare(a)))
    );

    this.allSkuList$ = this.userFacadeService.getAllSkuList();

    this.existingSkuList$ = this.allSkuList$.pipe(map(sku => sku.filter((sku) =>
    _(this.includedIds).includes(sku.sku_id) || _(this.excludedIds).includes(sku.sku_id))
    .map((sku) => (
        { ... sku,
          list: _(this.includedIds).includes(sku.sku_id) ? 'Included' : 'Excluded',
        })
        )
    ));

    this.skuListForm = new FormGroup({ sku: new FormControl('') });
  }

createPriceLadder() {
    this.priceLadderGridApi.addItems([{ name: 'New Price Ladder', prices: '', new: true }]);
  }

createDiscountLadder() {
    this.discountLadderGridApi.addItems([{ name: 'New Discount Ladder', discounts: '', new: true }]);
  }

onHierarchyMapped(e: IHierarchyMapParams) {
    this.userFacadeService.mapMerchHierarchy(e);
  }

updateMinDiscountRoundPrice(e) {
    this.store.dispatch(new UserSetOrgSettingAction({ name: 'rounding_method_discount_min', value: String(this.minDiscount) }));
  }

updateMaxDiscountRoundPrice(e) {
    this.store.dispatch(new UserSetOrgSettingAction({ name: 'rounding_method_discount_max', value: String(this.maxDiscount) }));
  }

deptSelected() {
    this.deptListChanged = !_.isEqual(this.deptName.value.sort(), this.dataStoreDepts.sort());
  }

saveDepartments() {
    this.store.dispatch(new UserSetOrgSettingAction({ name: 'departments', value: this.deptName.value }));
  }

seasonSelected() {
    this.seasonChanged = true;
  }

saveSeasons() {
    this.store.dispatch(new UserSetOrgSettingAction({name: 'seasons', value: this.seasonName.value }));
  }

saveIncludedSkuList() {
  this.store.dispatch(new UserSetOrgSettingAction({ name: 'included_lines', value: this.includedIds }));
  }

saveExcludedSkuList() {
   this.store.dispatch(new UserSetOrgSettingAction({ name: "excluded_lines", value: this.excludedIds }));
  }

addSkuToInclude() {
    const addedSku = this.skuListForm.value.sku;
    let skuInList = false;
    this.skuListGridApi.forEachNode((row) => {
      if (addedSku && row.data.sku_id === addedSku.sku_id) {
        skuInList = true;
      }
    });
    if (!skuInList && addedSku) {
      this.includedIds.push(addedSku.sku_id);
      this.addItemsToSkuGrid(addedSku, 'Included');
      this.saveIncludedSkuList();
    }
  }

addSkuToExclude() {
    const excludedSku = this.skuListForm.value.sku;
    let skuInList = false;
    this.skuListGridApi.forEachNode((row) => {
      if (excludedSku && row.data.sku_id === excludedSku.sku_id) {
        skuInList = true;
      }
    });
    if (!skuInList && excludedSku) {
      this.excludedIds.push(excludedSku.sku_id);
      this.addItemsToSkuGrid(excludedSku, 'Excluded');
      this.saveExcludedSkuList();
    }
  }

addItemsToSkuGrid(item, list) {
    this.skuListGridApi.addItems([
      {
        sku_id: item.sku_id,
        sku_desc: item.sku_desc,
        collection_desc: item.collection_desc,
        season_desc: item.season_desc,
        list,
      },
    ]);
  }

openBulkSkuDialog() {
    const dialogRef = this.dialog.open(SettingsBulkSkuDialogComponent, {
      width: '59vw',
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(data => {
        if (data) {
          if (data.listName === '') {
            this.bulkSkuRemove(data);
          } else {
            this.bulkSkuAdd(data);
          }
        }
      });
  }

bulkSkuRemove(data) {
    let includedDeleted;
    let excludedDeleted;

    this.skuListGridApi.forEachNode((row) => {
      if (_(data.skus).includes(row.data.sku_id)) {
        if (row.data.list === 'Included') {
          includedDeleted = true;
          this.includedIds = this.includedIds.filter(skuId => skuId !== row.data.sku_id);
        } else {
          excludedDeleted = true;
          this.excludedIds = this.excludedIds.filter(skuId => skuId !== row.data.sku_id);
        }
        this.skuListGridApi.updateRowData({ remove: [row.data] });
      }
    });
    this.saveIncludedSkuList();
    this.saveExcludedSkuList();
  }

bulkSkuAdd(data) {
    const gridRows = [];
    let itemsAdded;
    this.skuListGridApi.forEachNode((row) => {
      gridRows.push(row);
    });
    this.allSkuList$.pipe(first(), map(allSku => allSku.filter(sku => _(data.skus).includes(sku.sku_id)))).subscribe((skuInfo) => {
      _(skuInfo).forEach(sku => {
        if (!_(gridRows.map(row => row.data.sku_id)).includes(sku.sku_id)) {
          this.addItemsToSkuGrid(sku, data.listName);
          itemsAdded = true;
          if (data.listName === 'Included') {
            this.includedIds.push(sku.sku_id);
          } else {
            this.excludedIds.push(sku.sku_id);
          }
        }
      });
      if (data.listName === 'Included' && itemsAdded) {
        this.saveIncludedSkuList();
      } else if (data.listName === 'Excluded' && itemsAdded) {
        this.saveExcludedSkuList();
      }
    });
  }


applyFilter(condition) {
    const filterComponent = this.skuListGridApi.getFilterInstance('list');
    const filterModel = filterComponent.getModel();
    if (condition) {
      if (filterModel && (filterModel.values).includes(condition)) {
        filterComponent.setModel(null);
        this.filterSelected = null;
      } else {
        this.filterSelected = condition;
        filterComponent.setModel({ values: [condition] });
      }
      this.skuListGridApi.onFilterChanged();
    }
  }
}
