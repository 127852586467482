import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import {
  DiscountLadderAssignmentGridMappingCellComponent,
} from './discount-ladder-assignment-grid-mapping-cell/discount-ladder-assignment-grid-mapping-cell.component';
import { IDiscountLadder } from '../discount-ladders';
import { Subject } from 'rxjs';
import { MerchHierarchyLineage, IHierarchyMapParams } from '../../model/state/model';
import * as _ from 'lodash';

@Component({
  selector: 'app-discount-ladder-assignment',
  templateUrl: './discount-ladder-assignment.component.html',
  styleUrls: ['./discount-ladder-assignment.component.scss'],
})
export class DiscountLadderAssignmentComponent implements OnInit {
  @Input()
  merchHierarchy: MerchHierarchyLineage[];

  @Input()
  discountLadders: IDiscountLadder[];

  @Output()
  private hierarchyMapped: EventEmitter<any> = new EventEmitter();

  rowUpdatedSubject = new Subject();

  gridOptions: GridOptions = {
    context: {
      parentComponent: this,
    },
    deltaRowDataMode: true,
    getRowNodeId: (data) => data.id,
    onRowDataUpdated: (e) => {
      this.rowUpdatedSubject.next({x: true});
      this.showLoadingOverlay = false;
      e.api.sizeColumnsToFit();
    },
    onGridReady: (e) => {
      e.api.sizeColumnsToFit();
    },
    autoGroupColumnDef: {
      headerName: 'Group',
      cellRendererParams: {
        suppressCount: true,
        suppressDoubleClickExpand: true,
      },
    },
  };

  columns: ColDef[] = [
    {field: 'h1Desc', headerName: 'h1Desc', rowGroup: true, hide: true},
    {field: 'h2Desc', headerName: 'h2Desc', rowGroup: true, hide: true},
    {
      headerName: '',
      cellRendererFramework: DiscountLadderAssignmentGridMappingCellComponent,
      suppressMenu: true,
      sortable: false,
      // minWidth: 60,
      // maxWidth: 60,
    },
  ];

  constructor() { }
  showLoadingOverlay;

  mapHierarchy(e: IHierarchyMapParams) {
    this.hierarchyMapped.emit(e);
    this.showLoadingOverlay = true;
  }

  ngOnInit() {
  }
}
