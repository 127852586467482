import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-settings-bulk-sku-dialog',
  templateUrl: './settings-bulk-sku-dialog.component.html',
  styleUrls: ['./settings-bulk-sku-dialog.component.scss']
})
export class SettingsBulkSkuDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<SettingsBulkSkuDialogComponent>, ) { }
  skuPasted = '';
  listName;

  ngOnInit() {
  }

  bulkAction(listName) {
    this.listName = listName;
    this.closeDialog();
  }

  closeDialog() {
    const skuList = this.skuPasted.split(/\r?\n/).filter(d => !!d);
    this.dialogRef.close({ skus: skuList, listName: this.listName });
  }
}
