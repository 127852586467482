import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';
import { map, pluck } from 'rxjs/operators';

import { DATE_FORMAT, CURRENT_DATE_OVERRIDE_FOR_MODEL_LINE_PREDICTION, EventModelStatus, GRAPH_QL_ENDPOINT } from '../../../abstracts';
import {
  Model,
  CreateModelParams,
  UpdateModelParams,
  Line,
  GetLineOverridePredictionParams,
  LineOverridePrediction,
  DiscountDistribution,
  DiscountDistributionMetric,
  ModelDetails,
} from './model';
import { Observable, forkJoin, of } from 'rxjs';

function normalizeModelMapper(model: Model): Model {
  const firstEvent = _.first(model.event);
  const eventName = firstEvent && firstEvent.name;
  const eventType = firstEvent && firstEvent.eventType;
  const status = EventModelStatus[model.status];
  const statusCode = +model.status;
  const variant = firstEvent && firstEvent.variant && _.uniqBy(model.variant.concat(firstEvent.variant), v => v && v.id);
  const totalLines = model.totalLines || 0;

  return {
    ...model,
    eventName,
    eventType,
    status,
    statusCode,
    variant,
    totalLines,
  };
}

function normalizeLineMapper(line) {
  return {
    age: line.age,
    avgCover: line.avg_cover,
    avgSales: line.avg_sales,
    avgSalesStore: line.avg_sales_store,
    avgSellingPrice: line.avg_selling_price,
    categoryDesc: line.category_desc,
    changeRecommended: line.change_recommended,
    collectionDesc: line.collection_desc,
    colourDesc: line.colour_desc,
    commitment: line.commitment,
    currentMargin: line.current_margin,
    currentPrice: line.current_price,
    currentSt: line.current_st,
    departmentDesc: line.department_desc,
    divisionDesc: line.division_desc,
    exitDate: line.exit_date,
    fcstEndCover: line.fcst_end_cover,
    fcstEndSales: line.fcst_end_sales,
    fcstEndSt: line.fcst_end_st,
    fcstEndStock: line.fcst_end_stock,
    fcstMdSales: line.fcst_md_sales,
    fcstPremdSales: line.fcst_premd_sales,
    fcstSpend: line.fcst_spend,
    fcstStartSales: line.fcst_start_sales,
    fcstStartSt: line.fcst_start_st,
    fcstStartStock: line.fcst_start_stock,
    finalDisc: line.final_disc,
    finalMargin: line.final_margin,
    finalPrice: line.final_price,
    fullPrice: line.full_price,
    lwCover: line.lw_cover,
    lwSales: line.lw_sales,
    mdCount: line.md_count,
    modelId: line.model_id,
    modelName: line.model_name,
    optDisc: line.opt_disc,
    optMargin: line.opt_margin,
    optPrice: line.opt_price,
    ovrPrice: line.ovr_price,
    season: line.season,
    skuDesc: line.sku_desc,
    skuId: line.sku_id,
    stockOnHand: line.stock_on_hand,
    storesCount: line.stores_count,
    taken: line.taken,
    totalSales: line.total_sales,
    totalStock: line.total_stock,
    unitCost: line.unit_cost,
    note: line.note,
    ageStore: line.age_store,
    lastPriceChangeDate: line.last_price_change_date,
    lastWavePrice: line.last_wave_price,
    mdCountEvent: line.md_count_event,
    currentPriceEvent: line.current_price_event,
    salesGammeId: line.sales_gamme_id
  };
}

function normalizeLineOverridePredictionMapper(
  eventID,
  modelID
): (params: {
  skuid: string;
  optimised_price: number;
  gross_sales_event: number;
  gross_sales_lw_event: number;
  stock_event: number;
  fcst_spend: number;
  final_disc: number;
  final_margin: number;
  fcst_end_st: number;
  final_price: number;
}) => Partial<Line> {
  return ({
    skuid,
    optimised_price,
    gross_sales_event,
    gross_sales_lw_event,
    stock_event,
    fcst_spend,
    final_disc,
    final_margin,
    fcst_end_st,
    final_price,
  }) => ({
    skuId: skuid,
    eventID,
    modelId: modelID,
    ovrPrice: optimised_price,
    fcstMdSales: gross_sales_event,
    fcstEndSales: gross_sales_lw_event,
    fcstEndStock: stock_event,
    fcstSpend: fcst_spend,
    finalDisc: final_disc,
    finalMargin: final_margin,
    finalPrice: final_price,
    fcstEndSt: fcst_end_st,
  });
}

const allModelAttributes = `
        id
        taskStatus
        region
        regionCode
        regionWebCode
        eventId
        name
        variantId
        departmentId
        departmentDesc
        type
        wave
        estimateSpend
        totalLines
        linesTaken
        minDiscReq
        eventId
        lastEdit
        lastEditUser
        lastOptimisationDate
        status
        owner
        variantId
   `;

@Injectable({
  providedIn: 'root',
})
export class ModelService {
  constructor(private http: HttpClient) {}

  loadAll(): Observable<Array<Model>> {
    const query = `{
      models {
        ${allModelAttributes}
      }
    }`;

    return (
      this.http
        .post<any>(GRAPH_QL_ENDPOINT, query)
        // return this.http.post<any>('/mock/api/models', query)
        .pipe(map(({ models }) => models.map(normalizeModelMapper)))
    );
  }

  createWizard(models: CreateModelParams[]): Observable<Model[]> {
    const createModels = models.map(model => this.create(model));

    return forkJoin(createModels);
  }

  create({ eventId, name, variantId, departmentId, region, minDiscReq, wave }: CreateModelParams) {
    const query = `
      mutation {
        createModel(
          eventId:${eventId},
          name:"${name}",
          variantId:${variantId},
          departmentId:"${departmentId}",
          region: "${region}",
          minDiscReq: ${+minDiscReq}
          wave:${wave},
          ) {
            ${allModelAttributes}
        }
      }
    `;

    return this.http
      .post<{ createModel: Model[] }>(GRAPH_QL_ENDPOINT, query)
      .pipe(map(({ createModel }) => _.first(createModel.map(normalizeModelMapper))));
  }

  update({ id, modelName, departmentId, startDate, endDate }: UpdateModelParams): Observable<Partial<Model>> {
    const query = `mutation {
      updateModel(
          id: ${id},
          name:"${modelName}",
          departmentId:"${departmentId}",
          startDate: "${startDate.format(DATE_FORMAT)}",
          endDate: "${endDate.format(DATE_FORMAT)}"
          ) {
        id
        name
        startDate
        endDate
        departmentId
      }
    }`;

    return this.http.post<{ updateModel: Array<Partial<Model>> }>(GRAPH_QL_ENDPOINT, query).pipe(map(data => _.first(data.updateModel)));
  }

  delete({ id }): Observable<{ id: number }> {
    const query = `mutation {
      deactivateModel(
        id: ${id}
      )
    }`;

    return this.http.post<any>(GRAPH_QL_ENDPOINT, query);
  }

  loadLines({ modelIDs }: { modelIDs: Array<string | number> }): Observable<{ [key: number]: Line[] }> {
    if (modelIDs.length === 0) {
      return of({});
    }

    const body = {
      model_ids: modelIDs.map(modelID => Number(modelID)),
    };

    return this.http.post<any>('/api/lines', body).pipe(
      map(data =>
        _(data.lines.map(normalizeLineMapper))
          .groupBy('modelId')
          .value()
      )
    );
  }

  updateWorkflowStatus({ id, status }: { id: number; status: number }): Observable<{ id: string; status: number }[]> {
    const query = `mutation {
      updateModel(id: ${id} status: ${status}) { id status }
    }`;

    return this.http
      .post<{ updateModel: Array<{ id: string; status: number }> }>(GRAPH_QL_ENDPOINT, query)
      .pipe(map(({ updateModel }) => updateModel));
  }

  optimizeModel(modelIDs: Array<string>) {
    const query = `{
      "model_ids":[${modelIDs}]
    }`;

    return this.http.post<any>('/api/model/optimise', query).pipe(map(data => _.first(data.updateModel)));
  }

  // would be nice if this api would return the entire line instead of a partial
  // so that it could be updated in the model entity reducer
  // also it would be nice if the response from the api either didn't need to be mapped
  // or could use the existing line mapper (model.service#normalizeLineMapper)
  getLineOverridePrediction({
    eventID,
    modelID,
    variantID,
    skuList,
    override,
  }: GetLineOverridePredictionParams): Observable<Array<Partial<Line>>> {
    const body = {
      eventID,
      modelID,
      variantID,
      skuList,
      override,
      CURRENT_DATE_OVERRIDE_FOR_MODEL_LINE_PREDICTION,
    };

    return this.http
      .post<LineOverridePrediction[]>('/ml/prediction', body)
      .pipe(map(data => data.map(normalizeLineOverridePredictionMapper(eventID, modelID))));
  }

  applyVariant({ id, variantId }: { id: number | string; variantId: number }): Observable<{ id: number; variantId: number }> {
    const query = `mutation {
      updateModel(
        id: ${id}
        variantId: ${variantId}
      ) {
        id
        variantId
      }
    }`;

    return this.http
      .post<{ updateModel: Array<{ id: number; variantId: number }> }>(GRAPH_QL_ENDPOINT, query)
      .pipe(map(({ updateModel }) => _.first(updateModel)));
  }

  getDiscountDistribution({
    metric,
    id,
  }: {
    metric: DiscountDistributionMetric;
    id: number;
  }): Observable<{ modeldistribution: DiscountDistribution; id: number; metric: DiscountDistributionMetric }> {
    const query = `{
      modeldistribution(metric:"${metric}", modelId:"${id}") {
        bucket10
        bucket15
        bucket20
        bucket25
        bucket30
        bucket35
        bucket40
        bucket45
        bucket50
        bucket55
        bucket60
        bucket65
        bucket70
        bucket75
      }
    }`;

    return (
      this.http
        .post<{ modeldistribution: Array<DiscountDistribution> }>(GRAPH_QL_ENDPOINT, query)
        // return this.http.post<any>('/mock/api/modeldistribution', query)
        .pipe(
          map(({ modeldistribution }) => ({
            modeldistribution: _.first(modeldistribution),
            id,
            metric,
          }))
        )
    );
  }

  getDetails(id: number): Observable<{ modelDetails: ModelDetails; id: number }> {
    const query = `{
      modeldetails(modelId:"${id}"){
        totalLines
        linesTaken
        estimateSpend
        averageDepth
        estimateSellThrough
        compliance
      }
    }`;

    return (
      this.http
        .post<{ modeldetails: Array<ModelDetails> }>(GRAPH_QL_ENDPOINT, query)
        // return this.http.post<any>('/mock/api/modeldetails', query)
        .pipe(
          map(data => ({
            modelDetails: _.first(data.modeldetails),
            id,
          }))
        )
    );
  }

  takeUntakeLines({
    lines,
    taken,
  }: {
    lines: any[];
    taken: 0 | 1;
  }): Observable<Array<{ taken: 0 | 1; skuId: string; modelId: number; lineCount: number }>> {
    const formattedSkuList = lines.map(line => `${line.skuId}_${line.model.id}`);
    const query = `
      mutation {
        takeManyLines(skuList: ["${formattedSkuList.join('","')}"], taken: ${taken}) {
          taken
          skuId
          modelId
          lineCount
        }
      }`;

    return this.http
      .post<{ takeManyLines: Array<{ taken: 0 | 1; skuId: string; modelId: number; lineCount: number }> }>(GRAPH_QL_ENDPOINT, query)
      .pipe(pluck('takeManyLines'));
  }

  editLineNote({ skuId, modelId, note }: { skuId: string; modelId: number; note: string }): Observable<any> {
    const query = `mutation {
      updateLine(
        skuId: "${skuId}",
        modelId: ${modelId},
        note: "${note}"
      ) {
      note
      skuId
      modelId
        }
      }
    `;

    return this.http
      .post<{ updateLine: Array<{ note: string; modelId: number; skuId: string }> }>(GRAPH_QL_ENDPOINT, query)
      .pipe(map(({ updateLine }) => _.first(updateLine)));
  }

  refresh(modelIDs: Array<string | number>) {
    return forkJoin({ lines: this.loadLines({ modelIDs }), models: this.loadAll() });
  }
}
