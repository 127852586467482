import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import {
  Effect,
  Actions,
  ofType
} from '@ngrx/effects';

import { map, exhaustMap, catchError, switchMap } from 'rxjs/operators';

import * as org from './org.actions';

import { Observable, of } from 'rxjs';
import { OrgService } from '../org.service';

@Injectable()
export class OrgEffects {
  @Effect()
  getOrgs$: Observable<Action> = this.actions$
    .pipe(
      ofType(org.ORGGET),
      switchMap(() =>
        this.orgService
          .getOrgs()
          .pipe(
            map((data) => new org.OrgGetSuccessAction(data)),
            catchError(error => of(new org.OrgGetErrorAction(error)))
          )
      )
    );
  @Effect()
  createOrgUser$: Observable<Action> = this.actions$
    .pipe(
      ofType(org.ORGCREATEUSER),
      switchMap((action: org.OrgCreateUserAction) =>
        this.orgService
          .createOrgUser(action.payload)
          .pipe(
            map((data) => new org.OrgCreateUserSuccessAction(data)),
            catchError(error => of(new org.OrgCreateUserFailureAction(error)))
          )
      )
    );

  @Effect()
  createOrg$: Observable<Action> = this.actions$
    .pipe(
      ofType(org.ORGCREATE),
      switchMap((action: org.OrgCreateAction) =>
        this.orgService
          .createOrg(action.payload)
          .pipe(
            map((data) => new org.OrgCreateSuccessAction(data)),
            catchError(error => of(new org.OrgCreateErrorAction(error)))
          )
      )
    );

  @Effect()
  orgGetUsers$: Observable<Action> = this.actions$
    .pipe(
      ofType(org.ORGGETUSERS),
      switchMap(() =>
        this.orgService
          .getOrgUsers()
          .pipe(
            map((data) => new org.OrgGetUsersActionSuccess(data)),
            catchError(error => of(new org.OrgGetUsersActionFailure(error)))
          )
      )
    );

  @Effect()
  orgUserToggleActive$: Observable<Action> = this.actions$
    .pipe(
      ofType(org.ORGTOGGLEUSERACTIVE),
      switchMap((action: org.OrgToggleUserActiveAction) =>
        this.orgService
          .toggleUserActive(action.payload)
          .pipe(
            map((data) => new org.OrgToggleUserActiveSuccessAction(data)),
            catchError(error => of(new org.OrgToggleUserActiveFailureAction(error)))
          )
      )
    );

  @Effect()
  orgUpdateUserRoles$: Observable<Action> = this.actions$
    .pipe(
      ofType(org.ORGUPDATEUSERROLES),
      switchMap((action: org.OrgUpdateUserRolesAction) =>
        this.orgService
          .updateUserRoles(action.payload)
          .pipe(
            map((data) => new org.OrgUpdateUserRolesSuccessAction(data)),
            catchError((error) => of(new org.OrgUpdateUserRolesFailureAction(error)))
          )
      )
    );

  constructor(
        private orgService: OrgService,
        private actions$: Actions
      ) {}
}
