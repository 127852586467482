import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { Store, select } from '@ngrx/store';

import * as fromRoot from '../../../app.reducer';
import { filter, tap, first, switchMap, catchError } from 'rxjs/operators';
import { getPriceLaddersIDs } from './state/price-ladders.selectors';
import { PriceLaddersGetAllAction } from './state/price-ladders.actions';

@Injectable({
  providedIn: 'root'
})
export class PriceLaddersGuard implements CanActivate {

  constructor(private store: Store<fromRoot.State>) { }

  getFromStoreOrAPI(): Observable<any> {
    return this.store
      .pipe(
        select(getPriceLaddersIDs),
        tap((priceLaddersIDs: any) => {
          if (!priceLaddersIDs) {
            this.store.dispatch(new PriceLaddersGetAllAction());
          }
        }),
        filter((priceLaddersIDs: any) => priceLaddersIDs),
        first()
      );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.getFromStoreOrAPI()
      .pipe(
        switchMap(() => of(true)),
        catchError(() => of(false))
      );
  }
}
