import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ModelGridSelectService {
  //// state held outside the store for the purpose of hooking up models grid subcomponent to the models view
  //// todo: find a better way to do this, without the state getting involved (some select API in ag-grid?)
  selected = [];

  idSelected$ = new BehaviorSubject([]);

  get selectedIDs() {
    return this.selected.map(d => d.id);
  }

  get first() {
    return _.first(this.selected);
  }

  constructor() {}
}
