import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { IUser } from '../../../../user/user';

import { Store } from '@ngrx/store';

import * as fromRoot from '../../../../app.reducer';
import { OrgUpdateUserRolesAction } from '../state/org.actions';

@Component({
  selector: 'app-update-user-dialog',
  templateUrl: './update-user-dialog.component.html',
  styleUrls: ['./update-user-dialog.component.scss']
})
export class UpdateUserDialogComponent implements OnInit {
  @Output()
  closeDialog = new EventEmitter();

  @Input()
  topFix = true;

  @Input()
  selectedUser: IUser;

  userRoles: any;

  constructor(
    public store: Store<fromRoot.State>,
  ) {
  }

  ngOnInit() {
    this.userRoles = this.selectedUser.role;
  }

  onCloseDialog() {
    this.closeDialog.emit(true);
  }

  updateUserForOrg() {
    this.store.dispatch(new OrgUpdateUserRolesAction({ id: this.selectedUser.id, roles: this.userRoles }));
  }

}
