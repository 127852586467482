import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

import * as _ from 'lodash';
import { Model } from '../../../../features/model/state/model';
import { UserFacadeService } from '../../../../user/user-facade.service';
import { filter, first, map } from 'rxjs/operators';

const DEFAULT_COLLECTION = 'SS';
const DEFAULT_REPORT = 'collection';

@Component({
  selector: 'app-reporting-events-grid-nav-cell',
  templateUrl: './reporting-events-grid-nav-cell.component.html',
  styleUrls: ['./reporting-events-grid-nav-cell.component.scss'],
})
export class ReportingEventsGridNavCellComponent implements OnInit {
  private params: any;
  navigationStart = false;

  agInit(params: any): void {
    this.params = params;
  }

  constructor(private router: Router, private userFacadeService: UserFacadeService) {}

  ngOnInit() {}

  refresh(): boolean {
    return false;
  }

  goToReports() {
    /*SBX-556 */
    this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationStart) {
        this.navigationStart = true;
      } else if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
        this.navigationStart = false;
      }
    });
    const firstModel: Model = _.first(this.params.data.relatedModels);
    this.router.navigate(['/reporting', this.params.data.id, DEFAULT_REPORT, firstModel.region, firstModel.wave, DEFAULT_COLLECTION]);
  }

  goToEventReport(reportType: string) {
    const eventID = this.params.data.id;
    const firstModel: Model = _.first(this.params.data.relatedModels);
    const region = firstModel.region;
    const wave = firstModel.wave;
    this.router.navigateByUrl(`/reporting/${eventID}/${reportType}/${region}/${wave}/${DEFAULT_COLLECTION}`);
  }

  goToForecastReport() {
    const eventID = this.params.data.id;
    const firstModel: Model = _.first(this.params.data.relatedModels);
    const region = firstModel.region;
    const wave = firstModel.wave;
    this.userFacadeService
      .getDefaultSeason()
      .pipe(
        filter(defaultSeason => !!defaultSeason),
        first()
      )
      .subscribe(defaultSeason =>
        this.router.navigateByUrl(`/reporting/live/event/${eventID}/region/${region}/wave/${wave}/season/${defaultSeason}`)
      );
  }
}
