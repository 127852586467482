import { Action } from '@ngrx/store';
import { IOrg } from './org.reducer';

export const ORGGET = '[ORG] Get';
export const ORGGETSUCCESS = '[ORG] GetSuccess';
export const ORGGETFAILURE = '[ORG] GetFailure';
export const ORGCREATE = '[ORG] Create';
export const ORGCREATESUCCESS = '[ORG] CreateSuccess';
export const ORGCREATEFAILURE = '[ORG] CreateFailure';
export const ORGSELECT = '[ORG] Select';
export const ORGCREATEUSER = '[ORG] CreateUser';
export const ORGCREATEUSERSUCCESS = '[ORG] CreateUserSuccess';
export const ORGCREATEUSERFAILURE = '[ORG] CreateUserFailure';
export const ORGGETUSERS = '[ORG] GetUsers';
export const ORGGETUSERSSUCCESS = '[ORG] GetUsersSuccess';
export const ORGGETUSERSFAILURE = '[ORG] GetUsersFailure';
export const ORGTOGGLEUSERACTIVE = '[ORG] ToggleUserActive';
export const ORGTOGGLEUSERACTIVESUCCESS = '[ORG] ToggleUserActiveSuccess';
export const ORGTOGGLEUSERACTIVEFAILURE = '[ORG] ToggleUserActiveFailure';
export const ORGUPDATEUSERROLES = '[ORG] UpdateUserRoles';
export const ORGUPDATEUSERROLESSUCCESS = '[ORG] UpdateUserRolesSuccess';
export const ORGUPDATEUSERROLESFAILURE = '[ORG] UpdateUserRolesFailure';

export class OrgGetAction implements Action {
  type = ORGGET;
  constructor() { }
}

export class OrgGetSuccessAction implements Action {
  type = ORGGETSUCCESS;

  constructor(public payload: IOrg[]) { }
}

export class OrgGetErrorAction implements Action {
  type = ORGGETFAILURE;

  constructor(public payload) { }
}

export class OrgCreateAction implements Action {
  type = ORGCREATE;

  constructor(public payload: { name: string, db_name: string, admin_user: string }) { }
}

export class OrgCreateSuccessAction implements Action {
  type = ORGCREATESUCCESS;

  constructor(public payload: IOrg) { }
}

export class OrgCreateErrorAction implements Action {
  type = ORGCREATEFAILURE;

  constructor(public payload) { }
}

export class OrgSelectAction implements Action {
  type = ORGSELECT;

  constructor(public payload) { }
}

export class OrgCreateUserAction implements Action {
  type = ORGCREATEUSER;

  constructor(public payload: { email: string, role: string, org: number }) { }
}

export class OrgCreateUserSuccessAction implements Action {
  type = ORGCREATEUSERSUCCESS;

  constructor(public payload) { }
}

export class OrgCreateUserFailureAction implements Action {
  type = ORGCREATEUSERFAILURE;

  constructor(public payload) { }
}

export class OrgGetUsersAction implements Action {
  type = ORGGETUSERS;

  constructor() { }
}

export class OrgGetUsersActionSuccess implements Action {
  type = ORGGETUSERSSUCCESS;

  constructor(public payload: {id: any, users: any[]}) { }
}

export class OrgGetUsersActionFailure implements Action {
  type = ORGGETUSERSFAILURE;

  constructor(public payload) { }
}

export class OrgToggleUserActiveAction implements Action {
  type = ORGTOGGLEUSERACTIVE;

  constructor(public payload) { }
}

export class OrgToggleUserActiveSuccessAction implements Action {
  type = ORGTOGGLEUSERACTIVESUCCESS;

  constructor(public payload) { }
}

export class OrgToggleUserActiveFailureAction implements Action {
  type = ORGTOGGLEUSERACTIVEFAILURE;

  constructor(public payload) { }
}

export class OrgUpdateUserRolesAction implements Action {
  type = ORGUPDATEUSERROLES;

  constructor(public payload: {id, roles: string[]}) { }
}

export class OrgUpdateUserRolesSuccessAction implements Action {
  type = ORGUPDATEUSERROLESSUCCESS;

  constructor(public payload) { }
}

export class OrgUpdateUserRolesFailureAction implements Action {
  type = ORGUPDATEUSERROLESFAILURE;

  constructor(public payload) { }
}

export type actions =
  OrgGetSuccessAction |
  OrgCreateSuccessAction |
  OrgSelectAction |
  OrgCreateUserSuccessAction |
  OrgGetUsersActionSuccess |
  OrgToggleUserActiveSuccessAction |
  OrgUpdateUserRolesSuccessAction;
