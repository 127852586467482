import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';

import * as fromRoot from '../app.reducer';
import { getOrgZones } from './user.reducer';
import { tap, filter, first, switchMap, catchError } from 'rxjs/operators';
import { UserGetOrgZonesAction } from './user.actions';

@Injectable({
  providedIn: 'root'
})
export class ZonesGuard implements CanActivate {

  constructor(private store: Store<fromRoot.State>) { }

  getFromStoreOrAPI(): Observable<any> {
    return this.store
      .pipe(
        select(getOrgZones),
        tap((eventIDs: any) => {
          if (!eventIDs) {
            this.store.dispatch(new UserGetOrgZonesAction());
          }
        }),
        filter((orgs: any) => !!orgs),
        first(),
      );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.getFromStoreOrAPI()
      .pipe(
        switchMap(() => of(true)),
        catchError(() => of(false)),
      );
  }
}
