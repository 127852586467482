import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as fromRoot from '../../app.reducer';
import { Variant } from './variant.model';
import {
  VariantsCreateAction,
  VariantsUpdateAction,
  VariantsCreateWizardAction,
  VariantsDeleteAction,
} from '../variants/state/variants.actions';
import { RouterFacadeService } from '../../router-facade.service';
import { getVariants } from '../variants/state/variants.selectors';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IEvent } from '../../abstracts/event.interface';
import { EventDetails, VariantDetails } from '../event/event';
import {
  ModelDetails
} from '../event/components/event-create-edit-stepper-model-details-form/event-create-edit-stepper-model-details-form.component';
import { PartialArray, filterCol } from '../../infra/utilities';

@Injectable({
  providedIn: 'root',
})
export class VariantFacadeService {
  constructor(private store: Store<fromRoot.State>, private routerFacadeService: RouterFacadeService) {}

  create(variant: Variant & { uuid }) {
    this.store.dispatch(new VariantsCreateAction(variant));
  }

  update(variant: Variant) {
    this.store.dispatch(new VariantsUpdateAction(variant));
  }

  get(params: PartialArray<Variant>): Observable<Array<Variant>> {
    return this.getAll().pipe(map(variants => filterCol(variants)(params)));
  }

  delete(variant: Variant) {
    const { id } = variant;
    this.store.dispatch(new VariantsDeleteAction({ id: +id }));
  }

  getAll(): Observable<Variant[]> {
    return this.store.pipe(select(getVariants));
  }

  getVariantsForSelectedEvent() {
    return this.routerFacadeService.getSelectedEventID().pipe(switchMap(id => this.get({ eventId: [+id] })));
  }

  createWizard(params: {
    event: IEvent;
    params: {
      eventDetails: EventDetails;
      variantsDetails: VariantDetails[];
      modelsDetails: ModelDetails[];
    };
  }) {
    this.store.dispatch(new VariantsCreateWizardAction(params));
  }
}
