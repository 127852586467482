// get all lines in event

// endStockValue

import * as _ from 'lodash';
import { Line } from '../../model/state/model';

function getPriceChangeTaken(lines: Line[]) {
  return lines
    .filter((line: Line) => ((line.taken === 'Yes') && (line.finalPrice < line.currentPrice)));
}

function endStockValue(lines: Line[]) {
  const priceChangeTakenLines = getPriceChangeTaken(lines);
  return priceChangeTakenLines
    .map((line) => line.fcstEndStock * line.finalPrice)
    .reduce((acc, stockValue) => acc + stockValue, 0) / 1000;
}

// divisionDesc

// currentStockValue
function currentStockValue(lines: Line[]) {
  const priceChangeTakenLines = getPriceChangeTaken(lines);
  return priceChangeTakenLines
    .map((line) => line.currentPrice * line.totalStock)
    .reduce((acc, stockValue) => acc + stockValue, 0) / 1000;
}

// currentCover
function currentCover(lines: Line[]) {
  const takenLines = getPriceChangeTaken(lines);
  const totalStock = takenLines
    .reduce((acc, line) => acc + line.totalStock, 0);
  const lwSales = takenLines
    .reduce((acc, line) => acc + line.lwSales, 0);

  if (!lwSales) {
    return 0;
  }

  return totalStock / lwSales;
}

// departmentDesc

// startStockUnits

function startStockUnits(lines: Line[]) {
  const priceChangeTakenLines = getPriceChangeTaken(lines);
  return priceChangeTakenLines
    .reduce((acc, line) => acc + line.fcstStartStock, 0);
}

// linesTaken SBX-575
function linesTaken(lines: Line[]) {
  const priceChangeTakenLines = getPriceChangeTaken(lines);
  return _(priceChangeTakenLines).uniqBy(d => d.skuId).value().length;

 }

// spend
function spend(lines: Line[]) {
  const priceChangeTakenLines = getPriceChangeTaken(lines);
  return priceChangeTakenLines
    .reduce((acc, line) => acc + line.fcstSpend, 0) / 1000;
}

// currentSellThrough
function currentSellThrough(lines: Line[]) {
  const takenLines = getPriceChangeTaken(lines);
  const totalSales = takenLines
    .reduce((acc, line) => acc + line.totalSales, 0);
  const alltimeStock = takenLines
    .map((line) => line.totalSales + line.totalStock)
    .reduce((acc, val) => acc + val, 0);

  if (!alltimeStock) {
    return 0;
  }

  return totalSales / alltimeStock;
}

// startCover
function startCover(lines: Line[]) {
  // needs proc change and service change
  const takenLines = getPriceChangeTaken(lines);
  const fcstStartStockSum = takenLines
    .reduce((acc, line) => acc + line.fcstStartStock, 0);
  const fcstStartSalesSum = takenLines
    .reduce((acc, line) => acc + line.fcstStartSales, 0);

  if (!fcstStartSalesSum) {
    return 0;
  }

  return fcstStartStockSum / fcstStartSalesSum;
}

// newDepth
function newDepth(lines: Line[]) {
  const takenLines = getPriceChangeTaken(lines);
  const finalPriceSum = takenLines
    .reduce((acc, line) => acc + (line.finalPrice * line.fcstStartStock), 0);
  const fullPriceSum = takenLines
    .reduce((acc, line) => acc + (line.fullPrice * line.fcstStartStock), 0);

  if (!fullPriceSum) {
    return 0;
  }

  return 1 - (finalPriceSum / fullPriceSum);
}

// newTotalDepth
function newTotalDepth(lines: Line[]) {
  const takenLines = getPriceChangeTaken(lines);
  const untakenLines = lines
    .filter((line: Line) => line.taken ==='No');
  const finalPriceSumTaken = takenLines
    .reduce((acc, line) => acc + (line.finalPrice * line.fcstStartStock), 0);
  const finalPriceSumUntaken = untakenLines
    .reduce((acc, line) => acc + (line.currentPrice * line.fcstStartStock), 0);
  const fullPriceSum = lines
    .reduce((acc, line) => acc + (line.fullPrice * line.fcstStartStock), 0);

  if (!fullPriceSum) {
    return 0;
  }

  return 1 - ((finalPriceSumTaken + finalPriceSumUntaken)/ fullPriceSum);
}

// startStockValue
function startStockValue(lines: Line[]) {
  const priceChangeTakenLines = getPriceChangeTaken(lines);
  return priceChangeTakenLines
    .map((line) => line.fcstStartStock * line.finalPrice)
    .reduce((acc, stockValue) => acc + stockValue, 0) / 1000;
}

// endCover
function endCover(lines: Line[]) {
  // needs proc change and service change
  const takenLines = getPriceChangeTaken(lines);
  const fcstEndStockSum = takenLines
    .reduce((acc, line) => acc + line.fcstEndStock, 0);
  const fcstEndSalesSum = takenLines
    .reduce((acc, line) => acc + line.fcstEndSales, 0);

  if (!fcstEndSalesSum) {
    return 0;
  }

  return fcstEndStockSum / fcstEndSalesSum;
}

// modelName

// currentStockUnits
function currentStockUnits(lines: Line[]) {
  const priceChangeTakenLines = getPriceChangeTaken(lines);
  return priceChangeTakenLines
    .reduce((acc, line) => acc + line.totalStock, 0);
}

// currentDepth
function currentDepth(lines: Line[]) {
  const takenLines = getPriceChangeTaken(lines);
  const currentPriceSum = takenLines
    .reduce((acc, line) => acc + (line.currentPrice * line.totalStock), 0);
  const fullPriceSum = takenLines
    .reduce((acc, line) => acc + (line.fullPrice * line.totalStock), 0);

  if (!fullPriceSum) {
    return 0;
  }

  return 1 - (currentPriceSum / fullPriceSum);
}

// currentTotalDepth
function currentTotalDepth(lines: Line[]) {
  const currentPriceSum = lines
    .reduce((acc, line) => acc + (line.currentPrice * line.totalStock), 0);
  const fullPriceSum = lines
    .reduce((acc, line) => acc + (line.fullPrice * line.totalStock), 0);

  if (!fullPriceSum) {
    return 0;
  }

  return 1 - (currentPriceSum / fullPriceSum);
}

// startSellThrough
function startSellThrough(lines: Line[]) {
  const takenLines = getPriceChangeTaken(lines);
  const fcstStartStockSum = takenLines
    .reduce((acc, line) => acc + line.fcstStartStock, 0);
  const alltimeStock = takenLines
    .map((line) => line.totalSales + line.totalStock)
    .reduce((acc, val) => acc + val, 0);

    if (!alltimeStock) {
      return 0;
    }

  return (alltimeStock - fcstStartStockSum) / alltimeStock;
}

// endStockUnits
function endStockUnits(lines: Line[]) {
  const priceChangeTakenLines = getPriceChangeTaken(lines);
  return priceChangeTakenLines
    .filter((line: Line) => line.taken === 'Yes')
    .reduce((acc, line) => acc + line.fcstEndStock, 0);
}

// endSellThrough
function endSellThrough(lines: Line[]) {
  const takenLines = getPriceChangeTaken(lines);
  const fcstEndStockSum = takenLines
    .reduce((acc, line) => acc + line.fcstEndStock, 0);
  const alltimeStock = takenLines
    .map((line) => line.totalSales + line.totalStock)
    .reduce((acc, val) => acc + val, 0);

  if (!fcstEndStockSum) {
    return 0;
  }

  return (alltimeStock - fcstEndStockSum) / alltimeStock;
}
function getLinesByCollection(data, collection, view) {
  if (collection === 'Other') {
    return data.filter((d) => !(_.startsWith(d.collection_desc, view)) );
  }
  if (collection === 'AW Only' && view === 'AW') {
    return data.filter((d) => (_.startsWith(d.collection_desc, view)) );
  }
  if (collection === 'SS Only' && view === 'SS') {
    return data.filter((d) => (_.startsWith(d.collection_desc, view)) );
  }
  if (collection === 'Total') {
    return data;
  } else {
    return data.filter((d) => d.collection_desc === collection);
  }
}

function newStockByCollection(data, collection, view) {
  const dataByCollection = getLinesByCollection(data, collection, view);
  return dataByCollection
    .reduce((acc, d) => acc + d.stockMd, 0);
}

function stockTotalUnits(data, collection, view) {
  const dataByCollection = getLinesByCollection(data, collection, view);
  return dataByCollection.reduce((acc, d) => acc + d.stockTotal, 0);
}

function stockOverMD(data, collection, view) {
 const dataByCollection = getLinesByCollection(data, collection, view);
 
 const sumStockUnits =  dataByCollection
    .reduce((acc, d) => acc + d.stockMd, 0);
    const sumStockTotalUnits =  dataByCollection
    .reduce((acc, d) => acc + d.stockTotal, 0);
  if (!sumStockUnits) {
    return 0;
  }
  return sumStockUnits / sumStockTotalUnits;
}

function mdDepthCurrentByCollection(data, collection, view) {
  const dataByCollection = getLinesByCollection(data, collection, view);
  const mdDepthNumeratorSum =  dataByCollection
    .reduce((acc, d) => acc + d.mdDepthNumerator , 0);
  const mdDepthDenominatorSum =  dataByCollection
    .reduce((acc, d) => acc + d.mdDepthDenominator, 0);

  if (!mdDepthNumeratorSum) {
    return 0;
  }
  return  1 - (mdDepthNumeratorSum / mdDepthDenominatorSum);
}

function newTotalDepthByCollection(data, collection, view) {
  const dataByCollection = getLinesByCollection(data, collection, view);
  const totalNumeratorSum =  dataByCollection
    .reduce((acc, d) => acc + d.totalDepthNumerator , 0);
  const totalDenominatorSum =  dataByCollection
    .reduce((acc, d) => acc + d.totalDepthDenominator, 0);
    if (!totalNumeratorSum) {
    return 0;
  }

  return  1 - (totalNumeratorSum / totalDenominatorSum);
}

function mdDepthFullByCollection(data, collection, view) {
  const dataByCollection = getLinesByCollection(data, collection, view);
  const discNumeratorSum =  dataByCollection
    .reduce((acc, d) => acc + d.allDiscountDepthNumerator , 0);
  const discDenominatorSum = dataByCollection
    .reduce((acc, d) => acc + d.allDiscountDepthDenominator, 0);
    if (!discNumeratorSum) {
    return 0;
  }

  return 1 - (discNumeratorSum / discDenominatorSum);
}

function getDataBySkuType(data, skuType) {
  if (skuType === 'New MD') {
    return data.filter((d) => d.sku_type === skuType);
  } else if (skuType === 'Discounted') {
    return data.filter((d) => (d.sku_type === 'New MD') || (d.sku_type === 'Discounted'));
  } else {
    return data;
  }
}
function totalSkuTypeCount(data, skuType) {
  const dataBySkuType = getDataBySkuType(data, skuType);
  if (dataBySkuType.map(d => d.sku_count).length > 0) {
    return dataBySkuType.reduce((acc, d) => acc + d.sku_count, 0);
  } else {
    return 0;
  }
}

function skuPctByDiscount(data, dataByDiscount, skuType) {
  const dataBySkuType = getDataBySkuType(dataByDiscount, skuType);
  const totalSkuCount = getDataBySkuType(data, skuType).reduce((acc, d) => acc + d.sku_count,0);
  return dataBySkuType.reduce((acc, d) => acc + d.sku_count, 0) / totalSkuCount;
}

function totalStoreStockUnits(data, skuType) {
  const dataBySkuType = getDataBySkuType(data, skuType);
  if (dataBySkuType.map(d => d.store_stock_units).length > 0) {
    return dataBySkuType.reduce((acc, d) => acc + d.store_stock_units, 0);
  } else {
    return 0;
  }
}

function storeStockPctByDiscount(data, dataByDiscount, skuType) {
  const dataBySkuType = getDataBySkuType(dataByDiscount, skuType);
  const totalStockUnits = getDataBySkuType(data, skuType).reduce((acc, d) => acc + d.store_stock_units, 0);
  return dataBySkuType.reduce((acc, d) => acc + d.store_stock_units, 0) / totalStockUnits;
}

function pctOfAllStock(data, dataByDiscount, skuType) {
  const dataBySkuType = getDataBySkuType(data, skuType);
  const allStockUnits = getDataBySkuType(data, 'All').reduce((acc, d) => acc + d.store_stock_units, 0);
  return dataBySkuType.reduce((acc, d) => acc + d.store_stock_units, 0) / allStockUnits;
}

function fullPriceAverageDiscount(data, dataByDiscount, skuType) {
  const dataBySkuType = getDataBySkuType(data, skuType);
  const totalFullPrice = dataBySkuType.reduce((acc, d) => acc + d.full_price_sum, 0);
  const totalFinalPrice = dataBySkuType.reduce((acc, d) => acc + d.final_price_sum, 0);
  if (!totalFinalPrice) {
    return 0;
  }
  return 1 - ( totalFinalPrice / totalFullPrice );
}

function currentPriceAverageDiscount(data, dataByDiscount, skuType) {
  const dataBySkuType = getDataBySkuType(data, skuType);
  const totalFullPrice = dataBySkuType.reduce((acc, d) => acc + d.full_price_sum, 0);
  const totalCurrentPrice = dataBySkuType.reduce((acc, d) => acc + d.current_price_sum, 0);
  if (!totalCurrentPrice) {
    return 0;
  }
  return 1 - ( totalCurrentPrice / totalFullPrice );
}
export {
  endSellThrough,
  endStockUnits,
  startSellThrough,
  currentDepth,
  currentTotalDepth,
  currentStockUnits,
  startStockValue,
  newDepth,
  newTotalDepth,
  currentSellThrough,
  spend,
  linesTaken,
  startStockUnits,
  currentCover,
  currentStockValue,
  endStockValue,
  startCover,
  endCover,
  stockTotalUnits,
  stockOverMD,
  newStockByCollection,
  mdDepthCurrentByCollection,
  newTotalDepthByCollection,
  mdDepthFullByCollection,
  totalSkuTypeCount,
  skuPctByDiscount,
  totalStoreStockUnits,
  storeStockPctByDiscount,
  pctOfAllStock,
  fullPriceAverageDiscount,
  currentPriceAverageDiscount
};
