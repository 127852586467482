import { Component, OnInit } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import * as _ from 'lodash';

import { ModelFacadeService } from '../../model-facade.service';

@Component({
  selector: 'app-model-grid-nav-cell',
  templateUrl: './model-grid-nav-cell.component.html',
  styleUrls: ['./model-grid-nav-cell.component.scss'],
})
export class ModelGridNavCellComponent implements ICellRendererAngularComp, OnInit {
  private params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  constructor(private modelFacadeService: ModelFacadeService) {}

  ngOnInit() {}

  refresh(): boolean {
    return false;
  }

  editModel() {
    const modelIDs = this.params.node.allLeafChildren.map(({data}) => data.id);
    this.modelFacadeService.selectModels(modelIDs);
  }
}
