import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { EventLiveReportRow } from '../../event/event';
import { map } from 'rxjs/operators';

export function generateLiveReport(data: _.Collection<any>, aggSeason: string) {
  // should be EventLiveReportRow
  const liveReportGridRows: LiveReportGridRow[] = [];

  const aggSeasonRows = data.filter(row => row.season_code === aggSeason);
  // get collections for season
  const collectionsForAggSeason = aggSeasonRows
    .map(({ collection_code }) => collection_code)
    .uniq()
    .value();
  // for each collection, aggregate the data into a row
  const totalUnits = aggSeasonRows.sumBy('gsales_u');
  collectionsForAggSeason.forEach(collectionCode => {
    const reportRowsForCollectionCode = aggSeasonRows.filter(row => row.collection_code === collectionCode).value();
    const aggRow = getAggRow(reportRowsForCollectionCode, collectionCode);
    const salesMix = aggRow.unitSales / totalUnits;
    liveReportGridRows.push({
      ...aggRow,
      salesMix,
    });
  });
  // for aggSeason, create a row with collections agg'd
  liveReportGridRows.push(getAggRow(aggSeasonRows.value(), aggSeason));
  // for all other data, create an other row with aggregates

  const otherRows = data.filter(row => row.season_code !== aggSeason).value();
  liveReportGridRows.push(getAggRow(otherRows, 'Other'));
  // total
  liveReportGridRows.push(getAggRow(data.value(), 'Total'));

  return liveReportGridRows;
}

export function getAggRow(rows: EventLiveReportRow[], aggLevel: string): LiveReportGridRow {
  const margin = _.sumBy(rows, d => d.margin_v_excl_tax);
  const salesExclTax = _.sumBy(rows, d => d.gsales_v_excl_tax);
  const sales = _.sumBy(rows, d => d.gsales_v_incl_tax);
  const fpSales = _.sumBy(rows, d => d.fpsales_v_incl_tax);
  return {
    aggLevel,
    sales,
    margin,
    marginRate: margin / sales,
    unitSales: _.sumBy(rows, d => d.gsales_u),
    salesMix: null,
    discountRate: 1 - sales / fpSales,
    startingStock: _.sumBy(rows, d => d.start_stock),
    endingStock: _.sumBy(rows, d => d.end_stock),
  };
}

export interface LiveReportGridRow {
  aggLevel: string;
  sales: number;
  margin: number;
  marginRate: number;
  unitSales: number;
  salesMix: number;
  discountRate: number;
  startingStock: number;
  endingStock: number;
  grouping?: string;
}

export interface LiveReport {
  allSku: LiveReportGridRow[];
  allSkuLy: LiveReportGridRow[];
  allSkuTyLy: LiveReportGridRow[];
  allSkuTyLyLfl: LiveReportGridRow[];
  saleSku: LiveReportGridRow[];
  saleSkuLy: LiveReportGridRow[];
  saleSkuTyLy: LiveReportGridRow[];
  saleSkuTyLyLfl: LiveReportGridRow[];
}

@Injectable({
  providedIn: 'root',
})
export class ReportingLiveService {
  constructor(private http: HttpClient) {}

  getLiveReport(
    aggSeason: string,
    aggLastSeason: string,
    region_ids: string[],
    last_year_start: string,
    last_year_end: string,
    this_year_start: string,
    this_year_end: string,
    include_dc_stock: boolean
  ): Observable<LiveReport> {
    const req = {
      last_year_start,
      last_year_end,
      this_year_start,
      this_year_end,
      region_ids,
      include_dc_stock,
    };
    return this.http.post<EventLiveReportRow[]>('/api/live-report', req).pipe(
      map(liveReportData => {
        const allSku = this.getReportGridRows(liveReportData, aggSeason, 'TY', false, false);
        const allSkuLy = this.getReportGridRows(liveReportData, aggLastSeason, 'LY', false, false);
        const allSkuTyLy = this.getTyLy(allSku, allSkuLy, aggSeason, aggLastSeason);
        const allSkuTyLyLfl = this.getTyLyLfl(liveReportData, aggSeason, aggLastSeason, false);
        const saleSku = this.getReportGridRows(liveReportData, aggSeason, 'TY', false, true);
        const saleSkuLy = this.getReportGridRows(liveReportData, aggLastSeason, 'LY', false, true);
        const saleSkuTyLy = this.getTyLy(saleSku, saleSkuLy, aggSeason, aggLastSeason);
        const saleSkuTyLyLfl = this.getTyLyLfl(liveReportData, aggSeason, aggLastSeason, true);

        return {
          allSku,
          allSkuLy,
          allSkuTyLy,
          allSkuTyLyLfl,
          saleSku,
          saleSkuLy,
          saleSkuTyLy,
          saleSkuTyLyLfl,
        };
      })
    );
  }

  getReportGridRows(
    data: EventLiveReportRow[],
    aggSeason: string,
    tyLyFlag: string,
    lflOnly: boolean,
    salesOnly: boolean
  ): LiveReportGridRow[] {
    let baseData = _(data);
    if (lflOnly) {
      baseData = baseData.filter(({ lfl }) => lfl);
    }
    if (salesOnly) {
      baseData = baseData.filter(({ on_sale }) => on_sale);
    }

    const relevantYearData = baseData.filter(row => row.ty_ly === tyLyFlag);

    const liveReportGridRows = generateLiveReport(relevantYearData, aggSeason);

    return liveReportGridRows;
  }

  getTyLy(ty: LiveReportGridRow[], ly: LiveReportGridRow[], aggSeason: string, aggLastSeason: string): LiveReportGridRow[] {
    const combined = ty.concat(ly);

    return (
      _(combined)
        .groupBy(d => {
          if (d.aggLevel === aggLastSeason) {
            return aggSeason;
          }

          return d.aggLevel;
        })
        // for each agglevel do 0/1 for each key and then push a row with agglevel = agglevel
        .map((val, aggLevel) => {
          const aggLevelTy = val[0];
          const aggLevelLy = val[1];
          const obj: LiveReportGridRow = {
            aggLevel,
            sales: aggLevelTy.sales / aggLevelLy.sales - 1,
            margin: aggLevelTy.margin / aggLevelLy.margin - 1,
            marginRate: aggLevelTy.marginRate - aggLevelLy.marginRate,
            unitSales: aggLevelTy.unitSales / aggLevelLy.unitSales - 1,
            salesMix: aggLevelTy.salesMix - aggLevelLy.salesMix,
            discountRate: aggLevelTy.discountRate - aggLevelLy.discountRate,
            startingStock: aggLevelTy.startingStock / aggLevelLy.startingStock - 1,
            endingStock: aggLevelTy.endingStock / aggLevelLy.endingStock - 1,
          };

          return obj;
        })
        .value()
    );
  }

  getTyLyLfl(data: EventLiveReportRow[], aggSeason: string, aggLastSeason: string, saleOnly: boolean) {
    const ty = this.getReportGridRows(data, aggSeason, 'TY', true, saleOnly);
    const ly = this.getReportGridRows(data, aggLastSeason, 'LY', true, saleOnly);

    return this.getTyLy(ty, ly, aggSeason, aggLastSeason);
  }
}
