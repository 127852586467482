import { Injectable } from '@angular/core';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class GridLayoutsServiceService {

  get layouts() {
    return JSON.parse(localStorage.getItem('modelsGridLayout'));
  }

  set layouts(layouts) {
    localStorage.setItem('modelsGridLayout', JSON.stringify(layouts));
  }

  removeLayout(layoutName) {
    const layouts = _.cloneDeep(this.layouts);

    const newLayouts = layouts.filter(layout => layout.layoutName !== layoutName);

    this.layouts = newLayouts;
  }

  getDefaultLayout() {
    return this.layouts && _.find(this.layouts, layout => layout.isDefault);
  }

  setLayoutAsDefault(layout) {
    const layouts = _.cloneDeep(this.layouts);
    if (layouts) {
      layouts.forEach(l => {
        if (layout && l.layoutName === layout.layoutName) {
          l.isDefault = true;
        } else {
          l.isDefault = false;
        }
      });
    }

    this.layouts = layouts;

    return layouts;
  }

  createNewLayout(layoutName, layoutColumns, isDefault) {
    let layouts = _.cloneDeep(this.layouts);

    const newLayout = {
      layoutName,
      layoutColumns,
      isDefault
    };

    if (isDefault) {
      layouts.forEach(layout => layout.isDefault = false);
    }

    if (layouts) {
      layouts.push(newLayout);
    } else {
      layouts = [newLayout];
    }

    this.layouts = layouts;
  }

  constructor() { }
}
