import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { GridLayoutsServiceService } from '../grid-layouts-tool-panel/grid-layouts-service.service';

@Component({
  selector: 'app-grid-layouts-tool-panel-container',
  templateUrl: './grid-layouts-tool-panel-container.component.html',
  styleUrls: ['./grid-layouts-tool-panel-container.component.scss']
})
export class GridLayoutsToolPanelContainerComponent implements OnInit, AfterViewInit {
  params;
  viewContainerParent;
  newLayoutName;
  saveMode;

  columnsChanged$ = new Subject()

  layouts = [];

  get columnApi() {
    return this.viewContainerParent.gridColumnApi;
  }

  get gridApi() {
    return this.params.api;
  }

  constructor(
    private gridLayoutsService: GridLayoutsServiceService,
  ) { }

  agInit(params): void {
    const viewContainerRef: any = params['frameworkComponentWrapper']['viewContainerRef'];
    this.viewContainerParent = viewContainerRef.get(0)._view.viewContainerParent.component;
    if (this.viewContainerParent.saveLayoutsLocalStorage) {
      this.saveMode = 'localStorage';
    }
    this.params = params;
    setTimeout(() => {
      params.api.addEventListener('displayColumnsChanged', (e) => {
        this.columnsChanged$.next();
      });
      params.api.addEventListener('columnResized', (e) => {
        this.columnsChanged$.next();
      });
    });
  }

  ngAfterViewInit() {
    // if (this.viewID) {
    this.layouts = this.gridLayoutsService.layouts || [];
    // }
  }

  onLayoutSave(e) {
    const columnState = this.columnApi.getColumnState();
    this.gridLayoutsService.createNewLayout(e.name, columnState, e.setAsDefault);
    this.layouts = this.gridLayoutsService.layouts;
  }

  onLayoutDelete(e) {
    this.gridLayoutsService.removeLayout(e.layoutName)
    this.layouts = this.gridLayoutsService.layouts;
  }

  onLayoutSetAsDefault(e) {
    this.gridLayoutsService.setLayoutAsDefault(e && e.layout);
    this.layouts = this.gridLayoutsService.layouts;
  }

  onLayoutUpdate(e) {
    const columnState = this.columnApi.getColumnState();
    // service.updateLayout({...e, columnState, viewID: this.viewID})
  }

  onSetLayout(e) {
    if (!e.layoutColumns) {
      this.columnApi.resetColumnState();
    } else {
      this.columnApi.setColumnState(e.layoutColumns);
    }
  }

  ngOnInit() {
  }

  refresh() {

  }

}
