import { Action } from '@ngrx/store';

export const DISCOUNTLADDERSGETALL = '[DISCOUNT LADDERS] GET ALL';
export const DISCOUNTLADDERSGETSALLSUCCESS = '[DISCOUNT LADDERS] GET ALL SUCCESS';
export const DISCOUNTLADDERSGETALLFAILURE = '[DISCOUNT LADDERS] GET ALL FAILURE';

export const DISCOUNTLADDERSCREATE = '[DISCOUNT LADDERS] CREATE';
export const DISCOUNTLADDERSCREATESUCCESS = '[DISCOUNT LADDERS] CREATESUCCESS';
export const DISCOUNTLADDERSCREATEFAILURE = '[DISCOUNT LADDERS] CREATEFAILURE';

export const DISCOUNTLADDERSUPDATEACTION = '[DISCOUNT LADDERS] UPDATE';
export const DISCOUNTLADDERSUPDATEACTIONSUCCESS = '[DISCOUNT LADDERS] UPDATE SUCCESS';
export const DISCOUNTLADDERSUPDATEACTIONFAILURE = '[DISCOUNT LADDERS] UPDATE FAILURE';

export const DISCOUNTLADDERSDELETEACTION = '[DISCOUNT LADDERS] DELETE';
export const DISCOUNTLADDERSDELETEACTIONSUCCESS = '[DISCOUNT LADDERS] DELETE SUCCESS';
export const DISCOUNTLADDERSDELETEACTIONFAILURE = '[DISCOUNT LADDERS] DELETE FAILURE';

export class DiscountLaddersGetAllAction implements Action {
  type = DISCOUNTLADDERSGETALL;

  constructor() { }
}

export class DiscountLaddersGetAllSuccessAction implements Action {
  type = DISCOUNTLADDERSGETSALLSUCCESS;

  constructor(public payload: any) { }
}

export class DiscountLaddersGetAllFailureAction implements Action {
  type = DISCOUNTLADDERSGETALLFAILURE;

  constructor(public payload: any) { }
}

export class DiscountLaddersCreateAction implements Action {
  type = DISCOUNTLADDERSCREATE;

  constructor(public payload: { name: string, discounts: string[] }) {}
}

export class DiscountLaddersCreateSuccessAction implements Action {
  type = DISCOUNTLADDERSCREATESUCCESS;

  constructor(public payload: any) { }
}

export class DiscountLaddersCreateFailureAction implements Action {
  type = DISCOUNTLADDERSCREATEFAILURE;

  constructor(public payload: any) { }
}

export class DiscountLaddersUpdateAction implements Action {
  type = DISCOUNTLADDERSUPDATEACTION;

  constructor(public payload: any) { }
}

export class DiscountLaddersUpdateSuccessAction implements Action {
  type = DISCOUNTLADDERSUPDATEACTIONSUCCESS;

  constructor(public payload: any) { }
}

export class DiscountLaddersUpdateFailureAction implements Action {
  type = DISCOUNTLADDERSUPDATEACTIONFAILURE;

  constructor(public payload: any) { }
}

export class DiscountLaddersDeleteAction implements Action {
  type = DISCOUNTLADDERSDELETEACTION;

  constructor(public payload: any) { }
}

export class DiscountLaddersDeleteSuccessAction implements Action {
  type = DISCOUNTLADDERSDELETEACTIONSUCCESS;

  constructor(public payload: any) { }
}

export class DiscountLaddersDeleteFailureAction implements Action {
  type = DISCOUNTLADDERSDELETEACTIONFAILURE;

  constructor(public payload: any) { }
}

export type actions =
  DiscountLaddersGetAllSuccessAction |
  DiscountLaddersGetAllFailureAction |
  DiscountLaddersCreateSuccessAction |
  DiscountLaddersCreateFailureAction |
  DiscountLaddersUpdateSuccessAction |
  DiscountLaddersUpdateFailureAction |
  DiscountLaddersDeleteSuccessAction |
  DiscountLaddersDeleteFailureAction;
