import { Component, OnInit } from '@angular/core';
import {
  PriceLaddersCreateAction,
  PriceLaddersUpdateAction,
  PriceLaddersDeleteAction,
} from '../price-ladders/state/price-ladders.actions';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';

@Component({
  selector: 'app-price-ladder-grid-actions-cell',
  templateUrl: './price-ladder-grid-actions-cell.component.html',
  styleUrls: ['./price-ladder-grid-actions-cell.component.scss']
})
export class PriceLadderGridActionsCellComponent implements OnInit {

  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  constructor(
    private store: Store<fromRoot.State>,
    ) { }

  ngOnInit() {
  }

  refresh(): boolean {
    return false;
  }

  removePriceLadder() {
    this.params.api.updateRowData({ remove: [this.params.data] });
  }

  deletePriceLadder() {
    if (this.params.data.id) {
      this.store.dispatch(
        new PriceLaddersDeleteAction(this.params.data)
      );
    } else {
    }
  }

  savePriceLadder() {
    if (this.params.data.id) {
      this.store.dispatch(
        new PriceLaddersUpdateAction({
          ...this.params.data,
          prices: this.params.data.prices && this.params.data.prices.split(','),
        })
      )
    } else {
      this.store.dispatch(
        new PriceLaddersCreateAction({
          ...this.params.data,
          name: this.params.data.ladder || this.params.data.name,
          prices: this.params.data.prices && this.params.data.prices.split(','),
        })
      );
    }
  }

}
