import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map, withLatestFrom, filter, first } from 'rxjs/operators';
import * as _ from 'lodash';

import { EventFacadeService } from '../../event/event-facade.service';
import { Event } from '../../event/event';
import { ModelFacadeService } from '../../model/model-facade.service';
import { ReportingEventLiveService } from './reporting-event-live.service';
import { UserFacadeService } from '../../../user/user-facade.service';
import { IZone } from '../../../user';
import { RouterFacadeService } from 'projects/sbx-ui-insights/src/app/router-facade.service';
import { Router } from '@angular/router';
import { LiveReportGridRow } from '../reporting-live/reporting-live.service';

@Component({
  selector: 'app-reporting-event-live',
  templateUrl: './reporting-event-live.component.html',
  styleUrls: ['./reporting-event-live.component.scss'],
})
export class ReportingEventLiveComponent implements OnInit {
  report$: Observable<LiveReportGridRow[]>;
  regions$: Observable<Array<string | number>>;
  seasons$: Observable<string[]>;
  selectedRegions: Array<string | number> = [];
  waves$: Observable<number[]>;
  selectedWave: number;
  aggSeason: string;
  event$: Observable<Event>;

  constructor(
    private reportingEventLiveService: ReportingEventLiveService,
    private userFacadeService: UserFacadeService,
    private modelFacadeService: ModelFacadeService,
    private eventFacadeService: EventFacadeService,
    private router: Router
  ) {
    this.event$ = this.eventFacadeService.getSelectedEvent();
    this.regions$ = this.modelFacadeService.getAll().pipe(
      withLatestFrom(this.event$.pipe(filter(d => !!d))),
      map(([models, { id: reportEventID }]) =>
        _(models)
          .filter(({ eventId: modelEventID }) => +modelEventID === +reportEventID)
          .map(({ region }) => region)
          .uniq()
          .value()
      )
    );
    this.seasons$ = this.userFacadeService.getOrgSeasons().pipe(map(seasons => _.sortBy(seasons, d => d.substring(0, d.length - 1))));
    this.waves$ = this.modelFacadeService.getAll().pipe(
      withLatestFrom(this.event$.pipe(filter(d => !!d))),
      map(([models, { id: reportEventID }]) =>
        _(models)
          .filter(({ eventId: modelEventID }) => +modelEventID === +reportEventID)
          .map(({ wave }) => wave)
          .uniq()
          .value()
      )
    );
    this.report$ = this.reportingEventLiveService.selectedEventLiveReport();
    this.reportingEventLiveService
      .getSelectedEventLiveReportConfiguration()
      .pipe(first())
      .subscribe(({ selectedRegions, selectedSeason, selectedWave }) => {
        this.selectedRegions = selectedRegions;
        this.aggSeason = selectedSeason;
        this.selectedWave = selectedWave;
      });
  }

  ngOnInit() {}

  public apply({ id: eventID }) {
    this.router.navigateByUrl(
      `/reporting/live/event/${eventID}/region/${this.selectedRegions}/wave/${this.selectedWave}/season/${this.aggSeason}`
    );
  }

  public switchToCollection({ id: eventID }: { id: number }) {
    this.router.navigateByUrl(`/reporting/${eventID}/collection/${this.selectedRegions}/${this.selectedWave}/SS`);
  }

  public switchToDiscount({ id: eventID }: { id: number }) {
    this.router.navigateByUrl(`/reporting/${eventID}/discount/${this.selectedRegions}/${this.selectedWave}/SS`);
  }
}
