import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { withLatestFrom, filter, map, switchMap } from 'rxjs/operators';
import * as moment from 'moment';

import { Event, EventDetails, VariantDetails } from '../../event';
import { EventFacadeService } from '../../event-facade.service';
import { ModelFacadeService } from '../../../../features/model/model-facade.service';
import { Model } from '../../../../features/model/state/model';
import { VariantFacadeService } from '../../../../features/variant/variant-facade.service';
import { Variant } from '../../../../features/variant/variant.model';
import { EventCreateEditStepperComponent } from '../event-create-edit-stepper/event-create-edit-stepper.component';
import { ModelDetails } from '../event-create-edit-stepper-model-details-form/event-create-edit-stepper-model-details-form.component';
import { ModelGridSelectService } from '../../../../features/model/components/model-grid-select-cell/model-grid-select.service';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailComponent implements OnInit {
  event$: Observable<Event>;
  models$: Observable<Model[]>;
  variants$: Observable<Variant[]>;
  showVariants = true;

  constructor(
    private eventFacadeService: EventFacadeService,
    private modelFacadeService: ModelFacadeService,
    private variantFacadeService: VariantFacadeService,
    public modelGridSelectService: ModelGridSelectService,
    private dialog: MatDialog
  ) {
    this.modelGridSelectService.selected = [];
  }

  ngOnInit() {
    this.event$ = this.eventFacadeService.getSelectedEvent();
    this.variants$ = this.variantFacadeService.getVariantsForSelectedEvent();

    this.models$ = this.modelFacadeService.getModelGroups().pipe(
      withLatestFrom(this.event$),
      filter(([_, event]) => !!event),
      map(([modelGroups, event]) => modelGroups.filter(model => +model.eventId === +event.id))
    );
  }

  toggleShowVariants() {
    this.showVariants = !this.showVariants;
  }

  openEditEventDialog(event: Event, variants: Variant[], models: Model[]) {
    const eventDetails = this.eventToEventDetailMapper(event);
    const variantsDetails = _.sortBy(variants.map(this.variantToVariantDetailMapper), ['wave']);
    const modelMapper = this.modelToModelDetailMapper(variantsDetails);
    const modelsDetails = models.map(modelMapper);
    const dialogRef = this.dialog.open(EventCreateEditStepperComponent, {
      height: '95vh',
      width: '95vw',
      maxWidth: '95vw',
      data: {
        eventDetails,
        variantsDetails,
        modelsDetails,
      },
    });
    // is afterClosed in events
    dialogRef
      .beforeClosed()
      .subscribe(
        (eventData: {
          eventDetails: Event & EventDetails & { id: number };
          variantsDetails: Array<VariantDetails & { updated: boolean }>;
          modelsDetails: ModelDetails[];
        }) => {
          if (eventData) {
            this.updateEvent(eventData);
            this.deleteVariants(_.differenceBy(variantsDetails, eventData.variantsDetails, ({ id }) => id));
          }
        }
      );
  }

  deleteVariants(variantsToDelete: any[]) {
    variantsToDelete.forEach(variant => {
      this.variantFacadeService.delete(variant);
    });
  }

  deleteEvent(id) {
    if (window.confirm('Are you sure you want to delete this event?')) {
      this.eventFacadeService.deleteEvent({ id: id });
    }
  }

  private eventToEventDetailMapper(event: Event) {
    // : EventDetails {
    return {
      ...event,
      detail: event.eventDetail,
      type: event.eventType,
    };
  }

  private variantToVariantDetailMapper(variant: Variant) {
    // : VariantDetails {
    return {
      ...variant,
      wave: `Wave ${variant.wave}`,
      minDisc: variant.minDisc ? variant.minDisc * 100 : variant.minDisc,
      maxDisc: variant.maxDisc ? variant.maxDisc * 100 : variant.maxDisc,
      optimisationTarget: variant.optimisationTarget ? variant.optimisationTarget * 100 : variant.optimisationTarget,
      minMargin: variant.minMargin ? variant.minMargin * 100 : variant.minMargin,
    };
  }

  private modelToModelDetailMapper(variants) {
    // : VariantDetails) {
    // : ModelDetails {
    return (model: Model) => ({
      ...model,
      department: {
        h2Desc: model.departmentDesc,
        h2Id: model.departmentId,
      },
      variant: _.find(variants, variant => +variant.id === +model.variantId),
    });
  }

  private updateEvent({
    eventDetails: updatedEventDetails,
    variantsDetails,
    modelsDetails,
  }: {
    eventDetails: Event & EventDetails & { id: number };
    variantsDetails: Array<VariantDetails & { updated: boolean }>;
    modelsDetails: Array<ModelDetails & { id?: number }>;
  }) {
    const updatedVariants = variantsDetails.filter(variant => !!variant.id);

    // pure update models (ie. variant has id)
    const updateModelsExistingVariant = modelsDetails.filter(model => model.id && !!model.variant.id);
    // update models (variant with new id)
    const updateModelsNewVariant = modelsDetails.filter(model => model.id && !model.variant.id);
    // new models
    const newModel = modelsDetails.filter(model => !model.id);

    this.eventFacadeService.update(updatedEventDetails);
    updatedVariants
      .filter(variant => variant.updated)
      .forEach(updatedVariant =>
        this.variantFacadeService.update({
          ...updatedVariant,
          endDate: moment(updatedVariant.endDate),
          startDate: moment(updatedVariant.startDate),
          wave: updatedVariant.wave.replace(/Wave /g, ''),
          minDisc: updatedVariant.minDisc || updatedVariant.minDisc === 0 ? updatedVariant.minDisc / 100 : updatedVariant.minDisc,
          maxDisc: updatedVariant.maxDisc || updatedVariant.maxDisc === 0 ? updatedVariant.maxDisc / 100 : updatedVariant.maxDisc,
          optimisationTarget:
            updatedVariant.optimisationTarget || updatedVariant.optimisationTarget === 0
              ? updatedVariant.optimisationTarget / 100
              : updatedVariant.optimisationTarget,
          minMargin: updatedVariant.minMargin || updatedVariant.minMargin === 0 ? updatedVariant.minMargin / 100 : updatedVariant.minMargin,
        })
      );

    this.variantFacadeService.createWizard({
      event: updatedEventDetails,
      params: {
        eventDetails: updatedEventDetails,
        modelsDetails: [...updateModelsNewVariant, ...newModel],
        variantsDetails: variantsDetails.map(d => ({
          ...d,
          eventId: Number(updatedEventDetails.id),
          startDate: moment(d.startDate),
          endDate: moment(d.endDate),
        })),
      },
    });
  }
  onClickEditSelectedModels() {
    const modelIDs = this.modelGridSelectService.selected.map(d => d.id);
    this.modelFacadeService.selectModels(modelIDs);
    this.modelGridSelectService.idSelected$.next([]);
  }
}
