import * as moment from 'moment';

import { formatPercent, formatCurrency, formatNumber } from '@angular/common';

export const dateFormatterUnix = (format: string) => params => moment.utc(+`${params.value}000`).format(format);

export const dateFormatter = (format: string) => params => (params.value ? moment.utc(params.value).format(format) : '');

export const percentFormatter = params => `${params.value}%`;

export const percentDecimalFormatter = params => (params.value || params.value === 0 ? formatPercent(params.value, 'en-US') : '');

export const currencyFormatter = currency => params =>
  params.value || params.value === 0 ? formatCurrency(params.value, 'en-US', currency) : '';

export const roundingFormatter = (decimalPoints: number) => params => parseFloat(Number(params.value).toFixed(decimalPoints));

export const thousandsFormatter = params => formatNumber(params.value, 'en-US');

// tslint:disable-next-line: max-line-length
export const thousandsRounded = (decimalPoints: number) => params =>
  formatNumber(parseFloat(Number(params.value).toFixed(decimalPoints)), 'en-US');

export const roundDownFormatter =  params => Math.floor(100 * params.value) / 100;
