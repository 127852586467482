import { Component, OnInit } from '@angular/core';
import { DiscountLadderAssignmentComponent } from '../discount-ladder-assignment.component';
import * as _ from 'lodash';
import { IDiscountLadder } from '../../discount-ladders';

@Component({
  selector: 'app-discount-ladder-assignment-grid-mapping-cell',
  templateUrl: './discount-ladder-assignment-grid-mapping-cell.component.html',
  styleUrls: ['./discount-ladder-assignment-grid-mapping-cell.component.scss']
})
export class DiscountLadderAssignmentGridMappingCellComponent implements OnInit {

  params: any;
  parentComponent: DiscountLadderAssignmentComponent;

  discountLadderChosenID: number;
  chosenDiscountLadder: any;

  get allLeaves() {
    return this.parentComponent &&
      this.parentComponent.merchHierarchy.filter((lineage) => lineage[this.params.node.field] === this.params.node.key);
  }

  agInit(params: any): void {
    this.params = params;
    this.parentComponent = this.params.context && this.params.context.parentComponent;
    this.parentComponent.rowUpdatedSubject.subscribe(() => this.setDiscountLadderChosenID());
  }

  constructor() { }

  setDiscountLadderChosenID() {
    this.discountLadderChosenID = undefined;
    this.chosenDiscountLadder = undefined;
    if ((Object.keys(_.keyBy(this.allLeaves, 'discountLadderId')).length === 1)) {
      const discountLadderID = +_.first(this.allLeaves).discountLadderId;
      this.discountLadderChosenID = discountLadderID;
      this.chosenDiscountLadder = _.find(this.parentComponent.discountLadders, (d: IDiscountLadder) => d.id === discountLadderID);
    }
  }

  ngOnInit() {
    this.setDiscountLadderChosenID();
  }

  refresh(): boolean {
    return false;
  }

  onChange() {
    let hierarchyLevel: number;
    let hierarchyId: string;
    if (this.params.node.field.indexOf(1) !== -1) {
      hierarchyLevel = 1;
      hierarchyId = _.first(this.allLeaves)['h1Id'];
      if (!hierarchyId) {
        hierarchyId = '0';
      }
    } else if (this.params.node.field.indexOf(2) !== -1) {
      hierarchyLevel = 2;
      hierarchyId = _.first(this.allLeaves)['h2Id'];
    } else {
      hierarchyLevel = 3;
      hierarchyId = _.first(this.allLeaves)['h3Id'];
    }
    const discountLadderId = this.discountLadderChosenID;

    this.parentComponent.mapHierarchy({ hierarchyLevel: hierarchyLevel, hierarchyId: hierarchyId, key:'discountLadderId', keyValue: discountLadderId });
  }

}
