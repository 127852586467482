import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  Inject,
} from '@angular/core';

import { Observable, merge, of } from 'rxjs';

import * as _ from 'lodash';

import { ModelDetailFacadeService } from '../../model-detail-facade.service';
import { ConfigurationSettingsService } from '../../../../user/configuration-settings.service';
import { VariantStats, CompareVariantsService } from './compare-variants.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Model } from '../../state/model';
import { ModelFacadeService } from '../../model-facade.service';
import * as XLSX from 'xlsx';
import { map, first, filter } from 'rxjs/operators';
import { currencyFormatter, percentDecimalFormatter, thousandsRounded, } from '../../../../infra/datagrid/datagrid.formatters';

@Component({
  selector: 'app-model-detail-compare-variants-dialog',
  templateUrl: './model-detail-compare-variants-dialog.component.html',
  styleUrls: ['./model-detail-compare-variants-dialog.component.scss'],
})
export class ModelDetailCompareVariantsDialogComponent implements OnInit {

  @Output()
  closeDialog = new EventEmitter();

  @Input()
  models: Model[];

  showLoadingOverlay = false;
  variants$: Observable<Array<VariantStats>> | Observable<Array<{ name: string }>>;
  view = 'compare';
  showVariantDetails = false;
  variantDetails = [];
  toolTipCurrentVariant: string;
  toolTipMinDiscount: string;
  toolTipMaxDiscount: string;
  toolTipSellThroughGoal: string;
  toolTipMinimumMargin: string;
  toolTipAverageDiscount: string;
  toolTipAverageDiscountStock: string;
  toolTipSpend: string;
  toolTipSalesUnits: string;
  toolTipSalesValue: string;
  toolTipClosingSellThrough: string;
  toolTipClosingStockUnits: string;
  toolTipClosingStockValue: string;

  constructor(
    private modelFacadeService: ModelFacadeService,
    private compareVariantsService: CompareVariantsService,
    public configurationSettingsService: ConfigurationSettingsService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.models = this.data.models;
    this.showLoadingOverlay = false;
    const modelIDs = this.models.filter(model => model.wave === this.data.selectedWave).map(({ id }) => id);
    const variantStats$ = this.compareVariantsService.getCompareVariants({ modelIDs });
    const dummyStats$ = of([{ name: undefined }, { name: undefined }, { name: undefined }]);
    this.variants$ = merge(dummyStats$, variantStats$);
    this.toolTipCurrentVariant = `Summary metrics based on optimal prices for models and regions selected in the planning view.`;
    this.toolTipMinDiscount = `Minimum discount that can be applied under the variant`;
    this.toolTipMaxDiscount = `Maximum discount  that can be applied under the variant`;
    this.toolTipSellThroughGoal = `Target sell through for the end of the event`; 
    this.toolTipMinimumMargin = `Lowest acceptable % margin`;
    this.toolTipAverageDiscount = `Average discount across all lines`;
    this.toolTipAverageDiscountStock = `Average discount across all lines weighted by stock`;
    this.toolTipSpend = `Forecasted spend for the variant (Discount on current price x total stock)`;
    this.toolTipSalesUnits = `Forecasted unit sales for the event`;
    this.toolTipSalesValue = `Forecasted sales value for the event`;
    this.toolTipClosingSellThrough = `Forecasted sell through at the end of the event`;
    this.toolTipClosingStockUnits = `Forecasted stock units at the end of the event`;
    this.toolTipClosingStockValue = `Forecasted stock value at the end of the event`;
  }

  selectVariant({ variantId }) {
    this.models
      .filter(model => model.wave === this.data.selectedWave)
      .filter(model => model.status === 'Planning')
      .forEach(({ id }) => {
        this.modelFacadeService.applyVariant({ variantId, id });
      });
    this.showLoadingOverlay = true;
    setTimeout(() => this.ngOnInit(), 2500); /// todo: noooot great...
  }

  onCloseDialog() {
    this.closeDialog.emit(true);
  }

  swapView(tab) {
    this.view = tab;
    this.showVariantDetails = !this.showVariantDetails;
  }

  exportExcel() {
    const variantDetails = [];
    const currency = this.configurationSettingsService.currency;
    const modelIDs = this.models
      .filter((model) => model.wave === this.data.selectedWave)
      .map(({ id }) => id);
    this.compareVariantsService
      .getCompareVariants({ modelIDs })
      .pipe(
        first(),
        filter((variants) => !!variants),
        map((v) => {
          variantDetails.push(_.flatten(['', _(v).map(v => v.name).value()]));
          variantDetails.push(_.flatten(['Min Discount', _(v).map(v => percentDecimalFormatter({value : v.minDiscount}))
          .value()]));
          variantDetails.push(_.flatten(['Max Discount', _(v).map(v => percentDecimalFormatter({ value: v.maxDiscount })).value()]));
          variantDetails.push(_.flatten(['Sell-Through Goal', _(v).map(v => percentDecimalFormatter({ value : v.sellThroughGoal })).
          value()]));
          variantDetails.push(_(['Minimum Margin', (_(v).map(v => v.minMargin ? percentDecimalFormatter({ value : v.minMargin }) :
           'N/A').value())]).flatten().value());
          variantDetails.push(_(['Average Discount', (_(v).map(v => percentDecimalFormatter({ value : v.avgDiscount })).
          value())]).flatten().value());
          variantDetails.push(_(['Average Discount by Stock', _(v).map(v => percentDecimalFormatter({ value: v.avgDiscountStock })).
          value()]).flatten().value());
          variantDetails.push(_(['Spend', _(v).map(v => currencyFormatter(currency)({ value: v.spend ? v.spend.toFixed(1) : v.spend })).
          value()]).flatten().value());
          variantDetails.push(_(['Sales Units', _(v).map(v => thousandsRounded(1)({ value : v.salesUnits})).value()]).flatten().value());
          variantDetails.push(_(['Sales Value', _(v).map(v => currencyFormatter(currency)({ value: v.salesValue ? v.salesValue.toFixed(1) :
          v.salesValue })).value()]).
          flatten().value());
          variantDetails.push(_(['Closing Sell Through', _(v).map(v => percentDecimalFormatter({ value: v.closingSellThrough })).
          value()]).flatten().value());
          variantDetails.push(_(['Closing Stock Units', _(v).map(v => thousandsRounded(1)({ value: v.closingStockUnits }) ).value()])
          .flatten().value());
          variantDetails.push(_(['Closing Stock Value', _(v).map(v => currencyFormatter(currency)({ value: v.closingStockValue ? 
          v.closingStockValue.toFixed(1) : v.closingStockValue})).value()]).flatten().value());

          return variantDetails;
        })).subscribe((d) => {
          const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(d);
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
          XLSX.writeFile(wb, 'Variants.xlsx');
        });
  }
}
