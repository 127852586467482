import { Component, OnInit, Input, Output, EventEmitter, forwardRef, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-wave-selector',
  templateUrl: './wave-selector.component.html',
  styleUrls: ['./wave-selector.component.scss'],
})
export class WaveSelectorComponent implements OnInit {
  constructor() {}

  get waveRange() {
    return _(this.maxWave).range();
  }

  @Input()
  maxWave: number;

  @Input()
  selectedWave: number;

  @Output()
  waveChanged: EventEmitter<number> = new EventEmitter();

  showWaveDropdown = false;

  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (event.target.className !== 'value') {
      this.showWaveDropdown = false;
    }
  }

  ngOnInit() {}

  selectWave(wave: number) {
    this.waveChanged.next(wave);
    this.showWaveDropdown = false;
  }

  prev() {
    const newValue = this.selectedWave - 1;
    if (newValue > 0) {
      this.waveChanged.next(newValue);
    }
  }

  next() {
    const newValue = this.selectedWave + 1;
    if (newValue <= this.maxWave) {
      this.waveChanged.next(newValue);
    }
  }
}
