import { Component, Input, Output, EventEmitter } from '@angular/core';

import { EventModelStatus } from '../../../abstracts/event-status.type';
import { IEvent } from '../../../abstracts/event.interface';
import { Model } from '../../model/state/model';

@Component({
  selector: 'app-dashboard-event-model-list',
  templateUrl: './dashboard-event-model-list.component.html',
  styleUrls: ['./dashboard-event-model-list.component.scss'],
})
export class DashboardEventModelListComponent {
  @Input()
  models: Array<Model>;

  @Input()
  events: Array<IEvent>;

  @Input()
  selectedEvent: IEvent;

  @Output()
  eventClicked: EventEmitter<IEvent> = new EventEmitter();

  @Output()
  showEventsClicked: EventEmitter<null> = new EventEmitter();

  @Output()
  modelsSelected: EventEmitter<Array<string | number>> = new EventEmitter();

  @Output()
  showEventReportsClicked: EventEmitter<IEvent> = new EventEmitter();

  selectedModelIDs: Array<string | number> = [];

  constructor() {}

  editSelectedModelIDs(): void {
    if (this.selectedModelIDs.length > 0) {
      this.modelsSelected.emit(this.selectedModelIDs);
    }
  }

  showEvents(): void {
    this.selectedModelIDs = [];
    this.showEventsClicked.emit();
  }

  onEventClick(event: IEvent): void {
    this.eventClicked.emit(event);
  }

  getStatusClass(status: EventModelStatus): string {
    const statusCode = EventModelStatus[status];
    return {
      0: 'button--white',
      1: 'button--pink',
      2: 'button--light-blue',
      3: 'button--light-green',
      4: 'button--forest-green',
      5: 'button--dark-grey',
    }[statusCode];
  }

  goToReports(e: Event, event: IEvent): void {
    e.stopPropagation();
    this.showEventReportsClicked.emit(event);
  }

  goToModelDetailForEvent(e: Event, event: IEvent & { models: { [key: string]: Array<Model> } }): void {
    e.stopPropagation();
    const ids = event.models['Planning'].map(d => d.id);
    this.modelsSelected.emit(ids);
  }

  onModelDblClick(modelDblClicked: Model & { regionsPlanned: Array<Model> }): void {
    const ids = [modelDblClicked, ...modelDblClicked.regionsPlanned].map(d => d.id);
    this.modelsSelected.emit(ids);
  }

  onModelClick(modelClicked: Model & { regionsPlanned: Array<Model> }): void {
    [modelClicked, ...modelClicked.regionsPlanned].forEach(model => {
      const index = this.selectedModelIDs.indexOf(model.id);
      if (index === -1) {
        this.selectedModelIDs.push(model.id);
      } else {
        this.selectedModelIDs.splice(index, 1);
      }
    });
  }

  goToModelDetail(e: Event, model: Model & { regionsPlanned: Array<Model> }): void {
    e.stopPropagation();
    const ids = model.regionsPlanned.map(d => d.id).concat([model.id]);
    this.modelsSelected.emit(ids);
  }
}
