import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import {
  Effect,
  Actions,
  ofType
} from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

import * as priceLadders from './price-ladders.actions';

import { PriceLaddersService } from '../../price-ladders.service';
import { IPriceLadder } from '../../price-ladders';
import { GetHelpService } from '../../../../features/get-help/get-help.service';

@Injectable()
export class PriceLaddersEffects {
  @Effect()
  getPriceLadders$: Observable<Action> = this.actions$
    .pipe(
      ofType(priceLadders.PRICELADDERSGETALL),
      switchMap(() =>
        this.priceLaddersService
          .getLadders()
          .pipe(
            map((data: IPriceLadder[]) => new priceLadders.PriceLaddersGetAllSuccessAction(data)),
            catchError(error => of(new priceLadders.PriceLaddersGetAllFailureAction(error)))
          )
      )
    );

  @Effect({ dispatch: false })
  getPriceLaddersFailure$: Observable<any> = this.actions$
    .pipe(
      ofType(priceLadders.PRICELADDERSGETALLFAILURE),
      switchMap(() => {
        this.getHelpService.showSnackbarError(priceLadders.PRICELADDERSGETALLFAILURE);

        return of(false);
      }),
    );

  @Effect()
  createPriceLadder$: Observable<Action> = this.actions$
    .pipe(
      ofType(priceLadders.PRICELADDERSCREATE),
      switchMap((action: priceLadders.PriceLaddersCreateAction) =>
        this.priceLaddersService
          .createLadder(action.payload)
          .pipe(
            map((data: any) => new priceLadders.PriceLaddersCreateSuccessAction(data)),
            catchError(error => of(new priceLadders.PriceLaddersCreateFailureAction(error)))
          )
      )
    );

  @Effect({ dispatch: false })
  createPriceLadderFailure$: Observable<any> = this.actions$
    .pipe(
      ofType(priceLadders.PRICELADDERSCREATEFAILURE),
      switchMap(() => {
        this.getHelpService.showSnackbarError(priceLadders.PRICELADDERSCREATEFAILURE);

        return of(false);
      }),
    );

  @Effect()
  updatePriceLadder$: Observable<Action> = this.actions$
    .pipe(
      ofType(priceLadders.PRICELADDERSUPDATEACTION),
      switchMap((action: priceLadders.PriceLaddersUpdateAction) =>
        this.priceLaddersService
          .updateLadder(action.payload)
          .pipe(
            map((data: any) => new priceLadders.PriceLaddersUpdateSuccessAction(data)),
            catchError(error => of(new priceLadders.PriceLaddersUpdateFailureAction(error)))
          )
      )
    );

  @Effect({ dispatch: false })
  updatePriceLadderFailure$: Observable<any> = this.actions$
    .pipe(
      ofType(priceLadders.PRICELADDERSUPDATEACTIONFAILURE),
      switchMap(() => {
        this.getHelpService.showSnackbarError(priceLadders.PRICELADDERSUPDATEACTIONFAILURE);

        return of(false);
      }),
    );

  @Effect()
  deletePriceLadder$: Observable<Action> = this.actions$
    .pipe(
      ofType(priceLadders.PRICELADDERSDELETEACTION),
      switchMap((action: priceLadders.PriceLaddersDeleteAction) =>
        this.priceLaddersService
          .deleteLadder(action.payload)
          .pipe(
            map((data: any) => new priceLadders.PriceLaddersDeleteSuccessAction({ ...action.payload })),
            catchError(error => of(new priceLadders.PriceLaddersDeleteFailureAction(error)))
          )
      )
    );

  @Effect({ dispatch: false })
  deletePriceLadderFailure$: Observable<any> = this.actions$
    .pipe(
      ofType(priceLadders.PRICELADDERSDELETEACTIONFAILURE),
      switchMap(() => {
        this.getHelpService.showSnackbarError(priceLadders.PRICELADDERSDELETEACTIONFAILURE);

        return of(false);
      }),
    );

  constructor(
    private priceLaddersService: PriceLaddersService,
    private actions$: Actions,
    private getHelpService: GetHelpService,
  ) { }
}
