import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IEventsState } from './events.reducer';

import * as _ from 'lodash';
import * as moment from 'moment';

export const getEventsState = createFeatureSelector<IEventsState>('events');

export const getEvents = createSelector(
  getEventsState,
  (state: IEventsState) => _.map(state.entities, val => ({ ...val, endDate: +moment(val.endDate), startDate: +moment(val.startDate) }))
);

export const getEvent = ({ id }) =>
  createSelector(
    getEventsState,
    (state: IEventsState) => state.entities[id]
  );

export const getEventById = (id: number) =>
  createSelector(
    getEventsState,
    (state: IEventsState) => state.entities[id]
  );

export const getEventIds = createSelector(
  getEventsState,
  (state: IEventsState) => state.ids
);

export const getLoading = createSelector(
  getEventsState,
  (state: IEventsState) => state.loading
);

export const getViewMode = createSelector(
  getEventsState,
  (state: IEventsState) => state.viewMode
);

export const getStats = ({ eventID }) =>
  createSelector(
    getEventsState,
    (state: IEventsState) => state.entities[+eventID].stats
  );

export const getCollectionReport = ({ eventIDs, regions, wave }: { eventIDs: string[]; regions: string[]; wave: string }) =>
  createSelector(
    getEventsState,
    (state: IEventsState) => {
      const report = _(eventIDs)
        .map(eventID => state.entities[eventID])
        .filter(d => !!d && !!d.eventCollectionReport)
        .map(entity => {
          const regionKey = regions.join();
          const region = entity.eventCollectionReport[regionKey];
          if (region) {
            return region[wave] || [];
          }

          return [];
        })
        .flatten()
        .value();

      return report;
    }
  );

export const getDiscountReport = ({ eventIDs, regions, wave }: { eventIDs: string[]; regions: string[]; wave: string }) =>
  createSelector(
    getEventsState,
    (state: IEventsState) => {
      const report = _(eventIDs)
        .map(eventID => state.entities[eventID])
        .filter(d => !!d && !!d.eventDiscountReport)
        .map(entity => {
          const regionKey = regions.join();
          const region = entity.eventDiscountReport[regionKey];
          if (region) {
            return region[wave] || [];
          }

          return [];
        })
        .flatten()
        .value();

      return report;
    }
  );
