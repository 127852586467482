import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';


import * as fromRoot from '../../app.reducer';
import { Store, select } from '@ngrx/store';

import { tap, filter, first, switchMap, catchError } from 'rxjs/operators';
import { getMerchHierarchy } from '../../user/user.reducer';
import { UserGetMerchHierarchyAction } from '../../user/user.actions';

@Injectable({
  providedIn: 'root'
})
export class MerchHierarchyGuard implements CanActivate {
  constructor(private store: Store<fromRoot.State>) { }

  getFromStoreOrAPI(): Observable<any> {
    return this.store
    .pipe(
      select(getMerchHierarchy),
      tap((merchHierarchy: any) => {
        if (!merchHierarchy) {
          this.store.dispatch(new UserGetMerchHierarchyAction());
        }
      }),
      filter((merchHierarchy: any) => merchHierarchy && merchHierarchy.length),
      first()
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.getFromStoreOrAPI()
      .pipe(
        switchMap(() => of(true)),
        catchError(() => of(false))
      );
  }
}
