import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';

import { Store } from '@ngrx/store';

import * as fromRoot from '../../../../app.reducer';
import { OrgCreateUserAction } from '../state/org.actions';
import { IOrg } from '../state/org.reducer';

@Component({
  selector: 'app-create-user-dialog',
  templateUrl: './create-user-dialog.component.html',
  styleUrls: ['./create-user-dialog.component.scss']
})
export class CreateUserDialogComponent implements OnInit {
  @Output()
  closeDialog = new EventEmitter();

  @Input()
  selectedOrg: IOrg;

  @Input()
  topFix = true;

  user_email: string;
  user_roles: any;

  constructor(
    public store: Store<fromRoot.State>,
  ) { }

  ngOnInit() {
  }

  onCloseDialog() {
    this.closeDialog.emit(true);
  }

  createNewUserForOrg() {
    const newUser = {
      email: this.user_email,
      role: this.user_roles.join(','),
      org: this.selectedOrg.id,
    };
    this.store.dispatch(new OrgCreateUserAction(newUser));
  }

}
