import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../auth.service';

declare var firebaseui;
declare var firebase;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  private loginError = false;
  private passwordResetEmailSent = false;
  private email: string;
  private password: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
  ) {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((idToken) => {
          const userIdToken = idToken;
          this.auth.setToken(userIdToken);
          this.route.queryParams.subscribe((params) => {
            if (params.redirect) {
              setTimeout(() => window.location.assign(params.redirect), 200);
            } else {
              setTimeout(() => window.location.assign('/'), 200);
            }
          });
          // this.router.navigate(['/']);
        });
      }
    });
  }

  onKeydown() {
    this.loginError = false;
    this.passwordResetEmailSent = false;
  }

  keyUpFunction(event) {
    if (event.key === 'Enter') {
      this.submitSignIn(this.email, this.password);
    }
  }

  submitSignIn(email, password) {
    firebase.auth().signInWithEmailAndPassword(email, password).catch((error) => {
      // Handle Errors here.
      this.loginError = true;
    });
  }

  sendPasswordResetEmail(email) {
    firebase.auth().sendPasswordResetEmail(email);
    this.passwordResetEmailSent = true;
  }

  ngOnInit() {

  }

}
