import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';

import { Observable, fromEvent } from 'rxjs';
import { map, delay, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';

import { EventModelStatus } from '../../../../abstracts/event-status.type';

import { ModelFacadeService } from '../../model-facade.service';
import { UserFacadeService } from '../../../../user/user-facade.service';
import { Model, Line } from '../../state/model';
import { isNotLocked } from '../../utilities/isNotLocked';

@Component({
  selector: 'app-model-detail-takeall-cell',
  templateUrl: './model-detail-takeall-cell.component.html',
  styleUrls: ['./model-detail-takeall-cell.component.scss'],
})
export class ModelDetailTakeallCellComponent implements IHeaderAngularComp, OnInit {
  params: any;
  allLines$: Observable<Array<Line>>;
  filterApplied = false;
  takeAll;
  showMenu: boolean;
  selectedModels$: Observable<{ [key: string]: Model }>;
  disableTakeall = false;
  status$: Observable<string>;

  @ViewChild('menuButton', { read: ElementRef, static: false })
  public menuButton;

  getFilteredLines(filterCondition) {
    if (this.params.api.getModel().rootNode.leafGroup === true) {
      return this.params.api
        .getModel()
        .rootNode.childrenAfterFilter.map(node => node.data)
        .filter(d => d.taken === filterCondition)
        .filter(d => isNotLocked(d));
    } else {
      return this.params.api
        .getModel()
        .rootNode.allLeafChildren.map(node => node.data)
        .filter(d => d.taken === filterCondition)
        .filter(d => isNotLocked(d));
    }
  }

  agInit(params: any): void {
    this.params = params;

    fromEvent(params.api, 'filterChanged').subscribe(() => {
      this.filterApplied = true;
      this.takeAll = this.getFilteredLines('No').length === 0;
    });
  }

  onMenuClicked() {
    this.params.showColumnMenu(this.menuButton.nativeElement);
  }

  constructor(private modelsFacadeService: ModelFacadeService, private userFacadeService: UserFacadeService) {}

  ngOnInit() {
    const seasonList$: Observable<string[]> = this.userFacadeService.getSelectedSeasons();

    const includedSkus$ = this.userFacadeService.getIncludedSkus();

    const excludedSkus$ = this.userFacadeService.getExcludedSkus();

    this.allLines$ = this.modelsFacadeService.getSelectedModelsRegionLines().pipe(
      withLatestFrom(seasonList$, includedSkus$, excludedSkus$),
      map(([lines, seasonList, includedIds, excludedIds]) => {
        return lines.filter(line => ((_(seasonList).includes(line.season) || _(includedIds).includes(line.skuId))
        ) && !_(excludedIds).includes(line.skuId));
      })
    );

    this.status$ = this.modelsFacadeService
      .getSelectedModelsRegion()
      .pipe(map(models => EventModelStatus[Math.min(...models.map(model => +EventModelStatus[model.status]))]));

    this.status$.pipe(distinctUntilChanged()).subscribe(status => {
      if (status !== 'Planning') {
        this.disableTakeall = true;
      } else {
        this.disableTakeall = false;
      }
    });

    this.allLines$
      .pipe(
        delay(100),
        map(lines => lines.filter(d => d.taken === 'No'))
      )
      .subscribe(untakenLines => {
        if (this.filterApplied) {
          this.takeAll = this.getFilteredLines('No').length === 0;
        } else {
          this.takeAll = untakenLines.length === 0;
        }
      });
  }

  toggleTakeAll(checked) {
    const filterCondition = checked ? 'No' : 'Yes';
    const filteredLines = this.getFilteredLines(filterCondition);
    this.modelsFacadeService.takeUntakeLines({
      lines: filteredLines,
      taken: checked ? 1 : 0,
    });
    if (filteredLines.length === 0) {
      this.takeAll = false;
    }
  }
}
