import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import * as _ from 'lodash';
import { VariantDetails } from '../../event';
import { ModelDetails } from '../event-create-edit-stepper-model-details-form/event-create-edit-stepper-model-details-form.component';
import { MatSnackBar } from '@angular/material';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../../../app.reducer';
import { IZone, getOrgSetting } from '../../../../user';

export interface ModelAdded {
  division: { h1Desc: string; h1Id: number };
  department: { h2Desc: string; h2Id: number };
  region: { id: string | number; description: string };
}

@Component({
  selector: 'app-event-create-edit-stepper-model-form',
  templateUrl: './event-create-edit-stepper-model-form.component.html',
  styleUrls: ['./event-create-edit-stepper-model-form.component.scss'],
})
export class EventCreateEditStepperModelFormComponent implements OnInit, OnChanges {
  @Input()
  merchHierarchy: {
    l1: any;
      l1Map: { [key: string]: { h1Desc: any, h1Id: any, h2Desc: any; h2Id: any }[] };
    l2: {
      h2Desc: any;
      h2Id: any;
    }[];
    merchHierarchy: any;
  };

  @Input()
  modelsDetails: ModelDetails[];

  @Input()
  variants: VariantDetails[];

  @Output()
  modelsAdded: EventEmitter<ModelAdded[]> = new EventEmitter();

  @Output()
  saveClicked = new EventEmitter();

  @Output()
  prevClicked = new EventEmitter();

  // todo: this could be part of models added (models changed?)
  @Output()
  modelRemoved: EventEmitter<number> = new EventEmitter();

  regionsWithNoLines = [];
  wave1Variants: any;
  dataStoreDepts = [];
  divisionList = [];
  get regions() {
    return _(this.variants)
      .map((d) => d.region)
      .uniqBy("id")
      .value();
  }

  divisionsSelected: any[] = [];
  departmentsSelected: { h2Desc: any; h2Id: any }[] = [];
  regionsSelected: IZone[] = [];

  departments: { h2Desc: any; h2Id: any }[] = [];

  constructor(
    public store: Store<fromRoot.State>,
    private snackBarService: MatSnackBar
  ) {}

  ngOnInit() {
    this.store
      .pipe(select(getOrgSetting(['departments'])))
      .subscribe(({ departments }) => {
        this.dataStoreDepts = departments;
      });
    const l1MapValues = _(Object.values(this.merchHierarchy.l1Map)).flatten().value();
    this.divisionList = _(l1MapValues).filter((division) => _(this.dataStoreDepts).includes(division.h2Id))
    .map(div => div.h1Desc).uniq().value();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.variants) {
      this.wave1Variants = changes.variants.currentValue.filter(
        (variant) => variant.wave.replace(/Wave /g, "") === "1"
      );
    }
  }

  onDivisionChange() {
    this.departments = _(_(this.divisionsSelected)
      .map((division) => this.merchHierarchy.l1Map[division])
      .flatten()
      .map(({ h2Id, h2Desc }) => ({ h2Id, h2Desc }))
      .uniqBy("h2Id")
      .value()
      .filter((d) => _.includes(this.dataStoreDepts, d.h2Id))).orderBy(['h2Desc'], ['asc']).value();
  }

  addModels() {
    const { merchHierarchy } = this.merchHierarchy;
    const modelsToAdd: ModelAdded[] = _(this.departmentsSelected)
      .map(department => {
        const { h1Desc, h1Id } = _.find(merchHierarchy, d => d.h2Id === department.h2Id);
        const totalLineCount = _.filter(merchHierarchy, d => d.h2Id === department.h2Id).map(d => d.lineCount);
        const regionsWithLines = _(totalLineCount).map(obj => Object.keys(obj)).flatten().value();
        const regionsWithLinesUnique = regionsWithLines.filter((item, index) => regionsWithLines.indexOf(item) === index);
        this.regionsWithNoLines = this.regionsSelected.filter((region) => !_(regionsWithLinesUnique).includes(region.id))
          .map(region => ({ division: { h1Desc, h1Id }, department, region }));
        return this.regionsSelected.filter((region) => _(regionsWithLinesUnique).includes(region.id))
          .map(region => ({ division: { h1Desc, h1Id }, department, region }));
      })
      .flatten()
      .value();
    if (this.regionsWithNoLines.length > 0) {
      this.showNoLinesMessage();
    }
    const uniqModels: ModelAdded[] = [];
    _(modelsToAdd).forEach((newModels) => {
      if (_(this.modelsDetails).map(m => m).findIndex(newModels) === -1) {
        uniqModels.push(newModels);
      }
    }
    );
    if (this.modelsDetails.length > 0) {
      this.modelsAdded.next(uniqModels);
    } else {
      this.modelsAdded.next(modelsToAdd);
    }

  }

  onModelRemoved(index: number) {
    this.modelRemoved.next(index);
  }

  save() {
    this.saveClicked.emit();
  }

  prev() {
    this.prevClicked.emit();
  }

  toggleRegionSelection(check) {
    if (check) {
      this.regionsSelected = this.regions;
    } else {
      this.regionsSelected = [];
    }
  }
  toggleDivisionSelection(check) {
    if (check) {
      this.divisionsSelected = this.divisionList;
      this.onDivisionChange();
    } else {
      this.divisionsSelected = [];
    }
  }
  toggleDeptSelection(check) {
    if (check) {
      this.departmentsSelected = this.departments;
    } else {
      this.departmentsSelected = [];
    }
  }

  showNoLinesMessage() {
    const noRegionsList = this.regionsWithNoLines.map((r) => {
      return r.division.h1Desc + "-" + r.department.h2Desc + "-" + r.region.id;
    });
    const snackbarStr = `No models created for ${noRegionsList} as no lines exist for the departments`;
    const snackBarRef = this.snackBarService.open(snackbarStr, "Dismiss", {
      verticalPosition: "top",
    });
    snackBarRef._dismissAfter(10000);
  }
}
