import {
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import * as _ from 'lodash';

import { IVariantsState } from './variants.reducer';
import { IVariant } from '../../../abstracts/event.interface';

export const getVariantsState = createFeatureSelector<IVariantsState>('variants');

export const getVariants = createSelector(
  getVariantsState,
  (state: IVariantsState) => _.map(state.entities, (val: IVariant) => val)
);

export const getVariantIDs = createSelector(
  getVariantsState,
  (state: IVariantsState) => state.ids
);

export const getVariantsByEventID = (eventID) => createSelector(
  getVariantsState,
  (state: IVariantsState) => _.filter(state.entities, (val: IVariant) => val.eventId === +eventID)
);

export const getLoading = createSelector(
  getVariantsState,
  (state: IVariantsState) => state.loading
);
