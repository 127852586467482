import { Component, AfterViewInit, ViewContainerRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material';

import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import * as _ from 'lodash';

import { roundDownFormatter } from '../../../../infra/datagrid/datagrid.formatters';

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;

@Component({
  selector: 'app-model-detail-grid-cell-editor',
  templateUrl: './model-detail-grid-cell-editor.component.html',
  styleUrls: ['./model-detail-grid-cell-editor.component.scss'],
})
export class ModelDetailGridCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  params: ICellEditorParams;
  value;

  private cancelBeforeStart = false;
  public highlightAllOnFocus = true;

  @ViewChild('input', { read: ViewContainerRef, static: false }) public input;

  constructor(private snackBarService: MatSnackBar) {}

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.value = this.params.value;
    this.setInitialState(this.params);

    this.cancelBeforeStart =
      this.params.node.data.model.statusCode !== 0 ||
      this.params.node.data.taken === 'No' ||
      (params.charPress && '1234567890.'.indexOf(params.charPress) < 0);
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.input.element.nativeElement.focus();
      if (this.highlightAllOnFocus) {
        this.input.element.nativeElement.select();
        this.highlightAllOnFocus = false;
      } else {
        const length = this.input.element.nativeElement.value
          ? this.input.element.nativeElement.value.length
          : 0;
        if (length > 0) {
          this.input.element.nativeElement.setSelectionRange(length, length);
        }
      }
    });
  }

  setInitialState(params: ICellEditorParams) {
    let startValue;
    let highlightAllOnFocus = true;
    if (params.keyPress === KEY_BACKSPACE || params.keyPress === KEY_DELETE) {
      startValue = '';
    } else if (params.charPress) {
      startValue = params.charPress;
      highlightAllOnFocus = false;
    } else {
      startValue = params.value;
    }

    this.value = startValue;
    this.highlightAllOnFocus = highlightAllOnFocus;
  }

  getValue(): any {
    return this.value;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  isCancelAfterEnd(): boolean {
    let testValue = this.value;
    const percentageMatch = /^\d+(?:\.\d+)?%$/.exec(testValue);
    const percentage = percentageMatch && percentageMatch[0];
    const variant = _.find(this.params.data.model.variant, d => +d.id === +this.params.data.model.variantId);
    let price: number;
    if (this.params.data.lastWavePrice) {
      price = this.params.data.lastWavePrice;
    } else if (variant && variant.discountMethod === 'full_price') {
      price = this.params.data.fullPrice;
    } else {
      price = this.params.data.currentPrice;
    }

    if (percentage) {
      if (+percentage >= 100) {
        const snackBarRef = this.snackBarService.open(`Invalid override percentage`);
        snackBarRef._dismissAfter(10000);

        return true;
      }
      testValue = price - (+percentage.replace('%', '') / 100) * price;
      this.value = roundDownFormatter({ value: testValue });
    }
    const isNonCurrency = /[^$,.\d]/.test(testValue);

    if (testValue > price) {
      const snackbarStr = `Invalid override price`;
      const snackBarRef = this.snackBarService.open(snackbarStr);
      snackBarRef._dismissAfter(10000);
    }

    return testValue > price || isNonCurrency;
  }
}
