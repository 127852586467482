export type EventStatus = 'Planning' | 'Other' | 'Complete';

export enum EventModelStatus {
    Planning,
    Submitted,
    Approved,
    Live,
    Complete,
    Closed,
}
