import { Action } from '@ngrx/store';

import { IUser } from './user';

export const USERGETCURRENTUSER = '[USER] GetCurrent';
export const USERGETCURRENTUSERSUCCESS = '[USER] GetCurrentSuccess';
export const USERGETCURRENTUSERFAILURE = '[USER] GetCurrentFailure';
export const USERGETADMIN = '[USER] GetAdmin';
export const USERGETADMINSUCCESS = '[USER] GetAdminSuccess';
export const USERSETORGSETTING = '[USER] SetOrgSetting';
export const USERSETORGSETTINGSUCCESS = '[USER] SetOrgSettingSuccess';
export const USERSETORGSETTINGFAILURE = '[USER] SetOrgSettingFailure';
export const USERGETORGZONES = '[USER] GetOrgZone';
export const USERGETORGZONESSUCCESS = '[USER] GetOrgZoneSuccess';
export const USERGETORGZONESFAILURE = '[USER] GetOrgZoneFailure';
export const USERGETMERCHHIERARCHY = '[USER] GetMerchHierarchy';
export const USERGETMERCHHIERARCHYSUCCESS = '[USER] GetMerchHierarchySuccess';
export const USERGETMERCHHIERARCHYFAILURE = '[USER] GetMerchHierarchyFailure';
export const USERMAPMERCHHIERARCHY = '[USER] MapMerchHierarchy';
export const USERMAPMERCHHIERARCHYSUCCESS = '[USER] MapMerchHierarchySuccess';
export const USERMAPMERCHHIERARCHYFAILURE = '[USER] MapMerchHierarchyFailure';
export const USERGETORGSEASONS = '[USER] GetOrgSeasons';
export const USERGETORGSEASONSSUCCESS = '[USER] GetOrgSeasonsSuccess';
export const USERGETORGSEASONSFAILURE = '[USER] GetOrgSeasonsFailure';

export class UserGetCurrentAction implements Action {
  type = USERGETCURRENTUSER;
  constructor() {}
}

export class UserGetSuccessAction implements Action {
  type = USERGETCURRENTUSERSUCCESS;

  constructor(public payload: IUser) {}
}

export class UserGetErrorAction implements Action {
  type = USERGETCURRENTUSERFAILURE;

  constructor(public payload) {}
}

export class UserGetAdminAction implements Action {
  type = USERGETADMIN;

  constructor() {}
}

export class UserGetAdminSuccessAction implements Action {
  type = USERGETADMINSUCCESS;

  constructor(public payload) {}
}

export class UserSetOrgSettingAction implements Action {
  type = USERSETORGSETTING;

  constructor(public payload: { name: string; value: any }) {}
}

export class UserSetOrgSettingSuccessAction implements Action {
  type = USERSETORGSETTINGSUCCESS;

  constructor(public payload) {}
}

export class UserSetOrgSettingFailureAction implements Action {
  type = USERSETORGSETTINGFAILURE;

  constructor(public payload) {}
}

export class UserGetOrgZonesAction implements Action {
  type = USERGETORGZONES;

  constructor() {}
}

export class UserGetOrgZonesActionSuccess implements Action {
  type = USERGETORGZONESSUCCESS;

  constructor(public payload) {}
}

export class UserGetOrgZonesActionFailure implements Action {
  type = USERGETORGZONESFAILURE;

  constructor(public payload) {}
}

export class UserGetMerchHierarchyAction implements Action {
  type = USERGETMERCHHIERARCHY;

  constructor() {}
}

export class UserGetMerchHierarchyActionSuccess implements Action {
  type = USERGETMERCHHIERARCHYSUCCESS;

  constructor(public payload) {}
}

export class UserGetMerchHierarchyActionFailure implements Action {
  type = USERGETMERCHHIERARCHYFAILURE;

  constructor(public payload) {}
}

export class UserMapMerchHierarchyAction implements Action {
  type = USERMAPMERCHHIERARCHY;

  constructor(public payload) {}
}

export class UserMapMerchHierarchySuccessAction implements Action {
  type = USERMAPMERCHHIERARCHYSUCCESS;

  constructor(public payload: any) {}
}

export class UserMapMerchHierarchyFailureAction implements Action {
  type = USERMAPMERCHHIERARCHYFAILURE;

  constructor(public payload: any) {}
}

export class UserGetOrgSeasonsAction implements Action {
  type = USERGETORGSEASONS;

  constructor() {}
}

export class UserGetOrgSeasonsSuccessAction implements Action {
  type = USERGETORGSEASONSSUCCESS;

  constructor(public payload) {}
}

export class UserGetOrgSeasonsFailureAction implements Action {
  type = USERGETORGSEASONSFAILURE;

  constructor(public payload) {}
}

export type actions =
  | UserGetSuccessAction
  | UserGetAdminSuccessAction
  | UserSetOrgSettingSuccessAction
  | UserGetMerchHierarchyActionSuccess
  | UserMapMerchHierarchySuccessAction
  | UserGetOrgSeasonsSuccessAction;
