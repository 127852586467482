import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';

import * as fromRoot from '../../../app.reducer';
import { tap, filter, first, switchMap, catchError, map } from 'rxjs/operators';
import { EventsFacadeService } from '../../events/events-facade.service';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ReportingDetailDiscountGuard implements CanActivate {
  constructor(private eventsFacadeService: EventsFacadeService) {}

  getFromStoreOrAPI({ eventIDs, regions, wave }): Observable<any> {
    let goneToStore;
    return this.eventsFacadeService.getDiscountReportData({ eventIDs, regions, wave }).pipe(
      tap(collectionData => {
        if (collectionData.length === 0 && !goneToStore) {
          this.eventsFacadeService.getEventsDiscountReport({ eventIDs, regions, wave });
          goneToStore = true;
        }
      }),
      filter(
        collectionData =>
          collectionData.length > 0 &&
          _(collectionData)
            .map(d => !!d)
            .indexOf(false) === -1
      ),
      first()
    );
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const eventIDs = next.params['id'].split(',');
    const regions = next.params['regions'].split(',');
    const wave = next.params['wave'];

    return this.getFromStoreOrAPI({ eventIDs, regions, wave }).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }
}
