import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDiscountLadder } from './discount-ladders';

@Injectable({
  providedIn: 'root'
})
export class DiscountLaddersService {

  constructor(private http: HttpClient) { }

  getDiscountLadders(): Observable<IDiscountLadder[]> {
    return this.http.get<IDiscountLadder[]>(`/api/discount_ladder`);
  }

  createDiscountLadder(data: { name: string, discounts: string[] }): Observable<IDiscountLadder> {
    return this.http.post<IDiscountLadder>(`/api/discount_ladder`, data);
  }

  updateDiscountLadder(data: { id: any, name: string, discounts: string[] }): Observable<IDiscountLadder> {
    return this.http.put<IDiscountLadder>(`/api/discount_ladder/${data.id}`, data);
  }

  deleteDiscountLadder(data: { id: any }): Observable<IDiscountLadder> {
    return this.http.delete<IDiscountLadder>(`/api/discount_ladder/${data.id}`, {});
  }

}
