import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { Store, select } from '@ngrx/store';

import { tap, switchMap, catchError } from 'rxjs/operators';
import { getSeasons } from './user.reducer';
import { UserGetOrgSeasonsAction } from './user.actions';

@Injectable({
  providedIn: 'root',
})
export class SeasonsGuard implements CanActivate {
  constructor(private store: Store<any>) {}

  getFromStoreOrAPI(): Observable<any> {
    return this.store.pipe(
      select(getSeasons),
      tap((seasonsList: any) => {
        if (!seasonsList) {
          this.store.dispatch(new UserGetOrgSeasonsAction());
        }
      })
    );
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.getFromStoreOrAPI().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }
}
