import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {
  Observable,
  of,
  combineLatest,
} from 'rxjs';

import {
  tap,
  filter,
  first,
  switchMap,
  catchError,
} from 'rxjs/operators';

import { ModelFacadeService } from './model-facade.service';

@Injectable({
  providedIn: 'root'
})
export class ModelGuard implements CanActivate {
  constructor(private modelFacadeService: ModelFacadeService) { }

  getFromStoreOrAPI(): Observable<any> {
    return combineLatest(this.modelFacadeService.getIDs(), this.modelFacadeService.getLoadingStatus()).pipe(

        tap(([ids, isLoading]) => {
          if (ids.length === 0 && !isLoading) {
            this.modelFacadeService.loadAll();
          }
        }),
        filter(([ids, isLoading]) => ids.length === 1 || !isLoading),
        first()
      );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.getFromStoreOrAPI()
      .pipe(
        switchMap(() => of(true)),
        catchError(() => of(false))
      );
  }
}


