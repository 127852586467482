import * as priceLadders from './price-ladders.actions';

import * as _ from 'lodash';
import { IPriceLadder } from '../../price-ladders';

export interface IPriceLaddersState {
  entities: { [key: string]: IPriceLadder };
  ids: any[];
  loading: boolean;
}

export const InitialState: IPriceLaddersState = {
  entities: {},
  ids: null,
  loading: false,
};

export function reducer(state = InitialState, action: priceLadders.actions) {
  switch (action.type) {
    case priceLadders.PRICELADDERSGETALL: {
      return {
        ...state,
        loading: true
      };
    }
    case priceLadders.PRICELADDERSGETSALLSUCCESS: {
      let ids = _.map(action.payload, (entity) => entity.id);
      if (!ids) {
        ids = [];
      }
      return {
        ...state,
        ids,
        entities: _.keyBy(action.payload, 'id'),
      };
    }
    case priceLadders.PRICELADDERSDELETEACTIONSUCCESS: {
      return {
        ...state,
        ids: state.ids.filter((id) => +id === action.payload.id),
        entities: _.omit(state.entities, action.payload.id),
      };
    }
    case priceLadders.PRICELADDERSCREATESUCCESS: {
      return {
        ...state,
        ids: [...state.ids, action.payload.id],
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload
        }
      }
    }
    case priceLadders.PRICELADDERSUPDATEACTIONSUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload
        }
      };
    }
    default:
      return state;
  }
}
