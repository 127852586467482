import {
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import { IOrgState, IOrg } from './org.reducer';

import * as _ from 'lodash';
import { IUser } from '../../../../user/user';

export const getOrgState = createFeatureSelector<IOrgState>('org');

export const getOrgs = createSelector(
  getOrgState,
  (state: IOrgState): IOrg[] => _.map(state.orgs, (org: IOrg) => org)
);

export const getSelectedOrg = createSelector(
  getOrgState,
  (state: IOrgState): IOrg => {
    if (state.orgs && state.orgs[state.selectedOrg]) {
      return state.orgs[state.selectedOrg];
    }
  }
);

export const getUsersByOrg = (orgID) => createSelector(
  getOrgState,
  (state: IOrgState): IUser[] => {
    if (state.orgs && state.orgs[orgID]) {
      return state.orgs[orgID].users;
    }
  }
)
