import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IZone } from './user.reducer';
import { map } from 'rxjs/operators';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) {}

  getCurrentUser(): Observable<any> {
    return this.http.get<any>('/api/org/user');
    // return this.http.get<any>('/mock/api/user');
  }

  getAdmin(): Observable<any> {
    return this.http.get<any>('/api/org/admin/user');
  }

  setSetting(params: { name: string; value: any }): Observable<any> {
    return this.http.post<any>(`/api/org/settings/${params.name}`, { value: params.value });
  }

  getOrgZones(): Observable<IZone[]> {
    const query = `{
      region {
      id
      description
      code
      }
    }`;

    return (
      this.http
        .post<{ region: IZone[] }>('/api', query)
        // return this.http.post<{ region: IZone[] }>('/mock/api/regions', query)
        .pipe(map(response => response.region))
    );
  }

  getOrgSeasons(): Observable<any> {
    const query = `{
      season {
        description
      }
    }`;

    return this.http
      .post<{ season: Array<{ description: string }> }>('/api', query)
      .pipe(map(({ season }) => season.map(d => d.description)));
  }

  getMerchHierarchy(): Observable<any> {
    const query = `{
      hierarchymapping {
        h1Desc
        h1Id
        h2Desc
        h2Id
        h3Desc
        h3Id
        h4Desc
        h4Id
        h5Desc
        h5Id
        priceLadderId
        lineCount
        discountLadderId
        minPrice
      }
    }`;

    return (
      this.http
        .post<any>('/api', query)
        // return this.http.post<any>('/mock/api/hierarchyMapping', query)
        .pipe(map(data => data.hierarchymapping.map(d => ({ ...d, lineCount: JSON.parse(d.lineCount) }))))
    );
  }

  mapMerchHierarchy(params): Observable<any> {
    const query = `mutation {
      updateProductHierarchy(
        hierarchyLevel: "${params.hierarchyLevel}",
        hierarchyId: ${params.hierarchyId},
        ${params.key != null ? params.key + ':' : ''} ${params.key === 'minPrice' ? params.keyValue : `"${params.keyValue}"` },
      ){
      hierarchyId
      hierarchyDesc
      priceLadderId
      discountLadderId
      minPrice
      }
    }`;
    return this.http.post<any>('/api', query).pipe(map(data => data.updateProductHierarchy));
  }

  getAllSkuList(): Observable<any> {
    return this.http.get<any>(`/api/all-lines`);
  }
}
