import { Component, OnInit } from '@angular/core';
import { PriceLadderAssignmentComponent } from '../price-ladder-assignment.component';
import * as _ from 'lodash';
import { IPriceLadder } from '../../price-ladders';

@Component({
  selector: 'app-price-ladder-assignment-grid-mapping-cell',
  templateUrl: './price-ladder-assignment-grid-mapping-cell.component.html',
  styleUrls: ['./price-ladder-assignment-grid-mapping-cell.component.scss']
})
export class PriceLadderAssignmentGridMappingCellComponent implements OnInit {

  params: any;
  parentComponent: PriceLadderAssignmentComponent;

  priceLadderChosenID: number;
  chosenPriceLadder: any;

  get allLeaves() {
    return this.parentComponent &&
      this.parentComponent.merchHierarchy.filter((lineage) => lineage[this.params.node.field] === this.params.node.key);
  }

  agInit(params: any): void {
    this.params = params;
    this.parentComponent = this.params.context && this.params.context.parentComponent;
    this.parentComponent.rowUpdatedSubject.subscribe(() => this.setPriceLadderChosenID());
  }

  constructor(
    // private router: Router,
    // private store: Store<fromRoot.State>,
  ) { }

  setPriceLadderChosenID() {
    this.priceLadderChosenID = undefined;
    this.chosenPriceLadder = undefined;
    if (Object.keys(_.keyBy(this.allLeaves, 'priceLadderId')).length === 1) {
      const priceLadderID = +_.first(this.allLeaves).priceLadderId;
      this.priceLadderChosenID = priceLadderID;
      this.chosenPriceLadder = _.find(this.parentComponent.priceLadders, (d: IPriceLadder) => d.id === priceLadderID);
    }
  }

  ngOnInit() {
    this.setPriceLadderChosenID();
  }

  refresh(): boolean {
    return false;
  }

  onChange() {
    let hierarchyLevel: number;
    let hierarchyId: string;
    if (this.params.node.field.indexOf(1) !== -1) {
      hierarchyLevel = 1;
      hierarchyId = _.first(this.allLeaves)['h1Id'];
      if (!hierarchyId) {
        hierarchyId = '0';
      }
    } else if (this.params.node.field.indexOf(2) !== -1) {
      hierarchyLevel = 2;
      hierarchyId = _.first(this.allLeaves)['h2Id'];
    } else {
      hierarchyLevel = 3;
      hierarchyId = _.first(this.allLeaves)['h3Id'];
    }
    const priceLadderId = this.priceLadderChosenID;
    this.parentComponent.mapHierarchy({ hierarchyLevel: hierarchyLevel, hierarchyId: hierarchyId, key: 'priceLadderId', keyValue:priceLadderId });
  }

}
