import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';

import {
  SideBarDef, GridApi, ColumnApi,
} from 'ag-grid-community';

import {
  Subject,
} from 'rxjs';

import * as _ from 'lodash';

@Component({
  selector: 'app-datagrid',
  templateUrl: './datagrid.component.html',
  styleUrls: ['./datagrid.component.scss']
})
export class DatagridComponent implements OnInit, OnChanges {
  @Input()
  columnDefs;

  @Input()
  rowData;

  @Output()
  rowClick = new EventEmitter();

  @Output()
  rowDoubleClick = new EventEmitter();

  @Output()
  modelUpdate = new EventEmitter();

  @Output()
  rowGroupOpen = new EventEmitter();

  @Output()
  rowGroupClose = new EventEmitter();

  @Input()
  gridOptions;

  @Input()
  quickFilter;

  @Input()
  enableFilter = false;

  @Input()
  enableSorting = false;

  @Input()
  sidebar: SideBarDef;

  @Input()
  autoGroupColumnDef;

  @Input()
  groupIncludeTotalFooter;

  @Input()
  filters$: Subject<{ condition, field: string, allStatusValues }>;

  @Output()
  gridReady = new EventEmitter();

  @Output()
  filterChanged = new EventEmitter();

  @Input()
  statusBar;

  private gridApi: GridApi;
  private gridColumnApi: ColumnApi;
  columnMadeVisible = false;
  columnHidden = false;
  columnsVisible = [];
  columnsHidden = [];
  savedColumnState = [];
  columnMoved = false;
  columnsChanged: Array<{ colId: string;  width: number }> = [];
  columnResized = false;
  constructor() { }

  ngOnInit() {
    this.gridOptions = {
      ...this.gridOptions,
      defaultColDef: {
        ...this.gridOptions && this.gridOptions.defaultColDef,
        resizable: true,
        filter: true
      },
    };
    if (this.filters$) {
      this.filters$.subscribe(({ field, condition, allStatusValues }) => {
        try {
          let filterComponent = this.gridApi.getFilterInstance(field);
          if (!filterComponent) {
            filterComponent = this.gridApi.getFilterInstance(field + '_1'); // temporary fix for filters not working
          }
          if (condition) {
            filterComponent.setModel(condition);
            if (!filterComponent.getModel() && (field === 'modelStatus' || field === 'status' )) {
             const filterInstance = this.gridOptions.api.getFilterInstance(field);
              filterInstance.setFilterValues(allStatusValues);
              filterInstance.selectNothing();
              condition.forEach(status => {
              filterInstance.selectValue(status);
            });
            this.gridApi.onFilterChanged();
            }
          } else {
            this.gridOptions.api.setFilterModel(null);
          }
          this.gridApi.onFilterChanged();
        } catch (e) {
          console.error(e);
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rowData && this.gridApi) {
      //this.gridColumnApi.autoSizeAllColumns(); ///// don't want this in all cases
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridReady.emit(params);
  }

  onRowClick(event) {
    this.rowClick.emit(event);
  }

  onModelUpdate(event) {
    this.modelUpdate.emit(event);
  }

  onRowGroupOpen(event) {
    this.rowGroupOpen.emit(event);
  }

  onRowGroupClose(event) {
    this.rowGroupClose.emit(event);
  }

  onRowDoubleClick(event) {
    this.rowDoubleClick.emit(event);
  }

  onColumnVisible(e) {
    if (e.visible === true && e.column && !this.columnsVisible.includes(e.column.colId)) {
      this.columnsVisible.push(e.column.colId);
      if (this.columnsVisible.includes(e.column.colId)) {
        this.columnsHidden = this.columnsHidden.filter(item => item !== (e.column.colId));
      }
      this.columnMadeVisible = true;
    } else if (e.visible === false && !this.columnsHidden.includes(e.column.colId)) {
      this.columnHidden = true;
      this.columnsHidden.push(e.column.colId);
      if (this.columnsVisible.includes(e.column.colId)) {
        this.columnsVisible = this.columnsVisible.filter(item => item !== (e.column.colId));
      }
    }
  }

  onDragStopped(e) {
   this.columnMoved = true;
  }

  private getFromQueue(property: string) {
  return _.find(this.columnsChanged, d => d.colId === property);
  }

  private setToQueue({ colId, width }) {
    this.columnResized = true;
    const columnChanged = this.getFromQueue(colId);
    if (columnChanged) {
      columnChanged.width = width;
    } else {
      this.columnsChanged.push({
        colId: colId,
        width: width
      });
    }
  }

  onColumnResized(e) {
    this.columnResized = true;
    if(e.column != null) {
      const colId = e.column.colId;
      const width = e.column.actualWidth;
      this.setToQueue({ colId, width});
    }

  }
  onComponentStateChanged(e) {
    if (this.columnMoved === true) {
       const columnOrder = e.columnApi.getAllGridColumns().map(column => column.getColId());
       e.columnApi.moveColumns(columnOrder, 0 );
    }
    if (this.columnMadeVisible  && (this.columnsVisible.length > 0 )) {
     e.columnApi.setColumnsVisible(this.columnsVisible, true);
    }
    if (this.columnHidden  && (this.columnsHidden.length > 0 )) {
       e.columnApi.setColumnsVisible(this.columnsHidden, false);
    }
    if (this.columnResized) {
        this.columnsChanged.map((column) => {
        e.columnApi.setColumnWidth(column.colId, column.width);
      });
    }
  }

  onFilterChanged(e) {
    this.filterChanged.emit(e.api.isAnyFilterPresent());
  }
}
