import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import * as _ from 'lodash';

import { DATE_FORMAT } from '../events/events.service';
import { Variant } from '../variant/variant.model';
import { forkJoin, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VariantsService {
  constructor(private http: HttpClient) {}

  delete({ id }: { id: number }) {
    const query = `mutation { deactivateVariant(id: ${id}) }`;

    return this.http.post<any>('api', query);
  }

  createVariantWizard(variants: Array<Variant & { uuid }>): Observable<Variant[]> {
    if (variants.length === 0) {
      return of([]);
    }
    const requests = variants.map(params => this.createVariant({ ...params, optimisationType: 'Sell Through' }));

    return forkJoin(requests);
  }

  createVariant(params: Variant & { uuid }): Observable<Variant> {
    const query = `mutation {
      createVariant(
        eventId: ${params.eventId}
        minDisc: ${params.minDisc || params.minDisc === 0 ? params.minDisc / 100 : ''}
        maxDisc: ${params.maxDisc || params.maxDisc === 0 ? params.maxDisc / 100 : ''}
        startDate: "${params.startDate.format(DATE_FORMAT)}"
        endDate: "${params.endDate.format(DATE_FORMAT)}"
        optimisationType: "${params.optimisationType}"
        optimisationTarget: ${params.optimisationTarget || params.optimisationTarget === 0 ? params.optimisationTarget / 100 : ''}
        ${params.minMargin != null ? 'minMargin:' : ''} ${params.minMargin || params.minMargin === 0 ? params.minMargin / 100 : ''}
        alias: "${params.alias}"
        wave: ${params.wave.replace(/Wave /g, '')}
        region: "${params.region}"
        ladderType:"${params.ladderType.toLowerCase()}"
        discountMethod:"${params.discountMethod}"
      ) {
        id
        eventId
        wave
        alias
        startDate
        endDate
        minDisc
        maxDisc
        optimisationType
        optimisationTarget
        minMargin
        alias
        wave
        region
        ladderType
        discountMethod
      }
    }`;

    return this.http.post<{ createVariant: Variant }>('api', query).pipe(map(data => ({ ...data.createVariant, uuid: params.uuid })));
  }

  getVariants() {
    const query = `{
      variants {
        id
        eventId
        region
        wave
        minDisc
        maxDisc
        startDate
        endDate
        optimisationType
        optimisationTarget
        minMargin
        alias
        wave
        ladderType
        discountMethod
      }
    }`;

    return (
      this.http
        .post<{ variants: Variant[] }>('api', query)
        // return this.http.post<{ variants: IVariant[] }>('/mock/api/variants', query)
        .pipe(map(data => data.variants))
    );
  }

  updateVariant(params: Variant) {
    const query = `
      mutation {
        updateVariant(
          id: ${params.id},
          minDisc: ${params.minDisc},
          maxDisc: ${params.maxDisc},
          startDate: "${params.startDate.format(DATE_FORMAT)}",
          endDate: "${params.endDate.format(DATE_FORMAT)}",
          optimisationType: "${params.optimisationType}",
          optimisationTarget: ${params.optimisationTarget},
          ${params.minMargin != null ? 'minMargin:' : ''} ${params.minMargin != null ? params.minMargin : ''},
          alias: "${params.alias}",
          ladderType:"${params.ladderType.toLowerCase()}",
          discountMethod:"${params.discountMethod}",
        ) {
          id
          eventId
          minDisc
          maxDisc
          startDate
          endDate
          optimisationType
          optimisationTarget
          minMargin
          alias
          wave
          region
          ladderType
          discountMethod
        }
      }
    `;

    return this.http.post<{ updateVariant: Variant[] }>('/api', query).pipe(map(data => _.first(data.updateVariant)));
  }
}
