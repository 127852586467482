import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { map, catchError, switchMap, mergeMap } from 'rxjs/operators';

import { Action } from '@ngrx/store';

import { Effect, Actions, ofType } from '@ngrx/effects';

import * as variants from './variants.actions';
import { VariantsService } from '../variants.service';
import { IVariant } from '../../../abstracts/event.interface';
import { GetHelpService } from '../../get-help/get-help.service';
import { EVENTSCREATEWIZARDSUCCESS, EventsCreateWizardSuccess } from '../../events/state/events.actions';
import { Variant } from '../../variant/variant.model';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Injectable()
export class VariantsEffects {
  @Effect()
  getVariants$: Observable<Action> = this.actions$.pipe(
    ofType(variants.VARIANTSGET),
    switchMap((action: variants.VariantsGetAction) =>
      this.variantsService.getVariants().pipe(
        map(data => new variants.VariantsGetSuccessAction(data)),
        catchError(error => of(new variants.VariantsGetFailureAction(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  getVariantsFailure$: Observable<any> = this.actions$.pipe(
    ofType(variants.VARIANTSGETFAILURE),
    switchMap(() => {
      this.getHelpService.showSnackbarError(variants.VARIANTSGETFAILURE);

      return of(false);
    })
  );

  @Effect()
  updateVariant$: Observable<Action> = this.actions$.pipe(
    ofType(variants.VARIANTSUPDATE),
    mergeMap((action: variants.VariantsUpdateAction) =>
      this.variantsService.updateVariant(action.payload).pipe(
        map((data: IVariant) => new variants.VariantsUpdateSuccessAction(data)),
        catchError(error => of(new variants.VariantsUpdateFailureAction(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateVariantFailure$: Observable<any> = this.actions$.pipe(
    ofType(variants.VARIANTSUPDATEFAILURE),
    switchMap(() => {
      this.getHelpService.showSnackbarError(variants.VARIANTSUPDATEFAILURE);

      return of(false);
    })
  );

  @Effect()
  deleteVariant$: Observable<any> = this.actions$.pipe(
    ofType(variants.VARIANTDELETE),
    mergeMap(({ payload }) =>
      this.variantsService.delete({ id: payload['id'] }).pipe(
        map(() => new variants.VariantsDeleteSuccessAction({ id: payload['id'] })),
        catchError(error => of(new variants.VariantsDeleteFailureAction(error)))
      )
    )
  );

  @Effect()
  createVariant$: Observable<Action> = this.actions$.pipe(
    ofType(variants.VARIANTSCREATE),
    mergeMap((action: variants.VariantsCreateAction) =>
      this.variantsService.createVariant({ ...action.payload }).pipe(
        map((data: IVariant) => new variants.VariantsCreateSuccessAction(data)),
        catchError(error => of(new variants.VariantsCreateFailureAction(error)))
      )
    )
  );

  @Effect()
  createVariantWizard$: Observable<Action> = this.actions$.pipe(
    ofType(EVENTSCREATEWIZARDSUCCESS, variants.VARIANTSCREATEWIZARD),
    mergeMap((action: EventsCreateWizardSuccess | variants.VariantsCreateWizardAction) =>
      this.variantsService
        .createVariantWizard(
          action.payload.params.variantsDetails
            .filter(variant => !variant.id)
            .map(variant => ({
              ...variant,
              eventId: Number(action.payload.event.id),
              region: variant.region.id,
              startDate: moment(variant.startDate),
              endDate: moment(variant.endDate),
            }))
        )
        .pipe(
          map(
            (newVariants: Array<Variant & { uuid }>) =>
              new variants.VariantsCreateWizardSuccessAction({ ...action.payload, variants: newVariants })
          ),
          catchError(error => of(new variants.VariantsCreateWizardFailureAction(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  variantWizardSuccessNavigate$: Observable<any> = this.actions$.pipe(
    ofType(variants.VARIANTSCREATEWIZARDSUCCESS),
    mergeMap((action: variants.VariantsCreateWizardSuccessAction) => {
      this.router.navigateByUrl(`/events/${action.payload.event.id}`);

      return of(false);
    })
  );

  constructor(
    private variantsService: VariantsService,
    private actions$: Actions,
    private getHelpService: GetHelpService,
    private router: Router
  ) {}
}
