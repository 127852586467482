import { Component, OnInit } from '@angular/core';

import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, first, tap, filter } from 'rxjs/operators';

import { ReportingLiveService, LiveReport } from './reporting-live.service';
import { UserFacadeService } from '../../../user/user-facade.service';
import { IZone } from '../../../user/user.reducer';

@Component({
  selector: 'app-reporting-live',
  templateUrl: './reporting-live.component.html',
  styleUrls: ['./reporting-live.component.scss'],
})
export class ReportingLiveComponent implements OnInit {
  reportData$: Observable<LiveReport>;
  seasons$: Observable<string[]>;
  regions$: Observable<IZone[]>;

  regions: string[] = [];
  aggSeason: string;
  lyStartDate: Date;
  lyEndDate: Date;
  tyStartDate: Date;
  tyEndDate: Date;
  includeDcStock = false;

  loading = false;
  toolTipAllSku: string;
  toolTipAllSkuLy: string;
  toolTipTyLy: string;
  toolTipTyLyLfl: string;
  toolTipSaleSkus: string;
  toolTipSaleSkusLy: string;
  toolTipSaleSkusLyTy: string;
  toolTipSaleSkusLyTYLfl: string;

  constructor(private reportingLiveService: ReportingLiveService, private userFacadeService: UserFacadeService) {}

  ngOnInit() {
    this.regions$ = this.userFacadeService.getOrgRegions();
    this.seasons$ = this.userFacadeService.getOrgSeasons().pipe(map(seasons => _.sortBy(seasons, d => d.substring(0, d.length - 1))));
    this.userFacadeService
      .getDefaultSeason()
      .pipe(
        filter(defaultSeason => !!defaultSeason),
        first()
      )
      .subscribe(defaultSeason => (this.aggSeason = defaultSeason));
    this.toolTipAllSku = `All SKUs for the specified season this year`;
    this.toolTipAllSkuLy = `All SKUs  for the corresponding season last year`;
    this.toolTipTyLy = `Percentage change this year vs last year for all SKUs`;
    this.toolTipTyLyLfl = `Percentage change this year vs last year for all SKUs in the stores open last year like-for-like`;
    this.toolTipSaleSkus = `All SKUs on sale for the specified season this year`;
    this.toolTipSaleSkusLy = `All SKUs on sale for the corresponding season last year`;
    this.toolTipSaleSkusLyTy = `Percentage change this year vs last year for all sale SKUs`;
    this.toolTipSaleSkusLyTYLfl = `Percentage change this year vs last year for all sale SKUs in the stores open last year like-for-like`; 
  }

  apply() {
    const match = /(^\d{4})(.{1})$/.exec(this.aggSeason);
    const currentYear = match[1];
    const currentSeason = match[2];
    const aggLastSeason = `${+currentYear - 1}${currentSeason}`;
    this.loading = true;
    this.reportData$ = this.reportingLiveService
      .getLiveReport(
        this.aggSeason,
        aggLastSeason,
        this.regions,
        moment(this.lyStartDate).format('YYYY-MM-DD'),
        moment(this.lyEndDate).format('YYYY-MM-DD'),
        moment(this.tyStartDate).format('YYYY-MM-DD'),
        moment(this.tyEndDate).format('YYYY-MM-DD'),
        this.includeDcStock
      )
      .pipe(
        first(),
        tap(() => (this.loading = false))
      );
  }

  sundayFilter(d: Date): boolean {
    const day = d.getDay();

    return true;
  }

  sundayFilterLyEnd = (d: Date): boolean => {
    const day = d.getDay();

    if (d < this.lyStartDate) {
      return false;
    }

    return true;
  }

  sundayFilterTyStart = (d: Date): boolean => {
    const day = d.getDay();

    if (d < this.lyEndDate) {
      return false;
    }

    return true;
  }

  sundayFilterTyEnd = (d: Date): boolean => {
    const day = d.getDay();

    if (d < this.tyStartDate) {
      return false;
    }

    return true;
  }
}
