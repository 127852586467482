import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { Store, select } from '@ngrx/store';

import * as fromRoot from '../../app.reducer';
import { getEventIds } from '../events/state/events.selectors';
import { filter, tap, first, switchMap, catchError } from 'rxjs/operators';
import { EventsGetAction } from '../events/state/events.actions';

@Injectable({
  providedIn: 'root'
})
export class EventsGuard implements CanActivate {

  constructor(private store: Store<fromRoot.State>) { }

  getFromStoreOrAPI(): Observable<any> {
    return this.store
      .pipe(
        select(getEventIds),
        tap((eventIDs: any) => {
          if (!eventIDs) {
            this.store.dispatch(new EventsGetAction());
          }
        }),
        filter((eventIDs: any) => eventIDs),
        first()
      );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      return this.getFromStoreOrAPI()
        .pipe(
          switchMap(() => of(true)),
          catchError(() => of(false))
        );
  }
}
