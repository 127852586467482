import { Component, OnInit } from '@angular/core';
import { OrgGetAction } from '../state/org.actions';
import { getOrgs } from '../state/org.selectors';
import { Observable } from 'rxjs';
import { IOrg } from '../state/org.reducer';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../app.reducer';
import { Router } from '@angular/router';

@Component({
  selector: 'app-org-grid',
  templateUrl: './org-grid.component.html',
  styleUrls: ['./org-grid.component.scss']
})
export class OrgGridComponent implements OnInit {
  orgs$: Observable<IOrg[]>;

  columns = [
    { headerName: 'NAME', field: 'name' },
    { headerName: 'DB NAME', field: 'db_name' },
    { headerName: 'ID', field: 'id' },
  ];

  constructor(
    public store: Store<fromRoot.State>,
    private router: Router,
  ) { }

  ngOnInit() {
    this.orgs$ = this.store.select(getOrgs);
  }

  onRowClick(event) {
    const orgID = event.data.id;
    this.router.navigate(['/sbadmin/organizations', orgID]);
  }

}
