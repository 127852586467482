import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPriceLadder } from './price-ladders';

@Injectable({
  providedIn: 'root'
})
export class PriceLaddersService {

  constructor(private http: HttpClient) { }

  getLadders(): Observable<IPriceLadder[]> {
    return this.http.get<IPriceLadder[]>(`/api/price_ladder`);
  }

  createLadder(data: {name: string, prices: string[]}): Observable<IPriceLadder> {
    return this.http.post<IPriceLadder>(`/api/price_ladder`, data);
  }

  updateLadder(data: { id: any, name: string, prices: string[] }): Observable<IPriceLadder> {
    return this.http.put<IPriceLadder>(`/api/price_ladder/${data.id}`, data);
  }

  deleteLadder(data: { id: any }): Observable<IPriceLadder> {
    return this.http.delete<IPriceLadder>(`/api/price_ladder/${data.id}`, {});
  }

}
