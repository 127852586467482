import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as moment from 'moment';
import * as _ from 'lodash';
import * as uuid from 'uuid';

import {
  isBetween,
  isMaxDiscLessThanMinDisc,
  isOptimisationTargetValid,
  isStartDateBeforeEndDate,
} from '../../../../infra/customValidators';
import { EventDetails, VariantDetails } from '../../event';
import { IZone } from '../../../../user';
import orgData from '../../../../../assets/json_data/org-data.json';
import data from '../../../../../assets/json_data/default-data.json';
import { MatSnackBar } from '@angular/material';
import { UserFacadeService } from '../../../../user';
import { Observable } from 'rxjs';

const DEFAULT_LADDER_TYPE = 'price';
const DEFAULT_DISCOUNT_TYPE = 'current_price_event';

@Component({
  selector: 'app-event-create-edit-stepper-variant-form',
  templateUrl: './event-create-edit-stepper-variant-form.component.html',
  styleUrls: ['./event-create-edit-stepper-variant-form.component.scss'],
})
export class EventCreateEditStepperVariantFormComponent implements OnInit, OnChanges {
  @Input()
  variantsDetails: VariantDetails[];

  @Input()
  eventDetails: EventDetails;

  @Input()
  currency: string;

  @Input()
  regions: IZone[];

  @Output()
  variantAdded: EventEmitter<VariantDetails> = new EventEmitter();

  @Output()
  variantRemoved: EventEmitter<VariantDetails> = new EventEmitter();

  @Output()
  variantEdited: EventEmitter<{
    details: VariantDetails;
    index: number;
  }> = new EventEmitter();

  @Output()
  nextClicked = new EventEmitter();

  @Output()
  prevClicked = new EventEmitter();

  @Output()
  saveClicked = new EventEmitter();

  newVariants = [];

  newVariantForm: FormGroup;
  variantStartDateFilter;
  variantEndDateFilter;
  waves: string[];
  selectedWaveIndex = -1;
  PROMO = 'Promo';
  disableDelete;
  allRegionsSelected;
  disableNextButton;
  selectedVariant: VariantDetails;
  _regions: Array<IZone & { disabled: boolean }>;
  enableSelectFields = true;
  removeItemHighlight = false;
  discountMethod$: Observable<any>;
  constructor(private snackBarService: MatSnackBar,
              private userFacadeService: UserFacadeService) {
    if (orgData[0].hasOwnProperty('MODEL TYPE LIST')) {
      this.waves = orgData[0]['MODEL TYPE LIST'];
    } else {
      this.waves = data[0]['MODEL TYPE LIST'];
    }
  }

  ngOnInit() {
    const startDate = this.eventDetails.startDate;
    const endDate = this.eventDetails.endDate;
    this.variantStartDateFilter = (date: Date) =>
      date >
        moment(this.eventDetails.startDate)
          .subtract(1, 'days')
          .toDate() && date <= moment(this.eventDetails.endDate).toDate();
    this.variantEndDateFilter = this.variantStartDateFilter;
    this.discountMethod$ = this.userFacadeService.getDiscountMethod();
    this.newVariantForm = new FormGroup(
      {
        alias: new FormControl(null, [Validators.required, Validators.maxLength(255), Validators.pattern(/^[\x00-\x7F]+$/)]),
        startDate: new FormControl(startDate, [Validators.required]),
        endDate: new FormControl(endDate, [Validators.required]),
        minDisc: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]*$/), isBetween(0, 100)]),
        maxDisc: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]*$/), isBetween(0, 100)]),
        optimisationType: new FormControl(null, []),
        optimisationTarget: new FormControl(null, [Validators.required, isBetween(0, 100)]),
        minMargin: new FormControl(null, [isBetween(-100, 100)]),
        wave: new FormControl(null, [Validators.required]),
        region: new FormControl('', [Validators.required]),
        uuid: new FormControl(null),
        ladderType: new FormControl(DEFAULT_LADDER_TYPE, [Validators.required]),
        discountMethod: new FormControl(DEFAULT_DISCOUNT_TYPE, [Validators.required]),
      },
      [isMaxDiscLessThanMinDisc, isOptimisationTargetValid, isStartDateBeforeEndDate]
    );
    this.newVariantForm.get('wave').valueChanges.subscribe(wave => {
      this.newVariantForm.get('region').reset();
      if (wave) {
        const waveNumber = wave.replace(/Wave /g, '');
        if (+waveNumber > 1) {
          this._regions = this.regions.map(region => {
            // does region have a wave-1 variant
            const disabled = !_.find(
              this.variantsDetails,
              variant => variant.region.id === region.id && variant.wave === `Wave ${waveNumber - 1}`
            );

            return { ...region, disabled };
          });
        } else {
          this._regions = this.regions.map(region => ({ ...region, disabled: false }));
        }
      }
    });
    this.newVariantForm.get('region').valueChanges.subscribe(region => {
      if (this.newVariantForm.value.wave) {
        const waveNumber = this.newVariantForm.value.wave.replace(/Wave /g, '');

        if (+waveNumber > 1) {
          _(region)
            .map(reg => {
              const previousWaveStart = _.min(
                _(this.variantsDetails)
                  .filter(variant => variant.region.id === reg.id && variant.wave === `Wave ${waveNumber - 1}`)
                  .map(v => moment(v.startDate).toDate())
                  .value()
              );
              this.variantStartDateFilter = (date: Date) =>
                date > moment(previousWaveStart).toDate() && date <= moment(this.eventDetails.endDate).toDate();
            })
            .value();
        } else {
          _(region)
            .map(reg => {
              const futureWaveStart = _.max(
                _(this.variantsDetails)
                  .filter(variant => variant.region.id === reg.id && +variant.wave.replace(/Wave /g, '') > +waveNumber)
                  .map(v => moment(v.startDate).toDate())
                  .value()
              );
              if (futureWaveStart) {
                this.variantStartDateFilter = (date: Date) =>
                  date >
                    moment(this.eventDetails.startDate)
                      .subtract(1, 'days')
                      .toDate() && date < moment(futureWaveStart).toDate();
              }
            })
            .value();
        }
      }
    });
    // if (this.savedVariants) {
    //   this.variantsDetails = this.savedVariants;
    //   this.savedVariants.forEach(variant => {
    //     this.variantAdded.next(variant);
    //   });
    //   this.newVariantForm.controls['wave'].valueChanges.subscribe(value => {
    //     this.newVariantForm.controls['alias'].setErrors(null);
    //   });
    //   this.newVariantForm.controls['region'].valueChanges.subscribe(value => {
    //     this.newVariantForm.controls['alias'].setErrors(null);
    //   });
    // }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.eventDetails && this.newVariantForm) {
      this.newVariantForm.controls.startDate.setValue(changes.eventDetails.currentValue.startDate);
      this.newVariantForm.controls.endDate.setValue(changes.eventDetails.currentValue.endDate);
      this.variantStartDateFilter = (date: Date) =>
        date >
          moment(this.eventDetails.startDate)
            .subtract(1, 'days')
            .toDate() && date <= moment(this.eventDetails.endDate).toDate();
      this.variantEndDateFilter = this.variantStartDateFilter;
    }
    if (changes.regions) {
      this._regions = changes.regions.currentValue.map(region => ({ ...region, disabled: false }));
    }
  }

  addVariant() {
    this.newVariantForm.value.region.forEach(region => {
      if (!this.aliasExists()) {
        this.variantAdded.next({
          alias: this.newVariantForm.value.alias,
          startDate: this.newVariantForm.value.startDate,
          endDate: this.newVariantForm.value.endDate,
          minDisc: this.newVariantForm.value.minDisc,
          maxDisc: this.newVariantForm.value.maxDisc,
          optimisationType: this.newVariantForm.value.optimisationType,
          optimisationTarget: this.newVariantForm.value.optimisationTarget,
          minMargin: this.newVariantForm.value.minMargin,
          wave: this.newVariantForm.value.wave,
          region,
          uuid: uuid.v4(),
          ladderType: this.newVariantForm.value.ladderType,
          discountMethod: this.newVariantForm.value.discountMethod,
        });
      }
    });
    this.validateWaveDates();
  }

  aliasExists() {
    const region: any = _.first(
      _(this.newVariantForm.value.region)
        .map(r => r)
        .value()
    );
    const existingVariantIndex = _(this.variantsDetails).findIndex(
      variant =>
        variant.alias === this.newVariantForm.value.alias &&
        (variant.region.id ? variant.region.id : variant.region) === region.id &&
        variant.wave === this.newVariantForm.value.wave
    );
    if (existingVariantIndex !== -1 && existingVariantIndex !== this.selectedWaveIndex) {
      this.newVariantForm.controls.alias.setErrors({ aliasForRegionExists: true });
      return true;
    } else {
      this.newVariantForm.controls.alias.setErrors(null);
      return false;
    }
  }

  onItemsDeleted(items) {
    items.forEach(item => {
      const variantRemoved = _.find(this.variantsDetails, variant =>
        variant.uuid ? variant.uuid === item.uuid : +variant.id === +item.id
      );
      this.variantRemoved.emit(variantRemoved);
    });
    this.selectedVariant = null;
    this.selectedWaveIndex = -1;
    this.enableSelectFields = true;
    this.ngOnInit();
  }

  onRemoveClicked() {
    this.variantRemoved.emit(this.variantsDetails[this.selectedWaveIndex]);
    this.selectedVariant = null;
    this.ngOnInit(); // todo: don't do this
    this.selectedWaveIndex = -1;
    this.enableSelectFields = true;
  }

  waveSelected(waveData) {
    this.removeItemHighlight = false;
    this.updateVariantform(waveData.item, waveData.edit);
  }

  isRegionDisabled(region) {
    console.log(this.newVariantForm.value.wave);
    console.log(region);
    return true;
  }

  updateVariantform(waveData, edit) {
    this.ngOnInit(); // todo: don't do this
    let waveValue;
    let regionValue;
    this.enableSelectFields = edit;
    if (!edit) {
      this.newVariantForm.disable();
    } else {
      this.newVariantForm.enable();
    }
    const selectedVariant = _.first(
      _(this.variantsDetails)
        .filter(
          variant =>
            waveData.label === variant.alias &&
            _(waveData.id).includes(variant.region.id ? variant.region.id : variant.region) &&
            waveData.wave === variant.wave
        )
        .value()
    );
    this.selectedVariant = selectedVariant;
    const waveNumber = this.selectedVariant.wave.replace(/Wave /g, '');
    if (+waveNumber > 1) {
      const previousWaveStart = _.min(
        _(this.variantsDetails)
          .filter(variant => variant.region.id === this.selectedVariant.region.id && this.selectedVariant.wave === `Wave ${waveNumber - 1}`)
          .map(v => moment(v.startDate).toDate())
          .value()
      );
      this.variantStartDateFilter = (date: Date) =>
        date > moment(previousWaveStart).toDate() && date <= moment(this.eventDetails.endDate).toDate();
    }
    if (selectedVariant && Object.keys(selectedVariant).includes('uuid')) {
      this.selectedWaveIndex = this.variantsDetails.findIndex(details => details.uuid === selectedVariant.uuid);
      this.disableDelete = false;
    } else if (selectedVariant && Object.keys(selectedVariant).includes('id')) {
      this.selectedWaveIndex = this.variantsDetails.findIndex(details => details.id === selectedVariant.id);
      this.disableDelete = true;
    }
    this.newVariantForm.controls.alias.setValue(selectedVariant.alias);
    this.newVariantForm.controls.startDate.setValue(selectedVariant.startDate);
    this.newVariantForm.controls.endDate.setValue(selectedVariant.endDate);
    this.newVariantForm.controls.minDisc.setValue(selectedVariant.minDisc);
    this.newVariantForm.controls.maxDisc.setValue(selectedVariant.maxDisc);
    this.newVariantForm.controls.optimisationType.setValue(selectedVariant.optimisationType);
    this.newVariantForm.controls.optimisationTarget.setValue(selectedVariant.optimisationTarget);
    this.newVariantForm.controls.minMargin.setValue(selectedVariant.minMargin);
    this.newVariantForm.controls.wave.setValue(selectedVariant.wave);
    this.newVariantForm.controls.region.setValue([selectedVariant.region]);
    this.newVariantForm.controls.ladderType.setValue(selectedVariant.ladderType);
    this.newVariantForm.controls.discountMethod.setValue(selectedVariant.discountMethod);
  }

  next() {
    this.nextClicked.emit();
  }

  prev() {
    this.prevClicked.emit();
  }

  onClickSave() {
    if (window.confirm('Are you sure you want to save and close this window?')) {
      this.saveClicked.next();
    }
  }

  updateVariant() {
    if (!this.aliasExists()) {
      if (this.selectedWaveIndex >= 0) {
        this.variantEdited.next({
          details: {
            alias: this.newVariantForm.value.alias,
            startDate: this.newVariantForm.value.startDate,
            endDate: this.newVariantForm.value.endDate,
            minDisc: this.newVariantForm.value.minDisc,
            maxDisc: this.newVariantForm.value.maxDisc,
            optimisationType: this.newVariantForm.value.optimisationType,
            optimisationTarget: this.newVariantForm.value.optimisationTarget,
            minMargin: this.newVariantForm.value.minMargin,
            wave: this.newVariantForm.value.wave,
            region: _.first(this.newVariantForm.value.region),
            uuid: this.variantsDetails[this.selectedWaveIndex].uuid,
            id: this.selectedVariant.id,
            ladderType: this.newVariantForm.value.ladderType,
            discountMethod: this.newVariantForm.value.discountMethod,
          },
          index: this.selectedWaveIndex,
        });
        this.selectedWaveIndex = -1;
      }
      this.selectedVariant = null;
    }
  }

  regionSelected() {
    if (this.allRegionsSelected) {
      this.allRegionsSelected = !this.allRegionsSelected;
    }
    if (this.newVariantForm.controls.region.value.length === this.regions.length) {
      this.allRegionsSelected = true;
    }
  }
  toggleRegionSelection(check) {
    if (check) {
      this.allRegionsSelected = check;
      this.newVariantForm.controls.region.patchValue(this._regions.filter(region => !region.disabled));
    } else {
      this.newVariantForm.controls.region.patchValue([]);
      this.newVariantForm.value.region = [];
    }
  }

  changeToAddView() {
    this.ngOnInit(); // todo: don't do this
    this.selectedVariant = null;
    this.selectedWaveIndex = -1;
    this.enableSelectFields = true;
    this.removeItemHighlight = true;
  }

  public getErrorMessage(field: string) {
    const errors = this.newVariantForm.controls[field].errors;
    if (errors.aliasForRegionExists) {
      return 'Variant already exists';
    }
  }

  disableNext(e) {
    this.disableNextButton = e;
  }

  compareObjects(object1: any, object2: any) {
    return object1 && object2 ? object1.id === object2.id : object1 === object2;
  }

  validateWaveDates() {
    const waveNumber = this.newVariantForm.value.wave.replace(/Wave /g, '');
    const start = this.newVariantForm.value.startDate;
    const lastWaveStart = _.max(
      _(this.variantsDetails)
        .filter(variant => variant.wave === `Wave ${waveNumber - 1}`)
        .map(v => v.startDate)
        .value()
    );
    if (+waveNumber > 1 && start < lastWaveStart) {
      const snackbarStr = `Start date for this wave is less than start date for ${waveNumber}`;
      const snackBarRef = this.snackBarService.open(snackbarStr, 'Dismiss', {
        verticalPosition: 'top',
      });
      snackBarRef._dismissAfter(10000);
    }
  }
}
