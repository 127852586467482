import { Component, OnInit, ViewChild, HostListener, Inject } from '@angular/core';
import { MatStepper, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { Observable } from 'rxjs';
import { map, withLatestFrom, first } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';

import { EventDetails, VariantDetails } from '../../event';
import { ConfigurationSettingsService, UserFacadeService, IZone } from '../../../../user';
import { ModelDetails } from '../event-create-edit-stepper-model-details-form/event-create-edit-stepper-model-details-form.component';
import { ModelAdded } from '../event-create-edit-stepper-model-form/event-create-edit-stepper-model-form.component';
import { EventsFacadeService } from '../../../../features/events/events-facade.service';

@Component({
  selector: 'app-event-create-edit-stepper',
  templateUrl: './event-create-edit-stepper.component.html',
  styleUrls: ['./event-create-edit-stepper.component.scss'],
})
export class EventCreateEditStepperComponent implements OnInit {
  @ViewChild('stepper', { static: false })
  stepper: MatStepper;

  regions$: Observable<IZone[]>;
  merchHierarchy$: Observable<any>;

  eventDetails: EventDetails;
  variantsDetails: VariantDetails[] = [];
  modelsDetails: ModelDetails[] = [];
  variantsUpdated: VariantDetails[] = [];

  @HostListener('window:keyup.esc') onKeyUp() {
    this.beforeClose();
  }

  constructor(
    public configurationSettingsService: ConfigurationSettingsService,
    private userFacadeService: UserFacadeService,
    private eventsFacadeService: EventsFacadeService, // todo: removing this breaks the application?
    private matDialog: MatDialogRef<EventCreateEditStepperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.matDialog.disableClose = true;
    this.matDialog.backdropClick().subscribe(() => this.beforeClose());
  }

  beforeClose() {
    if (window.confirm('Are you sure you want to close this window? You will lose all changes made to this event.')) {
      this.matDialog.close();
    }
  }

  ngOnInit() {
    this.regions$ = this.userFacadeService.getOrgRegions();
    this.merchHierarchy$ = this.userFacadeService.getMerchHierarchy().pipe(
      map(merchHierarchy => {
        const l1 = _(merchHierarchy)
          .keyBy('h1Desc')
          .map((val, key) => key)
          .value();

        return {
          l1,
          l1Map: _(merchHierarchy)
            .groupBy('h1Desc')
            .value(),
          l2: merchHierarchy.map(mapping => ({
            h2Desc: mapping.h2Desc,
            h2Id: mapping.h2Desc,
          })),
          merchHierarchy,
        };
      })
    );
    if (this.data) {
      this.eventDetails = this.data.eventDetails;
      this.variantsDetails = this.data.variantsDetails;
      this.regions$
        .pipe(
          withLatestFrom(this.merchHierarchy$),
          first()
        )
        .subscribe(([regions, { merchHierarchy }]) => {
          this.variantsDetails.forEach(variant => (variant.region = _.find(regions, region => region.id === variant.region)));
          this.modelsDetails = this.data.modelsDetails.map(model => {
            return {
              ...model,
              division: _.find(merchHierarchy, level => level.h2Id === model.department.h2Id),
              region: _.find(regions, region => region.id === model.region),
            };
          });
          this.variantsDetails = this.data.variantsDetails.map(variant => {
            let preventDelete: boolean;
            const modelForRegion = _.find(this.modelsDetails, model => model.region === variant.region);
            if (modelForRegion) {
              preventDelete = true;
            }

            return { ...variant, preventDelete };
          });
        });
    }
  }

  onNextClicked() {
    this.stepper.next();
  }

  onPrevClicked() {
    this.stepper.previous();
  }

  onSaveClicked() {
    this.submit();
  }

  onEventDetailsChanged(eventDetails: EventDetails) {
    this.eventDetails = { ...this.eventDetails, ...eventDetails }; // preserves event's id for update
  }

  onVariantAdded(variant: VariantDetails) {
    this.variantsDetails = [...this.variantsDetails, variant];
    this.variantsDetails = _.sortBy(this.variantsDetails, ['wave']);
  }

  onVariantRemoved(variantToBeRemoved: VariantDetails) {
    const index = _.findIndex(this.variantsDetails, variant =>
      variant.uuid ? variant.uuid === variantToBeRemoved.uuid : +variant.id === +variantToBeRemoved.id
    );
    this.variantsDetails.splice(index, 1);
    this.variantsDetails = [...this.variantsDetails];

    if (variantToBeRemoved.id && _.includes(this.variantsUpdated.map(variants => variants.id), variantToBeRemoved.id)) {
      this.variantsUpdated = this.variantsUpdated.filter(variant => variant.id === variantToBeRemoved.id);
    }
  }

  onModelsAdded(modelsAdded: ModelAdded[]) {
    this.modelsDetails = this.modelsDetails.concat(
      modelsAdded.map(modelAdded => ({
        ...modelAdded,
        name: `${this.eventDetails.name}-${modelAdded.department.h2Desc}`,
        variant: _.first(this.variantsDetails.filter(variant => variant.wave === `Wave 1` && variant.region.id === modelAdded.region.id)),
      }))
    );
  }

  onModelRemoved(index: number) {
    this.modelsDetails.splice(index, 1);
  }

  onVariantEdited(data) {
    data.details.updated = true;
    if (data.index !== -1) {
      this.variantsDetails.splice(data.index, 1);
      this.variantsDetails = [...this.variantsDetails, data.details];
      this.variantsUpdated = [...this.variantsUpdated, data.details]; // existing variants updated
    }
    this.variantsDetails = _.sortBy(this.variantsDetails, ['region.id', 'wave']);
  }

  updateVariants() {
    const variantsToAdd = _(this.variantsDetails)
      .filter(variants => Object.keys(variants).includes('uuid'))
      .value(); // new variants to be saved
  }

  private submit() {
    this.matDialog.close({
      eventDetails: {
        ...this.eventDetails,
        startDate: moment(this.eventDetails.startDate),
        endDate: moment(this.eventDetails.endDate),
      },
      variantsDetails: this.variantsDetails,
      modelsDetails: this.modelsDetails,
    });
  }
}
