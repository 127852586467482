import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as fromRoot from '../app.reducer';
import { getMerchHierarchy, getLastDataRefresh, getOrgSetting, getOrgZones, getCurrentUser, getOrgSeasons } from './user.reducer';
import { UserMapMerchHierarchyAction } from './user.actions';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class UserFacadeService {
  constructor(private store: Store<fromRoot.State>, private userService: UserService) {}

  getMerchHierarchy() {
    return this.store.pipe(select(getMerchHierarchy));
  }

  getLastDataRefresh() {
    return this.store.pipe(select(getLastDataRefresh));
  }

  getSelectedSeasons(): Observable<Array<string>> {
    return this.store.pipe(
      select(getOrgSetting(['seasons'])),
      map(({ seasons }) => seasons)
    );
  }

  getDefaultSeason() {
    return this.getSelectedSeasons().pipe(map(selectedSeason => _.first(selectedSeason)));
  }

  getOrgSeasons() {
    return this.store.pipe(select(getOrgSeasons));
  }

  getOrgRegions() {
    return this.store.pipe(select(getOrgZones));
  }

  getCurrentUser() {
    return this.store.pipe(select(getCurrentUser));
  }

  mapMerchHierarchy(e) {
    this.store.dispatch(new UserMapMerchHierarchyAction(e));
  }

  getAllSkuList() {
    return this.userService.getAllSkuList();
  }

  getIncludedSkus(): Observable<Array<string>> {
    return this.store.pipe(
      select(getOrgSetting(['included_lines'])),
      map(({ included_lines }) => included_lines)
    );
  }

  getExcludedSkus(): Observable<Array<string>> {
    return this.store.pipe(
      select(getOrgSetting(['excluded_lines'])),
      map(({ excluded_lines }) => excluded_lines)
    );
  }

  getDiscountMethod(): Observable<Array<string>> {
    return this.store.pipe(
      select(getOrgSetting(['discount_method'])),
      map(({ discount_method }) => discount_method));
  }
}
