import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import {
  DiscountLaddersCreateAction,
  DiscountLaddersUpdateAction,
  DiscountLaddersDeleteAction,
} from './discount-ladders/state/discount-ladders.actions';
@Injectable({
  providedIn: 'root'
})
export class DiscountLadderFacadeService {

  constructor(private store: Store<fromRoot.State>) { }

  create(params) {
    this.store.dispatch(new DiscountLaddersCreateAction(params));
  }

  update(params) {
    this.store.dispatch(new DiscountLaddersUpdateAction(params));
  }

  delete(params) {
    this.store.dispatch(new DiscountLaddersDeleteAction(params));
  }
}
