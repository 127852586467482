import { Component, OnDestroy } from '@angular/core';

import { Store, select } from '@ngrx/store';

import * as fromRoot from './app.reducer';
import { getLoading as eventsGetLoading } from './features/events/state/events.selectors';
import { combineLatest, Observable, Subject} from 'rxjs';
import { getNavigationExpanded } from './infra/navigation/navigation.state';
import { getLoading as variantsGetLoading } from './features/variants/state/variants.selectors';
import { ConfigurationSettingsService } from './user/configuration-settings.service';
import { LoadingBarService } from './loading-bar.service';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import { InternetConnectionService } from './internet-connection.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy  {
  title = 'sparkbox-app';
  loading = false;
  expanded$: Observable<boolean>;
  componentDestroy$ = new Subject();
  constructor(
    public store: Store<fromRoot.State>,
    public configurationSettingsService: ConfigurationSettingsService,
    public loadingBarService: LoadingBarService,
    private snackBarService: MatSnackBar,
    public internetConnectionService: InternetConnectionService
  ) {
    // const modelsGetLoading$ = this.store.pipe(select(modelsGetLoading));
    this.internetConnectionService.getInternetError().pipe(takeUntil(this.componentDestroy$)).subscribe((online) => {
      if (!online) {
      this.showSnackBar(`No internet connection`);
      }
    });

    this.internetConnectionService.getHttpInternetError().pipe(takeUntil(this.componentDestroy$)).subscribe((internetError) => {
      if (internetError) {
        this.showSnackBar(`Please try again after connecting to the internet`);
      }
    });
    const eventsGetLoading$ = this.store.pipe(select(eventsGetLoading));
    const variantsGetLoading$ = this.store.pipe(select(variantsGetLoading));
    this.expanded$ = this.store.pipe(select(getNavigationExpanded));

    const combined = combineLatest(eventsGetLoading$, variantsGetLoading$);
    combined.subscribe(([modelsLoadingStatus, eventsLoadingStatus]) => {
      if (modelsLoadingStatus || eventsLoadingStatus) {
        this.loading = true;
      } else if (!modelsLoadingStatus && !eventsLoadingStatus) {
        this.loading = false;
      }
    });
  }

  ngOnDestroy() {
    this.componentDestroy$.next();
  }

  showSnackBar(message) {
    let snackBarRef;
    const snackbarStr = message;
    snackBarRef = this.snackBarService.open(snackbarStr, 'Dismiss', {
      verticalPosition: 'top',
    });
    snackBarRef._dismissAfter(10000);
  }
}

