import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable, timer, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import * as moment from 'moment';
import * as _ from 'lodash';

import { IEvent, IVariant } from '../../abstracts/event.interface';
import { EventsGetAction } from './state/events.actions';
import { Store } from '@ngrx/store';
import { EventModelStatus } from '../../abstracts/event-status.type';
import { thousandsRounded } from '../../infra/datagrid/datagrid.formatters';

const statusMap = {
  0: 'Planning',
  1: 'Planning',
  2: 'Planning',
  3: 'Closed',
};

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export interface EventStatsResponse {
  id: string;
  linesTaken: number;
  estimateSpend: number;
  averageDepth: number;
  estimateSellThrough: number;
  stockByDept: string;
  spendByDept: string;
}

export interface IUpdateEventParams {
  id: number;
  name: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
  note: string;
}

export interface ICreateEventsParams {
  name: string;
  detail: string;
  type: string;
  starts: moment.Moment;
  ends: moment.Moment;
  notes: string;
}

export interface CreatedEvent {
  id: string;
  eventType: string;
  eventDetail: string;
  name: string;
  startDate: string;
  endDate: string;
  owner: string;
  lastEdit: string;
  lastEditUser: string;
  status: number;
  note: string;
}

export interface EventCollectionReport {
  event_id: number;
  totalDepthNumerator: number;
  collection_desc: string;
  stockMd: number;
  stockOverMD: number;
  mdDepthNumerator: number;
  mdDepth: number;
  totalDepth: number;
  department_desc: string;
  totalDepthDenominator: number;
  mdDepthDenominator: number;
  stockTotal: number;
  stockMdPct: number;
}

export interface EventDiscountReport {
  event_id: number;
  current_price_sum: number;
  store_stock_units: number;
  final_price_sum: number;
  full_price_sum: number;
  sku_count: number;
  discount_bin: string;
  sku_type: string;
}

export interface EventRegion {
  eventId: number;
  region: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  eventsPoll$;

  constructor(private http: HttpClient, private store: Store<any>) {}

  deleteEvent(params: { id: number }) {
    const query = `
      mutation {
        deactivateEvent(
          id: ${params.id}
        )
      }
    `;

    return this.http.post<any>('/api', query).pipe(map(data => ({ ...params })));
  }

  createEvent(params: ICreateEventsParams): Observable<any> {
    const query = `
      mutation {
        createEvent(
          eventType:"${params.type}",
          eventDetail: "${params.detail}",
          name:"${params.name}",
          startDate:"${params.starts.format(DATE_FORMAT)}",
          endDate:"${params.ends.format(DATE_FORMAT)}",
          note:"${params.notes || ''}") {
          id
          eventType
          eventDetail
          name
          startDate
          endDate
          owner
          lastEdit
          lastEditUser
          status
          note
        }
      }`;

    return this.http.post<{ createEvent: CreatedEvent }>('/api', query).pipe(map(data => data.createEvent));
  }

  getEvents(): Observable<any[]> {
    const query = `{events {id eventType eventDetail startDate endDate owner lastEdit lastEditUser status note name }}`;
    return (
      this.http
        .post<{ events: any[] }>('/api', query)
        // return this.http.post<{ events: any[] }>('/mock/api/events', query)
        .pipe(
          map(data =>
            data.events
              .filter(event => !!event)
              .map(event => {
                return {
                  ...event,
                  variants: 2,
                  models_published: 2,
                  departments_published: 2,
                  status: EventModelStatus[event.status],
                };
              })
          )
        )
    );
  }

  updateEvent(params: IUpdateEventParams): Observable<IEvent> {
    const query = `mutation {
      updateEvent(
        id: ${params.id},
        name: "${params.name}",
        startDate:"${params.startDate.format(DATE_FORMAT)}",
        endDate:"${params.endDate.format(DATE_FORMAT)}",
        note:"${params.note || ''}"
      ) {
        id
        name
        startDate
        endDate
        note
      }
    }`;

    return this.http.post<{ updateEvent: IEvent[] }>('/api', query).pipe(map(data => _.first(data.updateEvent)));
  }

  optimizeEvent(params: { id: number }) {
    const query = `
      { "event_id": "${params.id}" }
    `;

    return this.http.post<any>('api/event/optimise', query).pipe(map(data => ({ ...params })));
  }

  getEventStatistics({ eventID }): Observable<any> {
    const query = `{
      events(id:"${eventID}") {
        id
        linesTaken
        estimateSpend
        averageDepth
        estimateSellThrough
        stockByDept
        spendByDept
      }
    }`;

    // return this.http.post<{}>('/api', query)
    return this.http.post<{ events: EventStatsResponse[] }>('/api', query).pipe(
      map(({ events }) => {
        const event = _.first(events);
        return {
          ...event,
          stockByDept: JSON.parse(event.stockByDept),
          spendByDept: JSON.parse(event.spendByDept),
          estimateSpend: thousandsRounded(1)({ value: event.estimateSpend / 1000 }),
        };
      })
    );
  }

  getCollectionReport({ eventIDs, regions, wave }: { eventIDs: string[]; regions: string[]; wave: string }) {
    const query = `
        { "event_id": "${eventIDs}", "region": "${regions}", "wave":"${wave}" }
      `;
    return this.http.post<{ events: EventCollectionReport[] }>('api/collection-report', query);
  }

  getDiscountReport({ eventIDs, regions, wave }: { eventIDs: string[]; regions: string[]; wave: string }) {
    const query = `
        { "event_id": "${eventIDs}", "region": "${regions}", "wave": "${wave}" }
      `;
    return this.http.post<{ events: EventDiscountReport[] }>('api/discount-report', query);
  }
}
