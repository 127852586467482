import { Component, OnInit, Input } from '@angular/core';
import { Model } from '../../state/model';
import { columns, sideBar, gridOptions } from '../models/models.grid-config';
import { RegionPipe } from '../../../../user';

@Component({
  selector: 'app-model-grid',
  templateUrl: './model-grid.component.html',
  styleUrls: ['./model-grid.component.scss'],
})
export class ModelGridComponent implements OnInit {
  columns = columns(this.regionPipe);
  sidebar = sideBar;
  gridOptions = gridOptions;

  @Input()
  models: Model[];

  constructor(private regionPipe: RegionPipe) {}

  ngOnInit() {
    gridOptions.groupDefaultExpanded = 1;
  }
}
