import { Component, OnInit } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';

import { Store } from '@ngrx/store';

import * as fromRoot from '../../../app.reducer';
import { OrgToggleUserActiveAction } from '../../sb-admin/org/state/org.actions';

@Component({
  selector: 'app-admin-panel-disable-user-cell',
  templateUrl: './admin-panel-disable-user-cell.component.html',
  styleUrls: ['./admin-panel-disable-user-cell.component.scss']
})
export class AdminPanelDisableUserCellComponent implements ICellRendererAngularComp, OnInit {

  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  constructor(
    public store: Store<fromRoot.State>,
  ) { }

  ngOnInit() {
  }

  refresh(): boolean {
    return false;
  }

  toggleUser() {
    this.store.dispatch(new OrgToggleUserActiveAction(this.params.data.id));
  }

}
