import { Component, OnInit, Input, Output, EventEmitter, ElementRef, HostListener, ViewContainerRef, AfterViewInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import * as _ from 'lodash';
import { Line } from '../../features/model/state/model';

@Component({
  selector: 'app-slider-control',
  templateUrl: './slider-control.component.html',
  styleUrls: ['./slider-control.component.scss'],
  providers: [DecimalPipe]
})
export class SliderControlComponent implements OnInit, AfterViewInit {
  @Input()
  takenLines: Line[];

  @Input()
  property: string;

  @Input()
  percent: boolean;

  @Input()
  lumpMax: number;

  @Output()
  valueChange = new EventEmitter();

  @Output()
  highValueChange = new EventEmitter();

  @Output()
  outSideClick = new EventEmitter();

  sliderWidth = 0;

  value: any = 40;
  highValue: any = 60;
  slidersOpen = [];
  options: { floor: any; ceil: any; translate: any } = {
    floor: 0,
    ceil: 100,
    translate: (value: number) => {
      if (this.percent) {
        return value + '%';
      } else {
        return value;
      }
    }
  };

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (!this.elemRef.nativeElement.contains(event.target)) {
      if (!(event.target as Element).className.includes('toggle')) {
        if (this.property) {
          this.outSideClick.emit();
          this.slidersOpen = [];
        }
      } else if ((event.target as Element).className.includes('toggle') || (event.target as Element).className.includes('select')) {
        if (this.slidersOpen.length > 0) {
          this.outSideClick.emit();
        }
        this.slidersOpen.push(this.property);
      }
    }
  }

  constructor(private _decimalPipe: DecimalPipe, private elemRef: ElementRef, private elementRef: ElementRef) {}

  ngAfterViewInit() {
    // console.log(this.elementRef);
    // console.log(this.elementRef.nativeElement.parentElement);
    this.sliderWidth = this.elementRef.nativeElement.parentElement.clientWidth;
  }

  ngOnInit() {
    let allProps;
    if (this.percent) {
      allProps = this.takenLines.map(line => +line[this.property] * 100);
    } else {
      allProps = this.takenLines.map(line => +line[this.property]);
    }
    this.options.floor = Number(Math.floor(Math.min(...allProps)));
    this.options.ceil = Number(Math.ceil(Math.max(...allProps)));
    if (this.lumpMax) {
      this.options.ceil = this.lumpMax;
      this.options.translate = value => {
        if (+value === +this.lumpMax) {
          return `${value}+`;
        } else {
          return value;
        }
      };
    }
    this.value = +Math.floor(+this.options.floor);
    this.highValue = +Math.ceil(+this.options.ceil);
  }

  onValueChange(e: number) {
    if (this.percent) {
      this.valueChange.emit(e / 100);
    } else {
      this.valueChange.emit(e);
    }
  }

  onHighValueChange(e: number) {
    if (this.percent) {
      this.highValueChange.emit(e / 100);
    } else {
      this.highValueChange.emit(e);
    }
  }
}
