import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import * as fromRoot from '../../app.reducer';
import { UserGetAdminAction } from '../../user/user.actions';
import { getCurrentUser } from '../../user/user.reducer';
import { filter } from 'rxjs/operators';
import { OrgGetAction } from './org/state/org.actions';

@Component({
  selector: 'app-sb-admin',
  templateUrl: './sb-admin.component.html',
  styleUrls: ['./sb-admin.component.scss']
})
export class SbAdminComponent implements OnInit {

  constructor(
    public store: Store<fromRoot.State>
  ) {
    this.store.dispatch(new UserGetAdminAction());
  }

  ngOnInit() {
    this.store
      .select(getCurrentUser)
      .pipe(
        filter(user => user.url && (!user.role || user.role && user.role.indexOf('Administrator') === -1))
      )
      .subscribe((user) => {
        window.location.assign(user.url);
      });
  }

}
