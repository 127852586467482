import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { LinesExportService } from '../../model/lines-export.service';
import * as _ from 'lodash';
import { Model } from '../../model/state/model';

const DEFAULT_COLLECTION = 'SS';
const DEFAULT_REPORT = 'collection';

@Component({
  selector: 'app-events-grid-nav-cell',
  templateUrl: './events-grid-nav-cell.component.html',
  styleUrls: ['./events-grid-nav-cell.component.scss']
})
export class EventsGridNavCellComponent implements ICellRendererAngularComp, OnInit {
  private params: any;
  showExportDialog: boolean;

  agInit(params: any): void {
    this.params = params;
  }

  constructor(
    private router: Router,
    private linesExportService: LinesExportService,
  ) { }

  ngOnInit() {
  }

  refresh(): boolean {
    return false;
  }

  goToReports(e) {
    e.stopPropagation();
    const firstModel: Model = _.first(this.params.data.relatedModels);
    this.router.navigate(['/reporting', this.params.data.id, DEFAULT_REPORT, firstModel.region, firstModel.wave, DEFAULT_COLLECTION]);
  }

  goToModels(e) {
    e.stopPropagation();
    this.router.navigate(['/models'], {queryParams: { eventID: this.params.data.id }});
  }

  toggleExportTo(e) {
    this.showExportDialog = !this.showExportDialog;
    e.stopPropagation();
  }

  exportTo(e, format, point) {
    this.showExportDialog = !this.showExportDialog;
    this.linesExportService.exportLinesByEvent(this.params.data.id, format, point);
    e.stopPropagation();
  }
}
