import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

export interface VariantStats {
  name: string;
  minDiscount: string;
  maxDiscount: string;
  sellThroughGoal: string;
  minMargin: string;
  avgDiscount: number;
  avgDiscountStock: number;
  spend: number;
  salesUnits: number;
  salesValue: number;
  closingSellThrough: number;
  closingStockUnits: number;
  closingStockValue: number;
  variantId: string;
  variantNumber: string;
}

@Injectable({
  providedIn: 'root'
})
export class CompareVariantsService {

  constructor(private http: HttpClient) { }

  getCompareVariants({ modelIDs }) {
    const query = `{
      modelvariantcompare(modelId: "${modelIDs.join(',')}" omniModel: true){
        name
        minDiscount
        maxDiscount
        sellThroughGoal
        minMargin
        avgDiscount
        avgDiscountStock
        spend
        salesUnits
        salesValue
        closingSellThrough
        closingStockUnits
        closingStockValue
        variantId
      }
    }`;

    return this.http.post<{ modelvariantcompare: Array<VariantStats> }>('/api', query)
      .pipe(map(({ modelvariantcompare }) => modelvariantcompare));
  }

}
