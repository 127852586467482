import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import * as fromRoot from '../../app.reducer';
import { getCurrentUser, IUserState } from '../../user/user.reducer';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  currentUser$: Observable<IUserState>;

  constructor(
    public store: Store<fromRoot.State>
  ) { }

  ngOnInit() {
    this.currentUser$ = this.store.select(getCurrentUser);
  }

}
