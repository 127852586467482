import { Component, OnInit } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import * as _ from 'lodash';
import { ModelFacadeService } from '../../model-facade.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-model-grid-delete-cell',
  templateUrl: './model-grid-delete-cell.component.html',
  styleUrls: ['./model-grid-delete-cell.component.scss']
})
export class ModelGridDeleteCellComponent implements ICellRendererAngularComp, OnInit {

  private params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  constructor(private modelFacadeService: ModelFacadeService,
    private snackBarService: MatSnackBar
  ) { }

  ngOnInit() { }

  refresh(): boolean {
    return false;
  }

  deleteModel() {
    const modelIDs = [];
    let allowDelete = true;
    if (this.params.node.allLeafChildren) {
        this.params.node.allLeafChildren.map(({ data }) => modelIDs.push(data.id));
    } else {
      modelIDs.push(this.params.node.data.id);
      const waveSelected = this.params.node.data.wave;
      const maxRegionWave = _(this.params.node.parent.allLeafChildren.map(({ data }) => data.wave)).max();
      allowDelete = maxRegionWave <= waveSelected ? true : false;
    }
    if (allowDelete) {
      if (window.confirm('Are you sure you want to delete this model?')) {
          modelIDs.forEach((id) =>
          this.modelFacadeService.delete({id})
          );
      }
    } else {
      const snackbarStr = `Cannot delete model as future waves exist`;
      const snackBarRef = this.snackBarService.open(snackbarStr, 'Dismiss', {
        verticalPosition: 'top',
      });
      snackBarRef._dismissAfter(10000);
    }
  }

}
