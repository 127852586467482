import { Action } from '@ngrx/store';

export const PRICELADDERSGETALL = '[PRICE LADDERS] GET ALL';
export const PRICELADDERSGETSALLSUCCESS = '[PRICE LADDERS] GET ALL SUCCESS';
export const PRICELADDERSGETALLFAILURE = '[PRICE LADDERS] GET ALL FAILURE';

export const PRICELADDERSCREATE = '[PRICE LADDERS] CREATE';
export const PRICELADDERSCREATESUCCESS = '[PRICE LADDERS] CREATESUCCESS';
export const PRICELADDERSCREATEFAILURE = '[PRICE LADDERS] CREATEFAILURE';

export const PRICELADDERSUPDATEACTION = '[PRICE LADDERS] UPDATE';
export const PRICELADDERSUPDATEACTIONSUCCESS = '[PRICE LADDERS] UPDATE SUCCESS';
export const PRICELADDERSUPDATEACTIONFAILURE = '[PRICE LADDERS] UPDATE FAILURE';

export const PRICELADDERSDELETEACTION = '[PRICE LADDERS] DELETE';
export const PRICELADDERSDELETEACTIONSUCCESS = '[PRICE LADDERS] DELETE SUCCESS';
export const PRICELADDERSDELETEACTIONFAILURE = '[PRICE LADDERS] DELETE FAILURE';

export class PriceLaddersGetAllAction implements Action {
  type = PRICELADDERSGETALL;

  constructor() { }
}

export class PriceLaddersGetAllSuccessAction implements Action {
  type = PRICELADDERSGETSALLSUCCESS;

  constructor(public payload: any) { }
}

export class PriceLaddersGetAllFailureAction implements Action {
  type = PRICELADDERSGETALLFAILURE;

  constructor(public payload: any) { }
}

export class PriceLaddersCreateAction implements Action {
  type = PRICELADDERSCREATE;

  constructor(public payload: { name: string, prices: string[] }) {}
}

export class PriceLaddersCreateSuccessAction implements Action {
  type = PRICELADDERSCREATESUCCESS;

  constructor(public payload: any) { }
}

export class PriceLaddersCreateFailureAction implements Action {
  type = PRICELADDERSCREATEFAILURE;

  constructor(public payload: any) { }
}

export class PriceLaddersUpdateAction implements Action {
  type = PRICELADDERSUPDATEACTION;

  constructor(public payload: any) { }
}

export class PriceLaddersUpdateSuccessAction implements Action {
  type = PRICELADDERSUPDATEACTIONSUCCESS;

  constructor(public payload: any) { }
}

export class PriceLaddersUpdateFailureAction implements Action {
  type = PRICELADDERSUPDATEACTIONFAILURE;

  constructor(public payload: any) { }
}

export class PriceLaddersDeleteAction implements Action {
  type = PRICELADDERSDELETEACTION;

  constructor(public payload: any) { }
}

export class PriceLaddersDeleteSuccessAction implements Action {
  type = PRICELADDERSDELETEACTIONSUCCESS;

  constructor(public payload: any) { }
}

export class PriceLaddersDeleteFailureAction implements Action {
  type = PRICELADDERSDELETEACTIONFAILURE;

  constructor(public payload: any) { }
}

export type actions =
  PriceLaddersGetAllSuccessAction |
  PriceLaddersGetAllFailureAction |
  PriceLaddersCreateSuccessAction |
  PriceLaddersCreateFailureAction |
  PriceLaddersUpdateSuccessAction |
  PriceLaddersUpdateFailureAction |
  PriceLaddersDeleteSuccessAction |
  PriceLaddersDeleteFailureAction;
