import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { EventModelStatus } from '../../../../abstracts/event-status.type';
import { isCurrentOrgMarkdownAndPromos } from '../../../../user/user.reducer';
import { Observable } from 'rxjs';

import * as moment from 'moment';

@Component({
  selector: 'app-reporting-events-grid-filters',
  templateUrl: './reporting-events-grid-filters.component.html',
  styleUrls: ['./reporting-events-grid-filters.component.scss'],
})
export class ReportingEventsGridFiltersComponent implements OnInit {
  @Output()
  quickFilterTextChanged: EventEmitter<string> = new EventEmitter();

  @Output()
  dateRangeChanged: EventEmitter<Date[]> = new EventEmitter();

  @Output()
  eventTypeClicked: EventEmitter<Array<string>> = new EventEmitter();

  @Output()
  statusClicked: EventEmitter<Array<EventModelStatus>> = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter();

  @Input()
  filterValueChanged: boolean;

  @Input()
  defaultStatusFilter: boolean;

  quickFilterText: string;
  fromDate: Date;
  toDate: Date;
  eventType: Array<string> = ['Promo', 'Markdown'];
  status: Array<EventModelStatus> = [0, 1, 2, 3, 4, 5];
  isCurrentOrgMarkdownAndPromos$: Observable<boolean>;

  fromDateFilter: any = () => true; // (date: Date) => date >= moment().toDate() && date <= moment('Oct 12, 2019').toDate();
  toDateFilter: any = () => true;

  constructor(private store: Store<any>) {
    this.isCurrentOrgMarkdownAndPromos$ = this.store.pipe(select(isCurrentOrgMarkdownAndPromos));
  }

  ngOnInit() {
    if (this.defaultStatusFilter) {
      this.status.splice(-2); // SBX-782 Default filter for status remove - Complete, Closed
    }
  }

  onQuickFilterTextChange(e: string) {
    this.quickFilterTextChanged.emit(e);
  }

  clearQuickFilter() {
    this.quickFilterText = null;
    this.quickFilterTextChanged.emit(this.quickFilterText);
  }

  onFromDateChosen({ value }) {
    this.toDateFilter = (date: Date) => date >= moment(value).toDate();
    this.fromDate = value;
    this.dateRangeChanged.emit([value, this.toDate]);
  }

  onToDateChosen({ value }) {
    this.fromDateFilter = (date: Date) => date <= moment(value).toDate();
    this.toDate = value;
    this.dateRangeChanged.emit([this.fromDate, value]);
  }

  onClickEventType(type) {
    const index = this.eventType.indexOf(type);
    if (index > -1) {
      this.eventType.splice(index, 1);
    } else {
      this.eventType.push(type);
    }

    this.eventTypeClicked.emit(this.eventType);
  }

  onClickStatus(status: EventModelStatus) {
    const index = this.status.indexOf(status);
    if (index > -1) {
      this.status.splice(index, 1);
    } else {
      this.status.push(status);
    }

    this.statusClicked.emit(this.status);
  }

  onClearFilter() {
    this.clearFilter.emit();
  }
}
