import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'app-model-detail-status-custom-filter',
  templateUrl: './model-detail-status-custom-filter.component.html',
  styleUrls: ['./model-detail-status-custom-filter.component.scss']
})
export class ModelDetailStatusCustomFilterComponent implements OnInit, ICellRendererAngularComp {
  private params: any;
  public text = '';
  statusClass;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    if (this.params.value === 'Less than sell-through target') {
        this.statusClass = 'status-icon-red';
    } else if (this.params.value === 'Reached sell-through target') {
        this.statusClass = 'status-icon-green';
    } else if (this.params.value === 'Sell-through target within 10%') {
        this.statusClass = 'status-icon-yellow';
    }
  }

  refresh(): boolean {
    return false;
  }

  ngOnInit() {

  }

}
