import { Component, OnInit } from '@angular/core';
import { UserFacadeService } from '../../../user/user-facade.service';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import { getOrgSetting } from '../../../user/user.reducer';
import { UserSetOrgSettingAction } from '../../../user/user.actions';

@Component({
  selector: 'app-sku-list-grid-actions-cell',
  templateUrl: './sku-list-grid-actions-cell.component.html',
  styleUrls: ['./sku-list-grid-actions-cell.component.scss']
})
export class SkuListGridActionsCellComponent implements OnInit {

  params: any;
  includedIds = [];
  excludedIds = [];

  agInit(params: any): void {
    this.params = params;
  }

  constructor(public store: Store<fromRoot.State>,
    public userFacadeService: UserFacadeService) { }

  ngOnInit() {
  }

  refresh(): boolean {
    return false;
  }

  deleteSku() {
    this.params.api.updateRowData({ remove: [this.params.data] });
    if (this.params.data.sku_id && this.params.data.list === 'Included' ) {
      this.store
        .pipe(select(getOrgSetting(['included_lines'])))
        .subscribe(({ included_lines }) => {
          this.includedIds = included_lines;
        });
      this.includedIds = this.includedIds.filter(sku_id => sku_id !== this.params.data.sku_id );
      this.store.dispatch(new UserSetOrgSettingAction({ name: 'included_lines', value: this.includedIds }));
    } else if (this.params.data.sku_id && this.params.data.list === 'Excluded') {
      this.store
        .pipe(select(getOrgSetting(['excluded_lines'])))
        .subscribe(({ excluded_lines }) => {
          this.excludedIds = excluded_lines;
        });
      this.excludedIds = this.excludedIds.filter(sku_id => sku_id !== this.params.data.sku_id)
      this.store.dispatch(new UserSetOrgSettingAction({ name: 'excluded_lines', value: this.excludedIds }));
    }
  }

}
