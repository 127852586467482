import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

import * as jshashes from 'jshashes';

@Injectable({
  providedIn: 'root'
})
export class GetHelpService {

  constructor(
    private router: Router,
    private snackBarService: MatSnackBar,
  ) { }

  showSnackbarError(error: string, customMessage?: string) {
    const sha1 = new jshashes.SHA1;
    const message = customMessage || 'There was an issue communicating with the Sparkbox server.'

    let errorCode = sha1.hex(error);
    errorCode = errorCode.substr(errorCode.length - 10);

    const snackBarRef = this.snackBarService.open(
      `${message} (Error Code ${errorCode})`,
      'Get Help',
    );
    snackBarRef.onAction().pipe(first()).subscribe(() => {
      this.router.navigate(['/get-help']);
    });
  }
}
