import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// import { Store, select } from '@ngrx/store';
// import { Observable } from 'rxjs';

// import * as fromRoot from '../../../app.reducer';
// import { getMerchHierarchy } from '../../models/state/models.selectors';
import { ColDef, GridOptions } from 'ag-grid-community';
import {
  PriceLadderAssignmentGridMappingCellComponent,
} from './price-ladder-assignment-grid-mapping-cell/price-ladder-assignment-grid-mapping-cell.component';
import { IPriceLadder } from '../price-ladders';
import { Subject } from 'rxjs';
import { MerchHierarchyLineage, IHierarchyMapParams } from '../../model/state/model';
import * as _ from 'lodash';

@Component({
  selector: 'app-price-ladder-assignment',
  templateUrl: './price-ladder-assignment.component.html',
  styleUrls: ['./price-ladder-assignment.component.scss'],
})
export class PriceLadderAssignmentComponent implements OnInit {
  @Input()
  merchHierarchy: MerchHierarchyLineage[];

  @Input()
  priceLadders: IPriceLadder[];

  @Output()
  private hierarchyMapped: EventEmitter<any> = new EventEmitter();

  rowUpdatedSubject = new Subject();

  gridOptions: GridOptions = {
    context: {
      parentComponent: this,
    },
    deltaRowDataMode: true,
    getRowNodeId: data => data.id,
    onRowDataUpdated: e => {
      this.rowUpdatedSubject.next({ x: true });
      this.showLoadingOverlay = false;
      e.api.sizeColumnsToFit();
    },
    onGridReady: e => {
      e.api.sizeColumnsToFit();
    },
    autoGroupColumnDef: {
      headerName: 'Group',
      cellRendererParams: {
        suppressCount: true,
        suppressDoubleClickExpand: true,
      },
    },
  };

  columns: ColDef[] = [
    {field: 'h1Desc', headerName: 'Division', rowGroup: true, hide: true},
    {field: 'h2Desc', headerName: 'Department', rowGroup: true, hide: true},
    {
      headerName: '',
      cellRendererFramework: PriceLadderAssignmentGridMappingCellComponent,
      suppressMenu: true,
      sortable: false,
    },
  ];

  constructor() {}
  showLoadingOverlay;
  mapHierarchy(e: IHierarchyMapParams) {
    this.hierarchyMapped.emit(e);
    this.showLoadingOverlay = true;
  }

  ngOnInit() {
  }
}
