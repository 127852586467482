import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import {
  Effect,
  Actions,
  ofType
} from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import { map, exhaustMap, catchError, switchMap } from 'rxjs/operators';

import * as discountLadders from './discount-ladders.actions';

import { DiscountLaddersService } from '../../discount-ladders.service';
import { IDiscountLadder } from '../../discount-ladders';
import { GetHelpService } from '../../../../features/get-help/get-help.service';

@Injectable()
export class DiscountLaddersEffects {
  @Effect()
  getDiscountLadders$: Observable<Action> = this.actions$
    .pipe(
      ofType(discountLadders.DISCOUNTLADDERSGETALL),
      switchMap(() =>
        this.discountLaddersService
          .getDiscountLadders()
          .pipe(
            map((data: IDiscountLadder[]) => new discountLadders.DiscountLaddersGetAllSuccessAction(data)),
            catchError(error => of(new discountLadders.DiscountLaddersGetAllFailureAction(error)))
          )
      )
    );

  @Effect({ dispatch: false })
  getDiscountLaddersFailure$: Observable<any> = this.actions$
    .pipe(
      ofType(discountLadders.DISCOUNTLADDERSGETALLFAILURE),
      switchMap(() => {
        this.getHelpService.showSnackbarError(discountLadders.DISCOUNTLADDERSGETALLFAILURE);

        return of(false);
      }),
    );

  @Effect()
  createDiscountLadder$: Observable<Action> = this.actions$
    .pipe(
      ofType(discountLadders.DISCOUNTLADDERSCREATE),
      switchMap((action: discountLadders.DiscountLaddersCreateAction) =>
        this.discountLaddersService
          .createDiscountLadder(action.payload)
          .pipe(
            map((data: any) => new discountLadders.DiscountLaddersCreateSuccessAction(data)),
            catchError(error => of(new discountLadders.DiscountLaddersCreateFailureAction(error)))
          )
      )
    );

  @Effect({ dispatch: false })
  createDiscountLadderFailure$: Observable<any> = this.actions$
    .pipe(
      ofType(discountLadders.DISCOUNTLADDERSCREATEFAILURE),
      switchMap(() => {
        this.getHelpService.showSnackbarError(discountLadders.DISCOUNTLADDERSCREATEFAILURE);

        return of(false);
      }),
    );

  @Effect()
  updateDiscountLadder$: Observable<Action> = this.actions$
    .pipe(
      ofType(discountLadders.DISCOUNTLADDERSUPDATEACTION),
      switchMap((action: discountLadders.DiscountLaddersUpdateAction) =>
        this.discountLaddersService
          .updateDiscountLadder(action.payload)
          .pipe(
            map((data: any) => new discountLadders.DiscountLaddersUpdateSuccessAction(data)),
            catchError(error => of(new discountLadders.DiscountLaddersUpdateFailureAction(error)))
          )
      )
    );

  @Effect({ dispatch: false })
  updateDiscountLadderFailure$: Observable<any> = this.actions$
    .pipe(
      ofType(discountLadders.DISCOUNTLADDERSUPDATEACTIONFAILURE),
      switchMap(() => {
        this.getHelpService.showSnackbarError(discountLadders.DISCOUNTLADDERSUPDATEACTIONFAILURE);

        return of(false);
      }),
    );

  @Effect()
  deleteDiscountLadder$: Observable<Action> = this.actions$
    .pipe(
      ofType(discountLadders.DISCOUNTLADDERSDELETEACTION),
      switchMap((action: discountLadders.DiscountLaddersDeleteAction) =>
        this.discountLaddersService
          .deleteDiscountLadder(action.payload)
          .pipe(
            map((data: any) => new discountLadders.DiscountLaddersDeleteSuccessAction({ ...action.payload })),
            catchError(error => of(new discountLadders.DiscountLaddersDeleteFailureAction(error)))
          )
      )
    );

  @Effect({ dispatch: false })
  deleteDiscountLadderFailure$: Observable<any> = this.actions$
    .pipe(
      ofType(discountLadders.DISCOUNTLADDERSDELETEACTIONFAILURE),
      switchMap(() => {
        this.getHelpService.showSnackbarError(discountLadders.DISCOUNTLADDERSDELETEACTIONFAILURE);

        return of(false);
      }),
    );

  constructor(
    private discountLaddersService: DiscountLaddersService,
    private actions$: Actions,
    private getHelpService: GetHelpService,
  ) { }
}
