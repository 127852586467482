import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import * as fromRoot from '../../../app.reducer';
import { isCurrentUserAdmin, getCurrentUser } from '../../../user/user.reducer';
import { filter, first, withLatestFrom } from 'rxjs/operators';
import { OrgGetAction } from '../org/state/org.actions';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  user: { name: string; url: string; role: string[]; env: string; views: string[]; email: string; };

  constructor(
    public store: Store<fromRoot.State>
  ) { }

  ngOnInit() {
    this.store
      .select(isCurrentUserAdmin)
      .pipe(
        filter((isAdmin) => !!isAdmin),
        first(),
        withLatestFrom(this.store.select(getCurrentUser)),
      )
      .subscribe(([admin, user]) => {
        if (admin) {
          this.user = user;
          this.store.dispatch(new OrgGetAction());
        }
      });
  }

}
