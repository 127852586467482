import { Pipe, PipeTransform } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { getOrgZones, IZone } from './user.reducer';
import { filter, first } from 'rxjs/operators';

import * as _ from 'lodash';

@Pipe({
  name: 'region',
})
export class RegionPipe implements PipeTransform {
  zones: { [key: string]: IZone };

  constructor(private store: Store<any>) {
    this.store
      .pipe(
        select(getOrgZones),
        filter(d => !!d),
        first()
      )
      .subscribe(zones => (this.zones = _.keyBy(zones, d => d.id)));
  }

  transform(value: any, args?: any): any {
    return this.zones[value].description;
  }
}
