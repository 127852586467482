import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Chart } from 'chart.js';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit, OnChanges {
  @Input()
  barChartData;

  @Input()
  showXAxisLabels = true;

  chart;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.barChartData.currentValue && changes.barChartData.currentValue.datasets[0].data.length > 0) {
      const ctx = document.getElementById('canvas');
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: this.barChartData,
        options: {
          responsive: true,
          legend: false,
          maintainAspectRatio: true,
          scales: {
            xAxes: [{
              display: true,
              gridLines: {display: false, color: 'transparent'},
              ticks: {
                fontFamily: 'Open Sans',
                fontStyle: 'bold',
                fontSize: 8
              },
              scaleLabel: {
              display: true,
              labelString: 'DISCOUNT (%)',
              fontSize: 8,
              fontFamily: 'Open Sans',
              }
            }],
            yAxes: [{
              display: true,
              ticks: {
                sampleSize: 3,
                fontFamily: 'Open Sans',
                fontSize: 8
              },
              scaleLabel: {
                display: true,
                labelString: 'STOCK UNITS',
                fontSize: 7,
                fontFamily: 'Open Sans',
              }
            }],
          }
        }
      });
    }
  }

  ngOnInit() {
  }

}
