import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  EventEmitter,
  HostListener,
  ElementRef,
} from '@angular/core';

import * as _ from 'lodash';
import { of, Observable, Subject } from 'rxjs';
import { map, first, filter, distinctUntilChanged } from 'rxjs/operators';
import { thousandsRounded } from '../../../../infra/datagrid/datagrid.formatters';
import { ConfigurationSettingsService } from '../../../../user/configuration-settings.service';
import { Line, Model } from '../../state/model';

import { MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material';
import { ModelDetailCompareVariantsDialogComponent } from '../model-detail-compare-variants-dialog/model-detail-compare-variants-dialog.component';
import { ModelPlanningDialogComponent } from '../model-planning-dialog/model-planning-dialog.component';
import { ModelFacadeService } from '../../model-facade.service';

// should live in a service or something
export function estimatedSpend(takenLines: Line[]): string {
  const value = takenLines.reduce((acc, line) => acc + line.fcstSpend, 0) / 1000;

  return thousandsRounded(1)({ value });
}

export function averageNewDepth(takenLines: Line[]): number {
  const finalPriceSum = takenLines.reduce((acc, line) => acc + line.finalPrice * line.fcstStartStock, 0);
  const fullPriceSum = takenLines.reduce((acc, line) => acc + line.fullPrice * line.fcstStartStock, 0);

  if (!fullPriceSum) {
    return 0;
  }

  return 1 - finalPriceSum / fullPriceSum;
}

export function averageTotalDepth(lines: Line[]): number {
  const takenLines = lines.filter((line: Line) => line.taken === 'Yes');
  const untakenLines = lines.filter((line: Line) => line.taken === 'No');
  const finalPriceSumTaken = takenLines.reduce((acc, line) => acc + line.finalPrice * line.fcstStartStock, 0);
  const finalPriceSumUntaken = untakenLines.reduce((acc, line) => acc + (line.lastWavePrice ? line.lastWavePrice : line.currentPrice) * line.fcstStartStock, 0);
  const fullPriceSum = lines.reduce((acc, line) => acc + line.fullPrice * line.fcstStartStock, 0);

  if (!fullPriceSum) {
    return 0;
  }

  return 1 - (finalPriceSumUntaken + finalPriceSumTaken) / fullPriceSum;
}

export function estimatedClosingStock(lines: Line[]): string {
  const takenLines = this.getTakenLines(lines);
  const value = takenLines.map(line => line.fcstEndStock).reduce((acc, closingStock) => acc + closingStock, 0);

  return thousandsRounded(1)({ value });
}

export function percentLinesOverridden(takenLines: Line[]): number {
  const countLinesOverriden = takenLines.filter(line => line.ovrPrice);
  return countLinesOverriden.length / takenLines.length;
}

export function estimatedClosingST(takenLines: Line[]): number {
  const endStockSum = takenLines.map(line => line.fcstEndStock).reduce((acc, endStock) => acc + endStock, 0);

  const sellThroughSum = takenLines
    .map(line => {
      // SBX-239
      if (line.fcstEndSt === 1) {
        return line.totalStock + line.totalSales;
      }

      return line.fcstEndStock / (1 - line.fcstEndSt);
    })
    .reduce((acc, st) => acc + st, 0);
  // SBX-239
  if (sellThroughSum === 0) {
    return 1;
  }

  return 1 - endStockSum / sellThroughSum;
}

@Component({
  selector: 'app-model-detail-summary-ribbon',
  templateUrl: './model-detail-summary-ribbon.component.html',
  styleUrls: ['./model-detail-summary-ribbon.component.scss'],
})
export class ModelDetailSummaryRibbonComponent implements OnInit, OnChanges {
  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public configurationSettingsService: ConfigurationSettingsService,
    private snackBarService: MatSnackBar,
    private element: ElementRef,
    private dialog: MatDialog,
    private modelFacadeService: ModelFacadeService
  ) { }
  @Input()
  takenLines: Line[];

  @Input()
  model: Model[];

  @Input()
  numberOfRegions: number;

  @Input()
  overviewSelected: boolean;

  @Input()
  selectedWave;

  regionSelected$: Observable<string>;

  showCompareVariantsDialog = false;

  waveModels: Model[];

  // barChart = null;

  // chartType = 'Line';

  barChart = {
    labels: ['10%', '15%', '20%', '25%', '30%', '35%', '40%', '45%', '50%', '55%', '60%', '65%', '70%', '75%'],
    datasets: [
      {
        backgroundColor: 'rgba(32, 97, 193, 1)',
        data: [0, 4, 2, 3, 6, 14, 23, 3, 11, 34, 65, 1, 23, 24],
      },
    ],
  };

  estimatedSpend = estimatedSpend;
  averageNewDepth = averageNewDepth;
  averageTotalDepth = averageTotalDepth;
  estimatedClosingStock = estimatedClosingStock;
  percentLinesOverridden = percentLinesOverridden;
  estimatedClosingST = estimatedClosingST;

  overrideVariantTitle;
  totalLines;
  estimatedDisposalCost;

  stats = {
    percentLinesOverridden: 0,
    averageNewDepth: 0,
    averageTotalDepth: 0,
    linesTakenCount: 0,
    estimatedSpend: 0,
    estimatedClosingStock: 0,
    estimatedOpeningStock: 0,
    estimatedClosingST: 0,
    estimatedOpeningST: 0,
    estimatedDisposalCost: 0,
  };
  selectedVariantName;
  toolTipTakenLines: string;
  toolTipMdDepth: string;
  toolTipEstSpend: string;
  toolTipClosingStockUnits: string;
  toolTipEstClosingStock: string;
  toolTipBarChart: string;
  showInfo = false;
  @HostListener("document:click", ["$event"])
  onclick(event) {
    if (!this.element.nativeElement.contains(event.target)) {
      this.showCompareVariantsDialog = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.takenLines) {
      const takenLines = this.getTakenLines(changes.takenLines.currentValue);
      this.stats.linesTakenCount = this.countLinesTaken(takenLines);
      this.totalLines = _(changes.takenLines.currentValue)
        .uniqBy(d => d.skuId)
        .value().length;
      this.stats.percentLinesOverridden = this.percentLinesOverridden(takenLines);
      this.stats.averageTotalDepth = this.averageTotalDepth(changes.takenLines.currentValue);
      this.stats.averageNewDepth = this.averageNewDepth(takenLines);
      // @ts-ignore
      this.stats.estimatedSpend = estimatedSpend(takenLines);
      // @ts-ignore
      this.stats.estimatedClosingStock = this.estimatedClosingStock(takenLines);
      // @ts-ignore
      this.stats.estimatedOpeningStock = this.estimatedOpeningStock(takenLines);
      this.stats.estimatedClosingST = this.estimatedClosingST(takenLines);

      this.stats.estimatedOpeningST = this.estimatedOpeningST(takenLines);
      this.stats.estimatedDisposalCost = +this.estimatedDisposal(takenLines);
    }

    if (changes.takenLines && changes.takenLines.currentValue) {
      this.changeDetectorRef.detectChanges();
      const data = [
        changes.takenLines.currentValue.filter((line: Line) => line.finalDisc < 0.15 && line.finalDisc >= 0.1 && line.taken === 'Yes')
          .length,
        changes.takenLines.currentValue.filter((line: Line) => line.finalDisc < 0.2 && line.finalDisc >= 0.15 && line.taken === 'Yes')
          .length,
        changes.takenLines.currentValue.filter((line: Line) => line.finalDisc < 0.25 && line.finalDisc >= 0.2 && line.taken === 'Yes')
          .length,
        changes.takenLines.currentValue.filter((line: Line) => line.finalDisc < 0.3 && line.finalDisc >= 0.25 && line.taken === 'Yes')
          .length,
        changes.takenLines.currentValue.filter((line: Line) => line.finalDisc < 0.35 && line.finalDisc >= 0.3 && line.taken === 'Yes')
          .length,
        changes.takenLines.currentValue.filter((line: Line) => line.finalDisc < 0.4 && line.finalDisc >= 0.35 && line.taken === 'Yes')
          .length,
        changes.takenLines.currentValue.filter((line: Line) => line.finalDisc < 0.45 && line.finalDisc >= 0.4 && line.taken === 'Yes')
          .length,
        changes.takenLines.currentValue.filter((line: Line) => line.finalDisc < 0.5 && line.finalDisc >= 0.45 && line.taken === 'Yes')
          .length,
        changes.takenLines.currentValue.filter((line: Line) => line.finalDisc < 0.55 && line.finalDisc >= 0.5 && line.taken === 'Yes')
          .length,
        changes.takenLines.currentValue.filter((line: Line) => line.finalDisc < 0.6 && line.finalDisc >= 0.55 && line.taken === 'Yes')
          .length,
        changes.takenLines.currentValue.filter((line: Line) => line.finalDisc < 0.65 && line.finalDisc >= 0.6 && line.taken === 'Yes')
          .length,
        changes.takenLines.currentValue.filter((line: Line) => line.finalDisc < 0.7 && line.finalDisc >= 0.65 && line.taken === 'Yes')
          .length,
        changes.takenLines.currentValue.filter((line: Line) => line.finalDisc < 0.75 && line.finalDisc >= 0.7 && line.taken === 'Yes')
          .length,
        changes.takenLines.currentValue.filter((line: Line) => line.finalDisc >= 0.75 && line.taken === 'Yes').length,
      ];
      this.barChart = {
        labels: ['10%', '15%', '20%', '25%', '30%', '35%', '40%', '45%', '50%', '55%', '60%', '65%', '70%', '75%'],
        datasets: [
          {
            backgroundColor: 'rgba(32, 97, 193, 1)',
            data,
          },
        ],
      };
    }
    if (changes.model && changes.model.currentValue) {
      const visibleModels = changes.model.currentValue;
      const uniqueVariantIds = _(visibleModels)
        .map((model) => model.variantId)
        .uniq()
        .value();
      if (uniqueVariantIds.length > 1) {
        this.selectedVariantName = 'Multiple';
      } else {
        const firstModel = _.find(visibleModels, model => _(uniqueVariantIds).includes(model.variantId));
        if (firstModel) {
          const modelVariant = _.find(firstModel.variant, v => _(uniqueVariantIds).includes(+v.id));
          this.selectedVariantName = modelVariant.alias || '';
        }
      }
    }
  }

  ngOnInit() {
    this.regionSelected$ = this.modelFacadeService.getSelectedRegion();
    this.toolTipTakenLines = `Total number of lines taken in the model & % of prices overridden.`;
    this.toolTipMdDepth = `Average discount from the lines taken and Average total discount for all products in the models,
    weighted by stock and price.`;
    this.toolTipEstSpend = `Total forecasted spend for the event`;
    this.toolTipClosingStockUnits = `Estimated remaining stock units at the event of the event, and estimated disposal costs`;
    this.toolTipEstClosingStock = `Estimated sell through at the beginning and end of the event`;
    this.toolTipBarChart = `Distribution of Discounts by line across all regions`;
  }

  toggleCompareVariants() {
    let allModelIds;
    this.modelFacadeService
      .getSelectedModels()
      .pipe(first())
      .subscribe(models => {
        allModelIds = models.map(d => d.id);
      });
    if (this.stats.linesTakenCount > 0) {
      this.dialog
        .open(ModelDetailCompareVariantsDialogComponent, {
          position: {
            top: '5vw',
            left: '15vw',
          },
          data: { models :this.model, selectedWave: this.selectedWave }
        })
        .afterClosed()
        .subscribe(modelsUpdated => {
          if (modelsUpdated) {
            this.modelFacadeService.refresh(modelsUpdated);
          } else {
            this.modelFacadeService.refresh(allModelIds);
          }
        });
    } else {
      const snackbarStr = `Take at least one line to see variants`;
      const snackBarRef = this.snackBarService.open(snackbarStr, 'Dismiss', {
        verticalPosition: 'top',
      });
      snackBarRef._dismissAfter(10000);
    }
  }

  getTakenLines(lines: Line[]): Line[] {
    if (lines) {
      return lines.filter(line => line.taken === 'Yes');
    }

    return [];
  }

  countLinesTaken(lines: Line[]): number {
    if (lines) {
      return _(this.getTakenLines(lines))
        .uniqBy(d => d.skuId)
        .value().length;
    }
  }

  averagePreviousDepth(takenLines: Line[]): number {
    return (
      takenLines.map(line => (line.fullPrice - (line.lastWavePrice ? line.lastWavePrice : line.currentPrice)) / line.fullPrice).reduce((acc, depth) => acc + depth, 0) /
      takenLines.length
    );
  }

  estimatedOpeningStock(takenLines: Line[]): string {
    const value = takenLines.map(line => line.stockOnHand * (line.lastWavePrice ? line.lastWavePrice : line.currentPrice)).reduce((acc, openingStock) => acc + openingStock, 0) / 1000;

    return thousandsRounded(1)({ value });
  }

  estimatedOpeningST(takenLines: Line[]): number {
    const startStockSum = takenLines.map(line => line.fcstStartStock).reduce((acc, startStock) => acc + startStock, 0);

    const sellThroughSum = takenLines
      .map(line => {
        // SBX-239
        if (line.fcstStartSt === 1) {
          return line.totalStock + line.totalSales;
        }

        return line.fcstStartStock / (1 - line.fcstStartSt);
      })
      .reduce((acc, st) => acc + st, 0);
    // SBX-239
    if (sellThroughSum === 0) {
      return 1;
    }

    return 1 - startStockSum / sellThroughSum;
  }

  estimatedDisposal(takenLines: Line[]): string {
    const endStockSum = takenLines.map(line => line.fcstEndStock).reduce((acc, endStock) => acc + endStock, 0);
    const value = (4 * endStockSum) / 1000;
    return thousandsRounded(1)({ value });
  }
}
