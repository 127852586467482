import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { SideBarDef, GridOptions, ColDef } from 'ag-grid-community';
import { Observable, Subject } from 'rxjs';

import * as fromRoot from '../../app.reducer';

import { Store, select } from '@ngrx/store';
import { getEvents } from '../events/state/events.selectors';

import { Event } from '../event/event';
import { EventModelStatus, EventStatus } from '../../abstracts/event-status.type';
import { GridReadyEvent } from 'ag-grid-community';
import * as _ from 'lodash';

import * as moment from 'moment';
import { ModelFacadeService } from '../model/model-facade.service';
import { withLatestFrom } from 'rxjs/operators';
import { Model } from '../model/state/model';

@Component({
  selector: 'app-events-feature-grid',
  templateUrl: './events-feature-grid.component.html',
  styleUrls: ['./events-feature-grid.component.scss'],
})
export class EventsFeatureGridComponent implements OnInit {
  @Input()
  columnDefs: ColDef[];

  @Input()
  gridOptions: GridOptions;

  @Input()
  filterValueChanged: boolean;

  @Output()
  rowClick = new EventEmitter();

  allEvents: Array<Event & { relatedModels: Model[] }>;
  visibleEvents: Array<Event & { relatedModels: Model[] }>;

  defaultStatusFilter = true;

  filters$: Subject<{}> = new Subject();

  quickFilterText: string;
  statusList = [];
  sideBar: SideBarDef = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressPivots: true,
          suppressPivotMode: true,
          suppressSideButtons: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
  };

  constructor(public store: Store<fromRoot.State>, private modelFacadeService: ModelFacadeService) {}

  ngOnInit() {
    this.store
      .pipe(
        select(getEvents),
        withLatestFrom(this.modelFacadeService.getAll())
      )
      .subscribe(([events, models]) => {
        this.allEvents = events.map(event => ({ ...event, relatedModels: models.filter(model => +model.eventId === +event.id) }));
        this.visibleEvents = _.orderBy(this.allEvents, ['startDate'], ['asc']);
      });
    this.statusList = _(this.allEvents)
      .map(event => event.status)
      .uniq()
      .value();
  }

  onEventTypeClick(e: string[]) {
    this.filters$.next({
      field: 'eventType',
      condition: e,
    });
  }

  onDateRangeChange([_fromDate, _toDate]) {
    const fromDate = _fromDate || new Date('Jan 1, 2001');
    const toDate = _toDate || new Date('Dec 31, 2050');
    this.visibleEvents = this.allEvents.filter(event => {
      return (
        (event.startDate >= +moment(fromDate) && event.startDate <= +moment(toDate)) ||
        (event.endDate >= +moment(fromDate) && event.endDate <= +moment(toDate))
      );
    });
  }

  onStatusClicked(e: EventStatus[]) {
    this.filters$.next({
      field: 'status',
      condition: e.map(eventStatus => EventModelStatus[eventStatus]),
      allStatusValues: this.statusList,
    });
  }

  onQuickFilterTextChange(e: string) {
    this.quickFilterText = e;
  }

  onRowClick(e) {
    this.rowClick.emit(e);
  }

  onFilterCleared(e) {
    const condition = null;
    this.filters$.next({
      field: '',
      condition,
    });
  }

  filterChanged(e) {
    this.filterValueChanged = e;
  }

  onGridReady(e?: GridReadyEvent) {
    this.filters$.next({
      field: 'status',
      condition: ['Planning', 'Submitted', 'Approved', 'Live'],
      allStatusValues: this.statusList,
    });
  }
}
