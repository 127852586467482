import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap, filter } from 'rxjs/operators';
import { generateLiveReport, LiveReportGridRow } from '../reporting-live/reporting-live.service';
import { RouterFacadeService } from '../../../router-facade.service';

@Injectable({
  providedIn: 'root',
})
export class ReportingEventLiveService {
  constructor(private http: HttpClient, private routerFacadeService: RouterFacadeService) {}

  public getSelectedEventLiveReportConfiguration() {
    const routeEventID$ = this.routerFacadeService.getParamFromRouter('eventid');
    const routeRegions$ = this.routerFacadeService.getParamFromRouter('regions');
    const routeWave$ = this.routerFacadeService.getParamFromRouter('wave');
    const routeSeason$ = this.routerFacadeService.getParamFromRouter('season');

    return combineLatest([routeEventID$, routeRegions$, routeWave$, routeSeason$]).pipe(
      filter(
        ([selectedEventID, selectedRegions, selectedWave, selectedSeason]) =>
          !!selectedEventID && !!selectedRegions && !!selectedWave && !!selectedSeason
      ),
      map(([selectedEventID, selectedRegions, selectedWave, selectedSeason]) => ({
        selectedEventID: +selectedEventID,
        selectedRegions: selectedRegions.split(','),
        selectedWave: +selectedWave,
        selectedSeason,
      }))
    );
  }

  public selectedEventLiveReport() {
    return this.getSelectedEventLiveReportConfiguration().pipe(
      switchMap(({ selectedEventID, selectedRegions, selectedWave, selectedSeason }) =>
        this.getReport(selectedEventID, selectedRegions, selectedWave, selectedSeason)
      )
    );
  }

  private getReport(eventID: number, region: string[], wave: number, aggSeason: string) {
    return this.http.post('/api/forecast-report', { event_id: eventID, region, wave }).pipe(
      map((data: LiveReportGridRow[]) => {
        const baseData = _(data);
        let returnData = [...generateLiveReport(baseData, aggSeason).map(d => ({ ...d, grouping: 'Total' }))];

        baseData.groupBy('department_desc').forEach((vals, departmentDesc) => {
          returnData = returnData.concat(generateLiveReport(_(vals), aggSeason).map(d => ({ ...d, grouping: departmentDesc })));
        });

        const finalData = _(returnData)
          .groupBy('grouping')
          .map(vals => {
            if (vals.length === 7) {
              return vals;
            }
            if (aggSeason.indexOf('S') > -1) {
              const grouping = vals[0].grouping;
              const ss1 = _.find(vals, ({ aggLevel }) => aggLevel === 'SS1');
              if (!ss1) {
                vals.push({
                  aggLevel: 'SS1',
                  discountRate: 0,
                  endingStock: 0,
                  grouping,
                  margin: 0,
                  marginRate: 0,
                  sales: 0,
                  salesMix: 0,
                  startingStock: 0,
                  unitSales: 0,
                });
              }
              const ss2 = _.find(vals, ({ aggLevel }) => aggLevel === 'SS2');
              if (!ss2) {
                vals.push({
                  aggLevel: 'SS2',
                  discountRate: 0,
                  endingStock: 0,
                  grouping,
                  margin: 0,
                  marginRate: 0,
                  sales: 0,
                  salesMix: 0,
                  startingStock: 0,
                  unitSales: 0,
                });
              }
              const ss3 = _.find(vals, ({ aggLevel }) => aggLevel === 'SS3');
              if (!ss3) {
                vals.push({
                  aggLevel: 'SS3',
                  discountRate: 0,
                  endingStock: 0,
                  grouping,
                  margin: 0,
                  marginRate: 0,
                  sales: 0,
                  salesMix: 0,
                  startingStock: 0,
                  unitSales: 0,
                });
              }
              const ss4 = _.find(vals, ({ aggLevel }) => aggLevel === 'SS4');
              if (!ss4) {
                vals.push({
                  aggLevel: 'SS4',
                  discountRate: 0,
                  endingStock: 0,
                  grouping,
                  margin: 0,
                  marginRate: 0,
                  sales: 0,
                  salesMix: 0,
                  startingStock: 0,
                  unitSales: 0,
                });
              }
            }
            if (aggSeason.indexOf('W') > -1) {
              const aw1 = _.find(vals).aggLevel === 'AW1';
              const aw2 = _.find(vals).aggLevel === 'AW2';
              const aw3 = _.find(vals).aggLevel === 'AW3';
              const aw4 = _.find(vals).aggLevel === 'AW4';
            }

            const sortedVals = vals.sort((firstEl, secondEl) => {
              // first is less than second
              if (firstEl.aggLevel.includes('SS') || firstEl.aggLevel.includes('AW')) {
                if (secondEl.aggLevel.includes('SS') || secondEl.aggLevel.includes('AW')) {
                  const firstAggLevel = firstEl.aggLevel.replace('SS', '').replace('AW', '');
                  const secondAggLevel = secondEl.aggLevel.replace('SS', '').replace('AW', '');
                  if (+firstAggLevel > +secondAggLevel) {
                    return 1;
                  }

                  return -1;
                } else {
                  return -1;
                }
              }
            });

            return sortedVals;
          });

        return finalData.flatten().value();
      })
    );
  }
}
