import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

import * as fromRoot from '../../../../app.reducer';
import { ModelFacadeService } from '../../model-facade.service';
import { Line } from '../../state/model';
import { isNotLocked } from '../../utilities/isNotLocked';

@Component({
  selector: 'app-model-detail-taken-cell',
  templateUrl: './model-detail-taken-cell.component.html',
  styleUrls: ['./model-detail-taken-cell.component.scss'],
})
export class ModelDetailTakenCellComponent implements ICellRendererAngularComp, OnInit {
  params: any;
  value: any;
  status$: Observable<string>;
  planningStatus;
  get data(): Line[] {
    return this.params.node.allLeafChildren.map((leaf: { data: Line[] }) => leaf.data).filter(d => isNotLocked(d));
  }

  agInit(params: any): void {
    this.params = params;
  }

  constructor(public store: Store<fromRoot.State>, public route: ActivatedRoute, private modelFacadeService: ModelFacadeService) {}

  ngOnInit() {
    if (!this.params.value) {
      if (this.params.data && Object.keys(_.keyBy(this.data, 'taken')).length === 1) {
        this.value = _.first(this.data).taken;
      } else if (!this.params.data) {
        const untakenChildNodes = this.params.node.allLeafChildren.map((leaf: { data: Line[] }) => leaf.data).filter(d => d.taken === 'No')
          .length;
        if (untakenChildNodes === 0) {
          this.value = 'Yes';
        }
      } else {
        this.value = 'indeterminate';
      }
    }
  }

  refresh(): boolean {
    return false;
  }

  getAllLeafLines(filterCondition) {
    return this.params.node.allLeafChildren
      .map((leaf: { data: Line[] }) => leaf.data)
      .filter(d => d.taken === filterCondition)
      .filter(d => isNotLocked(d));
  }

  toggleTaken(checked) {
    let taken;
    let lines = [];

    taken = this.params.value === 'Yes' ? 0 : 1;
    if (this.params.valueFormatted != null && this.params.data && this.params.data.model) {
      lines = this.params.data.models.map(model => ({ model, skuId: this.params.data.skuId_overview }));
      this.params.data.taken = taken === 0 ? 'No' : 'Yes';
    } else if (!this.params.data) {
      const filterCondition = checked === true ? 'No' : 'Yes';
      lines = this.getAllLeafLines(filterCondition);
      taken = checked === true ? 1 : 0;
    } else {
      lines = [this.params.data];
    }

    this.modelFacadeService.takeUntakeLines({
      taken,
      lines,
    });
  }
}
