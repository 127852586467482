import { Action } from '@ngrx/store';
import { IVariant, IEvent } from '../../../abstracts/event.interface';
import { Variant } from '../../variant/variant.model';
import { ModelDetails } from '../../event/components/event-create-edit-stepper-model-details-form/event-create-edit-stepper-model-details-form.component';
import { VariantDetails, EventDetails } from '../../event/event';

export const VARIANTSGET = '[VARIANTS] Get';
export const VARIANTSGETSUCCESS = '[VARIANTS] GetSuccess';
export const VARIANTSGETFAILURE = '[VARIANTS] GetFailure';
export const VARIANTSUPDATE = '[VARIANTS] Update';
export const VARIANTSUPDATESUCCESS = '[VARIANTS] UpdateSuccess';
export const VARIANTSUPDATEFAILURE = '[VARIANTS] UpdateFailure';
export const VARIANTSCREATE = '[VARIANTS] Create';
export const VARIANTSCREATESUCCESS = '[VARIANTS] CreateSuccess';
export const VARIANTSCREATEFAILURE = '[VARIANTS] CreateFailure';
export const VARIANTSCREATEWIZARD = '[VARIANTS] CreateWizard';
export const VARIANTSCREATEWIZARDSUCCESS = '[VARIANTS] CreateWizardSuccess';
export const VARIANTSCREATEWIZARDFAILURE = '[VARIANTS] CreateWizardFailure';
export const VARIANTDELETE = '[VARIANTS] Delete';
export const VARIANTDELETESUCCESS = '[VARIANTS] DeleteSuccess';
export const VARIANTDELETEFAILURE = '[VARIANTS] DeleteFailure';

export class VariantsGetAction implements Action {
  type = VARIANTSGET;

  constructor() {}
}

export class VariantsGetSuccessAction implements Action {
  type = VARIANTSGETSUCCESS;

  constructor(public payload: IVariant[]) {}
}

export class VariantsGetFailureAction implements Action {
  type = VARIANTSGETFAILURE;

  constructor(public payload) {}
}

export class VariantsUpdateAction implements Action {
  type = VARIANTSUPDATE;

  constructor(public payload: IVariant) {}
}

export class VariantsUpdateSuccessAction implements Action {
  type = VARIANTSUPDATESUCCESS;

  constructor(public payload: IVariant) {}
}

export class VariantsUpdateFailureAction implements Action {
  type = VARIANTSUPDATEFAILURE;

  constructor(public payload) {}
}

export class VariantsCreateAction implements Action {
  type = VARIANTSCREATE;

  constructor(public payload: IVariant & { uuid }) {}
}

export class VariantsCreateSuccessAction implements Action {
  type = VARIANTSCREATESUCCESS;

  constructor(public payload: IVariant) {}
}

export class VariantsCreateFailureAction implements Action {
  type = VARIANTSCREATEFAILURE;

  constructor(public payload) {}
}

export class VariantsCreateWizardAction implements Action {
  type = VARIANTSCREATEWIZARD;

  constructor(
    public payload: {
      event: IEvent;
      params: {
        eventDetails: EventDetails;
        variantsDetails: VariantDetails[];
        modelsDetails: ModelDetails[];
      };
    }
  ) {}
}

export class VariantsCreateWizardSuccessAction implements Action {
  type = VARIANTSCREATEWIZARDSUCCESS;

  constructor(
    public payload: {
      event: IEvent;
      params: {
        eventDetails: EventDetails;
        variantsDetails: VariantDetails[];
        modelsDetails: Array<ModelDetails & { id? }>;
      };
      variants: Array<Variant & { uuid }>;
    }
  ) {}
}

export class VariantsCreateWizardFailureAction implements Action {
  type = VARIANTSCREATEWIZARDFAILURE;

  constructor(public payload) {}
}
export class VariantsDeleteAction implements Action {
  type = VARIANTDELETE;

  constructor(public payload: { id: number }) {}
}
export class VariantsDeleteSuccessAction implements Action {
  type = VARIANTDELETESUCCESS;

  constructor(public payload: { id: number }) {}
}
export class VariantsDeleteFailureAction implements Action {
  type = VARIANTDELETEFAILURE;

  constructor(public payload) {}
}

export type actions =
  | VariantsGetSuccessAction
  | VariantsUpdateSuccessAction
  | VariantsCreateSuccessAction
  | VariantsCreateWizardSuccessAction
  | VariantsDeleteSuccessAction;
