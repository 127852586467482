import { createAction, props } from '@ngrx/store';
import { Model, CreateModelParams, UpdateModelParams, GetLineOverridePredictionParams, Line, DiscountDistributionMetric } from './model';
import { Update } from '@ngrx/entity';

export enum ModelActionTypes {
  LoadAll = '[Model] Load All',
  LoadAllSuccess = '[Model] Load All Success',
  LoadAllFailure = '[Model] Load All Failure',
  Create = '[Model] Create',
  CreateSuccess = '[Model] Create Success',
  CreateFailure = '[Model] Create Failure',
  Update = '[Model] Update',
  UpdateSuccess = '[Model] Update Success',
  UpdateFailure = '[Model] Update Failure',
  Delete = '[Model] Delete',
  DeleteSuccess = '[Model] Delete Success',
  DeleteFailure = '[Model] Delete Failure',
  LoadLines = '[Model] Load Lines',
  LoadLinesSuccess = '[Model] Load Lines Success',
  LoadLinesFailure = '[Model] Load Lines Failure',
  Submit = '[Model] Submit',
  SubmitSuccess = '[Model] Submit Success',
  SubmitFailure = '[Model] Submit Failure',
  Retract = '[Model] Retract',
  RetractSuccess = '[Model] Retract Success',
  RetractFailure = '[Model] Retract Failure',
  Approve = '[Model] Approve',
  ApproveSuccess = '[Model] Approve Success',
  ApproveFailure = '[Model] Approve Failure',
  Optimize = '[Model] Optimize',
  OptimizeSuccess = '[Model] Optimize Success',
  OptimizeFailure = '[Model] Optimize Failure',
  GetLineOverridePrediction = '[Model] Get Line Override Prediction',
  GetLineOverridePredictionSuccess = '[Model] Get Line Override Prediction Success',
  GetLineOverridePredictionFailure = '[Model] Get Line Override Prediction Failure',
  ApplyVariant = '[Model] Apply Variant',
  ApplyVariantSuccess = '[Model] Apply Variant Success',
  ApplyVariantFailure = '[Model] Apply Variant Failure',
  GetDiscountDistribution = '[Model] Get Discount Distribution',
  GetDiscountDistributionSuccess = '[Model] Get Discount Distribution Success',
  GetDiscountDistributionFailure = '[Model] Get Discount Distribution Failure',
  GetDetails = '[Model] Get Details',
  GetDetailsSuccess = '[Model] Get Details Success',
  GetDetailsFailure = '[Model] Get Details Failure',
  TakeUntakeLines = '[Model] Take Untake Lines',
  TakeUntakeLinesSuccess = '[Model] Take Untake Lines Success',
  TakeUntakeLinesFailure = '[Model] Take Untake Lines Failure',
  EditLineNote = '[Model] Edit Line Note',
  EditLineNoteSuccess = '[Model] Edit Line Note Success',
  EditLineNoteFailure = '[Model] Edit Line Note Failure',
  Refresh = '[Model] Refresh',
  RefreshSuccess = '[Model] Refresh Success',
  RefreshFailure = '[Model] Refresh Failure',
  CreateWizardSuccess = '[Model] Create Wizard Success',
  CreateWizardFailure = '[Model] Create Wizard Failure',
}

export const ModelLoadAll = createAction(ModelActionTypes.LoadAll);

export const ModelLoadAllSuccess = createAction(ModelActionTypes.LoadAllSuccess, props<{ entities: Model[] }>());

export const ModelLoadAllFailure = createAction(ModelActionTypes.LoadAllFailure, props<{ error: any }>());

export const ModelCreate = createAction(ModelActionTypes.Create, props<CreateModelParams & { navigateAfterCreate?: boolean | number[] }>());

export const ModelCreateSuccess = createAction(ModelActionTypes.CreateSuccess, props<{ entities: Model[], navigateTo: number[] }>());

export const ModelCreateFailure = createAction(ModelActionTypes.CreateFailure, props<{ error: any }>());

export const ModelUpdate = createAction(ModelActionTypes.Update, props<UpdateModelParams>());

export const ModelUpdateSuccess = createAction(ModelActionTypes.UpdateSuccess, props<{ update: Update<Model> }>());

export const ModelUpdateFailure = createAction(ModelActionTypes.UpdateFailure, props<{ error: any }>());

export const ModelDelete = createAction(ModelActionTypes.Delete, props<{ id: number }>());

export const ModelDeleteSuccess = createAction(ModelActionTypes.DeleteSuccess, props<{ id: number }>());

export const ModelDeleteFailure = createAction(ModelActionTypes.DeleteFailure, props<{ error: any }>());

export const ModelLoadLines = createAction(ModelActionTypes.LoadLines, props<{ modelIDs: Array<string | number> }>());

export const ModelLoadLinesSuccess = createAction(ModelActionTypes.LoadLinesSuccess, props<{ updates: Update<Model>[] }>());

export const ModelLoadLinesFailure = createAction(ModelActionTypes.LoadLinesFailure, props<{ error: any }>());

export const ModelSubmit = createAction(ModelActionTypes.Submit, props<{ id: number }>());

export const ModelSubmitSuccess = createAction(ModelActionTypes.SubmitSuccess, props<{ update: Update<Model>[] }>());

export const ModelSubmitFailure = createAction(ModelActionTypes.SubmitFailure, props<{ error: any }>());

export const ModelRetract = createAction(ModelActionTypes.Retract, props<{ id: number }>());

export const ModelRetractSuccess = createAction(ModelActionTypes.RetractSuccess, props<{ update: Update<Model>[] }>());

export const ModelRetractFailure = createAction(ModelActionTypes.RetractFailure, props<{ error: any }>());

export const ModelApprove = createAction(ModelActionTypes.Approve, props<{ id: number }>());

export const ModelApproveSuccess = createAction(ModelActionTypes.ApproveSuccess, props<{ update: Update<Model>[] }>());

export const ModelApproveFailure = createAction(ModelActionTypes.ApproveFailure, props<{ error: any }>());

export const ModelOptimize = createAction(ModelActionTypes.Optimize, props<{ modelIDs: string[] }>());

export const ModelOptimizeSuccess = createAction(ModelActionTypes.OptimizeSuccess);

export const ModelOptimizeFailure = createAction(ModelActionTypes.OptimizeFailure, props<{ error: any }>());

export const ModelRefresh = createAction(ModelActionTypes.Refresh, props<{ modelIDs: string[] }>());

export const ModelRefreshSuccess = createAction(
  ModelActionTypes.RefreshSuccess,
  props<{ lines: { [key: number]: Line[] }; models: Model[] }>()
);

export const ModelRefreshFailure = createAction(ModelActionTypes.RefreshFailure, props<{ error: any }>());

export const ModelGetLineOverridePrediction = createAction(
  ModelActionTypes.GetLineOverridePrediction,
  props<GetLineOverridePredictionParams>()
);

export const ModelGetLineOverridePredictionSuccess = createAction(
  ModelActionTypes.GetLineOverridePredictionSuccess,
  props<{ lines: Array<Partial<Line>> }>()
);

export const ModelGetLineOverridePredictionFailure = createAction(
  ModelActionTypes.GetLineOverridePredictionFailure,
  props<{ error: any; skuList: Array<string | number>; modelID: number }>()
);

export const ModelApplyVariant = createAction(ModelActionTypes.ApplyVariant, props<{ id: number | string; variantId: number }>());

export const ModelApplyVariantSuccess = createAction(ModelActionTypes.ApplyVariantSuccess, props<{ update: Update<Model> }>());

export const ModelApplyVariantFailure = createAction(ModelActionTypes.ApplyVariantFailure, props<{ error: any }>());

export const ModelGetDiscountDistribution = createAction(
  ModelActionTypes.GetDiscountDistribution,
  props<{ metric: DiscountDistributionMetric; id: number }>()
);

export const ModelGetDiscountDistributionSuccess = createAction(
  ModelActionTypes.GetDiscountDistributionSuccess,
  props<{ update: Update<Model> }>()
);

export const ModelGetDiscountDistributionFailure = createAction(ModelActionTypes.GetDiscountDistributionFailure, props<{ error: any }>());

export const ModelGetDetails = createAction(ModelActionTypes.GetDetails, props<{ id: number }>());

export const ModelGetDetailsSuccess = createAction(ModelActionTypes.GetDetailsSuccess, props<{ update: Update<Model> }>());

export const ModelGetDetailsFailure = createAction(ModelActionTypes.GetDetailsFailure, props<{ error: any }>());

export const ModelTakeUntakeLines = createAction(ModelActionTypes.TakeUntakeLines, props<{ lines: Array<Line>; taken: 0 | 1 }>());

export const ModelTakeUntakeLinesSuccess = createAction(
  ModelActionTypes.TakeUntakeLinesSuccess,
  props<{ modelLines: Array<{ taken: 0 | 1; skuId: string; lineCount: number; modelId: number }> }>()
);

export const ModelTakeUntakeLinesFailure = createAction(ModelActionTypes.TakeUntakeLinesFailure, props<{ error: any }>());

export const ModelEditLineNote = createAction(ModelActionTypes.EditLineNote, props<{ skuId: string; modelId: number; note: string }>());

export const ModelEditLineNoteSuccess = createAction(
  ModelActionTypes.EditLineNoteSuccess,
  props<{ note: string; modelId: number; skuId: string }>()
);

export const ModelEditLineNoteFailure = createAction(ModelActionTypes.EditLineNoteFailure, props<{ error: any }>());

export const ModelCreateWizardSuccess = createAction(ModelActionTypes.CreateWizardSuccess, props<{}>());

export const ModelCreateWizardFailure = createAction(ModelActionTypes.CreateWizardFailure, props<{ error: any }>());
