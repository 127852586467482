import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { tap, filter, first, switchMap, catchError } from 'rxjs/operators';
import * as _ from 'lodash';

import { ModelFacadeService } from '../../model-facade.service';

@Injectable({
  providedIn: 'root',
})
export class MultiModelDetailGuard implements CanActivate {
  constructor(private modelFacadeService: ModelFacadeService) {}

  getFromStoreOrAPI({ modelIDs, regionID, waveID }: { modelIDs: Array<string>; regionID: string; waveID: string }): Observable<any> {
    let loadingLines = false;
    let modelsToLoad;
    return this.modelFacadeService.get({ id: modelIDs }).pipe(
      filter(models => models.length > 0), // models have to have loaded first
      tap(models => {
        let modelIDsToLoad;
        modelsToLoad = models.filter(model => model.wave === +waveID && model.region === regionID && !model.lines);
        if (modelsToLoad.length === 0) {
          return;
        }
        if (!loadingLines) {
          modelIDsToLoad = modelsToLoad.map(({ id }) => id);
          this.modelFacadeService.getLines({ modelIDs: modelIDsToLoad });
          loadingLines = true;
        }
      }),
      filter(() => modelsToLoad.length === 0),
      first()
    );
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const modelIDs = next.params['ids'].split(',');
    const regionID = next.params['region'];
    const waveID = next.params['wave'];
    return this.getFromStoreOrAPI({ modelIDs, regionID, waveID }).pipe(
      switchMap(() => of(true)),
      catchError(error => of(false))
    );
  }
}
