import { Component, OnInit, ViewContainerRef, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { DepartmentMinimumPriceAssignmentComponent } from '../department-minimum-price-assignment.component';

@Component({
  selector: 'app-min-price-cell-editor',
  templateUrl: './min-price-cell-editor.component.html',
  styleUrls: ['./min-price-cell-editor.component.scss']
})
export class MinPriceCellEditorComponent implements OnInit {
  params: any;
  value;
  parentComponent: DepartmentMinimumPriceAssignmentComponent;

  private cancelBeforeStart = false;

  @ViewChild('input', { read: ViewContainerRef, static: false }) public input;

  constructor() { }

  get allLeaves() {
    return this.parentComponent &&
      this.parentComponent.merchHierarchy.filter((lineage) => lineage[this.params.node.field] === this.params.node.key);
  }

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
    this.setMinPrice();
    this.parentComponent = this.params.context && this.params.context.parentComponent;
    this.parentComponent.rowUpdatedSubject.subscribe(() => this.setMinPrice());
    this.cancelBeforeStart = (params.charPress && '1234567890.'.indexOf(params.charPress) < 0);
  }

  setMinPrice() {
    this.value = undefined;
    // Assign the min price of child nodes to the grouped row if they all have same value
    if (_.uniqBy(this.allLeaves, ({ minPrice }) => minPrice).length === 1) {
      this.value  = +_.first(this.allLeaves).minPrice;
    }
  }

  ngOnInit() {
    this.setMinPrice();
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  isCancelAfterEnd(): boolean {
    const isNonNumber = /[^$,.\d]/.test(this.value);
    return isNonNumber;
  }

  onChange(event) {
    if (this.isCancelAfterEnd()) {
      this.value = this.setMinPrice();
    } else {
    let hierarchyLevel: number;
    let hierarchyId: string;
    if (this.params.node.field.indexOf('h1') !== -1) {
      hierarchyLevel = 1;
      hierarchyId = _.first(this.allLeaves)['h1Id'];
      if (!hierarchyId) {
        hierarchyId = '0';
      }
    } else if (this.params.node.field.indexOf('h2') !== -1) {
      hierarchyLevel = 2;
      hierarchyId = _.first(this.allLeaves)['h2Id'];
    } else {
      hierarchyLevel = 3;
      hierarchyId = _.first(this.allLeaves)['h3Id'];
    }

    this.parentComponent.mapHierarchy({ hierarchyLevel: hierarchyLevel, hierarchyId: hierarchyId, key : 'minPrice', keyValue: +this.value });
    }
  }
}
