import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';

import * as fromRoot from '../app.reducer';
import { isCurrentUserSuperuser } from '../user/user.reducer';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public store: Store<fromRoot.State>,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const observable = this.store.select(isCurrentUserSuperuser);

      return observable;
    }
}
