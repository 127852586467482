import { Store } from '@ngrx/store';

import * as fromRoot from './app.reducer';
import { UserGetCurrentAction } from './user/user.actions';
import { getCurrentUser } from './user/user.reducer';
import { filter, take } from 'rxjs/operators';

declare var firebase;

export function initApplication(store: Store<fromRoot.State>): Function {
  return () =>
    new Promise(resolve => {
      firebase.auth();
      store.dispatch(new UserGetCurrentAction());
      store
        .select(getCurrentUser)
        .pipe(
          filter(user => !!user.env),
          take(1)
        )
        .subscribe(user => {
          if (!user.name && String(window.location).indexOf('login') === -1 && String(window.location).indexOf('organizations') === -1) {
            window.location.assign('/login');
          }
          resolve(true);
        });
    });
}
