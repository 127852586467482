import { Component, OnInit } from '@angular/core';
import { ReportingGridSelectService } from './reporting-grid-select.service';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import { Event } from '../../event/event';
import * as _ from 'lodash';
import { getEvents } from '../../events/state/events.selectors';

@Component({
  selector: 'app-reporting-grid-select-cell',
  templateUrl: './reporting-grid-select-cell.component.html',
  styleUrls: ['./reporting-grid-select-cell.component.scss'],
})
export class ReportingGridSelectCellComponent implements OnInit {
  constructor(public reportingGridSelectService: ReportingGridSelectService, public store: Store<fromRoot.State>) {}

  private params: any;
  allEvents: Event[];

  agInit(params: any): void {
    this.params = params;
  }

  ngOnInit() {
    this.store.pipe(select(getEvents)).subscribe(events => {
      this.allEvents = events;
    });
  }

  onClick(e) {
    e.stopPropagation();
  }

  toggleTaken(checked) {
    const event = this.params.data;
    if (checked && this.reportingGridSelectService.selectedIDs.indexOf(event.id) === -1) {
      this.reportingGridSelectService.selected = this.reportingGridSelectService.selected.concat([event]);
    } else {
      this.reportingGridSelectService.selected = this.reportingGridSelectService.selected.filter(d => d.id !== this.params.data.id);
    }
  }
}
