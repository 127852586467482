import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import * as fromRoot from '../../../app.reducer';
import { UserGetAdminAction } from '../../../user/user.actions';
import { getCurrentUser, isCurrentUserAdmin } from '../../../user/user.reducer';
import { filter, first, withLatestFrom } from 'rxjs/operators';
import { OrgGetAction } from './state/org.actions';

@Component({
  selector: 'app-org',
  templateUrl: './org.component.html',
  styleUrls: ['./org.component.scss']
})
export class OrgComponent implements OnInit {
  user;
  showCreateOrgDialog = false;

  constructor(
    public store: Store<fromRoot.State>
  ) { }

  ngOnInit() {
    this.store
      .select(isCurrentUserAdmin)
      .pipe(
        filter((isAdmin) => !!isAdmin),
        first(),
        withLatestFrom(this.store.select(getCurrentUser)),
      )
      .subscribe(([admin, user]) => {
        if (admin) {
          this.user = user;
          this.store.dispatch(new OrgGetAction());
        }
      });
  }

  toggleCreateOrgDialog() {
    this.showCreateOrgDialog = true;
  }

  onCloseDialog(closeDialog: boolean) {
    if (closeDialog) {
      this.showCreateOrgDialog = false;
    }
  }

}
