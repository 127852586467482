import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { LoadingBarService } from './loading-bar.service';

@Injectable()
export class LoadingBarInterceptor implements HttpInterceptor {
  constructor(private loadingBarService: LoadingBarService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingBarService.outstandingReqCount += 1;
    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            this.loadingBarService.outstandingReqCount -= 1;
          }
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            this.loadingBarService.outstandingReqCount -= 1;
          }
        }
      )
    );
  }
}
