import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ModelState, selectAll, selectIds } from './model.reducer';

export const getModelState = createFeatureSelector<ModelState>('model');

export const getAll = createSelector(getModelState, selectAll);

export const getIDs = createSelector(getModelState, selectIds);

export const getLoadingState = createSelector(getModelState, ({ loadingEntities }) => loadingEntities);

// todo: used by line export service... needs to go

import * as _ from 'lodash';

export const getRelatedModelsStatus2 = (modelID: number) =>
  createSelector(getModelState, (state: ModelState) => {
    let relevantModels = [];
    const model = _.find(state.entities, m => +m.id === +modelID);

    if (model) {
      relevantModels = relevantModels.concat(
        _(state.entities)
          .filter(
            m =>
              (model.masterId && +m.id === +model.masterId) ||
              (model.masterId && +m.masterId === +model.masterId) ||
              +m.masterId === +model.id ||
              +m.id === +model.id
          )
          .filter(d => String(d.status) === '2' || d.status === 'Approved')
          .value()
      );
    }

    return relevantModels;
  });
