import {
  Component,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import * as fromRoot from '../../app.reducer';
import { getCurrentUser } from '../../user/user.reducer';

import { getEvents } from '../events/state/events.selectors';
import { Observable } from 'rxjs';
import { IEvent } from '../../abstracts/event.interface';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit {
  user$: any;

  constructor(
    private router: Router,
    public store: Store<fromRoot.State>,
  ) { }

  ngOnInit() {
    this.user$ = this.store.pipe(select(getCurrentUser));
    this.user$
      .pipe(filter((user: any) => !!user.env))
      .subscribe(user => {
        if (!user.name) {
          this.router.navigate(['/login']);
        }
      });
  }
}
