import { Component, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { ModelFacadeService } from '../../model-facade.service';

@Component({
  selector: 'app-model-grid-status-cell',
  templateUrl: './model-grid-status-cell.component.html',
  styleUrls: ['./model-grid-status-cell.component.scss'],
})
export class ModelGridStatusCellComponent implements OnInit, ICellRendererAngularComp {
  params: ICellRendererParams;
  status;
  taskStatus: boolean;
  groupMaxWave: number;
  hasGroupMaxWaveVariant: boolean;

  constructor(private store: Store<any>, private modelFacadeService: ModelFacadeService) {}

  ngOnInit() {}

  agInit(params: any): void {
    this.params = params;
    if (this.params.data) {
      this.groupMaxWave = Math.max(...this.params.node.parent.allLeafChildren.map(({ data }) => data.wave));
      this.hasGroupMaxWaveVariant =
        _(this.params.data.event)
          .first()
          .variants.filter(v => v.wave === this.groupMaxWave + 1 && v.region === this.params.data.region).length > 0;
      this.status = this.params.data.status;
      if (params.data.taskStatus === true) {
        this.taskStatus = true;
      }
    } else if (this.params.node.allLeafChildren) {
      const statuses = this.params.node.allLeafChildren && this.params.node.allLeafChildren.map(node => node.data.status);
      if (statuses.indexOf('Planning') !== -1) {
        this.status = 'Planning';
      } else if (statuses.indexOf('Submitted') !== -1) {
        this.status = 'Submitted';
      } else if (statuses.indexOf('Approved') !== -1) {
        this.status = 'Approved';
      } else if (statuses.indexOf('Live') !== -1) {
        this.status = 'Live';
      } else if (statuses.indexOf('Complete') !== -1) {
        this.status = 'Complete';
      } else {
        this.status = 'Closed';
      }
      const taskStatuses = params.node.allLeafChildren.map(node => node.data.taskStatus);
      if (taskStatuses.indexOf(true) !== -1) {
        this.taskStatus = true;
      } else {
        this.taskStatus = false;
      }
    }
  }

  refresh(): boolean {
    const value = this.params.getValue();

    if (value) {
      this.status = value;
    } else if (this.params.node.allLeafChildren) {
      const statuses = this.params.node.allLeafChildren && this.params.node.allLeafChildren.map(node => node.data.status);
      if (statuses.indexOf('Planning') !== -1) {
        this.status = 'Planning';
      } else if (statuses.indexOf('Submitted') !== -1) {
        this.status = 'Submitted';
      } else if (statuses.indexOf('Approved') !== -1) {
        this.status = 'Approved';
      } else if (statuses.indexOf('Live') !== -1) {
        this.status = 'Live';
      } else if (statuses.indexOf('Complete') !== -1) {
        this.status = 'Complete';
      } else {
        this.status = 'Closed';
      }
    }

    return true;
  }

  approve(e: Event) {
    e.stopPropagation();
    this.status = 'Approved';
    if (this.params.data) {
      this.modelFacadeService.approve({ id: this.params.data.id });
    } else if (this.params.node.allLeafChildren) {
      this.params.node.allLeafChildren
        .filter(({ data }) => data.status === 'Submitted')
        .forEach(({ data }) => {
          this.modelFacadeService.approve({ id: data.id });
        });
    }
  }

  reject(e: Event) {
    e.stopPropagation();
    this.status = 'Planning';
    if (this.params.data) {
      this.modelFacadeService.retract({ id: this.params.data.id });
    } else if (this.params.node.allLeafChildren) {
      this.params.node.allLeafChildren
        .filter(({ data }) => data.status === 'Submitted' || data.status === 'Approved')
        .forEach(({ data }) => {
          this.modelFacadeService.retract({ id: data.id });
        });
    }
  }

  planNextWave(e: Event) {
    e.stopPropagation();
    const { departmentId, event, minDiscReq, name, region, wave } = this.params.data;
    const { id: eventId, variants } = _.first(event);
    const { id: variantId } = _(variants)
      .filter(v => v.wave === wave + 1 && v.region === region)
      .first();
    this.modelFacadeService.create({ departmentId, eventId, minDiscReq, name, region, variantId, wave: wave + 1 });
    this.groupMaxWave = wave + 1;
    this.hasGroupMaxWaveVariant =
      _(this.params.data.event)
        .first()
        .variants.filter(v => v.wave === this.groupMaxWave).length > 0;
  }
}
