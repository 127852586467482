import {
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import { IDiscountLaddersState } from './discount-ladders.reducer';

import * as _ from 'lodash';

export const getDiscountLaddersState = createFeatureSelector<IDiscountLaddersState>('discountLadders');

export const getDiscountLadders = createSelector(
  getDiscountLaddersState,
  (state: IDiscountLaddersState) => _.map(state.entities, (val) => val)
);

export const getDiscountLaddersIDs = createSelector(
  getDiscountLaddersState,
  (state: IDiscountLaddersState) => state.ids
);

export const getDiscountLadderByID = (id) => createSelector(
  getDiscountLaddersState,
  (state: IDiscountLaddersState) => state.entities[id]
);