import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Line } from '../../state/model';

import * as gridConfig from '../omni-model-detail/model-detail.grid-config';
import { GridReadyEvent, ColumnApi, GridApi, ColDef } from 'ag-grid-community';
import { GridLayoutsServiceService } from '../../../../infra/grid-layouts-tool-panel/grid-layouts-service.service';
import { ModelFacadeService } from '../../model-facade.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigurationSettingsService } from '../../../../user';
import { MerchHierarchyPipe } from '../../merch-hierarchy.pipe';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-omni-model-detail-grid',
  templateUrl: './omni-model-detail-grid.component.html',
  styleUrls: ['./omni-model-detail-grid.component.scss'],
})
export class OmniModelDetailGridComponent implements OnInit {
  @Input()
  lines: Line[];

  @Input()
  quickFilterText: string;

  @Input()
  filters$; // needs to go away?

  @Input()
  redrawRows$; // needs to go away too, see comment on ngOnInit

  @Input()
  regions;

  @Input()
  selectedRegion;

  @Output()
  regionSelected: EventEmitter<string | number> = new EventEmitter();

  @Output()
  filterChangedInGrid = new EventEmitter();

  gridOptions = gridConfig.gridOptions;
  sideBar = gridConfig.sideBar;
  api: GridApi;
  columnApi: ColumnApi;
  filterValueChanged: any;
  columns: ColDef[];

  componentDestroy$ = new Subject();
  invalidOverrides = [];
  constructor(
    private gridLayoutsService: GridLayoutsServiceService,
    private modelFacadeService: ModelFacadeService,
    private configurationSettingsService: ConfigurationSettingsService,
    private merchHierarchyPipe: MerchHierarchyPipe,
    private snackBarService: MatSnackBar
  ) {
    this.gridOptions.onCellValueChanged = params => {
      const colId = params.column.getId();
      if (params.oldValue !== params.newValue) {
        if (colId === 'ovrPrice') {
          const price = params.data.lastWavePrice ? params.data.lastWavePrice : params.data.currentPrice;
          if (params.newValue > price) {
            params.data.ovrPrice = params.oldValue;
            this.invalidOverrides.push(params.data.skuId);
          }
          this.modelFacadeService.getLineOverridePrediction({
            eventID: +params.data.model.eventId,
            modelID: +params.data.model.id,
            variantID: params.data.model.variantId,
            skuList: [params.data.skuId],
            override: +params.data.ovrPrice,
          });
          this.modelFacadeService
            .getLoadingStatus()
            // .pipe(takeUntil(this.componentDestroy$)) //
            .subscribe(isLoading => {
              if (isLoading) {
                params.columnApi.getColumn('loadingIcon').getColDef().cellRendererParams.editted = true;
                params.columnApi.getColumn('loadingIcon').getColDef().cellRendererParams.skuChanged = params.data.skuId;
              } else {
                params.columnApi.getColumn('loadingIcon').getColDef().cellRendererParams.editted = false;
              }
            });
        } else if (colId === 'note') {
          this.modelFacadeService.editLineNote({
            skuId: params.data.skuId,
            modelId: params.data.model.id,
            note: params.data.note,
          });
          const newValue = params.data[colId];
          if (params.api.isColumnFilterPresent()) {
            const instance: any = this.api.getFilterInstance(colId);
            const model = instance.getModel();
            const filterWasActive = model !== null;
            instance.resetFilterValues();
            if (filterWasActive) {
              if (model && model.values.indexOf(newValue) < 0) {
                model.values.push(newValue);
              }
              instance.setModel(model);
              this.api.onFilterChanged();
            }
          } else {
            params.api.destroyFilter(colId);
            params.api.onFilterChanged();
          }
        }
      }
    };
    this.gridOptions.onCellKeyDown = params => {
      if ((params.event as KeyboardEvent).key === 'Backspace') {
        let index;
        const rowSelected = params.api.getCellRanges();
        rowSelected.forEach(row => {
          const startIndex = row.startRow.rowIndex;
          const endIndex = row.endRow.rowIndex;
          index = startIndex;
          this.api.forEachNode(rows  => {
            if ((rows.rowIndex <= endIndex) && (rows.rowIndex >= startIndex)) {
                const rowNode = params.api.getDisplayedRowAtIndex(index);
                rowNode.setDataValue('ovrPrice', null);
                index++;
            }
          });
        });
      }
    };
    this.gridOptions.onPasteEnd = params => {
      if (this.invalidOverrides.length > 0) {
        const snackbarStr = `Invalid over price for SKU ID ${this.invalidOverrides}`;
        const snackBarRef = this.snackBarService.open(snackbarStr);
        snackBarRef._dismissAfter(10000);
      }
    };
  }
  ngOnInit() {
    ///////////
    // todo: this shouldn't be here, but is here because the grid can't redraw rows when new data comes in
    // because then the ovr price that the user has typed but not submitted will be cleared when the line
    // + model update comes in every few minutes. grid should diff the changes and submit them as transactions
    // but something about the state is preventing the delta mode from working as expected
    this.modelFacadeService
      .getSelectedWave()
      .pipe(takeUntil(this.componentDestroy$))
      .subscribe(() => setTimeout(() => this.api && this.api.redrawRows()));
    // tslint:disable-next-line: no-unused-expression
    this.redrawRows$.subscribe(() => setTimeout(() => this.api && this.api.redrawRows()));
    ///////////

    this.columns = gridConfig.columns(this.merchHierarchyPipe, this.configurationSettingsService.currency);
  }

  // todo: see ngOnInit getSelectedWave
  selectRegion(region: string | number) {
    this.regionSelected.next(region);
    if (this.columnApi) {
      // setTimeout because the grid columns may not have been set yet
      setTimeout(() => {
        this.api.redrawRows();
        this.api.destroyFilter('loadingIcon');
        this.api.onFilterChanged();
      });
    }
  }

  onGridReady(e?: GridReadyEvent) {
    if (e) {
      this.api = e.api;
      this.columnApi = e.columnApi;
    }
    const defaultCustomLayout = this.gridLayoutsService.getDefaultLayout();
    if (defaultCustomLayout) {
      this.columnApi.setColumnState(defaultCustomLayout.layoutColumns);
    }
  }

  filterChanged(e) {
    this.filterValueChanged = e;
    this.filterChangedInGrid.emit(e);
  }
}
