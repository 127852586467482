import { dateFormatter, thousandsRounded } from '../../../../infra/datagrid/datagrid.formatters';

import { ModelGridNavCellComponent } from '../model-grid-nav-cell/model-grid-nav-cell.component';

import { eventTypeRenderer } from '../../../events-grid/events-grid.component';

import { SideBarDef, GridOptions, RowNode } from 'ag-grid-community';
import * as _ from 'lodash';
import { RegionPipe } from '../../../../user/region.pipe';
import { ModelGridStatusCellComponent } from '../model-grid-status-cell/model-grid-status-cell.component';
import data from '../../../../../assets/json_data/default-data.json';
import orgData from '../../../../../assets/json_data/org-data.json';
import { ModelGridSelectCellComponent } from '../model-grid-select-cell/model-grid-select-cell.component';
import { EventModelStatus } from '../../../../abstracts/event-status.type';
import { ModelGridDeleteCellComponent } from '../model-grid-delete-cell/model-grid-delete-cell.component';

const sumOverAllRegions = params => {
  if (params.data && params.data.estimateSpend) {
    return thousandsRounded(1)({ value: params.data.estimateSpend / 1000 });
  } else if (params.node && params.node.allLeafChildren) {
    // tslint:disable-next-line: max-line-length
    return thousandsRounded(1)({
      value: _(params.node.allLeafChildren.map(node => node.data.estimateSpend)).reduce((acc, val) => acc + val, 0) / 1000,
    });
  }

  return thousandsRounded(1)({ value: params.value / 1000 });
};

const nodeFormatter = (hideValue, hideNode, field, formatter?) => params => {
  if (!hideValue && (params.value || params.value === 0)) {
    if (formatter) {
      return formatter(params);
    }
  }
  if (!hideNode && !params.value) {
    if (params.data) {
      return params.data.field;
    } else if (params.node && params.node.allLeafChildren) {
      const first = _.first(params.node.allLeafChildren)['data'];
      const minDate = _.first(_.sortBy(params.node.allLeafChildren.map(node => node.data), ['startDate']));
      const maxDate = _.last(_.sortBy(params.node.allLeafChildren.map(node => node.data), ['endDate']));

      if (formatter) {
        if (field == 'startDate') {
          return formatter({ ...params, value: minDate[field] });
        } else if (field == 'endDate') {
          return formatter({ ...params, value: maxDate[field] });
        } else if (field == 'lastOptimisationDate') {
          return '';
        }
        return formatter({ ...params, value: first[field] });
      }
      return first[field];
    }
  } else {
    if (
      params.data &&
      (field == 'type' || field == 'linesTaken' || field == 'eventType' || field == 'eventName' || field == 'departmentDesc')
    ) {
      return params.data.field;
    } else {
      return params.value;
    }
  }
};

export const xrenderer = params => {
  const first = _.first(params.node.allLeafChildren)['data'];

  return first.event[0].name;
};

export const columns = (regionPipe: RegionPipe) => [
         {
           headerName: '',
           cellRendererFramework: ModelGridNavCellComponent,
           suppressMenu: true,
           sortable: false,
           lockPosition: true,
           minWidth: 39,
           maxWidth: 39,
           pinned: 'left',
           suppressToolPanel: true,
         },
         {
           headerName: '',
           cellRendererFramework: ModelGridSelectCellComponent,
           suppressMenu: true,
           sortable: false,
           lockPosition: true,
           //  minWidth: 39,
           //  maxWidth: 44,
           pinned: 'left',
           suppressToolPanel: true,
         },
         {
           headerName: 'EVENT TYPE',
           field: 'eventType',
           valueFormatter: nodeFormatter(false, false, 'eventType'),
           cellRenderer: eventTypeRenderer,
           minWidth: 119,
           maxWidth: 119,
         },
         {
           headerName: 'ID',
           field: 'id',
           hide: true,
         },
         { headerName: 'EVENT NAME', field: 'eventName', valueFormatter: nodeFormatter(false, false, 'eventName') },
         {
           headerName: 'REGION',
           field: 'region',
           valueFormatter: nodeFormatter(false, true, 'region', params => regionPipe.transform(params.value)),
           rowGroup: true,
           rowGroupIndex: 3,
         },
         { headerName: 'EVENT ID', field: 'eventId', hide: true },
         // { headerName: 'MODEL NAME', field: 'name' },
         {
           headerName: 'DIVISION',
           field: 'divisionDesc',
           valueFormatter: nodeFormatter(true, false, 'divisionDesc'),
           hide: true,
           rowGroup: true,
           rowGroupIndex: 1,
         },
         {
           headerName: getHeaderName('DEPARTMENT'),
           field: 'departmentDesc',
           valueFormatter: nodeFormatter(true, false, 'departmentDesc'),
           rowGroup: true,
           rowGroupIndex: 2,
         },
         {
           headerName: 'LAST EDITED',
           field: 'lastEdit',
           valueFormatter: nodeFormatter(false, true, 'lastEdit', dateFormatter('DD/MM/YYYY k:mm')),
         },
         { headerName: 'LAST EDITED BY', field: 'lastEditUser' },
         {
           headerName: 'START DATE',
           field: 'startDate',
           valueGetter : params => {
             if (params.data) {
              const model = params.data;
              const variant = _.find(model.variant, v => +v.id === model.variantId);
              if (variant) {
                const { startDate } = variant;
                return startDate;
              }
             }
           },
           valueFormatter: nodeFormatter(false, false, 'startDate', dateFormatter('DD/MM/YYYY')),
         },
         {
           headerName: 'WAVE',
           field: 'wave',
           // valueFormatter: nodeFormatter(false, false, 'startDate', dateFormatter('DD/MM/YYYY')),
         },
         {
           headerName: 'END DATE',
           field: 'endDate',
           valueGetter: params => {
             if (params.data) {
               const model = params.data;
               const variant = _.find(model.variant, v => +v.id === model.variantId);
               if (variant) {
                 const { endDate } = variant;
                 return endDate;
               }
             }
           },
           valueFormatter: nodeFormatter(false, false, 'endDate', dateFormatter('DD/MM/YYYY')),
         },
         {
           headerName: 'LAST OPTIMISATION DATE',
           field: 'lastOptimisationDate',
           valueFormatter: nodeFormatter(false, false, 'lastOptimisationDate', dateFormatter('DD/MM/YYYY')),
         },
         { headerName: 'LINES TAKEN', field: 'linesTaken', valueFormatter: nodeFormatter(false, false, 'linesTaken') },
         // tslint:disable-next-line: max-line-length
         {
           headerName: 'SUBMITTED',
           field: 'status',
           valueFormatter: nodeFormatter(false, false, 'status', params => {
             if (!params.node) {
               return '';
             }
             return (
               (params.node.allLeafChildren &&
                 params.node.allLeafChildren.map(model => EventModelStatus[model.data.status]).filter(statusCode => statusCode === 1)
                   .length) ||
               0
             );
           }),
         },
         {
           headerName: 'APPROVED',
           field: 'status',
           valueFormatter: nodeFormatter(false, false, 'status', params => {
             if (!params.node.allLeafChildren) {
               return '';
             }
             return (
               (params.node.allLeafChildren &&
                 params.node.allLeafChildren.map(model => EventModelStatus[model.data.status]).filter(statusCode => statusCode > 1)
                   .length) ||
               0
             );
           }),
         },
         { headerName: 'ESTIMATED SPEND (K)', field: 'estimateSpend', valueFormatter: sumOverAllRegions },
         {
           headerName: 'STATUS',
           field: 'status',
           colId: 'modelStatus',
           filter: true,
           cellRendererFramework: ModelGridStatusCellComponent,
           valueGetter: params => {
             if (!params.node.allLeafChildren) {
               return params.data.status;
             }
             return params.node.allLeafChildren.map(row => row.status);
           },
           sortable: false,
           minWidth: 175,
           maxWidth: 175,
           pinned: 'right',
         },
         { headerName: 'OWNER', field: 'owner', hide: true },
         {
           headerName: 'groupID',
           field: 'groupID',
           rowGroup: true,
           enableRowGroup: true,
           cellRenderer: xrenderer,
           hide: true,
           suppressToolPanel: true,
           rowGroupIndex: 0,
         },
         {
           headerName: '',
           field: 'delete',
           colId: 'delete',
           cellRendererFramework: ModelGridDeleteCellComponent,
           minWidth: 39,
           maxWidth: 39,
           pinned: 'right',
         },
       ];

export const sideBar: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressSideButtons: true,
        suppressColumnFilter: true,
        suppressColumnSelectAll: true,
        suppressColumnExpandAll: true,
      },
    },
    // {
    //   id: 'filters',
    //   labelDefault: 'Filters',
    //   labelKey: 'filters',
    //   iconKey: 'filters',
    //   toolPanel: 'agFiltersToolPanel',
    // },
  ],
};

const defaultGroupSortComparator = (nodeA: RowNode, nodeB: RowNode) => {
  const nodeALatestEdit = Math.max(...nodeA.allLeafChildren.map(childNode => +new Date(childNode.data.lastEdit)));
  const nodeBLatestEdit = Math.max(...nodeB.allLeafChildren.map(childNode => +new Date(childNode.data.lastEdit)));
  if (nodeALatestEdit > nodeBLatestEdit) {
    return -1;
  } else if (nodeALatestEdit < nodeBLatestEdit) {
    return 1;
  }

  return 0;
};
const getHeaderName = (headerName: string) => {
  if (orgData[0].hasOwnProperty(headerName)) {
    return orgData[0][headerName];
  } else {
    return data[0][headerName];
  }
};

export const getContextMenuItems = params => {
  const menu = [
    'copy',
    'copyWithHeaders',
    'paste',
    'separator',
    {
      name: 'Export',
      subMenu: [
        {
          name: 'Excel Export (.xlsx)',
          action: function () {
            params.api.exportDataAsExcel({ sheetName: 'Sparkbox Export' });
          },
        }
      ],
      icon: `<span class ='ag-icon ag-icon-save'></span>`,
    }
  ];
  return menu;
};

export const gridOptions: GridOptions = {
  autoGroupColumnDef: {
    headerName: 'MODEL NAME',
    field: 'name',
    // cellRenderer: (params) => `*${params.value}`
  },
  deltaRowDataMode: true,
  getRowNodeId: data => data.id,
  rememberGroupStateWhenNewData: true,
  defaultGroupSortComparator: defaultGroupSortComparator,
  onGridReady: event => event.api.setSortModel([{ colId: 'lastEdit', sort: 'asc' }]),
  onRowDataChanged: params => {
    params.columnApi.autoSizeAllColumns();
  },
  onRowDataUpdated: params => {
    params.columnApi.autoSizeAllColumns();
  },
  getContextMenuItems: getContextMenuItems,
  groupDefaultExpanded: 0
};
