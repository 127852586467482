import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @Input()
  text: string;

  @Output()
  dateChosen: EventEmitter<MatDatepickerInputEvent<Date>> = new EventEmitter();

  @Input()
  dateSelected: Date;

  @Input()
  disableDefault: boolean;

  @Input()
  valid: boolean;

  @Input()
  dateFilter = () => true


  constructor() { }

  ngOnInit() {
    if (!this.disableDefault) {
      this.dateSelected = new Date();
    }
  }

  dateUpdate(date: MatDatepickerInputEvent<Date>) {
    this.dateChosen.emit(date);
    if (!date.value) {
      this.dateFilter = () => true;
    }
  }

}
