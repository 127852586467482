import * as discountLadders from './discount-ladders.actions';

import * as _ from 'lodash';
import { IDiscountLadder } from '../../discount-ladders';

export interface IDiscountLaddersState {
  entities: { [key: string]: IDiscountLadder };
  ids: any[];
  loading: boolean;
}

export const InitialState: IDiscountLaddersState = {
  entities: {},
  ids: null,
  loading: false,
};

export function reducer(state = InitialState, action: discountLadders.actions) {
  switch (action.type) {
    case discountLadders.DISCOUNTLADDERSGETALL: {
      return {
        ...state,
        loading: true
      };
    }
    case discountLadders.DISCOUNTLADDERSGETSALLSUCCESS: {
      let ids = _.map(action.payload, (entity) => entity.id);
      if (!ids) {
        ids = [];
      }
      return {
        ...state,
        ids,
        entities: _.keyBy(action.payload, 'id'),
      };
    }
    case discountLadders.DISCOUNTLADDERSDELETEACTIONSUCCESS: {
      return {
        ...state,
        ids: state.ids.filter((id) => +id === action.payload.id),
        entities: _.omit(state.entities, action.payload.id),
      };
    }
    case discountLadders.DISCOUNTLADDERSCREATESUCCESS: {
      return {
        ...state,
        ids: [...state.ids, action.payload.id],
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload
        }
      }
    }
    case discountLadders.DISCOUNTLADDERSUPDATEACTIONSUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload
        }
      };
    }
    default:
      return state;
  }
}
