import { Component, Output, EventEmitter, Input, AfterViewInit, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';

import * as _ from 'lodash';

@Component({
  selector: 'app-grid-layouts-tool-panel',
  templateUrl: './grid-layouts-tool-panel.component.html',
  styleUrls: ['./grid-layouts-tool-panel.component.scss']
})
export class GridLayoutsToolPanelComponent implements AfterViewInit, OnChanges {
  firstChange = true;
  doNotUpdate: boolean;
  newLayoutName;
  recommendedIsDefault;
  nameForRecommendedView = 'Standard';
  doNotUpdateTwice = false;

  selectedViewName;
  hasSelectedViewChanged;

  @Output()
  layoutDelete = new EventEmitter();

  @Output()
  layoutSetAsDefault = new EventEmitter();

  @Output()
  layoutSave = new EventEmitter();

  @Output()
  layoutUpdate = new EventEmitter();

  @Output()
  setLayout = new EventEmitter();

  @Input()
  layouts;

  @Input()
  columnsChanged$: Subject<any>;

  constructor() { }

  ngOnChanges(changes) {
    if (changes.layouts && changes.layouts.currentValue) {
      const defaultLayout = _.find(changes.layouts.currentValue, (layout) => layout.isDefault);
      if (!defaultLayout) {
        this.recommendedIsDefault = true;
        this.doNotUpdateTwice = true;
        if (this.firstChange) {
          this.selectedViewName = this.nameForRecommendedView;
        }
      } else {
        this.recommendedIsDefault = false;
        if (this.firstChange) {
          this.selectedViewName = defaultLayout.layoutName;
        }
      }
      if (!changes.layouts.firstChange) {
        this.firstChange = false;
      }
    }
  }

  ngAfterViewInit() {
    this.columnsChanged$.subscribe(() => {
      if (this.doNotUpdate) {
        if (this.doNotUpdateTwice) {
          this.doNotUpdateTwice = !this.doNotUpdateTwice;
        } else {
          this.doNotUpdate = !this.doNotUpdate;
        }
      } else {
        this.hasSelectedViewChanged = true;
      }
    });
  }

  updateLayout(layout) {
    this.layoutUpdate.emit({
      layout
    });

    this.hasSelectedViewChanged = false;
  }

  saveLayout(setAsDefault) {
    if (setAsDefault) {
      this.recommendedIsDefault = false;
    }
    this.layoutSave.emit({
      setAsDefault,
      name: this.newLayoutName,
    });
    this.newLayoutName = '';
  }

  setColumnState(layout?) {
    this.hasSelectedViewChanged = false;
    this.doNotUpdate = true;
    this.setLayout.emit({ layoutColumns: layout && layout.layoutColumns });
    if (!layout) {
      this.selectedViewName = this.nameForRecommendedView;
    } else {
      this.selectedViewName = layout.layoutName;
    }
  }

  deleteLayout(layoutName) {
    this.layoutDelete.emit({ layoutName });
  }

  setLayoutAsDefault(layout?) {
    this.layoutSetAsDefault.emit({ layout });

    if (!layout) {
      this.recommendedIsDefault = true;
    } else {
      this.recommendedIsDefault = false;
    }
  }

  isDefaultLayoutChanged(e, layout?) {
    if (layout && layout.isDefault) {
      this.layoutSetAsDefault.emit();
    } else {
      this.layoutSetAsDefault.emit({ layout });
    }
  }

  onNewLayoutNameKeydown(e) {
    if (e.key === 'Enter') {
      this.saveLayout(false);
    }
  }
}
