import { Injectable } from '@angular/core';
import { Observable, Subject, of, fromEvent, merge } from 'rxjs';
import { takeUntil, mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InternetConnectionService {
  private httpInternetError$ = new Subject();

  getHttpInternetError() {
    return this.httpInternetError$.asObservable();
  }
  getInternetError(): Observable<boolean> {
    const offline$ = fromEvent(window, 'offline').pipe(mapTo(false));
    const online$ = fromEvent(window, 'online').pipe(mapTo(true));
    return merge(
      offline$, online$
    );
  }
  setHttpInternetError(online) {
    this.httpInternetError$.next(online);
  }
  constructor() {}
}
