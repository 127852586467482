import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingBarService {
  outstandingReqCount = 0;

  constructor() {}
}
