import { Injectable } from '@angular/core';
import { tap, retryWhen } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { InternetConnectionService } from './internet-connection.service';

@Injectable()
export class InternetConnectionInterceptor implements HttpInterceptor {
  constructor(private internetConnectionService: InternetConnectionService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
        tap(
          (event) => { },
          (error: any) => {
            if (error instanceof HttpErrorResponse) {
              if (!navigator.onLine) {
                this.internetConnectionService.setHttpInternetError('true');
              }
            }
            return of(error);
          }
        ),
    );
  }
}
