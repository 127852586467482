import { Component, OnInit, Input } from '@angular/core';

import { ColDef, GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';

import { LiveReportGridRow } from '../reporting-live/reporting-live.service';
import {
  currencyFormatter,
  percentDecimalFormatter,
  thousandsFormatter,
  roundingFormatter,
} from '../../../infra/datagrid/datagrid.formatters';

const removeCentsFormatter = roundingFormatter(0);
const euroFormatter = currencyFormatter('€');
const noCentsEuroFormatter = params => euroFormatter({ value: removeCentsFormatter(params) }).replace(/\.00/, '');
@Component({
  selector: 'app-reporting-live-grid',
  templateUrl: './reporting-live-grid.component.html',
  styleUrls: ['./reporting-live-grid.component.scss'],
})
export class ReportingLiveGridComponent implements OnInit {
  @Input()
  data: LiveReportGridRow[];

  @Input()
  group: boolean;

  @Input()
  tyly: boolean;

  @Input()
  lfl: boolean;

  @Input()
  forecast: boolean;

  columnDefs: ColDef[] = [
    { headerName: '', field: 'aggLevel', width: 60 },
    {
      headerName: 'Sales €',
      field: 'sales',
      valueFormatter: params => (this.tyly ? percentDecimalFormatter(params) : noCentsEuroFormatter(params)),
      width: 104,
      suppressMenu: true,
    },
    {
      headerName: 'Margin €',
      field: 'margin',
      valueFormatter: params => (this.tyly ? percentDecimalFormatter(params) : noCentsEuroFormatter(params)),
      width: 104,
      suppressMenu: true,
    },
    { headerName: 'Margin Rate',
      field: 'marginRate',
      valueFormatter: percentDecimalFormatter,
      width: 68,
      suppressMenu: true,
    },
    {
      headerName: 'Unit Sales',
      field: 'unitSales',
      valueFormatter: params => (this.tyly ? percentDecimalFormatter(params) : thousandsFormatter(params)),
      width: 104,
      suppressMenu: true,
    },
    {
      headerName: 'Sales Mix (Units)',
      field: 'salesMix',
      valueFormatter: params => (params.value ? percentDecimalFormatter(params) : ''),
      width: 81,
      suppressMenu: true,
    },
    { headerName: 'Discount Rate',
      field: 'discountRate',
      valueFormatter: percentDecimalFormatter,
      width: 76,
      suppressMenu: true,
    },
    {
      headerName: 'Starting Stock',
      field: 'startingStock',
      valueFormatter: params => (this.tyly ? percentDecimalFormatter(params) : thousandsFormatter(params)),
      width: 104,
      suppressMenu: true,
    },
    {
      headerName: 'Ending Stock',
      field: 'endingStock',
      valueFormatter: params => (this.tyly ? percentDecimalFormatter(params) : thousandsFormatter(params)),
      width: 104,
      suppressMenu: true,
    },
  ];

  gridOptions: GridOptions = {
    headerHeight: 48,
  };

  constructor() {}

  ngOnInit() {
    if (this.group) {
      this.columnDefs.push({
        headerName: '',
        field: 'grouping',
        width: 104,
        suppressMenu: true,
        rowGroup: true,
        hide: true,
      });
      this.gridOptions.groupDefaultExpanded = 1;
    }
    if (this.forecast) {
      _.find(this.columnDefs, colDef => colDef.field === 'endingStock').headerName = 'Forecasted Ending Stock';
      _.find(this.columnDefs, colDef => colDef.field === 'startingStock').headerName = 'Forecasted Starting Stock';
    }

    this.columnDefs.forEach(colDef => {
      colDef.headerTooltip = this.getHeaderToolTip(colDef.field);
    });
  }

  getHeaderToolTip(field) {
    if (field === 'sales') {
      if (this.tyly) {
        if (this.lfl) {
          return `% change in sales € vs last year in the same stores like for like`;
        } else {
          return `% change in sales € vs last year`;
        }
      } else {
        return `Sales €`;
      }
    }
    if (field === 'margin') {
      if (this.tyly) {
        if (this.lfl) {
          return `% change in margin € vs last year in the same stores like for like`;
        } else {
          return `% change in margin € vs last year`;
        }
      } else {
        return `Margin €`;
      }
    }
    if (field === 'marginRate') {
      if (this.tyly) {
        if (this.lfl) {
          return `% difference in average margin percentage achieved vs last year in the same stores like for like`;
        } else {
          return `% difference in average margin percentage achieved vs last year`;
        }
      } else {
        return `Margin Percentage`;
      }
    }
    if (field === 'unitSales') {
      if (this.tyly) {
        if (this.lfl) {
          return `% change in unit sales vs last year in the same stores like for like`;
        } else {
          return `% change in unit sales vs last year`;
        }
      } else {
        return `Unit Sales`;
      }
    }
    if (field === 'salesMix') {
      if (this.tyly) {
        if (this.lfl) {
          return `% difference in sales distribution for each years corresponding collection during the time period
          vs last year in the same stores like for like`;
        } else {
          return `% difference in sales distribution for each years corresponding collection during the time period vs last year`;
        }
      } else {
        return `Percentage of sales for each collection during the period`;
      }
    }
    if (field === 'discountRate') {
      if (this.tyly) {
        if (this.lfl) {
          return `% difference in the average discount applied to the group of
          SKUs for the specified time period in the same stores like for like`;
        } else {
          return `% difference in the average discount applied to the group of SKUs for the specified time period`;
        }
      } else {
        return `Average discount applied to the group of SKUs for the specified time period`;
      }
    }
    if (field === 'startingStock') {
      if (this.tyly) {
        if (this.lfl) {
          return `% change in stock units at the start of the time period vs last year in the same stores like for like`;
        } else {
          return `% change in stock units at the start of the time period vs last year`;
        }
      } else {
        return `Stock units for the season at the start of the specified period`;
      }
    }
    if (field === 'endingStock') {
      if (this.tyly) {
        if (this.lfl) {
          return `% change in stock units at the end of the time period vs last year in the same stores like for like`;
        } else {
          return `% change in stock units at the end of the time period vs last year`;
        }
      } else {
        return `Stock units for the season at the end of the specified period`;
      }
    }
  }
}
