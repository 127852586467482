import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import { first, map, delay, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';

import { ModelDetailBulkSkuDialogComponent } from '../model-detail-bulk-sku-dialog/model-detail-bulk-sku-dialog.component';
import { Line, Model } from '../../state/model';
import { ModelFacadeService } from '../../model-facade.service';
import { isNotLocked } from '../../utilities/isNotLocked';

@Component({
  selector: 'app-model-detail-quick-selection',
  templateUrl: './model-detail-quick-selection.component.html',
  styleUrls: ['./model-detail-quick-selection.component.scss'],
})
export class ModelDetailQuickSelectionComponent implements OnInit, OnChanges {
  public quickSelectionForm: FormGroup;

  @Output()
  clickTitle = new EventEmitter();

  @Input()
  lines: Array<Line & { model: Model & { relatedModels: Model[] } }>;

  @Input()
  show: boolean;

  untakenLines = [];
  collections = [];
  seasons = [];
  priceChangedLines = [];
  takenPriceUnchangedLines = [];
  showAge = false;
  showAvgCover = false;
  showMarkdownCount = false;
  showSellthrough = false;
  showCollection = false;
  showOptDiscount = false;
  showSeason = false;
  ageHighValue;
  ageValue;
  coverHighValue;
  coverValue;
  markdownCountValue;
  markdownCountHighValue;
  sellthroughValue;
  sellthroughHighValue;
  optDiscountValue;
  optDiscountHighValue;

  filterQueue: Array<{ property: string; function: any; display: string }> = [];
  planningStatus;
  disableSelectionButtons$: Observable<boolean>;
  models$: Observable<Model[]>;
  regions$: Observable<Array<string | number>>;
  regionSelected$: Observable<string | number>;
  linesToBeTaken$: Observable<Line[]>;
  selectedRegion$: Observable<boolean>;

  regionWavesForCopy$: Observable<{ regions: Array<string | number>; regionWaves: { [key: string]: number[] } }>;

  constructor(private dialog: MatDialog, private modelFacadeService: ModelFacadeService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lines) {
      const changedLines = changes.lines.currentValue;
      this.untakenLines = changedLines.filter(d => d.taken === 'No' && isNotLocked(d));
      this.priceChangedLines = changedLines.filter(d =>
        (d.ovrPrice && d.ovrPrice !== (d.lastWavePrice ? d.lastWavePrice : d.currentPrice)) ||
        (!d.ovrPrice && d.optPrice !== (d.lastWavePrice ? d.lastWavePrice : d.currentPrice))
      );
      this.takenPriceUnchangedLines = changedLines.filter((d: Line) =>
        (d.taken === 'Yes' && d.ovrPrice && d.ovrPrice === (d.lastWavePrice ? d.lastWavePrice : d.currentPrice)) ||
        (!d.ovrPrice && d.optPrice === (d.lastWavePrice ? d.lastWavePrice : d.currentPrice))
      );
    }
  }

  ngOnInit() {
    if (this.lines) {
      this.untakenLines = this.lines.filter(d => d.taken === 'No' && isNotLocked(d));
      this.collections = _.sortBy(_.uniq(this.lines.map((d: Line) => d.collectionDesc)));
      this.seasons = _.sortBy(_.uniq(this.lines.map((d: Line) => d.season)));
      this.priceChangedLines = this.lines.filter(d =>
          (d.ovrPrice && d.ovrPrice !== (d.lastWavePrice ? d.lastWavePrice : d.currentPrice)) ||
          (!d.ovrPrice && d.optPrice !== (d.lastWavePrice ? d.lastWavePrice : d.currentPrice))
      );
      this.takenPriceUnchangedLines = this.lines.filter((d: Line) =>
          (d.taken === 'Yes' && d.ovrPrice && d.ovrPrice === (d.lastWavePrice ? d.lastWavePrice : d.currentPrice)) ||
          (!d.ovrPrice && d.optPrice === (d.lastWavePrice ? d.lastWavePrice : d.currentPrice))
      );
    }
    this.models$ = this.modelFacadeService.getSelectedModels();

    this.regions$ = this.models$.pipe(
      distinctUntilChanged(),
      map(models =>
        _(models)
          .map(model => model.region)
          .uniq()
          .value()
      )
    );

    this.regionWavesForCopy$ = this.models$.pipe(
      map(models => {
        const regions = _(models)
          .map(model => model.region)
          .uniq()
          .value();
        const regionWaves = _(regions)
          .keyBy()
          .mapValues(region =>
            _(models)
              .filter(model => model.region === region)
              .map(model => model.wave)
              .uniq()
              .value()
          )
          .value();

        return {
          regions,
          regionWaves,
        };
      })
    );

    this.regionSelected$ = this.modelFacadeService.getSelectedRegion();

    this.selectedRegion$ = this.regions$.pipe(
      withLatestFrom(this.regionSelected$),
      map(([regions, regionSelected]) => {
        if (_(regions).includes(regionSelected) || regionSelected === 'overview') {
          return true;
        }
        return false;
      })
    );

    this.disableSelectionButtons$ = this.modelFacadeService.getSelectedModelsRegion().pipe(
      map(models => {
        this.planningStatus =
          _(models)
            .map((model: Model) => model.status)
            .indexOf('Planning') > -1;
        return !this.planningStatus;
      })
    );

    this.quickSelectionForm = new FormGroup({ collectionControl: new FormControl(''),
    seasonControl: new FormControl(''), });
  }

  newApply() {
    const lines = _(this.filterQueue.reduce((acc, filter) => acc.filter(filter.function), this.untakenLines)).value();

    this.modelFacadeService.takeUntakeLines({
      lines,
      taken: 1,
    });

    this.filterQueue = [];
  }

  onClickTitle() {
    this.clickTitle.emit();
  }

  private getFromQueue(property: string) {
    return _.find(this.filterQueue, d => d.property === property);
  }

  private setToQueue({ property, fn, display }) {
    const queueMember = this.getFromQueue(property);
    if (queueMember) {
      queueMember.function = fn;
      queueMember.display = display;
    } else {
      this.filterQueue.push({
        function: fn,
        display,
        property,
      });
    }
  }

  onCollectionChange(e) {
    this.setToQueue({
      property: 'collectionDesc',
      fn: d => e.value.indexOf(d.collectionDesc) !== -1,
      display: `Collection: ${e.value.join(',')}`,
    });
  }

  toggleShowCollection() {
    this.showCollection = !this.showCollection;
  }

  toggleShowMarkdownCount() {
    this.showMarkdownCount = !this.showMarkdownCount;
  }

  toggleShowAge() {
    this.showAge = !this.showAge;
  }

  toggleShowAvgCover() {
    this.showAvgCover = !this.showAvgCover;
  }

  toggleShowSellthrough() {
    this.showSellthrough = !this.showSellthrough;
  }

  toggleShowOptDiscount() {
    this.showOptDiscount = !this.showOptDiscount;
  }

  onSellthroughHighValueChange(e) {
    this.sellthroughHighValue = e;
    const property = 'currentSt';
    const display = `Sell through: ${this.sellthroughValue} - ${this.sellthroughHighValue}`;
    const fn = (d: Line) => d[property] <= this.sellthroughHighValue && d[property] >= this.sellthroughValue;

    this.setToQueue({
      display,
      property,
      fn,
    });
  }

  onSellthroughValueChange(e) {
    this.sellthroughValue = e;
    const property = 'currentSt';
    const display = `Sell through: ${this.sellthroughValue} - ${this.sellthroughHighValue}`;
    const fn = (d: Line) => d[property] <= this.sellthroughHighValue && d[property] >= this.sellthroughValue;

    this.setToQueue({
      display,
      property,
      fn,
    });
  }

  onMarkdownCountHighValueChange(e) {
    this.markdownCountHighValue = e;
    const property = 'mdCount';
    const display = `Markdown Count: ${this.markdownCountValue} - ${this.markdownCountHighValue}`;
    const fn = (d: Line) => d.mdCount <= this.markdownCountHighValue && d.mdCount >= this.markdownCountValue;

    this.setToQueue({
      display,
      property,
      fn,
    });
  }

  onMarkdownCountValueChange(e) {
    this.markdownCountValue = e;
    const property = 'mdCount';
    const display = `Markdown Count: ${this.markdownCountValue} - ${this.markdownCountHighValue}`;
    const fn = (d: Line) => d.mdCount <= this.markdownCountHighValue && d.mdCount >= this.markdownCountValue;

    this.setToQueue({
      display,
      property,
      fn,
    });
  }

  onAgeHighValueChange(e) {
    this.ageHighValue = e;
    const property = 'age';
    const display = `Age: ${this.ageValue} - ${this.ageHighValue}`;
    const fn = (d: Line) => d.age <= this.ageHighValue && d.age >= this.ageValue;

    this.setToQueue({
      display,
      property,
      fn,
    });
  }
  onAgeValueChange(e) {
    this.ageValue = e;
    const property = 'age';
    const display = `Age: ${this.ageValue} - ${this.ageHighValue}`;
    const fn = (d: Line) => d.age <= this.ageHighValue && d.age >= this.ageValue;

    this.setToQueue({
      display,
      property,
      fn,
    });
  }

  onCoverHighValueChange(e) {
    this.coverHighValue = e;
    const property = 'cover';
    const display = `Cover: ${this.coverValue} - ${+this.coverHighValue < 99 ? this.coverHighValue : this.coverHighValue + '+'}`;
    let fn = (d: Line) => +d.avgCover <= +this.coverHighValue && +d.avgCover >= +this.coverValue;
    if (+this.coverHighValue === 99) {
      fn = (d: Line) => d.lwCover >= this.coverValue;
    }

    this.setToQueue({
      display,
      property,
      fn,
    });
  }

  onCoverValueChange(e) {
    this.coverValue = e;
    const property = 'cover';
    const display = `Cover: ${this.coverValue} - ${+this.coverHighValue < 99 ? this.coverHighValue : this.coverHighValue + '+'}`;
    let fn = (d: Line) => d.lwCover <= this.coverHighValue && d.lwCover >= this.coverValue;
    if (+this.coverHighValue === 99) {
      fn = (d: Line) => d.lwCover >= this.coverValue;
    }

    this.setToQueue({
      display,
      property,
      fn,
    });
  }

  takeAll() {
    if (window.confirm('Are you sure you want to take all?')) {
      this.modelFacadeService.takeUntakeLines({
        lines: this.untakenLines,
        taken: 1,
      });
    }
  }

  untakeAll() {
    if (window.confirm('Are you sure you want to untake all?')) {
      const lines = _(this.lines)
        .filter(d => d.taken === 'Yes' && isNotLocked(d))
        .value();

      this.modelFacadeService.takeUntakeLines({
        lines,
        taken: 0,
      });
    }
  }

  takeCustom() {
    const dialogRef = this.dialog.open(ModelDetailBulkSkuDialogComponent, {
      width: '59vw',
      data: { lines: this.lines },
    });
  }

  onOptDiscountHighValueChange(e) {
    this.optDiscountHighValue = e;
    const property = 'optDisc';
    const display = `Opt Discount: ${this.optDiscountValue} - ${this.optDiscountHighValue}`;
    const fn = (d: Line) => d.optDisc <= this.optDiscountHighValue && d.optDisc >= this.optDiscountValue;

    this.setToQueue({
      display,
      property,
      fn,
    });
  }

  onOptDiscountValueChange(e) {
    this.optDiscountValue = e;
    const property = 'optDisc';
    const display = `Opt Discount: ${this.optDiscountValue} - ${this.optDiscountHighValue}`;
    const fn = (d: Line) => d.optDisc <= this.optDiscountHighValue && d.optDisc >= this.optDiscountValue;

    this.setToQueue({
      display,
      property,
      fn,
    });
  }

  takeAllWithPriceChange() {
    if (window.confirm('Are you sure you want to take all with price change?')) {
      this.takeUntakeBulkLines(this.takenPriceUnchangedLines, 0);
      this.takeUntakeBulkLines(this.priceChangedLines, 1);
    }
  }

  takeUntakeBulkLines(lines: Array<Line>, taken: 1 | 0) {
    this.modelFacadeService.takeUntakeLines({
      lines: lines,
      taken: taken,
    });
  }

  clearFilterQueue(condition) {
    this.filterQueue = this.filterQueue.filter(queue => queue.property !== condition.property);
    if (condition.property === 'collectionDesc') {
      this.quickSelectionForm.controls.collectionControl.patchValue([]);
    }
    if (condition.property === 'season') {
      this.quickSelectionForm.controls.seasonControl.patchValue([]);
    }
  }

  copyFromRegionWave(region: string | number, regionWave: number) {
    if (window.confirm('Are you sure you want to copy all taken lines from region to the existing taken lines?')) {
      this.models$.pipe(first()).subscribe(models => {
        const skusToTake = _(models)
          .filter(model => model.region === region && model.wave === regionWave)
          .map(model => model.lines)
          .flatten()
          .filter(line => line.taken === 'Yes')
          .map(({ skuId }) => skuId)
          .value();
        const linesToTake: Line[] = this.lines.filter(line => _(skusToTake).includes(line.skuId));
        this.modelFacadeService.takeUntakeLines({
          lines: linesToTake,
          taken: 1,
        });
      });
    }
  }

  onPriceChangeSelection() {
    this.setToQueue({
      property: 'priceChange',
      fn: (d: Line) =>
        (d.ovrPrice && d.ovrPrice !== (d.lastWavePrice ? d.lastWavePrice : d.currentPrice)) ||
        (!d.ovrPrice && d.optPrice && d.optPrice !== (d.lastWavePrice ? d.lastWavePrice : d.currentPrice)),
      display: `Price Change`,
    });
  }

  toggleShowSeason() {
    this.showSeason = !this.showSeason;
  }

  onSeasonChange(e) {
    this.setToQueue({
      property: 'season',
      fn: d => e.value.indexOf(d.season) !== -1,
      display: `Season: ${e.value.join(',')}`,
    });
  }
}
