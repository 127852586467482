import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as moment from 'moment';
import { EventDetails } from '../../event';
import { isStartDateBeforeEndDate } from '../../../../infra/customValidators';

const DEFAULT_EVENT_DETAIL = 'End Season';
const DEFAULT_EVENT_TYPE = 'Markdown';

@Component({
  selector: 'app-event-create-edit-stepper-event-form',
  templateUrl: './event-create-edit-stepper-event-form.component.html',
  styleUrls: ['./event-create-edit-stepper-event-form.component.scss'],
})
export class EventCreateEditStepperEventFormComponent implements OnInit {
  newEventForm: FormGroup;

  @Input()
  eventDetails: EventDetails;

  @Output()
  eventDetailsChanged: EventEmitter<{ name; detail; type; startDate; endDate; note }> = new EventEmitter();

  @Output()
  nextClicked = new EventEmitter();

  @Output()
  saveClicked = new EventEmitter();

  eventDateFilter;

  constructor() {}

  ngOnInit() {
    let name = '';
    let startDate = new Date();
    let endDate = new Date();
    let note = '';
    const todaysDate = new Date();

    this.eventDateFilter = (date: Date) =>
      date >= moment(todaysDate).subtract(1, 'days').toDate() &&
      date <= moment(todaysDate).add(13, 'weeks').toDate();


    if (this.eventDetails) {
      name = this.eventDetails.name;
      startDate = moment(this.eventDetails.startDate).toDate();
      endDate = moment(this.eventDetails.endDate).toDate();
      note = this.eventDetails.note;
    }

    this.newEventForm = new FormGroup(
      {
        name: new FormControl(name, [Validators.required, Validators.maxLength(255), Validators.pattern(/^[\x00-\x7F]+$/)]),
        detail: new FormControl(DEFAULT_EVENT_DETAIL, [Validators.required]),
        type: new FormControl(DEFAULT_EVENT_TYPE, []),
        startDate: new FormControl({value : startDate, 
        disabled: moment(startDate).toDate() <= moment(todaysDate).subtract(1, 'days').toDate()
        },[Validators.required]),
        endDate: new FormControl(endDate, [Validators.required]),
        note: new FormControl(note, [Validators.pattern(/^[\x00-\x7F]+$/)]),
      },
      [isStartDateBeforeEndDate]
    );

    // todo: this shouldn't be called onClick
    this.newEventForm.valueChanges.subscribe(() =>
      this.eventDetailsChanged.emit({
        name: this.newEventForm.value['name'],
        detail: this.newEventForm.value['detail'],
        type: this.newEventForm.value['type'],
        startDate: this.newEventForm.value['startDate'],
        endDate: this.newEventForm.value['endDate'],
        note: this.newEventForm.value['note'],
      })
    );
  }

  // should be in a base class?
  public getErrorMessage(field: string) {
    const errors = this.newEventForm.controls[field].errors;
    if (errors['required']) {
      return 'Required field';
    }
    if (errors['notBetween0and100']) {
      return 'Must be between 0 and 100%';
    }
    if (errors['pattern'] && errors['pattern'].requiredPattern === '/^[\x00-\x7F]+$/') {
      return 'Must not contain special characters';
    }
    if (errors['maxDiscLessThanMinDisc'] && field === 'minDisc') {
      return 'Must be less than Max Disc';
    }
    if (errors['maxDiscLessThanMinDisc'] && field === 'maxDisc') {
      return 'Must be greater than Max Disc';
    }
    if (errors['startDateAfterEndDate'] && field === 'startDate') {
      return 'Must be before end date';
    }
    if (errors['startDateAfterEndDate'] && field === 'endDate') {
      return 'Must be after start date';
    }
    if (errors['maxlength']) {
      return 'Must be fewer than 255 characters';
    }
  }

  onClickSave() {
    this.saveClicked.emit();
  }

  onClick() {
    this.nextClicked.emit({
      name: this.newEventForm.value['name'],
      detail: this.newEventForm.value['detail'],
      type: this.newEventForm.value['type'],
      startDate: this.newEventForm.value['startDate'],
      endDate: this.newEventForm.value['endDate'],
      note: this.newEventForm.value['note'],
    });
  }
}
