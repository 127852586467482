import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { from } from 'rxjs';

declare var firebase;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token;

  constructor(
    private cookieService: CookieService,
  ) {
    firebase.auth();
    const sbxUserCookie = this.cookieService.get('sbx-user');
    if (sbxUserCookie) {
      this.token = sbxUserCookie;
    }
  }

  getToken() {
    const currentUser = firebase.auth().currentUser;
    let promise;
    if (currentUser) {
      promise = firebase.auth().currentUser.getIdToken();
    } else {
      promise = new Promise((resolve, reject) => {
        resolve(this.token);
      });
    }

    return from(promise);
  }

//   getToken() {
//     console.log(firebase);
//     return 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjVlOWVlOTdjODQwZjk3ZTAyNTM2ODhhM2I3ZTk0NDczZTUyOGE3YjUiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vc3Bhcmtib3gtZGV2IiwiYXVkIjoic3Bhcmtib3gtZGV2IiwiYXV0aF90aW1lIjoxNTg4MTEyMjIzLCJ1c2VyX2lkIjoiWWtNM2dLYUk1VGhLQWxuZG5oQWF6MW1zNmFEMyIsInN1YiI6IllrTTNnS2FJNVRoS0FsbmRuaEFhejFtczZhRDMiLCJpYXQiOjE1ODgyMTc1MTQsImV4cCI6MTU4ODIyMTExNCwiZW1haWwiOiJidXRsZXIuYy5hbmRyZXcrdGVzdG9yZ0BnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsiYnV0bGVyLmMuYW5kcmV3K3Rlc3RvcmdAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.rFgwhUpetJcM4nHoEtDMDiz_QCmVhkKg0sBXh7zSDklWkuwt8mg5fKhrEBxQw-UOgi0h2HRxv4B4Nkj5Y2t-SCLSeMjfKiJ7HuVIUT2YcUkSr7o0ChjYnuzHLVEwzqQHsOnd8hfMgsCsot1ZhNeFHXYjEWqkE6hLD5wJ0yJeWLBZdzu7yX619EbJO2rSZWiQtmcUMH8C_OjvSzdMekTRXMX3_-CGQmPjbKtgY8qL6zLx5I3FIddT3md6yw-57A9MDxSTfk8nvUlWYWV5phhYxKLBOIjuCMi2XTExYr81ujwY4-SBrlbuTAMmz0AhlUuyHJ0NLAKrlQDMeFmwiblARA';
// //
// //    return this.token;
//   }

  setToken(token) {
    this.token = token;
    this.cookieService.set('sbx-user', token);
  }

  signOut() {
    firebase.auth().signOut().then(() => {
      this.cookieService.delete('sbx-user');
      window.location.assign(`//${window.location.host}`);
    }, function (error) {
      console.error('Sign Out Error', error);
    });
  }
}
