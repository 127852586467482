import { Component, OnInit } from '@angular/core';

import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../../../app.reducer';
import { getSelectedOrg } from '../state/org.selectors';
import { Observable } from 'rxjs';
import { IOrg } from '../state/org.reducer';
import { ActivatedRoute } from '@angular/router';
import { OrgSelectAction } from '../state/org.actions';

import {
  AdminPanelDisableUserCellComponent,
} from '../../../admin-panel/admin-panel-disable-user-cell/admin-panel-disable-user-cell.component';

import { getRowClassDisabledUser } from '../../../admin-panel/admin-panel.component';
import { IUser } from '../../../../user/user';

@Component({
  selector: 'app-org-detail',
  templateUrl: './org-detail.component.html',
  styleUrls: ['./org-detail.component.scss']
})
export class OrgDetailComponent implements OnInit {
  selectedOrg$: Observable<IOrg>;

  showCreateUserDialog = false;
  showUpdateUserDialog = false;
  selectedUser: IUser;

  columns = [
    {
      headerName: '',
      cellRendererFramework: AdminPanelDisableUserCellComponent,
      suppressMenu: true,
      sortable: false,
    },
    { headerName: 'EMAIL', field: 'email' },
    { headerName: 'ROLES', field: 'role' },
  ];

  gridOptions = {
    getRowClass: getRowClassDisabledUser
  };

  constructor(
    public store: Store<fromRoot.State>,
    private route: ActivatedRoute
  ) {
    route.params.subscribe((params) => {
      this.store.dispatch(new OrgSelectAction(params.id));
    });
  }

  ngOnInit() {
    this.selectedOrg$ = this.store.pipe(select(getSelectedOrg));
  }

  showAddUserDialog() {
    this.showCreateUserDialog = true;
  }

  onCloseDialog(closeDialog: boolean) {
    if (closeDialog) {
      this.showCreateUserDialog = false;
    }
  }

  onUpdateCloseDialog(closeDialog: boolean) {
    if (closeDialog) {
      this.showUpdateUserDialog = false;
    }
  }

  onRowClick(event) {
    this.showCreateUserDialog = false;
    this.showUpdateUserDialog = false;
    setTimeout(() => this.showUpdateUserDialog = true, 0);
    this.selectedUser = event.data;
  }

}
