import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { Router } from '@angular/router';

import * as fromRoot from '../../app.reducer';
import { getCurrentUser } from '../../user/user.reducer';
import { Observable, Subject, of } from 'rxjs';
import { filter, switchMap, map, first } from 'rxjs/operators';
import { EventsFacadeService } from '../events/events-facade.service';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { Chart } from 'chart.js';

import { startOfDay, endOfDay, addDays } from 'date-fns';

import * as moment from 'moment';
import * as _ from 'lodash';
import { EventStatsResponse } from '../events/events.service';
import { ConfigurationSettingsService } from '../../user/configuration-settings.service';
import { ModelFacadeService } from '../model/model-facade.service';
import { IEvent } from '../../abstracts/event.interface';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  user$: Observable<any>;
  events$: Observable<any>;

  eventSelect$ = new Subject();

  calendarEvents$;
  models$: Observable<any>;

  ////
  showModels;
  selectedEvent;
  modelsStats$: Observable<{ count: any; stats: any }>;
  eventModelsStats$: Observable<{ count: any; stats: any }>;
  selectedModels = [];
  eventStats$: Observable<EventStatsResponse>;
  //

  constructor(
    public store: Store<fromRoot.State>,
    private router: Router,
    private eventsFacadeService: EventsFacadeService,
    private modelFacadeService: ModelFacadeService,
    public configurationSettingsService: ConfigurationSettingsService
  ) {}

  options = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    header: {
      left: 'prev,title,next',
      center: '',
      right: 'month,basicWeek,list',
    },
    height: () => {
      return document.getElementById('calendar').clientHeight - 35;
    },
    selectable: true,
    // select: this.onDateClick,
    eventClick: ({ event }) => this.onGridEventClicked(event._def.extendedProps.data),
    toolbar: {
      buttonText: {
        list: 'day',
      },
    },
    firstDay: 1, // SBX-537
  };

  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit() {
    setTimeout(() => {
      const ctx = document.getElementById('summary-chart');
      const data = {
        datasets: [
          {
            data: [10, 20, 30],
          },
        ],

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: [],
      };
      const myDoughnutChart = new Chart(ctx, {
        type: 'doughnut',
        data: data,
        // options: options
      });
    }, 1000);
  }

  onGridEventClicked(event) {
    this.eventSelect$.next(event);
    this.selectedEvent = event; // replace by eventSelect$ | async
    this.showModels = true;
    this.eventStats$ = this.eventsFacadeService.getEventStatistics({ eventID: event.id }).pipe(
      filter(d => !!d),
      first()
    );
  }

  onShowEventsClicked() {
    this.eventSelect$.next({ id: null });
  }

  editSelectedModels() {
    this.modelFacadeService.selectModels(this.selectedModels);
  }

  onShowEventReportsClicked(event: IEvent) {
    this.router.navigate(['/reporting', 'md', event.id, 'collectionAW']);
  }

  ngOnInit() {
    this.user$ = this.store.select(getCurrentUser);
    this.user$.pipe(filter(user => !!user.env)).subscribe(user => {
      // if (!user.name) {
      //   this.router.navigate(['/login']);
      // }
    });

    this.events$ = this.eventsFacadeService.getEventsWithModelCounts();

    this.modelsStats$ = this.modelFacadeService.getAll().pipe(
      map(models => {
        const relevantModels = _(models).filter(({ status }) => status !== 'Complete');
        return {
          count: relevantModels.value().length,
          stats: relevantModels.groupBy('status').value(),
        };
      })
    );

    this.calendarEvents$ = this.events$.pipe(map(events => this.formatEventsForCalendar(events)));
    this.models$ = this.eventSelect$.pipe(switchMap(({ id }) => (id ? this.modelFacadeService.getMasterModelsByEventID(id) : of(null))));

    this.eventModelsStats$ = this.models$.pipe(
      map(models => {
        const relevantModels = _(models).filter(({ status }) => status !== 'Complete');
        return {
          count: relevantModels.value().length,
          stats: relevantModels.groupBy('status').value(),
        };
      })
    );
  }

  onModelsSelected(ids: Array<number | string>) {
    this.modelFacadeService.selectModels(ids);
  }

  private formatEventsForCalendar(events: any[]): any[] {
    return events.map(event => {
      let color;
      const isPast = +moment() > +`${+moment(event.endDate)}`;
      /// this should be done with a class
      const isMarkdown = event.eventType === 'Markdown';
      if (isPast) {
        if (isMarkdown) {
          color = 'rgb(133,161,218)';
        } else if (!isMarkdown) {
          color = 'rgb(139,226,219)';
        }
      } else if (!isPast) {
        if (isMarkdown) {
          color = 'rgb(52,98,193)';
        } else if (!isMarkdown) {
          color = 'rgb(61,206,195)';
        }
      }
      return {
        // start: startOfDay(new Date(+`${event.start_date}000`)),
        start: startOfDay(moment(event.startDate).toDate()),
        end: addDays(endOfDay(new Date(event.endDate)), 1), // SBX-538
        title: event.name,
        allDay: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
        draggable: true,
        data: { ...event },
        color,
        classNames: [`event-${event.id}`, event.eventType.toLowerCase()],
      };
    });
  }
}
