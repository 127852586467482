import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { getOrgCurrency } from './user.reducer';

enum Currencies {
  EUR = '€',
  GBP = '£',
}

@Injectable({
  providedIn: 'root'
})
export class ConfigurationSettingsService {
  currency: string;

  constructor(private store: Store<any>) {
    this.store.pipe(select(getOrgCurrency)).subscribe(d => {
      this.currency = Currencies[d];
    });
  }
}
