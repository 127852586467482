import { Component, ChangeDetectionStrategy, OnInit, Output, EventEmitter, Input, ViewChild,
  AfterViewInit, ElementRef, Renderer2 } from '@angular/core';
import { FullCalendar } from 'primeng/fullcalendar';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, AfterViewInit {
  @Output()
  eventClick = new EventEmitter();

  @Output()
  dateClick = new EventEmitter();

  @Input()
  calendarEvents: any[];

  @Output()
  eventMouseEnter = new EventEmitter();

  @ViewChild('fc', { static: false }) fc: FullCalendar;

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2) { }

  ngAfterViewInit() {
    // change title of button from 'list' to 'day' (there is no api for this in primeng/fullcalender [would be buttonText])
    // this.fc.getCalendar().header.el.children[1].children[0].children[2].innerText = 'Day';
  }

  ngOnInit() { }

  onDateClick = (event) => {
    this.dateClick.emit({ x: event.jsEvent.x, y: event.jsEvent.y, start: event.start, end: event.end });
  }

  onEventClick = ({event}) => {
    document.querySelectorAll('.highlight-event').forEach((k) => k.classList.remove('highlight-event'));
    document.querySelectorAll(`.${event.classNames.join('.')}`).forEach((el) => {
      el.classList.add('highlight-event');
    });
    // event.el.classList.add('highlight-event');
    // if (event.event.def.extendedProps.data.eventType === 'MarkdownEvent') {
    //   event.el.classList.add('md');
    // } else {
    //   event.el.classList.add('promo');
    // }
    this.eventClick.emit(event.extendedProps.data);
  }

  onEventMouseEnter = (event) => {
    this.fc.el.nativeElement.querySelectorAll('.highlight-event').forEach((k) => k.classList.remove('highlight-event'));
    event.el.classList.add('highlight-event');
    this.eventMouseEnter.emit(event);
  }

  onEventMouseLeave = (event) => {
    this.fc.el.nativeElement.querySelectorAll('.highlight-event').forEach((k) => {
      k.classList.remove('highlight-event');
    });
    this.fc.el.nativeElement.querySelectorAll('.event-info').forEach(el => el.remove());
  }

  // tslint:disable-next-line:member-ordering
  options = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    header: {
      left: 'prev,title,next',
      center: '',
      // right: 'month,basicWeek,list'
      right: 'month',
    },
    height: () => {
      return document.getElementById('calendar-component-container').clientHeight;
    },
    selectable: true,
    select: this.onDateClick,
    eventClick: this.onEventClick,
    eventMouseEnter: this.onEventMouseEnter,
    eventMouseLeave: this.onEventMouseLeave,
    toolbar: {
      buttonText: {
        list: 'day'
      }
    },
    firstDay: 1 // SBX-537
  };
}
