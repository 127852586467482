import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, distinctUntilChanged, withLatestFrom, switchMap } from 'rxjs/operators';
import { EventsFacadeService } from '../../../events/events-facade.service';
import { ModelFacadeService } from '../../../model/model-facade.service';
import * as mapper from '../reporting-detail.mapper';
import { Router, ActivatedRoute } from '@angular/router';

export interface DiscountReportRow {
  event_id: number;
  current_price_sum: number;
  store_stock_units: number;
  final_price_sum: number;
  full_price_sum: number;
  sku_count: number;
  discount_bin: string;
  sku_type: string;
}

@Component({
  selector: 'app-reporting-detail-discount-summary',
  templateUrl: './reporting-detail-discount-summary.component.html',
  styleUrls: ['./reporting-detail-discount-summary.component.scss'],
})
export class ReportingDetailDiscountSummaryComponent implements OnInit, OnDestroy {
  @ViewChild('table', { read: ElementRef, static: false })
  public table;
  @Input()
  discountData;

  @Input()
  skuTypes;

  @Input()
  eventIDs;
  discountTypes = [];
  discountReportRows = [];
  regions = [];
  wave;
  discountReport$: Observable<DiscountReportRow[]>;

  componentDestroy$ = new Subject();
  toolTipDiscount: string;
  toolTipSkuCount: string;
  toolTipStoreStockUnits: string;
  toolTipStoreStockPct: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private eventsFacadeService: EventsFacadeService,
    private modelFacadeService: ModelFacadeService
  ) {}

  ngOnDestroy() {
    this.componentDestroy$.next();
  }

  ngOnInit() {
    this.skuTypes = ['New MD', 'Discounted', 'All'];
    this.toolTipDiscount = `Discounts applied to SKUs`;
    this.toolTipSkuCount = `Number of SKUs at each discount band`;
    this.toolTipStoreStockUnits = `Number of units in store stock at each discount band`;
    this.toolTipStoreStockPct = `Percentage of store stock at each discount band`;
    this.route.params
      .pipe(
        distinctUntilChanged(),
        withLatestFrom(this.modelFacadeService.getAll())
      )
      .subscribe(([{ id }, models]) => {
        this.wave = _.max(
          _(models)
            .filter(({ eventId }) => eventId === +id)
            .map(({ wave: modelWave }) => modelWave)
            .uniq()
            .value()
        );
        this.regions = _(models)
          .filter(({ eventId }) => eventId === +id)
          .map(({ region }) => region)
          .uniq()
          .value();
      });

    // todo: discountReportRows, discountTypes, and skuTypes should not be assigned as side-effects of the map operator
    this.discountReport$ = this.route.params.pipe(
      switchMap(({ id, wave, regions }) =>
        this.eventsFacadeService.getDiscountReportData({ eventIDs: [id], regions: regions.split(','), wave })
      ),
      map(data => {
        this.discountReportRows = [];
        const orderedDiscountTypes = _(data)
          .sortBy('discount_bin')
          .filter((d: any) => d.discount_bin !== 'FULL PRICE')
          .map((d: any) => d.discount_bin)
          .uniq()
          .value();
        this.discountTypes = ['FULL PRICE', ...orderedDiscountTypes];
        _(this.skuTypes).forEach(skuType => {
          _(this.discountTypes).forEach(discount_bin => {
            const dataByDiscount = data.filter((d: any) => d.discount_bin === discount_bin);
            const discountReportRow = {
              skuType: skuType,
              discount_bin: discount_bin,
              data: [
                {
                  sku_count: mapper.totalSkuTypeCount(dataByDiscount, skuType),
                  skuPct: mapper.skuPctByDiscount(data, dataByDiscount, skuType),
                  store_stock_units: mapper.totalStoreStockUnits(dataByDiscount, skuType),
                  storeStockPct: mapper.storeStockPctByDiscount(data, dataByDiscount, skuType),
                  allStockPct: mapper.pctOfAllStock(data, dataByDiscount, skuType),
                  fullAverageDiscount: mapper.fullPriceAverageDiscount(data, dataByDiscount, skuType),
                  currentAverageDiscount: mapper.currentPriceAverageDiscount(data, dataByDiscount, skuType),
                },
              ],
            };
            this.discountReportRows.push(discountReportRow);
          });
        });
        return this.discountReportRows;
      })
    );
    this.discountReport$.pipe(takeUntil(this.componentDestroy$)).subscribe(console.log);
  }
  getTotalData(type, column) {
    const values = _.first(
      _(this.discountReportRows)
        .filter(val => val.skuType === type)
        .value()
    );
    if (!values) {
      return 0;
    } else {
      return _.sum(values.data.map(d => d[column]));
    }
  }
  getData(type, discount, column) {
    const values = _.first(
      _(this.discountReportRows)
        .filter(val => val.skuType === type && val.discount_bin === discount)
        .value()
    );
    if (!values) {
      return 0;
    } else {
      return _.sum(values.data.map((d) => d[column]));
    }
  }
  getTotalSum(type, column) {
    const values =
      _(this.discountReportRows)
        .filter((val) => val.skuType === type).map(v => v.data).flatten()
        .value();
    if (!values) {
      return 0;
    } else {
      return _.sum(values.map((d) => d[column]));
    }
  }
  getSkuToolTip(type) {
    if (type === 'New MD') {
      return `Distribution of discounts for SKUs which have not previously been discounted`;
    } else if (type === 'Discounted') {
      return `Distribution of discounts for all SKUs which have been discounted`;
    } else {
      return `Distribution of discounts for all SKUs.`;
    }
  }
  getSkuPctToolTip(type) {
    if (type === 'New MD') {
      return `Number of SKUs by discount band as a % of the total new MD SKUs`;
    } else if (type === 'Discounted') {
      return `Number of SKUs at that discount band as a % of the total discounted SKUs`;
    } else {
      return `Number of SKUs at that discount band as a % of the total of all SKUs`;
    }
  }
}
