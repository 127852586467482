import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IOrg } from './state/org.reducer';
import { map } from 'rxjs/operators';
import { IUser } from '../../../user/user';

interface IGetOrgUsersResponse {
  id;
  name: string;
  products: string[];
  users: any[];
}

@Injectable({
  providedIn: 'root'
})
export class OrgService {

  constructor(private http: HttpClient) { }

  getOrgs(): Observable<IOrg[]> {
    return this.http.get<IOrg[]>('/api/org');
  }

  createOrg(data: { name: string, db_name: string, admin_user: string }): Observable<IOrg> {
    return this.http.post<IOrg>('/api/org', data);
  }

  createOrgUser(data: { email: string, role: string, org: number }): Observable<any> {
    return this.http.post<any>('/api/org/user', data)
      .pipe(map((response) => ({ ...response, orgID: data.org })));
  }

  getOrgUsers(): Observable<{id, users: any[]}> {
    return this.http.get<IGetOrgUsersResponse>('/api/org/users').pipe(map((response) => ({ id: response.id, users: [...response.users] })));
  }

  toggleUserActive(userID): Observable<IUser> {
    return this.http.post<IUser>(`/api/org/user/${userID}`, {});
  }

  updateUserRoles(params: {id: any, roles: string[]}): Observable<any> {
    return this.http.post<any>(`/api/org/user/${params.id}`, {roles: params.roles});
  }

}
