import * as fromUser from './user/user.reducer';
import * as fromOrg from './features/sb-admin/org/state/org.reducer';
import * as fromEvents from './features/events/state/events.reducer';
import * as fromNavigation from './infra/navigation/navigation.state';
import * as fromVariants from './features/variants/state/variants.reducer';
import * as fromPriceLadders from './features/settings/price-ladders/state/price-ladders.reducer';
import * as fromDiscountLadders from './features/settings/discount-ladders/state/discount-ladders.reducer';
///
import { ModelState, reducer } from './features/model/state/model.reducer';

import { getSelectors, RouterReducerState, routerReducer } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface State {
  user: fromUser.IUserState;
  org: fromOrg.IOrgState;
  events: fromEvents.IEventsState;
  navigation: fromNavigation.INavigationState;
  variants: fromVariants.IVariantsState;
  priceLadders: fromPriceLadders.IPriceLaddersState;
  discountLadders: fromDiscountLadders.IDiscountLaddersState;
  router: RouterReducerState;
  model: ModelState;
}

export const reducers = {
  user: fromUser.reducer,
  org: fromOrg.reducer,
  events: fromEvents.reducer,
  navigation: fromNavigation.reducer,
  variants: fromVariants.reducer,
  priceLadders: fromPriceLadders.reducer,
  discountLadders: fromDiscountLadders.reducer,
  router: routerReducer,
  model: reducer,
};

export const selectRouter = createFeatureSelector<State, RouterReducerState<any>>('router');

export const {
  selectQueryParams,
  selectRouteParams,
  selectRouteData,
  selectUrl,
} = getSelectors(selectRouter);
