import { Component, OnInit } from '@angular/core';

import { eventTypeRenderer, statusRenderer } from '../../events-grid/events-grid.component';
import { ReportingEventsGridNavCellComponent } from './reporting-events-grid-nav-cell/reporting-events-grid-nav-cell.component';
import { ReportingGridSelectCellComponent } from '../reporting-grid-select-cell/reporting-grid-select-cell.component';
// import * as moment from 'moment';
import { ColDef, GridOptions } from 'ag-grid-community';
import { ConfigurationSettingsService } from '../../../user/configuration-settings.service';
import { ReportingGridSelectService } from '../reporting-grid-select-cell/reporting-grid-select.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

export const eventDetailMapper = (currency) => ({ value }) => {
  const mapper = {
    'currency off': `${currency} off`,
  };
  if (mapper[value]) {
    return mapper[value];
  }

  return value;
};

@Component({
  selector: 'app-reporting-events-grid',
  templateUrl: './reporting-events-grid.component.html',
  styleUrls: ['./reporting-events-grid.component.scss']
})
export class ReportingEventsGridComponent implements OnInit {
  columns: ColDef[] = [
    { headerName: 'ID', field: 'id', minWidth: 55, maxWidth: 55 },
    { headerName: 'EVENT TYPE', field: 'eventType', cellRenderer: eventTypeRenderer, minWidth: 119, maxWidth: 119 },
    {
      headerName: 'EVENT DETAIL',
      field: 'eventDetail',
      valueFormatter: eventDetailMapper(this.configurationSettingsService.currency),
      minWidth: 135,
      maxWidth: 135,
    },
    { headerName: 'EVENT NAME', field: 'name' },
    // { headerName: 'LAST EDITED', field: 'lastEdited', valueFormatter: dateFormatter('DD/MM/YYYY k:mm') },
    // { headerName: 'LAST EDITED BY', field: 'lastEditUser' },
    { headerName: 'START DATE', field: 'startDate', valueFormatter: (params) => moment(params.value).format('DD/MM/YYYY') },
    { headerName: 'END DATE', field: 'endDate', valueFormatter: (params) => moment(params.value).format('DD/MM/YYYY') },
     //{ headerName: 'VARIANTS', field: 'variants' },
    { headerName: 'MODELS PUBLISHED', field: 'modelsPublished', minWidth: 172, maxWidth: 172, hide: true },
    { headerName: 'STATUS', field: 'status', cellRenderer: statusRenderer, minWidth: 119, maxWidth: 119 },
     { headerName: 'OWNER', field: 'owner'},
    { headerName: 'NOTE', field: 'note' },

    {
      headerName: '',
      cellRendererFramework: ReportingEventsGridNavCellComponent,
      suppressMenu: true,
      sortable: false,
      minWidth: 128,
      maxWidth: 128,
    },
  ];

  gridOptions: GridOptions = {
    rowHeight: 50,
    deltaRowDataMode: true,
    getRowNodeId: (data) => data.id,
    onRowDataUpdated: (e) => {
      e.api.sizeColumnsToFit();
    },
    onGridReady: (e) => {
      e.api.sizeColumnsToFit();
    }
  };

  constructor(private configurationSettingsService: ConfigurationSettingsService,
    public reportingGridSelectService: ReportingGridSelectService, private router: Router,
    private route: ActivatedRoute, ) { }

  ngOnInit() {
    this.reportingGridSelectService.selected = [];
  }
}
